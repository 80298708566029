import _ from "lodash";

export const getBaseUrl = () => {
  let baseUrl = window.location.origin;
  if (_.includes(baseUrl, "dev.fsekeygen.zeiss.com")) {
    baseUrl = `https://dev.fsekeygen.zeiss.com`;
  } else if (_.includes(baseUrl, "qa.fsekeygen.zeiss.com")) {
    baseUrl = `https://qa.fsekeygen.zeiss.com`;
  } else if (_.includes(baseUrl, "demo.fsekeygen.zeiss.com")) {
    baseUrl = `https://demo.fsekeygen.zeiss.com`;
  } else if (_.includes(baseUrl, "localhost")) {
    baseUrl = `http://localhost:3001`;
  } else if (_.includes(baseUrl, "stage-czm-servicekey.zeiss.com.cn")) {
    baseUrl = `https://stage-czm-servicekey.zeiss.com.cn`;
  } else {
    //baseUrl = `https://czm-servicekey.zeiss.com`;
    return baseUrl;
  }
  return baseUrl;
};

export const getXApiKey = () => {
  return process.env.REACT_APP_XAPI_KEY;
};

export const getESBApiBaseUrl = () => {
  // let baseUrl = window.location.origin;
  // if (_.includes(baseUrl, "dev.fsekeygen.zeiss.com")) {
  // 	baseUrl = process.env.REACT_APP_ESB_API_URL_STAGE;
  // } else if (_.includes(baseUrl, "qa.fsekeygen.zeiss.com")) {
  // 	baseUrl = process.env.REACT_APP_ESB_API_URL_STAGE;
  // } else if (_.includes(baseUrl, "demo.fsekeygen.zeiss.com")) {
  // 	baseUrl = process.env.REACT_APP_ESB_API_URL_STAGE;
  // } else if (_.includes(baseUrl, "localhost")) {
  // 	baseUrl = process.env.REACT_APP_ESB_API_URL_STAGE;
  // } else if (_.includes(baseUrl, "demo-czm-servicekey.zeiss.com.cn")) {
  // 	baseUrl = process.env.REACT_APP_ESB_API_URL_STAGE_CHINA;
  // } else {
  // 	baseUrl = process.env.REACT_APP_ESB_API_URL;
  // }
  return process.env.REACT_APP_ESB_API_URL;
};

export const getZeissIDAuthBaseUrl = () => {
  // let baseUrl = window.location.origin;
  // if (_.includes(baseUrl, "dev.fsekeygen.zeiss.com")) {
  // 	baseUrl = process.env.REACT_APP_ZEISSID_AUTH_URL_STAGE;
  // } else if (_.includes(baseUrl, "qa.fsekeygen.zeiss.com")) {
  // 	baseUrl = process.env.REACT_APP_ZEISSID_AUTH_URL_STAGE;
  // } else if (_.includes(baseUrl, "demo.fsekeygen.zeiss.com")) {
  // 	baseUrl = process.env.REACT_APP_ZEISSID_AUTH_URL_STAGE;
  // } else if (_.includes(baseUrl, "localhost")) {
  // 	baseUrl = process.env.REACT_APP_ZEISSID_AUTH_URL_STAGE;
  // } else if (_.includes(baseUrl, "demo-czm-servicekey.zeiss.com.cn")) {
  // 	baseUrl = process.env.REACT_APP_ZEISSID_AUTH_URL_STAGE_CHINA;
  // } else {
  // 	baseUrl = process.env.REACT_APP_ZEISSID_AUTH_URL;
  // }
  return process.env.REACT_APP_ZEISSID_AUTH_URL;
};

export const getZeissIDClientId = () => {
  // let baseUrl = window.location.origin;
  // if (_.includes(baseUrl, "dev.fsekeygen.zeiss.com")) {
  // 	baseUrl = process.env.REACT_APP_ZEISSID_CLIENTID_STAGE;
  // } else if (_.includes(baseUrl, "qa.fsekeygen.zeiss.com")) {
  // 	baseUrl = process.env.REACT_APP_ZEISSID_CLIENTID_STAGE;
  // } else if (_.includes(baseUrl, "demo.fsekeygen.zeiss.com")) {
  // 	baseUrl = process.env.REACT_APP_ZEISSID_CLIENTID_STAGE;
  // } else if (_.includes(baseUrl, "localhost")) {
  // 	baseUrl = process.env.REACT_APP_ZEISSID_CLIENTID_STAGE;
  // } else if (_.includes(baseUrl, "demo-czm-servicekey.zeiss.com.cn")) {
  // 	baseUrl = process.env.REACT_APP_ZEISSID_CLIENTID_STAGE_CHINA;
  // } else {
  // 	baseUrl = process.env.REACT_APP_ZEISSID_CLIENTID;
  // }
  return process.env.REACT_APP_ZEISSID_CLIENTID;
};

//Updated for ESB
export const getSubscription = () => {
  // let baseUrl = window.location.origin;
  // if (_.includes(baseUrl, "dev.fsekeygen.zeiss.com")) {
  // 	baseUrl = process.env.REACT_APP_ESB_SUBSCRIPTION_DEV_TEST_DEMO;
  // } else if (_.includes(baseUrl, "qa.fsekeygen.zeiss.com")) {
  // 	baseUrl = process.env.REACT_APP_ESB_SUBSCRIPTION_DEV_TEST_DEMO;
  // } else if (_.includes(baseUrl, "demo.fsekeygen.zeiss.com")) {
  // 	baseUrl = process.env.REACT_APP_ESB_SUBSCRIPTION_DEV_TEST_DEMO;
  // } else if (_.includes(baseUrl, "localhost")) {
  // 	baseUrl = process.env.REACT_APP_ESB_SUBSCRIPTION_DEV_TEST_DEMO;
  // } else if (_.includes(baseUrl, "demo-czm-servicekey.zeiss.com.cn")) {
  // 	baseUrl = process.env.REACT_APP_ESB_SUBSCRIPTION_DEV_TEST_DEMO_CHINA;
  // } else {
  // 	baseUrl = process.env.REACT_APP_ESB_SUBSCRIPTION;
  // }
  return process.env.REACT_APP_ESB_SUBSCRIPTION_KEY;
};

//Updated for ESB
export const getUserDetails = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/get-user-details`;
  //return `${esbApiUrl}/Login/GetUserDetails`;
};

//Updated for ESB
export const getKeyTypeBySkillUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/get-key-type-by-skill`;
  // return `${esbApiUrl}/Device/GetKeyTypeBySkill`;
};

//Updated for ESB
export const getDevicesByRoleUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/get-device-list/`;
  // return `${esbApiUrl}/Device/GetDeviceList/`;
};

//Updated for ESB
export const getDeviceByIdUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/get-device-by-id/`;
  // return `${esbApiUrl}/Device/GetDeviceById/`;
};

//Updated for ESB
export const getDeviceBySkillUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/get-devices-by-skill`;
  // return `${esbApiUrl}/Device/GetDevicesBySkill`;
};

//Updated for ESB
export const getHistoryDetailsUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/get-history-details`;
  // return `${esbApiUrl}/History/GetHistoryDetail`;
};

//Updated for ESB
//Todo 5. download log file
export const getLogDetailsUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/get-log-details`;
  // return `${esbApiUrl}/LogDetail/GetLogDetails`;
};

//Updated for ESB
export const searchSkillsUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/search-skill`;
  // return `${esbApiUrl}/Skill/SearchSkill`;
};

//Updated for ESB
export const addDeviceVersionUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/add-device-version`;
  // return `${esbApiUrl}/Device/AddDeviceVersion`;
};

//Updated for ESB
export const addSkillUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/add-skill`;
  // return `${esbApiUrl}/Skill/AddSkill`;
};

//Updated for ESB
export const getDeviceByNameUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/get-device-by-name/`;
  // return `${esbApiUrl}/Device/GetDeviceByName/`;
};

//Updated for ESB
export const getAddNewDeviceUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/add-device`;
  // return `${esbApiUrl}/Device/AddDevice`;
};

//TODO  Updated for ESB
//Todo 1. add disable skillset
export const disableDeviceSkillsetUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/Skill`; //TODO update this after confirmation
  // return `${esbApiUrl}/Skill/{id}/Disable`;
};

//Todo 2 add disable keytype
export const disableDeviceSkillsetKeyTypeUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/Device`; //TODO update this after confirmation
  // return `${esbApiUrl}/Device/{id}/DisableKeyType`;
};
//Todo 3 add disable device
export const disableDeviceUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/Device`; //TODO update this after confirmation
  // return `${esbApiUrl}/Device/{id}/Disable`;
};
//Todo 4. add for disable version
export const disableDeviceVersionUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/Device/DisableDeviceVersion`; //TODO update this after confirmation
  // return `${esbApiUrl}/Device/{id}/Disable`;
};
//Todo- 6  add to update email template
export const updatesEmailTemplateUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/EmailTemplate/UpdateEmailTemplate`; //TODO update this after confirmation
  // return `${esbApiUrl}/EmailTemplate/UpdateEmailTemplate`;
};
//Todo- 7  add to get email template
export const getEmailTemplateByIdUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/EmailTemplate/GetEmailTemplateById`; //TODO update this after confirmation
  // return `${esbApiUrl}/EmailTemplate/GetEmailTemplateById`;
};
//Todo 8 get email template list
//Updated for ESB
export const getEmailTemplatesUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/EmailTemplate/GetEmailTemplates`;
  // return `${esbApiUrl}/EmailTemplate/GetEmailTemplates/`;
};
//Todo- 9  add to get printout template
export const getPrintoutTemplateByIdUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/PrintoutTemplate/GetPrintoutTemplateById`; //TODO update this after confirmation
  // return `${esbApiUrl}/PrintoutTemplate/GetPrintoutTemplateById`;
};
//Todo- 10  add to update printout template
export const updatePrintoutTemplateUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/PrintoutTemplate/UpdatePrintoutTemplate`; //TODO update this after confirmation
  // return `${esbApiUrl}/PrintoutTemplate/UpdatePrintoutTemplate`;
};
//Todo- 11  add to get printout template list
export const getPrintoutTemplatesUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/PrintoutTemplate/GetPrintoutTemplates`; //TODO update this after confirmation
  // return `${esbApiUrl}/PrintoutTemplate/GetPrintoutTemplates`;
};
//Todo 12 get all request type list
export const getAllRequestTypeUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/RequestType/GetAllRequestType`; //TODO update this after confirmation
  // return `${esbApiUrl}/RequestType/GetAllRequestType`;
};
//Todo 13
export const sendNewRequestEmailUrl = () => {
  let esbApiUrl = getESBApiBaseUrl();
  return `${esbApiUrl}/RequestType/SendEmail`; //TODO update this after confirmation
  // return `${esbApiUrl}/RequestType/SendEmail`;
};

//Updated for ESB
export const getKeyServiceUrl = (id) => {
  let esbApiUrl = getESBApiBaseUrl();
  switch (id) {
    case 1: {
      return `${esbApiUrl}/master`;
      // return `${esbApiUrl}/KeyGenerator/GenerateMasterKey`;
    }
    case 2: {
      return `${esbApiUrl}/oneday`;
      // return `${esbApiUrl}/KeyGenerator/GenerateOneDayKey`;
    }
    case 3: {
      return `${esbApiUrl}/itadmin`;
      // return `${esbApiUrl}/KeyGenerator/GenerateITAdminKey`;
    }
    case 4: {
      return `${esbApiUrl}/oldservicepassword`;
      // return `${esbApiUrl}/KeyGenerator/GenerateOldServicePasswordKey`;
    }
    case 5: {
      return `${esbApiUrl}/biomed`;
      // return `${esbApiUrl}/KeyGenerator/GenerateBioMedKey`;
    }
    case 6: {
      return `${esbApiUrl}/factory`;
      // return `${esbApiUrl}/KeyGenerator/GenerateFactoryKey`;
    }
    default:
      return "";
  }
};

//Updated for ESB
export const getDownloadKeysUrl = (id) => {
  let esbApiUrl = getESBApiBaseUrl();
  switch (id) {
    case 1:
      return `${esbApiUrl}/download-key-file/FseKey`;
    // return `${esbApiUrl}/File/DownloadKey/FseKey`;
    case 2:
      return `${esbApiUrl}/download-key-file/OneDayKey`;
    // return `${esbApiUrl}/File/DownloadKey/OneDayKey`;
    case 3:
      return `${esbApiUrl}/download-key-file/ITAdminKey`;
    // return `${esbApiUrl}/File/DownloadKey/ITAdminKey`;
    case 4:
      return `${esbApiUrl}/download-key-file/OldServicePasswordKey`;
    // return `${esbApiUrl}/File/DownloadKey/OldServicePasswordKey`;
    case 5:
      return `${esbApiUrl}/download-key-file/BioMedKey`;
    // return `${esbApiUrl}/File/DownloadKey/BioMedKey`;
    case 6:
      return `${esbApiUrl}/download-key-file/FactoryKey`;
    //return `${esbApiUrl}/File/DownloadKey/FactoryKey`;
    default:
      return "";
  }
};
