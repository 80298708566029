import React from "react";
import useStyles from "./../KeyTypes/CommonStyle";
import { ZuiProgressRing } from "@zeiss/zui-react";

const Processing = ({ progressText }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div
        className={`${classes.progressRoot} ${classes.progress}`}
        role="progressbar"
        style={{ width: "40px", height: "40px" }}>
        <ZuiProgressRing size="m"></ZuiProgressRing>
      </div>
      {progressText ? (
        <div className={classes.progressText}>{"Please wait.."}</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Processing;
