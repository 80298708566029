/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import { bindActionCreators } from "redux";
import * as actions from "actions";
import { makeStyles } from "@material-ui/core/styles";
import { connect, useDispatch, useSelector } from "react-redux";
import { clearDeviceList } from "actions";
import { GetLogsDetails } from "./../../actions/index";
import RetrieveDataError from "./RetrieveDataError";
import WarnignModalPopup from "./../Modal/WarningModal";
import InternetDisconnected from "./../Modal/InternetDisconnected";
import LogFileDownloadModal from "./../Modal/LogFileDownloadModal";
import "@zeiss/zui";
import editIcon from "assets/icons/edit-icn.png";
import downloadIcon from "assets/icons/download-icon.png";
import useStyles from "./../KeyTypes/CommonStyle";
import "./Admin.css";
import "./../KeyTypes/CommonStyleSheet.css";
import { useHistory } from "react-router-dom";
import Processing from "./Processing";

const useTabStyle = makeStyles((theme) => ({
  divTabContainer: {
    position: "relative",
    display: "block",
    margin: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    color: "#07090D",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    background: "#FFFFFF",
  },
  divTabRoot: {
    display: "flex",
    overflow: "hidden",
    height: "48px",
  },
  divTabScroller: {
    flex: "1 1 auto",
    display: "inline-flex",
    position: "relative",
    whiteSpace: "nowrap",
  },
  divTab: {
    width: "50%",
    height: "100%",
    position: "relative",
    cursor: "pointer",
    margin: "auto",
  },
  divTabBar: {
    width: "100%",
    height: "4px",
    position: "absolute",
    margin: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: "#828D9E",
  },
  spanTabBarActive: {
    background: "#0050F2",
  },
  spanTabWrapper: {
    width: "100%",
    height: "100%",
    display: "inline-flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    font: "var(--zui-typography-caption)",
    fontSize: "12px !important",
    lineHeight: "16px !important",
    color: "#626C7A",
  },
  spanTabWrapperActive: {
    font: "var(--zui-typography-label1)",
    color: "#07090D",
  },
  divContainer: {
    width: "100%",
    height: "100%",
    position: "relative",
    [theme.breakpoints.down("md")]: { height: "84%" },
    [theme.breakpoints.up("lg")]: { height: "100%" },
  },
}));

const RenderDeviceListByTabs = ({ roleName, handleToggleRole }) => {
  const classes = useTabStyle();
  return (
    <>
      <div id="div-tab-container" className={`${classes.divTabContainer}`}>
        <div className={`${classes.divTabRoot}`}>
          <div className={`${classes.divTabScroller}`}>
            <div
              className={`${classes.divTab}`}
              onClick={() => {
                handleToggleRole("General");
              }}>
              <span
                className={`${classes.divTabBar} ${
                  roleName === "General" ? classes.spanTabBarActive : ""
                }`}></span>
              <span
                className={`${classes.spanTabWrapper} ${
                  roleName === "General" ? classes.spanTabWrapperActive : ""
                }`}>
                General
              </span>
            </div>
           {/* <div
              className={`${classes.divTab}`}
              onClick={() => {
                handleToggleRole("CIC");
              }}>
              <span
                className={`${classes.divTabBar} ${
                  roleName === "CIC" ? classes.spanTabBarActive : ""
                }`}></span>
              <span
                className={`${classes.spanTabWrapper} ${
                  roleName === "CIC" ? classes.spanTabWrapperActive : ""
                }`}>
                CIC
              </span>
              </div>*/}
            <div
              className={`${classes.divTab}`}
              onClick={() => {
                handleToggleRole("HQ");
              }}>
              <span
                className={`${classes.divTabBar} ${
                  roleName === "HQ" ? classes.spanTabBarActive : ""
                }`}></span>
              <span
                className={`${classes.spanTabWrapper} ${
                  roleName === "HQ" ? classes.spanTabWrapperActive : ""
                }`}>
                HQ
              </span>
            </div>
            {/*<div
              className={`${classes.divTab}`}
              onClick={() => {
                handleToggleRole("BIOMed");
              }}>
              <span
                className={`${classes.divTabBar} ${
                  roleName === "BIOMed" ? classes.spanTabBarActive : ""
                }`}></span>
              <span
                className={`${classes.spanTabWrapper} ${
                  roleName === "BIOMed" ? classes.spanTabWrapperActive : ""
                }`}>
                BIOMED
              </span>
              </div>*/}
          </div>
        </div>
      </div>
    </>
  );
};

const RenderDeviceList = ({
  roleName,
  deviceLists,
  history,
  sendDataToParent,
}) => {
  const classes = useStyles();
  return (
    <>
      <div
        id="divHistoryListSectionDesktop"
        className={`${classes.divDeviceListContainer} ${classes.divDeviceListTableContainer} `}>
        <table>
          <thead>
            <tr key={"DEVICELISTS"}>
              <th>
                <span className={`${classes.tableTextSpan}`}>{"DEVICES"}</span>
              </th>
              <th>
                <span className={`${classes.tableTextSpan}`}>{"IC CODE"}</span>
              </th>
              <th>
                <span className={`${classes.tableTextSpan}`}>
                  {"SOFTWARE VERSION"}
                </span>
              </th>
              <th>
                <span className={`${classes.tableTextSpan}`}>
                  {"KEY TYPES"}
                </span>
              </th>
              <th>
                <span className={`${classes.tableTextSpan}`}>
                  {"COURSE CODE"}
                </span>
              </th>
              <th>
                <span className={`${classes.tableTextSpan}`}>
                  {"SKILLSETS"}
                </span>
              </th>
              <th>
                <span className={`${classes.tableTextSpan}`}>{"ACTIONS"}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {deviceLists.length !== 0 ? (
              deviceLists.map((row, index) => {
                const isDisabled = row.device.isActive
                  ? ""
                  : classes.fadeDisplay;
                return (
                  <tr
                    key={`deviceList` + index.toString()}
                    style={{ font: "var(--zui-typography-body1)" }}
                    className={isDisabled}>
                    <td
                      valign="top"
                      style={{
                        width: "12%",
                        borderLeft: "1px solid #c1cbd9",
                        font: "var(--zui-typography-subtitle1)",
                      }}>
                      <span className={`${classes.tableTextSpan}`}>
                        {row.device.name}
                      </span>
                    </td>
                    <td valign="top" style={{ width: "8%" }}>
                      <span className={`${classes.tableTextSpan}`}>
                        {row.device.icCode}
                      </span>
                    </td>
                    <td valign="top" style={{ width: "12%" }}>
                      {row.deviceKeyGeneratorMappings.map((item, index) => {
                        return (
                          <span
                            key={`deviceVersion` + index.toString()}
                            className={`${classes.tableTextSpan}`}>{`${item.operator}${item.version}`}</span>
                        );
                      })}
                    </td>
                    <td valign="top" style={{ width: "20%" }}>
                      {row.keyTypes.map((item, index) => {
                        return (
                          <span
                            key={`deviceKeytpes` + index.toString()}
                            className={`${classes.tableTextSpan}`}>
                            {item.description}
                          </span>
                        );
                      })}
                    </td>
                    <td valign="top" style={{ width: "10%" }}>
                      {row.skills.length !== 0 ? (
                          row.skills.map((item, index) => {
                            return (
                              <span
                                key={`deviceSkills` + index.toString()}
                                className={`${classes.tableTextSpan}`}>
                                {item.courseCode}
                              </span>
                            );
                          })
                        ) : (
                          <span className={`${classes.tableTextSpan}`}>

                          </span>
                        )}
                    </td>
                    <td valign="top" style={{ width: "39%" }}>
                      {row.skills.length !== 0 ? (
                        row.skills.map((item, index) => {
                          return (
                            <span
                              key={`deviceSkills` + index.toString()}
                              className={`${classes.tableTextSpan}`}>
                              {item.name}
                            </span>
                          );
                        })
                      ) : (
                        <span className={`${classes.tableTextSpan}`}>
                          No Skillsets are enabled for this Device
                        </span>
                      )}
                    </td>
                    <td
                      style={{ width: "9%", borderRight: "1px solid #c1cbd9" }}
                      className={classes.minDownloadHeight}>
                      <span
                        className={`${classes.tableTextSpan}`}
                        style={{
                          display: "inline-block",
                          width: "auto",
                          margin: "20px",
                        }}>
                        <Tooltip title="Edit">
                          <IconButton
                            aria-label="Edit"
                            color="inherit"
                            style={{}}
                            onClick={() => {
                              sendDataToParent({
                                deviceCode: row.device.id,
                                role: roleName,
                              });
                            }}>
                            <img
                              height="14"
                              width="14"
                              src={editIcon}
                              alt="edit"
                              className="historyViewIcon"
                            />
                          </IconButton>
                        </Tooltip>
                      </span>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr key={"NoKeys"}>
                <td
                  colSpan={5}
                  align={"center"}
                  style={{ border: "1px solid #c1cbd9" }}>
                  {" No Devices "}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

const DeviceList = (props) => {
  localStorage.clear();
  sessionStorage.setItem("KeyTypeValue", 10);
  const classes = useStyles();
  const styles = useTabStyle();
  const { reduxState, reduxActions } = props;
  var role =
    props.location.state !== undefined && props.location.state !== null
      ? props.location.state.role
        ? props.location.state.role
        : "General"
      : "General";
  const { deviceListInfo } = reduxState;
  const { getDevicesByRole } = reduxActions;
  const [isDeviceListReady, setDeviceListReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRole, setRole] = useState(role);
  const [open, setOpen] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState(false);
  const [modalText, setModalText] = useState("");
  const [showPopUp, setshowPopUp] = useState(false);
  const [isTabChanged, setIsTabChanged] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state) => state.userAccount.tokenId);
  const [downloadStatus, setDownloadStatus] = useState(200);

  useEffect(() => {
    dispatch(clearDeviceList());
  }, [dispatch]);

  useEffect(() => {
    if (
      !isDeviceListReady &&
      deviceListInfo.fetched === "pending" &&
      !isTabChanged
    ) {
      getDevicesByRole(selectedRole).then(() => setIsLoading(false));
    }
    if (deviceListInfo.fetched === "fetched") {
      setDeviceListReady(true);
      setIsLoading(false);
    }
    if (
      deviceListInfo.isSuccess === false &&
      (deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize")
    ) {
      displayPopUp(deviceListInfo.errorMessage);
      setIsLoading(false);
    }
  }, [
    deviceListInfo,
    getDevicesByRole,
    isDeviceListReady,
    selectedRole,
    isTabChanged,
  ]);

  const redirectToDeviceView = async (state) => {
    setIsLoading(true);
    let onlineStatus = await actions.checkOnlineStatus();
    if (onlineStatus !== false) {
      setOnlineStatus(false);
      history.push({
        pathname: "/home/Devices/ViewDeviceDetails",
        state: state,
      });
    } else {
      setOnlineStatus(true);
      setIsLoading(false);
    }
  };
  const redirectToAddDevice = async () => {
    setIsLoading(true);
    let onlineStatus = await actions.checkOnlineStatus();
    if (onlineStatus !== false) {
      setOnlineStatus(false);
      history.push("/home/Devices/AddDevice");
    } else {
      setOnlineStatus(true);
      setIsLoading(false);
    }
  };

  const displayPopUp = (text) => {
    setModalText(text);
    setOpen(true);
  };

  const handleClose = () => {
    setIsLoading(true);
    setOpen(false);
    setModalText("");
    if (deviceListInfo.fetched === "unAuthorize") {
      logOut();
    } else if (downloadStatus === 401) {
      logOut();
    } else {
      setIsLoading(false);
    }
  };

  const closeOnlineStatus = () => {
    setOnlineStatus(false);
  };

  const logOut = () => {
    let path = window.location.origin + "/Logout";
    window.location.href = path;
  };

  const fetchDeviceListByRole = async (selectedRole) => {
    setIsLoading(true);
    setIsTabChanged(true);
    let onlineStatus = await actions.checkOnlineStatus();
    if (onlineStatus !== false) {
      setOnlineStatus(false);
      dispatch(clearDeviceList());
      setRole(selectedRole);
      await getDevicesByRole(selectedRole).then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
      setOnlineStatus(true);
    }
  };

  const displayDownloadPopUp = () => {
    setshowPopUp(true);
  };

  const handleCloseModal = () => {
    setIsLoading(true);
    setshowPopUp(false);
    if (deviceListInfo.fetched === "unAuthorize") {
      logOut();
    } else {
      setIsLoading(false);
    }
  };

  const handleDownloadLogFile = async ({ FromDate, ToDate }) => {
    setIsLoading(true);
    const filterParams = { FromDate, ToDate };
    let onlineStatus = await actions.checkOnlineStatus();
    if (onlineStatus !== false) {
      setOnlineStatus(false);
      const result = await GetLogsDetails(token, filterParams);
      if (result.status === false) {
        setIsLoading(false);
        setOpen(true);
        setModalText(result.message);
        setDownloadStatus(result.statusCode);
      } else {
        //get current date and time for logfilename : log_<date>_<time>.json
        let current = new Date();
        let cDate = `${current.getFullYear()}-${String(
          current.getMonth() + 1
        ).padStart(2, "0")}-${String(current.getDate()).padStart(2, "0")}`;
        let cTime = `${current.getHours()}-${String(
          current.getMinutes()
        ).padStart(2, "0")}-${String(current.getSeconds()).padStart(2, "0")}`;
        let dateTime = `${cDate}_${cTime}`;
        let filename = `log_${dateTime}.json`;

        let contentType = `application/json;charset=utf-8;`;
        var link = document.createElement("a");
        link.download = filename;
        link.href = `data:${contentType},${encodeURIComponent(
          JSON.stringify(result.data)
        )}`;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      setOnlineStatus(true);
      setDownloadStatus(200);
    }
    setshowPopUp(false);
  };

  return isLoading || deviceListInfo.fetched === "pending" ? (
    <Processing progressText={true} />
  ) : deviceListInfo.fetched === "fulfilled" ? (
    <>
      <div
        id="div_AdminDeviceList_Conainer"
        className={`${styles.divContainer}`}
        style={{ margin: "8px 8px 0px 8px" }}>
        <div
          id="div_selection"
          className={`${classes.divHeaderContainer} ${classes.displayFlex}`}>
          <div className={`${classes.AdminHeaderText}`}>{"Devices"}</div>
          <div id="form_Select_all" className={classes.divSelectAll}>
            <Tooltip title="Download log files">
              <IconButton
                aria-label="logs"
                color="inherit"
                className={``}
                style={{
                  borderRadius: "3px",
                  border: "1px solid #C1CBD9",
                  background: " #EDF2F7",
                  marginRight: "8px",
                  marginTop: "8px",
                  boxSizing: "border-box",
                  height: "32px",
                  width: "32px",
                }}
                onClick={displayDownloadPopUp}>
                <img height="16" width="16" src={downloadIcon} alt="logs" />
              </IconButton>
            </Tooltip>
            <zui-button
              emphasis="active-primary"
              onClick={() => redirectToAddDevice()}>
              {"Add a Device"}
            </zui-button>
          </div>
        </div>
        <RenderDeviceListByTabs
          roleName={selectedRole}
          handleToggleRole={fetchDeviceListByRole}></RenderDeviceListByTabs>
        <div
          className={`divAdminDeviceListContainerHeight ${classes.divAdminDeviceListContainer}`}>
          <RenderDeviceList
            roleName={selectedRole}
            deviceLists={deviceListInfo.deviceListByRole}
            history={history}
            sendDataToParent={(state) =>
              redirectToDeviceView(state)
            }></RenderDeviceList>
        </div>
        <div id="downloadModalPopup">
          <LogFileDownloadModal
            state={showPopUp}
            clickClose={handleCloseModal}
            downloadFile={handleDownloadLogFile}
          />
        </div>
        <div id="fseModalPopup">
          <WarnignModalPopup
            state={open}
            title={modalText}
            clickClose={handleClose}
          />
        </div>
        <InternetDisconnected
          state={onlineStatus}
          clickClose={closeOnlineStatus}></InternetDisconnected>
      </div>
    </>
  ) : (
    <>
      {(deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize") &&
      open ? (
        <div id="fseModalPopup">
          <WarnignModalPopup
            state={open}
            title={modalText}
            clickClose={handleClose}
          />
        </div>
      ) : (
        <RetrieveDataError />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      getDevicesByRole: actions.GetAllDevices,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  reduxState: {
    deviceListInfo: state.deviceListInfo,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceList);
