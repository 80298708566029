/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import zeiss_logo from "assets/images/zeiss_logo.svg";
import NativeSelect from "@material-ui/core/NativeSelect";
import { useHistory } from "react-router-dom";
import { setloggedOut, clearDeviceList } from "actions";
import { useDispatch } from "react-redux";
import useWindowSize from "actions/useWindowDimensions/useWindowSize";
import Background from "./../../assets/images/zeiss_cc_18.12.2014_g6465.jpg";
import "./loginPage.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "#fff",
    width: "100%",
    minHeight: "560px",
    backgroundSize: "cover",
    backgroundPosition: "initial",
    background: "#FFFFFF",
    height: "100% !important",
    [theme.breakpoints.up("md")]: {
      backgroundImage: "none",
    },
  },
  gridContainer: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("xs")]: {
      display: "grid",
      gridAutoRows: "min-content",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "self-start",
    },
  },
  row: {
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "self-start",
    },
  },
  formControl: {
    //margin: theme.spacing(1),
    width: 360,
    marginTop: 16,
  },
  selectEmpty: {
    marginTop: "50px",
    fontSize: "14px",
    lineHeight: "20px",
    font: "var(--zui-typography-body)",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#828D9E",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
      width: "100%",
      height: "100%",
    },
  },
  sectionMobileFormScreen: {
    //boxShadow: "1px 1px 5px 5px #e2e2ea",
    [theme.breakpoints.up("md")]: {
      boxShadow: "none",
    },
  },
  bgScreen: {
    textAlign: "center",
    background: "#f5f7fa",
  },
  background_img: {
    backgroundSize: "cover",
    backgroundPosition: "initial",
    backgroundImage: `url(${Background})`,
  },
  background_height: {
    width: "100%",
    height: "100%",
    minHeight: "580px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "640px",
      background: "#FFFFFF",
    },
  },
  bgRightScreen: {
    height: "100%",
    position: "relative",
    minWidth: "300px",
    maxWidth: "375px",
    minHeight: "300px",
    // height:"calc(100% - 100px)",
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    padding: "10px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      margin: "20px auto 20px",
    },
  },
  zeissIcon: {
    textAlign: "start",
    width: "100px",
    height: "100px",
    // position: "relative",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
    },
  },
  zeissIconWidth: {
    position: "absolute",
    margin: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "60px",
    },
  },
  applicationText: {
    font: "var(--zui-typography-h2)",
    color: "#353D45",
    fontSize: "24px",
    lineHeight: "36px",
    paddingBottom: "10px",
    marginTop: "20px",
    [theme.breakpoints.up("md")]: {
      marginTop: "70px",
    },
  },
  footerContainerLogin: {
    width: "100%",
    minWidth: "300px",
    maxWidth: "355px",
    zIndex: "20",
    textAlign: "center",
    // bottom:"20px",
    height: "70px",
    [theme.breakpoints.down("xs")]: {
      position: "relative",
    },
    [theme.breakpoints.down("md")]: {
      position: "relative",
    },
    // [theme.breakpoints.up("xl")]: {
    //   height: "96px",
    // },
  },
  versionText: {
    display: "block",
    position: "relative",
    font: "var(--zui-typography-label2)",
    color: "#353D45",
    fontSize: "14px",
    lineHeight: "20px",
    margin: "10px 0px",
  },
  regulatoryInfo: {
    display: "block",
    position: "relative",
    color: "#353D45",
    fontSize: "14px",
    lineHeight: "20px",
    margin: "10px 0px",
  },
  displayAssistance: {
    display: "block",
    position: "relative",
    maxHeight: "70px",
    overflow: "hidden",
    textAlign: "Center",
    lineHeight: "20px",
    lineBreak: "anywhere",
    margin: "40px 50px",
  },
  Typography: {
    font: "var(--zui-typography-subtitle1)",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "center",
    alignItems: "center",
    color: "#FFFFFF",
  },
  ProductInfoTypography: {
    font: "var(--zui-typography-label1)",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#626C7A",
  },
  TypographyVersion: {
    fontWeight: "Bold",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#353D45",
    textTransform: "uppercase",
  },
  RegulatoryInfoTypography: {
    font: "var(--zui-typography-subtitle1)",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0em",
    margin: "0px 10px",
    borderBottom: "1px solid #828D9E",
    color: "#828D9E",
    cursor: "pointer",
  },
  zeissButton: {
    color: "white",
    fontWeight: "500",
    width: "100%",
    transition: "opacity 0.25s ease-out",
    border: "0",
    height: "50px",
    left: "0px",
    marginTop: "90px",
    borderRadius: "3px",
    background: "#828D9E",
    cursor: "pointer",
  },
}));

const LoginPageComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const [language, setLanguage] = useState("en-US");
  const [height, width] = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch(setloggedOut());
    dispatch(clearDeviceList());
  }, []);

  // const handleChange = (event) => {
  //   const name = event.target.name;
  //   if (name) {
  //     // setError(false);
  //   }
  //   setState({
  //     ...state,
  //     [name]: event.target.value,
  //   });
  // };
  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  const onClickLogin = () => {
    history.push("/Login");
  };

  return (
    <>
      <div
        id="divContainer"
        style={{ height: height, maxWidth: width, minHeight: "578px" }}
        className={classes.root}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className={`${classes.gridContainer}`}>
          <div className={classes.zeissIcon}>
            <img
              height="80"
              width="80"
              src={zeiss_logo}
              alt="zeissIcon"
              className={classes.zeissIconWidth}
            />
          </div>
          <Grid
            className={`${classes.sectionDesktop} ${classes.background_height}`}
            item
            xs={6}
            sm={6}
            md={6}
            lg={7}
            xl={8}>
            <div
              className={`${classes.bgScreen} ${classes.background_img} ${classes.background_height}`}></div>
          </Grid>
          <Grid
            className={`${classes.sectionMobile} ${classes.background_height}`}
            item
            xs={12}
            sm={12}
            md={6}
            lg={5}
            xl={4}>
            <div id="divCulture" className={classes.background_height}>
              <div
                className={`${classes.bgRightScreen} ${classes.sectionMobileFormScreen}`}>
                <div
                  id="divCultureForm"
                  style={{ position: "relative", height: "100%" }}>
                  <div className={`${classes.row} ${classes.applicationText}`}>
                    {"CZM Service Password Portal"}
                  </div>
                  <NativeSelect
                    value={language}
                    onChange={handleChange}
                    name="language"
                    className={classes.selectEmpty}
                    style={{ width: "100%", padding: "0px" }}
                    placeholder="Select Language">
                    {/* <option value="" >Select Language</option> */}
                    <option value="en-US">English</option>
                    <option value="de-DE">German</option>
                  </NativeSelect>
                  <button
                    environment="auto"
                    className={`${classes.zeissButton} ${classes.Typography}`}
                    size="l"
                    onClick={onClickLogin}>
                    Next
                  </button>
                  <div
                    id="divAssistance"
                    className={`${classes.displayAssistance}`}>
                    <div
                      style={{
                        display: "inline-flex",
                        flexDirection: "column",
                      }}>
                      <span
                        style={{
                          color: "#626C7A",
                          fontSize: "12px",
                          font: "var(--zui-typography-label1)",
                        }}>{`Need assistance ? write to us`}</span>
                      <span
                        style={{
                          color: "#5C9DFF",
                          fontSize: "14px",
                          font: "var(--zui-typography-subtitle1)",
                        }}>{`support.servicekey@zeiss.com`}</span>
                      
                    </div>
                  </div>

                  <div
                    id="loginFooter"
                    className={`${classes.footerContainerLogin}`}>
                    <div className={`${classes.versionText}`}>
                      <label className={` ${classes.ProductInfoTypography}`}>
                        {"Product info:"}
                      </label>
                      <span className={` ${classes.TypographyVersion}`}>
                        VERSION: {process.env.REACT_APP_VERSION}
                      </span>
                    </div>
                    <div className={`${classes.regulatoryInfo}`}>
                      <a
                        className={`data-protection ${classes.RegulatoryInfoTypography}`}
                        href="https://www.zeiss.com/meditec-ag/legal-information/company-information.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        //onClick={() => window.open('/data-protection', 'newtab')}
                      >
                        {"Publisher"}
                        {/* <label  className={` ${classes.RegulatoryInfoTypography}`}>

                 </label> */}
                      </a>
                      {/* <a
                  className={`data-protection ${classes.RegulatoryInfoTypography}`}
                  href = '/terms-and-conditions'
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ outline: "none" }}
                  //onClick={() => window.open('/terms-and-conditions', 'newwindow')}


                >
                  {"Terms & Conditions"}
                </a> */}
                      {/* <label  className={` ${classes.RegulatoryInfoTypography}`}>

                </label> */}

                      <a
                        className={`data-protection ${classes.RegulatoryInfoTypography}`}
                        href="/data-protection"
                        target="_blank"
                        rel="noopener noreferrer"
                        //onClick={() => window.open('/data-protection', 'newtab')}
                      >
                        {"Data Protection"}
                      </a>
                      {/* <label  className={` ${classes.RegulatoryInfoTypography}`}>

                </label> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LoginPageComponent;
