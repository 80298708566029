import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import * as actions from "actions";
import { clearDeviceList, checkOnlineStatus } from "actions";
import { DisableDeviceSkillsetKeyType } from "./../../actions/index";
import RetrieveDataError from "./RetrieveDataError";
import {} from "@material-ui/core";
import WarnignModalPopup from "../Modal/WarningModal";
import { ZuiButton, ZuiToggleSwitch } from "@zeiss/zui-react";
import {} from "@zeiss/zui";
import useStyles from "../KeyTypes/CommonStyle";
import "./Template.css";
import InternetDisconnected from "../Modal/InternetDisconnected";
import Processing from "./Processing";

const DisableKeytype = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { reduxState, reduxActions } = props;
  const { deviceListInfo } = reduxState;
  const { GetDeviceByDeviceId } = reduxActions;

  const [updateKeytype, setUpdateKeytype] = useState("pending");
  const [isDeviceListReady, setDeviceListReady] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [onlineStatus, setOnlineStatus] = useState(false);

  const [isActiveState, setIsActiveState] = useState("Enabled");
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);
  const token = useSelector((state) => state.userAccount.tokenId);

  const deviceState = props.location.state;
  const deviceCode = deviceState !== undefined ? deviceState.deviceCode : null;
  const role = deviceState !== undefined ? deviceState.role : undefined;
  const skillsetName =
    deviceState !== undefined ? deviceState.skillsetName : undefined;
  const skillsetId =
    deviceState !== undefined ? deviceState.skillsetId : undefined;
  const skillcourseCode =
    deviceState !== undefined ? deviceState.skillCourseCode : undefined;
  

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearDeviceList());
    setIsLoading(true);
  }, [dispatch]);

  useEffect(() => {
    if (
      (!isDeviceListReady && deviceListInfo.fetched === "pending") ||
      (isActiveState !== "" && isUpdateSuccess)
    ) {
      GetDeviceByDeviceId(deviceCode, role);
      setIsLoading(false);
      setIsUpdateSuccess(false);
    }
    if (deviceListInfo.fetched === "fetched") {
      setDeviceListReady(true);
      setIsLoading(false);
    }
    if (
      deviceListInfo.isSuccess === false &&
      (deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize")
    ) {
      displayPopUp(deviceListInfo.errorMessage);
      setIsLoading(false);
    }
  }, [
    GetDeviceByDeviceId,
    deviceCode,
    deviceListInfo,
    isDeviceListReady,
    role,
    isActiveState,
    isUpdateSuccess,
  ]);

  const displayPopUp = (text) => {
    setErrorText(text);
    setIsError(true);
  };

  const handleClose = () => {
    setIsLoading(true);
    setIsError(false);
    setErrorText("");
    if (
      deviceListInfo.fetched === "unAuthorize" ||
      updateKeytype === "unAuthorize"
    ) {
      logOut();
    } else {
      setIsLoading(false);
      setUpdateKeytype("fulfilled");
    }
  };

  const logOut = () => {
    let path = window.location.origin + "/Logout";
    window.location.href = path;
  };

  const closeOnlineStatus = () => {
    setOnlineStatus(false);
  };

  const updateDisableKeytype = async ({ skillId, keyType, isActive }) => {
    const deviceId = deviceListInfo.deviceListByID.id;
    setIsLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      //KeyType give enum value and number keyType.id
      const result = await DisableDeviceSkillsetKeyType(token, {
        deviceId,
        skillId,
        keyType,
        isActive,
      });
      if (result.status === true) {
        setIsUpdateSuccess(true);
        setIsActiveState("");
      } else {
        if (result.statusCode === 401) {
          setUpdateKeytype("unAuthorize");
          displayPopUp(result.message);
        } else if (result.statusCode === 400) {
          //Reload the page as the data already exists in server
          setIsUpdateSuccess(true);
          setIsActiveState("");
        } else {
          setUpdateKeytype("failed");
          displayPopUp(result.message);
        }
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      setOnlineStatus(true);
    }
  };

  return isLoading || deviceListInfo.fetched === "pending" ? (
    <Processing progressText={true} />
  ) : deviceListInfo.fetched === "fulfilled" ? (
    <div className={classes.mainBodyContainer}>
      <div className="deviceNameContainer">
        <p className="headParagraph">Device: </p>
        <p className="headParagraphUpper">
          {deviceListInfo.deviceListByID.name}
          <span className="headSpan">
            ({deviceListInfo.deviceListByID.icCode})
          </span>
        </p>
      </div>
      <div className="deviceNameContainer">
        <p className="headParagraph">Skillset: </p>
        <p className="headParagraphUpper">{skillsetName} | {skillcourseCode} </p>
      </div>
      <div className={classes.deviceHeading}>{"Disable Skillset Keytypes"}</div>
      <div className="deviceKeytypeBodyContainer">
        {deviceListInfo.deviceListByID.skills.map((item, index) => {
          const disableAll = item.isActive ? false : true;
          return (
            item.id === skillsetId && (
              <>
                {item.keyTypes.map((keyType, index) => {
                  const isDisabled = keyType.isActive
                    ? ""
                    : classes.fadeDisplay;
                  return (
                    <div
                      key={"keyTypeDiv" + index}
                      className={`${isDisabled} deviceBodyItemDiv`}>
                      {`${keyType.description}`}
                      <ZuiToggleSwitch
                        key={"keyType" + index}
                        name={keyType.description}
                        disabled={disableAll}
                        value={keyType.isActive}
                        emphasis="primary"
                        onChange={(e) => {
                          const isActiveValue = item.isActive
                            ? "Enabled"
                            : "Disabled";
                          setIsActiveState(isActiveValue);
                          updateDisableKeytype({
                            skillId: item.id,
                            keyType: keyType.id,
                            isActive: e.target.value,
                          });
                        }}></ZuiToggleSwitch>
                    </div>
                  );
                })}
              </>
            )
          );
        })}
      </div>
      <div className="footerContainer">
        <ZuiButton
          size="s"
          title="Back"
          environment="auto"
          className="footerBackButton"
          onClick={() => {
            history.push({
              pathname: "/home/Devices/ViewDeviceDetails",
              state: { deviceCode: deviceCode, role: role },
            });
          }}>
          {"Back"}
        </ZuiButton>
      </div>
      <div id="fseModalPopup">
        <WarnignModalPopup
          state={isError}
          title={errorText}
          clickClose={handleClose}
        />
      </div>
      <InternetDisconnected
        state={onlineStatus}
        clickClose={closeOnlineStatus}></InternetDisconnected>
    </div>
  ) : (
    <>
      {(updateKeytype === "failed" ||
        updateKeytype === "unAuthorize" ||
        deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize") &&
      isError ? (
        <div id="fseModalPopup">
          <WarnignModalPopup
            state={isError}
            title={errorText}
            clickClose={handleClose}
          />
        </div>
      ) : (
        <RetrieveDataError />
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      GetDeviceByDeviceId: actions.GetDeviceByDeviceCode,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  reduxState: {
    deviceListInfo: state.deviceListInfo,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DisableKeytype);
