import axios from "axios";
import * as ErrorMessage from "../../../constants/errorMessages";
import {
  // disableDeviceSkillsetKeyTypeUrl,
  // getSubscription,
  getBaseUrl,
} from "Utils/getBaseUrl";

export const DisableDeviceSkillsetKeyType = (token, skillDetails) => {
  const id = skillDetails.deviceId; //todo: get this from skillDetails
  let url = getBaseUrl();
  if (url === "http://localhost:3001") {
    url = "https://qa.fsekeygen.zeiss.com";
  }
  // const getDisableKeyTypeUrl =
  //   disableDeviceSkillsetKeyTypeUrl() + id + "/DisableKeyType";
  // const esbSubscriptionKey = getSubscription();
  const getDisableKeyTypeUrl = `${url}/fsekeygenservice/api/v1/Device/${id}/DisableKeyType`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": `application/json`,
      "Access-Control-Allow-Origin": "*",
      // "EsbApi-Subscription-Key": esbSubscriptionKey,
    },
  };

  return axios
    .create(config)
    .put(`${getDisableKeyTypeUrl}`, skillDetails)
    .then((res) => {
      if (res.status === 200) {
        if (res.data) {
          return {
            status: true,
            statusCode: 200,
            message: "KeyType Disabled successfully",
          };
        } else {
          return {
            status: false,
            statusCode: 200,
            message: "Unable to Disable KeyType",
          };
        }
      } else if (res.status === 401) {
        return {
          status: false,
          statusCode: 401,
          message: ErrorMessage.SESSION_TIMEOUT_ERROR,
        };
      } else if (res.status === 400) {
        return {
          status: false,
          statusCode: 400,
          message: res.error.message,
        };
      } else {
        return {
          status: false,
          statusCode: 400,
          message: ErrorMessage.BAD_REQUEST,
        };
      }
    })
    .catch((error) => {
      let status = error;
      if (error.response) {
        status = error.response.status;
        if (status === 401) {
          return {
            status: false,
            statusCode: 401,
            message: ErrorMessage.SESSION_TIMEOUT_ERROR,
          };
        } else {
          return {
            status: false,
            statusCode: status,
            message: error.response.data.error.message,
          };
        }
      } else if (error.request) {
        return {
          status: false,
          statusCode: 401,
          message: ErrorMessage.NETWORK_ERROR,
        };
      } else {
        return {
          status: false,
          statusCode: 401,
          message: ErrorMessage.NETWORK_ERROR,
        };
      }
    });
};
