import React, {useEffect, useState }  from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import WarnignModalPopup from "./../Components/Modal/WarningModal";

const PrivateRoute = ({ component: Component, auth, isUserAccountFailed, isKeyTypesFetchedFailed, message ,keyTypeInfoMessage, deviceListInfoMessage, ...rest }) => {
  const history = useHistory();
  localStorage.setItem("requestPath",window.location.pathname);
  const [open, setOpen] = useState(true);
  const [modalText, setModalText] = useState("");
  useEffect(() => {
    if(auth){
      localStorage.clear();
      }
    if(message !== "")
    {
      setModalText(message);
    }
    else if(keyTypeInfoMessage !== "")
    {
      setModalText(keyTypeInfoMessage);
    }
    else if(deviceListInfoMessage !== "")
    {
      setModalText(deviceListInfoMessage);
    }
  }, [deviceListInfoMessage, message, keyTypeInfoMessage, auth]);

  const handleClose = () => {
    setOpen(false);
    setModalText("");
    logOut();
  };

  const logOut = () => {
    history.push("/Logout");
  };

  return (
    !auth ?
    <Redirect to="/Login" />
    :
  <Route
    {...rest}
    render={(props) => (auth && !isUserAccountFailed && !isKeyTypesFetchedFailed ? <Component {...props} /> : 
      <WarnignModalPopup state={open} title={modalText} clickClose={handleClose}/>
      )}
  />
)};

PrivateRoute.prototype = {
  auth: PropTypes.object.isRequired,
  isUserAccountFailed: PropTypes.object.isRequired,
  isKeyTypesFetchedFailed:  PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.userAccount.loggedIn,
  isUserAccountFailed: (state.userAccount.fetched === "failed" || state.userAccount.fetched === "unAuthorize") ? true : false,
  isKeyTypesFetchedFailed: (state.layoutInfo.fetched === "failed" || state.userAccount.fetched === "unAuthorize") ? true : false,
  message: (state.userAccount.fetched === "failed" || state.userAccount.fetched === "unAuthorize") ? state.userAccount.message : "" ,
  keyTypeInfoMessage: (state.layoutInfo.fetched === "failed" || state.layoutInfo.fetched === "unAuthorize") ? state.layoutInfo.message : "",
  deviceListInfoMessage: (state.deviceListInfo.fetched === "failed" || state.deviceListInfo.fetched === "unAuthorize") ? state.deviceListInfo.errorMessage : "" ,
});

export default connect(mapStateToProps)(PrivateRoute);