import { combineReducers } from "redux";
import userAccount from "./../reducer/userAccount";
import layoutInfo from "./../reducer/layoutInfo";
import deviceListInfo from "./../reducer/deviceListInfo"
import errorLogs from "./../reducer/errorlogs"
import settings from "./../reducer/settings";
export const makeRootReducer = (asyncReducers) => {
  return combineReducers({
    userAccount,
    layoutInfo,
    deviceListInfo,
    errorLogs,
    settings,
    ...asyncReducers,
  });
};

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
