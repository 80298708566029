import "./App.css";
import { Route, Switch, Link } from "react-router-dom";
import Login from "./Components/Login/Login";
import Authenticate from "./Components/Authenticate/Authenticate";
import PrivateRoute from "./routes/PrivateRoute";
import LoginPageComponent from "./Components/Dashboard/LoginPage";
import Home from "./Components/Home/Home";
import Logout from "./Components/Login/Logout";
//import TermsAndConditionComponent from "./Components/legalWriter/termsAndCondition/termsAndCondition";
import DataProtectionComponent from "./Components/legalWriter/dataProtection/dataProtection";
import RaiseRequest from "Components/RaiseRequest/RaiseRequest";

function App(props) {
  return (
    <Switch>
      <Route exact path="/" component={LoginPageComponent} />
      <Route exact path="/Login" component={Login} />
      <Route exact path="/Logout" component={Logout} />
      <Route exact path="/authenticate" component={Authenticate} />
      {/* <Route
          exact
          path="/terms-and-conditions"
          component={TermsAndConditionComponent}
        /> */}
      <Route
        exact
        path="/data-protection"
        component={DataProtectionComponent}
      />
      
      <PrivateRoute path="/home" component={Home} />
      <Route path="*">
        <div>Sorry!! the page you are looking for is not found</div>
        <Link to="/authenticate">return</Link>
      </Route>
    </Switch>
  );
}

export default App;
