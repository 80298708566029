import { getSubscription, getDownloadKeysUrl } from "../../Utils/getBaseUrl";
import axios from "axios";

const GetlocalDatetAsJSON = () => {
	const date = new Date();
	let hoursDiff = date.getHours() - date.getTimezoneOffset() / 60;
	let minutesDiff = date.getMinutes() - (date.getTimezoneOffset() % 60);

	date.setHours(hoursDiff);
	date.setMinutes(minutesDiff);
	return date.toISOString().split(".")[0] + "Z";
};

export function DownloadRequestmapper(keysData) {
	let date = GetlocalDatetAsJSON();
	return { keyGeneratorResponse: keysData, createdDate: date };
}

export const DownloadKey = (keysData, keyType) => (dispatch, getState) => {
	const state = getState();
	const token = state.userAccount.stateValue;
	let downloadKeysUrl = getDownloadKeysUrl(keyType);
	let payload = DownloadRequestmapper(keysData);
	const esbSubscriptionKey = getSubscription();
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": `application/json`,
			"Access-Control-Allow-Origin": "*",
			"EsbApi-Subscription-Key": esbSubscriptionKey,
		},
	};

	return axios
		.create(config)
		.post(`${downloadKeysUrl}`, payload)
		.then((res) => {
			if (res.status === 200) {
				if (res.data) {
					return {
						status: true,
						statusCode: 200,
						data: res.data,
					};
				} else {
					return {
						status: false,
						statusCode: 200,
						message: "Unable to download file",
					};
				}
			} else if (res.status === 401) {
				return {
					status: false,
					statusCode: 401,
					message:
						"Access is denied due to the session timeout. Please login again.",
				};
			} else {
				return {
					status: false,
					statusCode: 400,
					message: "Unable to download file",
				};
			}
		})
		.catch((error) => {
			let status = error;
			if (error.response) {
				status = error.response.status;
				if (status === 401) {
					return {
						status: false,
						statusCode: 401,
						message:
							"Access is denied due to the session timeout. Please login again.",
					};
				} else {
					return {
						status: false,
						statusCode: status,
						message: "Unable to download file",
					};
				}
			} else if (error.request) {
				return {
					status: false,
					statusCode: 401,
					message:
						"Your request could not be processed due to Network Interruption. Please login again.",
				};
			} else {
				return {
					status: false,
					statusCode: 401,
					message:
						"Your request could not be processed due to Network Interruption. Please login again.",
				};
			}
		});
};

export const validateUrl = async (url) => {
	const config = {
		headers: {
			"Content-Type": `application/json`,
		},
	};
	return axios
		.create(config)
		.get(`${url}`)
		.then((res) => {
			if (res.status === 200) {
				if (res.data) {
					return {
						status: true,
						statusCode: 200,
					};
				} else {
					return {
						status: false,
						statusCode: 200,
						message: "Unable to download file",
					};
				}
			} else if (res.status >= 401) {
				return {
					status: false,
					statusCode: 401,
					message:
						"Access is denied due to the session timeout. Please login again.",
				};
			} else {
				return {
					status: false,
					statusCode: 400,
					message: "Unable to download file",
				};
			}
		})
		.catch((error) => {
			let status = error;
			if (error.response) {
				status = error.response.status;
				if (status >= 401 && status < 500) {
					return {
						status: false,
						statusCode: 401,
						message:
							"Access is denied due to the session timeout. Please login again.",
					};
				} else if (status === 403) {
					return {
						status: false,
						statusCode: 401,
						message:
							"Access is denied due to the session timeout. Please login again.",
					};
				} else {
					return {
						status: false,
						statusCode: status,
						message: "Unable to download file",
					};
				}
			} else if (error.request) {
				return {
					status: false,
					statusCode: 401,
					message:
						"Your request could not be processed due to Network Interruption. Please login again.",
				};
			} else {
				return {
					status: false,
					statusCode: 401,
					message:
						"Your request could not be processed due to Network Interruption. Please login again.",
				};
			}
		});
};
