import axios from "axios";
import {
	getKeyTypeBySkillUrl,
	getSubscription,
} from "./../../../Utils/getBaseUrl";
export const GetKeyTypesBySkills = (skills, token) => {
	const keyTypeBaseUrl = getKeyTypeBySkillUrl();
	const esbSubscriptionKey = getSubscription();
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": `application/json`,
			"Access-Control-Allow-Origin": "*",
			"EsbApi-Subscription-Key": esbSubscriptionKey,
		},
	};
	const data = {
		skills: skills,
		keyType: 0,
	};

	return axios
		.create(config)
		.post(`${keyTypeBaseUrl}`, data)
		.then((res) => {
			if (res.status === 200) {
				if (res.data) {
					return {
						status: true,
						statusCode: 200,
						data: res.data,
					};
				} else {
					return {
						status: false,
						statusCode: 200,
						message: "Unable to download file",
					};
				}
			} else if (res.status === 401) {
				return {
					status: false,
					statusCode: 401,
					message:
						"Access is denied due to the session timeout. Please login again.",
				};
			} else {
				return {
					status: false,
					statusCode: 400,
					message: "Unable to download file",
				};
			}
		})
		.catch((error) => {
			let status = error;
			if (error.response) {
				status = error.response.status;
				if (status === 401) {
					return {
						status: false,
						statusCode: 401,
						message:
							"Access is denied due to the session timeout. Please login again.",
					};
				} else {
					return {
						status: false,
						statusCode: status,
						message: "Unable to download file",
					};
				}
			} else if (error.request) {
				return {
					status: false,
					statusCode: 401,
					message:
						"Your request could not be processed due to Network Interruption. Please login again.",
				};
			} else {
				return {
					status: false,
					statusCode: 401,
					message:
						"Your request could not be processed due to Network Interruption. Please login again.",
				};
			}
		});
};
