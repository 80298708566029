import React from "react";
import useStyles from "./CommonStyle";

const DeviceNotAvailable = (props) => {
  const classes = useStyles();

  return (
    <div id="div_Conainer" className={`${classes.divContainer}`}>
      <div id="divSelectionView" style={{ height: "calc(100% - 40px)" }}>
        <div className={`${classes.headerText}`}>{props.headerText}</div>
        <div
          id="div_selection"
          className={`${classes.divSelectionContainer} ${classes.displayFlex}`}>
          <div id="form_label" className={classes.formLable}>
            {"Devices not available"}
          </div>
        </div>
      </div>
      <div id="fixedFooter" className={`${classes.fixedFooter}`}>
        <div id="InfoConsent" className={classes.InfoConsent}>
          {
            "*All the devices displayed are based on the training certificates completed on LMS, in absense of certificate, kindly contact Cornerstone."
          }
        </div>
      </div>
    </div>
  );
};

export default DeviceNotAvailable;