/* eslint-disable react-hooks/rules-of-hooks */
import { Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import * as actions from "./../../../actions";
import { connect, useDispatch } from "react-redux";
import { clearDeviceList, checkOnlineStatus } from "actions";
import keyTypeEnum from "../../../constants/KeyTypeEnum";
import { printIframe } from "./../FSEMasterKey/FSEMasterKey";
import DeviceNotAvailable from "../DeviceNotAvailable";
import WarnignModalPopup from "./../../Modal/WarningModal";
import InternetDisconnected from "./../../Modal/InternetDisconnected";
import "@zeiss/zui";
import useStyles from "../CommonStyle";
import "./Factory.css";
import CustomDatePicker from "./../../CustomDatePicker/CustomDatePicker";
import Processing from "Components/Admin/Processing";

const FactoryKeysView = ({
  deviceKeyList,
  getSelectedKey,
  reloadFactoryKey,
  getPrintingText,
}) => {
  const classes = useStyles();
  const isIpad = /iPad|Macintosh/i.test(navigator.userAgent);
  const isTouchDevice = navigator.maxTouchPoints > 0;
  let showPrint = isIpad && isTouchDevice ? false : true;
  return (
    <>
      <div id="div_Factory_Conainer" className={`${classes.divContainer}`}>
        <div
          id="divSelectionView"
          className={classes.divSelectionViewContainer}>
          <div className={`${classes.headerText}`}>{"Factory Key"}</div>
          <div
            id="div_selection"
            className={`${classes.divSelectionContainer} ${classes.displayInlineGrid}`}>
            <div id="form_label" className={classes.formLableKey}>
              {'Please pay attention to "Applicable device versions”'}
            </div>
          </div>
          <div
            id="divDeviceListkeyContainer"
            className={`${classes.divDeviceListkeyContainer} divDeviceListkeyContainerHeight`}>
            <Grid
              className={`${classes.muiGridNoSpacing}`}
              container
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start">
              {deviceKeyList.map((value, index) => {
                const labelId = `transfer-list-all-item-${value}-label`;
                return (
                  <Grid
                    key={index}
                    role="listitem"
                    className={`${classes.gridPaddingX1}`}
                    value={value.device}
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={3}
                    xl={2}>
                    <Paper
                      className={`${classes.paper} ${classes.gridTileForKey}`}>
                      <li
                        id={labelId}
                        className={`${classes.deviceNameTileForKey}`}>
                        <span>{value.device}</span>
                      </li>
                      <li
                        id={labelId}
                        className={`${classes.deviceNameVersion}`}>
                        <span>{`Device Version ${value.version}`}</span>
                      </li>
                      <li id={labelId} className={`${classes.deviceNameSN}`}>
                        <span>
                          {`Date: `}
                          {Intl.DateTimeFormat("en-GB", {
                            //timeZone: "UTC",
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                          }).format(
                            new Date(value.validFrom.replace(/M|D|Y/g, ""))
                          )}
                        </span>
                      </li>
                      <li
                        id={labelId}
                        className={`${classes.displayAdminITkey}`}>
                        <span>{`${value.key.substring(0, 18)}`}</span>
                        <span>{`${value.key.substring(18, 40)}`}</span>
                      </li>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
        <div id="fixedFooter" className={`${classes.fixedFooter}`}>
          <div className={`${classes.footerContainer} `}>
            <button
              type="button"
              className={`${classes.button} btn-clear`}
              onClick={() => {
                reloadFactoryKey();
              }}>
              {"Back"}
            </button>
            {showPrint && (
              <button
                type="button"
                className={`${classes.button} btn-clear`}
                onClick={() => {
                  getPrintingText();
                }}>
                {"Print"}
              </button>
            )}
            <button
              type="button"
              className={`${classes.button} ${classes.btnSubmit}`}
              onClick={() => {
                getSelectedKey();
              }}>
              {"Download"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const DevicesViewMaster = ({ deviceListInfo, sendDataToParent }) => {
  const classes = useStyles();
  const [selectedDeviceDate, setSelectedDeviceDate] = useState();
  const [selectedDeviceName, setSelectedDeviceName] = useState("");
  const [isValidInput, setIsValidInput] = useState(true);
  const [validSelectedDevice, setValidSelectedDevice] = useState();

  const selectedDevice = (device) => {
    setIsValidInput(true);
    if (selectedDeviceName !== device.name) {
      setSelectedDeviceName(device.name);
      setSelectedDeviceDate("");
      setValidSelectedDevice(device);
    } else {
      setSelectedDeviceName("");
      setSelectedDeviceDate("");
      setValidSelectedDevice();
    }
  };
  return (
    <>
      <div id="div_Factory_Conainer" className={`${classes.divContainer}`}>
        <div id="divSelectionView" style={{ height: "calc(100% - 120px)" }}>
          <div className={`${classes.headerText}`}>{"Factory Key"}</div>

          <div
            id="div_selection"
            className={`${classes.divSelectionContainer} ${classes.displayInlineGrid}`}>
            <div id="form_label" className={classes.formLableKey}>
              {"Select a device and respective date to generate a key"}
            </div>
          </div>
          <div
            id="divDeviceListContainer"
            className={`divDeviceListContainerHeight ${classes.divDeviceListContainer}`}>
            <form autoComplete="off">
              <Grid
                className={`${classes.muiGridNoSpacing}`}
                container
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start">
                {deviceListInfo.map((device, index) => {
                  const labelId = `transfer-list-all-item-${device.id}-label`;
                  const list1 = `list${device.id}`;
                  return (
                    <Grid
                      key={index}
                      role="listitem"
                      className={`${classes.gridPaddingXl} `}
                      value={device.id}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={2}>
                      <Paper
                        id={labelId}
                        className={`${classes.paper} ${classes.gridTile} ${
                          classes.gridPenteroTile
                        }
                       cursorPoiner
                       ${
                         selectedDeviceName === device.name
                           ? classes.highlight
                           : ""
                       }`}
                        onClick={() => {
                          selectedDevice(device);
                          setSelectedDeviceDate("");
                        }}>
                        <li
                          id={list1}
                          className={`${classes.deviceName} ${classes.ITAdminDeviceTextFormat}`}>
                          {device.name}
                        </li>
                        <li
                          id={labelId}
                          className={`${classes.displayInputField} ${classes.cursorMenu}`}
                          style={{ display: "block" }}
                          onClick={(e) => e.stopPropagation()}>
                          <CustomDatePicker
                            value={
                              selectedDeviceName === device.name
                                ? selectedDeviceDate
                                : ""
                            }
                            isInvalid={
                              selectedDeviceName === device.name &&
                              !isValidInput
                                ? true
                                : false
                            }
                            disableInput={
                              selectedDeviceName === device.name ? false : true
                            }
                            setSelectedDate={(value) => {
                              device.serialNumber = Intl.DateTimeFormat(
                                "en-US",
                                {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",
                                }
                              ).format(value);
                              device.isValid = true;
                              setIsValidInput(true);
                              setSelectedDeviceDate(value);
                              setValidSelectedDevice(device);
                            }}></CustomDatePicker>
                          <span
                            className={`${
                              isValidInput === false &&
                              selectedDeviceName === device.name
                                ? classes.factoryErrorSpan
                                : classes.displayNone
                            }`}>
                            {"Please select a date"}
                          </span>
                        </li>
                        <div className={`${classes.divLablePadding}`}></div>
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            </form>
          </div>
        </div>
        <div id="fixedFooter" className={`${classes.fixedFooter}`}>
          <div id="InfoConsent" className={classes.InfoConsent}>
            {
              "*All the devices displayed are based on the training certificates completed on LMS, in absense of certificate, kindly contact Cornerstone."
            }
          </div>
          <div className={`${classes.footerContainer} `}>
            <button
              type="button"
              disabled={selectedDeviceName !== "" ? false : true}
              className={`${classes.button} ${
                selectedDeviceName === ""
                  ? classes.btnDisabled
                  : classes.btnSubmit
              }`}
              onClick={() => {
                if (
                  selectedDeviceDate !== undefined &&
                  selectedDeviceDate !== null &&
                  selectedDeviceDate !== ""
                ) {
                  setIsValidInput(true);
                  sendDataToParent(validSelectedDevice);
                } else {
                  setIsValidInput(false);
                }
              }}>
              {"Generate Key"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export function Factory(props) {
  const classes = useStyles();
  sessionStorage.setItem("KeyTypeValue", 6);
  const { reduxState, reduxActions } = props;
  const { deviceListInfo } = reduxState;
  const { getDeviceList, generateKey, downloadKeys, getPrintText } =
    reduxActions;
  const [isdeviceListReady, setisdeviceListReady] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState(200);
  const [isKeyLoading, setKeyLoading] = useState(true);
  const [showKeyView, setShowKeyView] = useState(false);
  const [factoryKey, setFactoryKey] = useState("");
  const [open, setOpen] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState(false);
  const [modalText, setModalText] = useState("");
  const [isDeviceNotAvailable, setIsDeviceNotAvailable] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearDeviceList());
  }, [dispatch]);

  useEffect(() => {
    if (isdeviceListReady === false && deviceListInfo.fetched !== "fulfilled") {
      getDeviceList();
      setisdeviceListReady(true);
      setKeyLoading(false);
      setIsDeviceNotAvailable(false);
    }
    if (
      deviceListInfo.isSuccess === false &&
      (deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize")
    ) {
      displayPopUp(deviceListInfo.errorMessage);
      setKeyLoading(false);
    }
    if (
      isdeviceListReady &&
      deviceListInfo.fetched === "fulfilled" &&
      factoryKey !== ""
    ) {
      setShowKeyView(true);
      setKeyLoading(false);
    } else if (
      isdeviceListReady &&
      deviceListInfo.fetched === "fulfilled" &&
      deviceListInfo.deviceList.length === 0
    ) {
      setIsDeviceNotAvailable(true);
    }
  }, [deviceListInfo, getDeviceList, isdeviceListReady, factoryKey]);

  const generateFactoryKey = async (validSelectedDevice) => {
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      let factorykeys = await generateKey(6, validSelectedDevice);
      if (factorykeys.status !== false) {
        setFactoryKey(factorykeys.data);
      } else {
        displayPopUp(factorykeys.message);
      }
    } else {
      setOnlineStatus(true);
    }
    setKeyLoading(false);
  };

  const downloadFactoryKey = async () => {
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      let downloadedKeyData = await downloadKeys([...factoryKey], 6);
      if (
        downloadedKeyData.status === true &&
        downloadedKeyData.data.data !== undefined &&
        downloadedKeyData.data.data.config !== null
      ) {
        await downloadURI(downloadedKeyData.data.data.config[0].url);
      } else {
        setDownloadStatus(downloadedKeyData.statusCode);
        displayPopUp(downloadedKeyData.message);
      }
    } else {
      setOnlineStatus(true);
    }
    setKeyLoading(false);
  };

  const getPrintoutText = async () => {
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      const keyType = keyTypeEnum[6];
      let result = await getPrintText([...factoryKey], keyType);
      if (result.status !== false) {
        printIframe("ipdf", result.data);
      } else {
        setKeyLoading(false);
        setModalText(result.message);
        setOpen(true);
        setDownloadStatus(result.statusCode);
      }
    } else {
      setOnlineStatus(true);
    }
    setKeyLoading(false);
  };

  async function reloadFactoryKey() {
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      window.location.reload(true);
    } else {
      setOnlineStatus(true);
      setKeyLoading(false);
    }
  }

  const handleCallback = (validSelectedDevice) => {
    setKeyLoading(true);
    generateFactoryKey(validSelectedDevice);
  };

  const displayPopUp = (text) => {
    setModalText(text);
    setOpen(true);
  };

  const closeOnlineStatus = () => {
    setOnlineStatus(false);
  };

  const handleClose = () => {
    setKeyLoading(true);
    setOpen(false);
    setModalText("");
    if (deviceListInfo.fetched === "unAuthorize") {
      logOut();
    } else if (downloadStatus === 401) {
      logOut();
    } else {
      setKeyLoading(false);
    }
  };

  const logOut = () => {
    let path = window.location.origin + "/Logout";
    window.location.href = path;
  };

  const downloadURI = async (uri) => {
    let link = document.createElement("a");
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (deviceListInfo.fetched === "pending" ||
    isKeyLoading ||
    !isdeviceListReady) ? (
    <Processing progressText={true} />
  ) : deviceListInfo.fetched === "fulfilled" ? (
    <>
      {showKeyView && !isKeyLoading ? (
        <FactoryKeysView
          deviceKeyList={factoryKey}
          getSelectedKey={downloadFactoryKey}
          reloadFactoryKey={reloadFactoryKey}
          getPrintingText={getPrintoutText}
        />
      ) : (
        <>
          {isDeviceNotAvailable ? (
            <DeviceNotAvailable headerText={"Factory Key"} />
          ) : (
            <DevicesViewMaster
              deviceListInfo={deviceListInfo.deviceList}
              sendDataToParent={handleCallback}
            />
          )}
        </>
      )}
      <div id="itAdminInfoModalPopup">
        <WarnignModalPopup
          state={open}
          title={modalText}
          clickClose={handleClose}
        />
      </div>
      <InternetDisconnected
        state={onlineStatus}
        clickClose={closeOnlineStatus}></InternetDisconnected>
    </>
  ) : (
    <>
      {(deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize") &&
      open ? (
        <div id="itAdminModalPopup">
          <WarnignModalPopup
            state={open}
            title={modalText}
            clickClose={handleClose}
          />
        </div>
      ) : (
        <div id="div_Factory_Conainer" className={`${classes.divContainer}`}>
          <div className={`${classes.headerText}`}>{"Factory Key"}</div>
          <div
            id="div_selection"
            className={`${classes.divSelectionContainer} ${classes.displayFlex}`}>
            <div id="form_label" className={classes.formLableKey}>
              {"Unable to retrieve data from the server"}
            </div>
            <div id="form_Select_all" className={classes.divSelectAll}>
              <button
                className={`${classes.btnSelectAll} cursorPoiner`}
                onClick={() => {
                  window.location.reload(true);
                }}>
                {"Reload"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      getDeviceList: actions.getDeviceList,
      generateKey: actions.GetGeneratedKey,
      downloadKeys: actions.DownloadKey,
      getPrintText: actions.GetPrintText,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  reduxState: {
    deviceListInfo: state.deviceListInfo,
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Factory);
