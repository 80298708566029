import { getSubscription, getKeyServiceUrl } from "../../Utils/getBaseUrl";
import axios from "axios";
import { clearDeviceList } from "actions";
import * as ActionTypes from "../../constants/actionTypes";

export const failedDL = (data) => ({
	type: ActionTypes.GET_DEVICE_LIST_FAILED,
	payload: data,
});

export function mapper(deviceList, email, keyType) {
  //let date = new Date().toISOString().split(".")[0] + "Z";
  let date = localDateToISOString(new Date()) + "Z";
  //for master and oneday key
  if (keyType === 1 || keyType === 2) {
    let icCodes = deviceList.map((device) => {
      return device.icCode;
    });
    return { icCodes, validFrom: date, userName: "" };
  }
  //for itadmin key
  if (keyType === 3) {
    let serialNoList = deviceList.map((device) => {
      return device.icCode + "" + device.serialNumber;
    });
    return { serialNo: serialNoList, validFrom: date };
  }
  //for pentero pwd
  if (keyType === 4) {
    let serialNo = deviceList.device.serialNo;
    return { serialNo, validFrom: date };
  }
  // Biomed
  if (keyType === 5) {
    let serialNo = deviceList.device.serialNo;
    return { serialNo };
  }
  //Factory pass device data as {icCode: "6918", id: 6, isValid: true, name: "CONVIVO", serialNumber: "09/04/2021",}
  if (keyType === 6) {
    return {
      icCode: deviceList.icCode,
      validFrom: deviceList.serialNumber,
    };
  }
}
function localDateToISOString(date) {
  const offsetMs = date.getTimezoneOffset() * 60 * 1000;
  const msLocal = date.getTime() - offsetMs;
  const dateLocal = new Date(msLocal);
  const iso = dateLocal.toISOString();
  const isoLocal = iso.slice(0, 19);
  return isoLocal;
}
export const GetGeneratedKey =
  (keyType, deviceData) => (dispatch, getState) => {
    const state = getState();
    const token = state.userAccount.stateValue;
    const email = state.userAccount.email;
    let payload;
    var date = localDateToISOString(new Date()) + "Z";
    let createdDate = [date];
    if (keyType === 4) {
      //for petero, pass deviceData as serialNo
      payload = { serialNo: deviceData, validFrom: date };
    } else if (keyType === 3) {
      //ITAdmin pass device data as list of serial no
      let serialNoList = deviceData.map((device) => {
        return device.icCode + "" + device.serialNumber;
      });
      payload = { serialNo: serialNoList, validFrom: date };
    } // Biomed
    else if (keyType === 5) {
      let serialNoList = deviceData.map((device) => {
        return device.icCode + "" + device.serialNumber;
      });
      payload = { serialNo: serialNoList, validFrom: date };
    } else if (keyType === 6) {
      //Factory pass device data as {icCode: "6918", id: 6, isValid: true, name: "CONVIVO", serialNumber: "09/04/2021"}
      payload = {
        icCode: deviceData.icCode,
        validFrom: deviceData.serialNumber,
      };
    } else {
      payload = mapper(deviceData, email, keyType);
    }
    let keyServiceUrl = getKeyServiceUrl(keyType);
    const esbSubscriptionKey = getSubscription();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": `application/json`,
        "Access-Control-Allow-Origin": "*",
        "Cache-Control": "no-cache",
        CreatedDate: createdDate,
        Pragma: "no-cache",
        Expires: "0",
        "EsbApi-Subscription-Key": esbSubscriptionKey,
      },
    };
    const errorMessage = {
      statusCode: "",
      message: "",
    };
    return axios
      .create(config)
      .post(`${keyServiceUrl}`, payload)
      .then((res) => {
        errorMessage.statusCode = res.status;
        if (res.status === 200) {
          if (res.data.state && res.data !== null) {
            if (res.data.data !== null) {
              return {
                status: true,
                data: res.data.data,
                message: "",
              };
            } else {
              return {
                status: false,
                statusCode: 200,
                message: "Unable to generate key due to bad request",
              };
            }
          } else if (
            res.data.state === false &&
            res.data.error.includes("no skills were found")
          ) {
            errorMessage.statusCode = 401;
            errorMessage.message =
              "You don’t have valid certificates for selected device/s in Cornerstone. Please complete your training to generate keys";
           // dispatch(failedDL(errorMessage));
            return {
              status: false,
              statusCode: 401,
              message: errorMessage.message,
            };
          } else {
            dispatch(clearDeviceList());
            return {
              state: false,
              statusCode: 200,
              message:
                res.data.error !== null
                  ? res.data.error.message
                  : "Unable to generate key due to bad request",
            };
          }
        } else if (res.status === 400) {
          errorMessage.message =
            "Unable to process the request. Please try again.";
          dispatch(failedDL(errorMessage));
          return {
            status: false,
            statusCode: 400,
            message: errorMessage.message,
          };
        } else if (res.status === 401) {
          errorMessage.message =
            "Access is denied due to the session timeout. Please login again.";
          dispatch(failedDL(errorMessage));
          return {
            status: false,
            statusCode: 401,
            message: errorMessage.message,
          };
        } else if (res.status === 404) {
          errorMessage.message =
            "The Requested URL was Not Found on this Server";
          dispatch(failedDL(errorMessage));
          return {
            status: false,
            statusCode: 404,
            message: errorMessage.message,
          };
        } else if (res.status === 406) {
          errorMessage.message =
            "The Requested URL was Not Found on this Server";
          dispatch(failedDL(errorMessage));
          return {
            status: false,
            statusCode: 404,
            message: errorMessage.message,
          };
        } else if (res.status === 500) {
          errorMessage.message = "Internal server error. Please try again.";
          dispatch(failedDL(errorMessage));
          return {
            status: false,
            statusCode: 500,
            message: errorMessage.message,
          };
        } else {
          errorMessage.message = "Something went wrong";
          dispatch(failedDL(errorMessage));
          return {
            status: false,
            statusCode: res.status,
            message: errorMessage.message,
          };
        }
      })
      .catch((error) => {
        let status = error;
        if (error.response) {
          status = error.response.status;
          errorMessage.statusCode = error.response.status;
          if (
            status === 400 &&
            error.response.statusText.includes("Request to Certificates Failed")
          ) {
            errorMessage.message =
              "Access is denied due to the session timeout. Please login again.";
            errorMessage.statusCode = 401;
            dispatch(failedDL(errorMessage));
            return {
              status: false,
              statusCode: 401,
              message: errorMessage.message,
            };
          } else if (status === 400) {
            errorMessage.message =
              "Unable to process the request. Please try again.";
            dispatch(failedDL(errorMessage));
            return {
              status: false,
              statusCode: 400,
              message: errorMessage.message,
            };
          } else if (
            status === 401 &&
            error.response.statusText.includes("Access Denied")
          ) {
            errorMessage.message =
              "Access denied. Please write to support.servicekey@zeiss.com";
            errorMessage.statusCode = 200;
            //dispatch(failedDL(errorMessage));
            return {
              status: false,
              statusCode: 200,
              message: errorMessage.message,
            };
          } else if (status === 401) {
            errorMessage.message =
              "Access is denied due to the session timeout. Please login again.";
            dispatch(failedDL(errorMessage));
            return {
              status: false,
              statusCode: 401,
              message: errorMessage.message,
            };
          } else if (status === 404) {
            errorMessage.message =
              "The Requested URL was Not Found on this Server";
            dispatch(failedDL(errorMessage));
            return {
              status: false,
              statusCode: 404,
              message: errorMessage.message,
            };
          } else if (status === 406) {
            errorMessage.message =
              "You don’t have valid certificates for one or more devices in Cornerstone. Please complete your training and log in again";
            dispatch(failedDL(errorMessage));
            return {
              status: false,
              statusCode: 406,
              message: errorMessage.message,
            };
          } else if (status === 500) {
            errorMessage.message = "Internal server error. Please try again.";
            dispatch(failedDL(errorMessage));
            return {
              status: false,
              statusCode: 500,
              message: errorMessage.message,
            };
          } else {
            errorMessage.message = "Something went wrong";
            dispatch(failedDL(errorMessage));
            return {
              status: false,
              statusCode: 503,
              message: errorMessage.message,
            };
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          errorMessage.statusCode = 401;
          errorMessage.message =
            "Your request could not be processed due to Network Interruption. Please login again.";
          dispatch(failedDL(errorMessage));
          return {
            status: false,
            statusCode: 401,
            message: errorMessage.message,
          };
        } else {
          errorMessage.statusCode = 401;
          errorMessage.message = error.message;
          dispatch(failedDL(errorMessage));
          return {
            status: false,
            statusCode: 401,
            message: errorMessage.message,
          };
        }
      });
  };
