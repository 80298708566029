import React, { useState } from "react";
import { makeStyles, Modal } from "@material-ui/core";
import infoIcon from "./../../assets/icons/info-icon.png";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
    background: "#1a23293b !important",
    zIndex: "3002 !important",
  },
  modalPaper: {
    position: "absolute",
    [theme.breakpoints.up("xs")]: {
      height: 239,
      width: 294,
    },
    [theme.breakpoints.up("md")]: {
      width: 450,
      height: 310,
    },
    backgroundColor: theme.palette.background.paper,
    borderTop: "5px solid #0050F2",
    boxShadow: theme.shadows[5],
    border: "none",
    padding: "0px",
    textAlign: "center",
    borderRadius: "5px",
    outline: "none",
  },
  modalHeader: {
    textAlign: "center",
    padding: "0px",
    border: "0px",
    [theme.breakpoints.up("xs")]: {
      marginTop: "20px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "49px",
    },
  },
  modalBody: {
    position: "relative",
    padding: "0px 25px",
    textAlign: "center",
    height: "calc(100% - 155px)",
    [theme.breakpoints.up("xs")]: {
      bottom: "10px",
    },
    [theme.breakpoints.up("md")]: {
      bottom: "0px",
    },
  },
  modalParagraph: {
    fontSize: "32px",
    lineHeight: "48px",
    margin: "10px",
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: "300",
    textAlign: "center",
    color: "#07090D",
    [theme.breakpoints.up("xs")]: {
      fontSize: "28px",
      lineHeight: "40px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "32px",
      lineHeight: "48px",
    },
  },
  showErrorMessage: {
    width: "100%",
    padding: "5px 0px",
    font: "var(--zui-typography-body)",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#828D9E",
    [theme.breakpoints.up("xs")]: {
      padding: "0",
    },
  },
  messagebody: {
    width: "inherit",
    position: "relative",
    display: "block",
    [theme.breakpoints.up("xs")]: {
      margin: "15px 0",
      // margin: "0",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "48px",
    },
  },
  modalFooter: {
    borderTop: "1px solid #DDE3ED",
    height: "64px",
    alignSelf: "center",
    display: "block",
    width: "100%",
    textAlign: "center",
    position: "absolute",
    left: "0",
    bottom: "0",
  },
  modalBtn: {
    display: "inline-block",
    border: "1px solid #DDE3ED",
    padding: "21px",
    width: "50%",
    textAlign: "center",
    cursor: "pointer",
    userSelect: "none",
    outline: "none",
    background: "transparent",
    whiteSpace: "nowrap",
    font: "var(--zui-typography-subtitle1)",
    fontWeight: 400,
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const InformationModalPopup = ({ state, title, clickClose, clickOk }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  return (
    <>
      <Modal
        disableAutoFocus={true}
        className={classes.modal}
        open={state}
        onClose={() => {
          clickClose();
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <div style={modalStyle} className={classes.modalPaper}>
          <div className={classes.modalHeader}>
            <img height="30" width="30" src={infoIcon} alt="info" />
          </div>
          <div className={classes.modalBody}>
            <div>
              <p className={classes.modalParagraph}>Confirmation</p>
            </div>
            <div className={classes.showErrorMessage}>
              <span className={classes.messagebody}>{title}</span>
            </div>
          </div>
          <div className={`${classes.modalFooter}`}>
            <button
              type="button"
              id="ConfirmData"
              className={`${classes.modalBtn} modalbtn2 cursorPoiner`}
              onClick={() => {
                clickOk();
              }}>
              Yes
            </button>
            <button
              type="button"
              id="DiscardData"
              className={`${classes.modalBtn} modalbtn2 cursorPoiner`}
              onClick={() => {
                clickClose();
              }}>
              No
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default InformationModalPopup;
