import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App.container";
import Root from "./Root";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import "@zeiss/zui";
//import { registerTypefaces } from "@zeiss/zui-theme-base";
import {
  themeBase,
  registerTheme,
  registerTypefaces,
  setAppTheme,
} from "@zeiss/zui-theme-base";
import { themeLight } from "@zeiss/zui-theme-light";
import "./index.css";

registerTheme(themeBase);
registerTheme(themeLight);

setAppTheme("light");

registerTypefaces("/fonts");

ReactDOM.render(
  <Root>
    <React.Fragment>
      <Router>
        <App />
      </Router>
    </React.Fragment>
  </Root>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
