import React ,{ useEffect, useState }  from "react";
import "./../KeyTypes/FSEMasterKey/FSEMasterKey.css";
import WarnignModalPopup from "./../Modal/WarningModal";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
const HomePageComponent = (props) => {
  const { isAdmin, reduxState } = props;
  const { layoutInfo } = reduxState;
  const [open, setOpen] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (window.location.pathname === `/home/`) {
      localStorage.clear();
      history.push("/home");
    } else {
      if (isAdmin) {
        history.replace("/home/Devices");
      } else {
        if (layoutInfo.data[0].id === 1) {
          history.replace("/home/FSEMasterKey");
        } else if (layoutInfo.data[0].id === 2) {
          history.replace("/home/OnedayKey");
        } else if (layoutInfo.data[0].id === 3) {
          history.replace("/home/AdminItKey");
        } else if (layoutInfo.data[0].id === 4) {
          history.replace("/home/Pentero");
        } else if (layoutInfo.data[0].id === 5) {
          history.replace("/home/Biomed");
        } else if (layoutInfo.data[0].id === 6) {
          history.replace("/home/Factory");
        } else {
          setOpen(true);
        }
      }
    }
  }, [history, layoutInfo, isAdmin]);

  const handleClose = () => {
    setOpen(false);
    history.push("/Logout");
  };
  return (
    <>
      <WarnignModalPopup
        state={open}
        title={"You are not authorized to visit this page, please login again"}
        clickClose={handleClose}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  reduxState: {
    layoutInfo: state.layoutInfo,
  },
});
export default connect(mapStateToProps)(HomePageComponent);
