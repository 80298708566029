import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import * as actions from "actions";
import { clearDeviceList, clearErrorMessage, checkOnlineStatus } from "actions";
import { DisableDevice } from "./../../actions/index";
import WarnignModalPopup from "./../Modal/WarningModal";
import DisableDeviceWarningModal from "./../Modal/DisableDeviceWarningModal";
import InternetDisconnected from "./../Modal/InternetDisconnected";
import "@zeiss/zui";
import Processing from "./Processing";
import { ZuiButton } from "@zeiss/zui-react";
import { Tooltip, IconButton } from "@material-ui/core";
import useStyles from "./../KeyTypes/CommonStyle";
import addIcon from "assets/icons/add.png";
import editIcon from "assets/icons/edit-icn.png";
// import backIcon from "assets/icons/back-icn.png";
import "./Admin.css";
import "./Template.css";

const ViewDeviceDetails = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const token = useSelector((state) => state.userAccount.tokenId);
  const role =
    props.location.state !== undefined ? props.location.state.role : undefined;
  const deviceCode =
    props.location.state !== undefined ? props.location.state.deviceCode : null;

  const { reduxState, reduxActions } = props;
  const { deviceListInfo } = reduxState;
  const { GetDeviceByDeviceId } = reduxActions;

  const [isDeviceListReady, setDeviceListReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [onlineStatus, setOnlineStatus] = useState(false);

  const dispatch = useDispatch();

  const [updateDeviceStatus, setUpdateDeviceStatus] = useState("pending");
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);
  const [isActiveState, setIsActiveState] = useState("Enabled");
  const [showPopUp, setshowPopUp] = useState(false);
  const [popUpText, setpopUpText] = useState("");

  const isDeviceActive = deviceListInfo.deviceListByID.isActive;
  const disableClassName = isDeviceActive ? "" : classes.hideButton;

  useEffect(() => {
    dispatch(clearDeviceList());
    setIsLoading(true);
  }, [dispatch]);

  const displayPopUp = (text) => {
    setErrorText(text);
    setIsError(true);
  };

  const displayDisablePopUp = (text) => {
    setpopUpText(text);
    setshowPopUp(true);
  };

  useEffect(() => {
    if (
      (!isDeviceListReady && deviceListInfo.fetched === "pending") ||
      (isActiveState !== "" && isUpdateSuccess)
    ) {
      GetDeviceByDeviceId(deviceCode, role);
      setIsLoading(false);
      setIsUpdateSuccess(false);
    }
    if (deviceListInfo.fetched === "fetched") {
      setDeviceListReady(true);
      setIsLoading(false);
    }
    if (
      deviceListInfo.isSuccess === false &&
      (deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize")
    ) {
      displayPopUp(deviceListInfo.errorMessage);
      setIsLoading(false);
    }
  }, [
    GetDeviceByDeviceId,
    deviceCode,
    deviceListInfo,
    isDeviceListReady,
    role,
    isActiveState,
    isUpdateSuccess,
  ]);

  const redirectToDeviceListView = async (state) => {
    setIsLoading(true);
    let onlineStatus = await actions.checkOnlineStatus();
    if (onlineStatus !== false) {
      setOnlineStatus(false);
      dispatch(clearDeviceList());
      history.push({
        pathname: "/home/Devices",
        state: state,
      });
    } else {
      setOnlineStatus(true);
      setIsLoading(false);
    }
  };

  const redirectToAddVersion = async (state) => {
    setIsLoading(true);
    let onlineStatus = await actions.checkOnlineStatus();
    if (onlineStatus !== false) {
      setOnlineStatus(false);
      dispatch(clearDeviceList());
      history.push({
        pathname: "/home/Devices/AddNewVersion",
        state: state,
      });
    } else {
      setOnlineStatus(true);
      setIsLoading(false);
    }
  };

  const redirectToAddSkills = async (state) => {
    setIsLoading(true);
    let onlineStatus = await actions.checkOnlineStatus();
    if (onlineStatus !== false) {
      setOnlineStatus(false);
      history.push({
        pathname: "/home/Devices/AddNewSkillsetKeyType",
        state: state,
      });
    } else {
      setOnlineStatus(true);
      setIsLoading(false);
    }
  };

  const redirectToDisableSkillset = async (state) => {
    setIsLoading(true);
    let onlineStatus = await actions.checkOnlineStatus();
    if (onlineStatus !== false) {
      setOnlineStatus(false);
      history.push({
        pathname: "/home/Devices/DisableSkillset",
        state: state,
      });
    } else {
      setOnlineStatus(true);
      setIsLoading(false);
    }
  };

  const redirectToDisableSkillsetKeytype = async (state) => {
    setIsLoading(true);
    let onlineStatus = await actions.checkOnlineStatus();
    if (onlineStatus !== false) {
      setOnlineStatus(false);
      history.push({
        pathname: "/home/Devices/DisableKeytype",
        state: state,
      });
    } else {
      setOnlineStatus(true);
      setIsLoading(false);
    }
  };

  const redirectToDisableVersion = async (state) => {
    setIsLoading(true);
    let onlineStatus = await actions.checkOnlineStatus();
    if (onlineStatus !== false) {
      setOnlineStatus(false);
      history.push({
        pathname: "/home/Devices/DisableVersion",
        state: state,
      });
    } else {
      setOnlineStatus(true);
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsLoading(true);
    setIsError(false);
    setErrorText("");
    if (deviceListInfo.fetched === "unAuthorize") {
      logOut();
    } else {
      setIsLoading(false);
    }
    dispatch(clearErrorMessage());
  };

  const handleCloseModal = () => {
    setIsLoading(true);
    setshowPopUp(false);
    setpopUpText("");
    if (deviceListInfo.fetched === "unAuthorize") {
      logOut();
    } else {
      setIsLoading(false);
    }
  };

  const closeOnlineStatus = () => {
    setOnlineStatus(false);
  };

  const logOut = () => {
    let path = window.location.origin + "/Logout";
    window.location.href = path;
  };

  const updateDisableDevice = async ({ isActive }) => {
    const deviceId = deviceListInfo.deviceListByID.id;
    setIsLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      const result = await DisableDevice(token, { deviceId, isActive });
      if (result.status === true) {
        setIsUpdateSuccess(true);
        setIsActiveState("");
      } else {
        if (result.statusCode === 401) {
          setUpdateDeviceStatus("unAuthorize");
          displayPopUp(result.message);
        } else if (result.statusCode === 400) {
          //Reload the page as the data already exists in server
          setIsUpdateSuccess(true);
          setIsActiveState("");
        } else {
          setUpdateDeviceStatus("failed");
          displayPopUp(result.message);
        }
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      setOnlineStatus(true);
    }
  };

  return isLoading || deviceListInfo.fetched === "pending" ? (
    <Processing progressText={true} />
  ) : deviceListInfo.fetched === "fulfilled" ? (
    <div
      className="deviceContainer"
      // style={{ width: "calc(100% - 184px)" }}
    >
      <div
        style={{
          margin: "8px",
          width: "98.5%",
          background: "var(	--zui-color-gs-10)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 16px 10px 24px",
          userSelect: "none",
        }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "auto 0px",
          }}>
          {/* <div
          style={{
            font: "var(--zui-typography-subtitle2)",
            color: "#555E6B",
            margin:"auto",
            marginRight: "30px",
          }}
        >
          {"Device"}
        </div> */}
          <div
            style={{
              textTransform: "uppercase",
              fontSize: "16px !important",
              lineHeight: "24px !important",
              margin: "0",
              color: "#1A2329",
              font: "var(--zui-typography-h4)",
            }}>
            {deviceListInfo.deviceListByID.name}
          </div>
          <span
            style={{
              padding: "0 0 0 16px",
              fontSize: "14px !important",
              lineHeight: "24px !important",
              color: "#353D45",
              font: "var(--zui-typography-body)",
              margin: "auto",
            }}>
            ({deviceListInfo.deviceListByID.icCode})
          </span>
        </div>
        <div style={{ display: "inline-flex" }}>
          {/* <img height="32" width="32" src={backIcon} alt="back"
        style={{cursor:"pointer"}}
          onClick={() => {
            dispatch(clearDeviceList());
            history.replace("/home/Devices");
          }} /> */}
          {isDeviceActive ? (
            <div>
              <button
                className={`${classes.btnDeviceActive}`}
                title="Disable"
                environment="auto"
                onClick={() => {
                  displayDisablePopUp(
                    `Are you sure, you want to disable ${deviceListInfo.deviceListByID.name}?`
                  );
                }}>
                {"Disable"}
              </button>
            </div>
          ) : (
            <div>
              <button
                className={`${classes.btnDeviceActive}`}
                title="Enable"
                environment="auto"
                onClick={() => {
                  updateDisableDevice({ isActive: true });
                  setIsActiveState("Enabled");
                }}>
                {"Enable"}
              </button>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          margin: "8px",
          width: "calc(100% - 16px)",
          height: "56px",
          background: "var(	--zui-color-gs-10)",
          display: "block",
          position: "relative",
          padding: "8px 16px 8px 24px",
          userSelect: "none",
        }}>
        <div
          id="divVersionContainer"
          style={{
            display: "inline-flex",
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "center",
          }}>
          <div
            id="divVersionlabel"
            style={{
              width: "121px",
              height: "inherit",
              margin: "auto 0px",
              paddingRight: "15px",
              font: "var(--zui-typography-subtitle2)",
              fontSize: "12px !important",
              lineHeight: "16px !important",
              color: "#555E6B",
            }}>
            {"Version"}
          </div>
          <div
            id="versionGrid"
            style={{
              display: "block",
              width: "calc(100% - 72px)",
              alignItems: "center",
              overflowX: "auto",
              marginRight: "10px",
            }}>
            <div id="divVersions" style={{ display: "inline-flex" }}>
              {deviceListInfo.deviceListByID.deviceKeyGeneratorMappings.map(
                (item, index) => {
                  let fadeVersion = "";
                  if (!isDeviceActive) {
                    fadeVersion = classes.fadeDisplay;
                  } else {
                    fadeVersion = item.isActive ? "" : classes.fadeDisplay;
                  }
                  return (
                    <div
                      key={"deviceListByID" + index}
                      className={fadeVersion}
                      style={{
                        padding: "8px",
                        font: "var(--zui-typography-subtitle2)",
                        minWidth: "105px",
                        alignItems: "center",
                      }}>
                      <div
                        key={"deviceListByID" + index}
                        style={{
                          width: "100%",
                          background: "#07090D",
                          color: "#EDF2F7",
                          display: "flex",
                          padding: "5px 16px",
                          fontSize: "12px",
                          userSelect: "none",
                        }}>
                        {item.operator}
                        {item.version}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div
            id="addVersion"
            style={{ display: "flex", alignItems: "center" }}>
            {deviceListInfo.deviceListByID.isGenelic === false ? (
              <>
                <img
                  className={disableClassName}
                  height="32"
                  width="32"
                  src={addIcon}
                  alt="add version"
                  title="add new version"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    redirectToAddVersion({
                      deviceCode: deviceListInfo.deviceListByID.id,
                      role: role,
                    })
                  }
                />
                <button
                  className={`${classes.btnDeviceActive} ${disableClassName}`}
                  title="Disable"
                  environment="auto"
                  onClick={() =>
                    redirectToDisableVersion({
                      deviceCode: deviceListInfo.deviceListByID.id,
                      role: role,
                    })
                  }>
                  {"Disable"}
                </button>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          margin: "8px",
          width: "98.5%",
          background: "var(	--zui-color-gs-10)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 16px 10px 24px",
          userSelect: "none",
        }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "auto 0px",
          }}>
          <div
            style={{
              font: "var(--zui-typography-subtitle2)",
              fontSize: "12px !important",
              lineHeight: "16px !important",
              color: "#555E6B",
            }}>
            {"Skillset"}
          </div>
        </div>
        <div style={{ display: "inline-flex" }}>
          <img
            className={disableClassName}
            height="32"
            width="32"
            src={addIcon}
            alt="add Skills"
            title="add new skills"
            style={{ cursor: "pointer" }}
            onClick={() =>
              redirectToAddSkills({
                deviceCode: deviceListInfo.deviceListByID.id,
                role: role,
              })
            }
          />
          <button
            className={`${classes.btnDeviceActive} ${disableClassName}`}
            title="Disable"
            environment="auto"
            onClick={() =>
              redirectToDisableSkillset({
                deviceCode: deviceListInfo.deviceListByID.id,
                role: role,
              })
            }>
            {"Disable"}
          </button>
        </div>
      </div>
      <div
        style={{
          margin: "-8px 8px 0 8px",
          width: "98.5%",
          background: "var(	--zui-color-gs-10)",
          display: "flex",
        }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}>
          <zui-section-divider
            style={{
              width: "100%",
              display: "flex",
              textTransform: "uppercase",
            }}>
            <span style={{ width: "52%" }}>{"Skillset Name"}</span>
            <span style={{ width: "48%" }}>{"Keytypes"}</span>
            <span style={{ width: "10%" }}>{"Actions"}</span>
          </zui-section-divider>
        </div>
      </div>
      <div className="viewDeviceBodyContainer">
        {deviceListInfo.deviceListByID.skills.map((item, index) => {
          let isDisabled = "";
          let hideButton = "";
          if (!isDeviceActive) {
            isDisabled = classes.fadeDisplay;
            hideButton = classes.hideButton;
          } else {
            isDisabled = item.isActive ? "" : classes.fadeDisplay;
            hideButton = item.isActive ? "" : classes.hideButton;
          }
          return (
            <div
              key={"skills" + index}
              className={isDisabled}
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "18px 24px",
                borderBottom: "1px solid #c1cbd9",
              }}>
              <div style={{ width: "52%" }}>
                <p style={{ margin: "0" }}>{item.name} | {item.courseCode} </p>
              </div>
              <div
                style={{
                  width: "48%",
                  display: "inline-flex",
                  flexWrap: "wrap",
                }}>
                {item.keyTypes.map((keyType, index) => {
                  let isDisabledKey = "";
                  if (isDisabled === "") {
                    isDisabledKey = keyType.isActive ? "" : classes.fadeDisplay;
                  }
                  return index !== item.keyTypes.length - 1 ? (
                    <p
                      className={isDisabledKey}
                      style={{ margin: "0" }}
                      key={"keyType" + index}>
                      {`${keyType.description},`}&nbsp;
                    </p>
                  ) : (
                    <p
                      className={isDisabledKey}
                      style={{ margin: "0" }}
                      key={"keyType" + index}>{`${keyType.description}`}</p>
                  );
                })}
              </div>
              <div style={{ width: "10%" }}>
                <span>
                  <Tooltip title="Edit">
                    <IconButton
                      className={`${hideButton} ${disableClassName}`}
                      aria-label="Edit"
                      color="inherit"
                      style={{}}
                      onClick={() => {
                        redirectToDisableSkillsetKeytype({
                          deviceCode: deviceListInfo.deviceListByID.id,
                          skillsetName: item.name,
                          skillsetId: item.id,
                          role: role,
                          skillCourseCode: item.courseCode,
                        });
                      }}>
                      <img
                        height="14"
                        width="14"
                        src={editIcon}
                        alt="edit"
                        className="historyViewIcon"
                      />
                    </IconButton>
                  </Tooltip>
                </span>
              </div>
            </div>
          );
        })}
      </div>
      <div className="footerContainer">
        <ZuiButton
          size="s"
          title="Back"
          environment="auto"
          className="footerBackButton"
          onClick={() => {
            redirectToDeviceListView({ role: role });
          }}>
          {"Back"}
        </ZuiButton>
      </div>
      <div id="fseModalPopup">
        <WarnignModalPopup
          state={isError}
          title={errorText}
          clickClose={handleClose}
        />
      </div>
      <div id="disableModalPopup">
        <DisableDeviceWarningModal
          state={showPopUp}
          title={popUpText}
          clickClose={handleCloseModal}
          clickOk={() => {
            handleCloseModal();
            setIsActiveState("Disabled");
            updateDisableDevice({ isActive: false });
          }}
        />
      </div>
      <InternetDisconnected
        state={onlineStatus}
        clickClose={closeOnlineStatus}></InternetDisconnected>
    </div>
  ) : (
    <>
      {(updateDeviceStatus === "failed" ||
        updateDeviceStatus === "unAuthorize" ||
        deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize") &&
      isError ? (
        <div id="fseModalPopup">
          <WarnignModalPopup
            state={isError}
            title={errorText}
            clickClose={handleClose}
          />
        </div>
      ) : (
        <div
          id="div_AdminDeviceList_Conainer"
          className={`${classes.divContainer}`}
          style={{ margin: "8px 8px 0px 8px" }}>
          <div
            id="div_selection"
            className={`${classes.divSelectionContainer} ${classes.displayFlex}`}>
            <div id="form_label" className={`${classes.formLable} `}>
              {"Unable to retrieve device data from the server"}
            </div>
            <div id="form_Select_all" className={classes.divSelectAll}>
              <zui-button
                size="l"
                environment="auto"
                onClick={() => {
                  redirectToDeviceListView({ role: role });
                }}>
                {"Back"}
              </zui-button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      GetDeviceByDeviceId: actions.GetDeviceByDeviceCode,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  reduxState: {
    deviceListInfo: state.deviceListInfo,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewDeviceDetails);
