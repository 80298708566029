import axios from "axios";
// import * as ActionTypes from "../../../constants/actionTypes";
import { addDeviceVersionUrl, getSubscription } from "Utils/getBaseUrl";

export const AddDeviceNewVersion = (token, versionDetails) => {
	const getAddDeviceVersionUrl = addDeviceVersionUrl();
	const esbSubscriptionKey = getSubscription();
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": `application/json`,
			"Access-Control-Allow-Origin": "*",
			"EsbApi-Subscription-Key": esbSubscriptionKey,
		},
	};

	return axios
		.create(config)
		.post(`${getAddDeviceVersionUrl}`, versionDetails)
		.then((res) => {
			if (res.status === 200) {
				if (res.data) {
					return {
						status: true,
						statusCode: 200,
						data: "Version Added Successfully",
					};
				} else {
					return {
						status: false,
						statusCode: 200,
						message: "Unable to add version",
					};
				}
			} else if (res.status === 401) {
				return {
					status: false,
					statusCode: 401,
					message:
						"Access is denied due to the session timeout. Please login again.",
				};
			} else if (res.status === 400) {
				return {
					status: false,
					statusCode: 400,
					message: "Version already exists.",
				};
			} else {
				return {
					status: false,
					statusCode: 400,
					message: "Bad Request",
				};
			}
		})
		.catch((error) => {
			let status = error;
			if (error.response) {
				status = error.response.status;
				if (status === 401) {
					return {
						status: false,
						statusCode: 401,
						message:
							"Access is denied due to the session timeout. Please login again.",
					};
				} else {
					return {
						status: false,
						statusCode: status,
						message: "Version already exists.",
					};
				}
			} else if (error.request) {
				return {
					status: false,
					statusCode: 401,
					message:
						"Your request could not be processed due to Network Interruption. Please login again.",
				};
			} else {
				return {
					status: false,
					statusCode: 401,
					message:
						"Your request could not be processed due to Network Interruption. Please login again.",
				};
			}
		});
};
