import axios from "axios";
// import * as ActionTypes from "../../../constants/actionTypes";
import { getDeviceByNameUrl, getSubscription } from "Utils/getBaseUrl";

export const GetDeviceByName = (token, deviceName) => {
	// const state = getState();
	// let getDeviceByIcCodeUrl = 'data/certificates.json' ;
	const getDeviceByDeviceNameUrl = getDeviceByNameUrl() + deviceName;
	const esbSubscriptionKey = getSubscription();
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": `application/json`,
			"Access-Control-Allow-Origin": "*",
			"EsbApi-Subscription-Key": esbSubscriptionKey,
		},
	};

	return axios
		.create(config)
		.get(`${getDeviceByDeviceNameUrl}`)
		.then((res) => {
			if (res.status === 200) {
				if (res.data) {
					return {
						status: true,
						statusCode: 200,
						data: res.data,
					};
				} else {
					return {
						status: false,
						statusCode: 200,
						message: "Device Not Found",
						data: res.data,
					};
				}
			} else if (res.status === 401) {
				return {
					status: false,
					statusCode: res.status,
					message:
						"Access is denied due to the session timeout. Please login again.",
				};
			} else if (res.status === 404) {
				return {
					status: false,
					statusCode: res.error.code,
					message: res.error.message,
				};
			} else {
				return {
					status: false,
					statusCode: 400,
					message: "Device Not Found",
				};
			}
		})
		.catch((error) => {
			let status = error;
			if (error.response) {
				status = error.response.status;
				if (status === 401) {
					return {
						status: false,
						statusCode: 401,
						message:
							"Access is denied due to the session timeout. Please login again.",
					};
				} else {
					return {
						status: false,
						statusCode: status,
						message: "Device Not Found",
					};
				}
			} else if (error.request) {
				return {
					status: false,
					statusCode: 401,
					message:
						"Your request could not be processed due to Network Interruption. Please login again.",
				};
			} else {
				return {
					status: false,
					statusCode: 401,
					message:
						"Your request could not be processed due to Network Interruption. Please login again.",
				};
			}
		});
};
