import axios from "axios";
import * as ErrorMessage from "../../constants/errorMessages";
import {
  // getLogDetailsUrl,
  // getSubscription,
  getBaseUrl,
} from "../../Utils/getBaseUrl";

export const GetLogsDetails = (token, filterParams) => {
  // let logDetailsUrl = getLogDetailsUrl();
  // const esbSubscriptionKey = getSubscription();

  let url = getBaseUrl();
  if (url === "http://localhost:3001") {
    url = "https://qa.fsekeygen.zeiss.com";
  }
  let logDetailsUrl = `${url}/fsekeygenservice/api/v1/LogDetail/GetLogsDetails`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": `application/json`,
      "Access-Control-Allow-Origin": "*",
      // "EsbApi-Subscription-Key": esbSubscriptionKey,
    },
  };
  return axios
    .create(config)
    .get(`${logDetailsUrl}`, { params: filterParams })
    .then((res) => {
      if (res.status === 200) {
        if (res.data) {
          if (res.data.length === 0) {
            return {
              status: false,
              statusCode: 200,
              message:
                "No log contents are available for particular date range.",
            };
          } else {
            return {
              status: true,
              statusCode: 200,
              message: "Downloaded Log Files successfully.",
              data: res.data,
            };
          }
        }
      } else if (res.status === 401) {
        return {
          status: false,
          statusCode: 401,
          message: ErrorMessage.SESSION_TIMEOUT_ERROR,
        };
      } else if (res.status === 500) {
        return {
          status: false,
          statusCode: 500,
          message: ErrorMessage.INTERNAL_SERVER_ERROR,
        };
      } else {
        return {
          status: false,
          statusCode: res.status,
          message: ErrorMessage.NETWORK_ERROR,
        };
      }
    })
    .catch((error) => {
      let status = error;
      if (error.response) {
        status = error.response.status;
        if (status === 401) {
          return {
            status: false,
            statusCode: 401,
            message: ErrorMessage.SESSION_TIMEOUT_ERROR,
          };
        } else {
          return {
            status: false,
            statusCode: status,
            message: error.response.data.error.message,
          };
        }
      } else if (error.request) {
        return {
          status: false,
          statusCode: 401,
          message: ErrorMessage.NETWORK_ERROR,
        };
      } else {
        return {
          status: false,
          statusCode: 401,
          message: ErrorMessage.NETWORK_ERROR,
        };
      }
    });
};
