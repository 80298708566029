import "../Admin/Admin.css";
import { useState, useEffect } from "react";

import { bindActionCreators } from "redux";
import * as actions from "actions";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { connect, useDispatch } from "react-redux";
import { clearDeviceList, clearErrorMessage, checkOnlineStatus } from "actions";
import RetrieveDataError from "./RetrieveDataError";
import {
  ZuiButton,
  ZuiRadioButton,
  ZuiRadioButtonGroup,
} from "@zeiss/zui-react";
import "@zeiss/zui";
import { AddDeviceNewVersion } from "./../../actions/index";
import {} from "@material-ui/core";
import WarnignModalPopup from "./../Modal/WarningModal";
import useStyles from "./../KeyTypes/CommonStyle";
import InternetDisconnected from "./../Modal/InternetDisconnected";
import Processing from "./Processing";

const AddNewVersion = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { reduxState, reduxActions } = props;
  const { deviceListInfo } = reduxState;
  const { GetDeviceByDeviceId } = reduxActions;
  const [newVersion, setNewVersion] = useState("");
  const [keyGenerator, setKeyGenerator] = useState("");
  const [isValidVersion, setIsValidVersion] = useState(false);
  const [isVersionExists, setIsVersionExists] = useState(false);

  const [deviceListStatus, setDeviceListStatus] = useState("Pending");
  const [isDeviceListReady, setDeviceListReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const token = useSelector((state) => state.userAccount.tokenId);
  const [onlineStatus, setOnlineStatus] = useState(false);
  //const deviceCode = history.location.state.deviceCode;
  const deviceCode =
    props.location.state !== undefined ? props.location.state.deviceCode : null;
  const role =
    props.location.state !== undefined ? props.location.state.role : undefined;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearDeviceList());
    setIsLoading(true);
  }, [dispatch]);

  useEffect(() => {
    if (!isDeviceListReady && deviceListInfo.fetched === "pending") {
      GetDeviceByDeviceId(deviceCode, role);
      setIsLoading(false);
    }
    if (deviceListInfo.fetched === "fetched") {
      setDeviceListReady(true);
      setIsLoading(false);
    }
    if (
      deviceListInfo.isSuccess === false &&
      (deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize")
    ) {
      displayPopUp(deviceListInfo.errorMessage);
      setIsLoading(false);
    }
  }, [
    GetDeviceByDeviceId,
    deviceCode,
    deviceListInfo,
    isDeviceListReady,
    role,
  ]);

  const addDeviceVersion = async () => {
    setIsValidVersion(false);
    setIsVersionExists(false);
    const deviceId = deviceListInfo.deviceListByID.id;
    if (
      newVersion !== "" &&
      newVersion.charAt(0) !== "<" &&
      newVersion.charAt(0) !== ">" &&
      newVersion.charAt(0) !== "="
    ) {
      setIsValidVersion(true);
      return;
    } else {
      setIsValidVersion(false);
    }
    setIsLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      if (newVersion !== "" && keyGenerator !== "") {
        const result = await AddDeviceNewVersion(token, {
          deviceId,
          version: newVersion,
          keyGenerator,
        });
        if (result.status === true) {
          history.push({
            pathname: "/home/Devices/ViewDeviceDetails",
            state: { deviceCode: deviceCode, role: role },
          });
        } else {
          if (result.statusCode === 401) {
            setDeviceListStatus("unAuthorize");
            displayPopUp(result.message);
          } else if (result.message === "Version already exists.") {
            setIsVersionExists(true);
          } else {
            setDeviceListStatus("failed");
            displayPopUp(result.message);
          }
          setIsLoading(false);
          //displayPopUp(result.message);
        }
      } else {
        setIsLoading(false);
        //history.push("/home/Devices");
      }
    } else {
      setIsLoading(false);
      setOnlineStatus(true);
    }
  };

  const displayPopUp = (text) => {
    setErrorText(text);
    setIsError(true);
  };

  const handleClose = () => {
    setIsLoading(true);
    setIsError(false);
    setErrorText("");
    if (
      deviceListInfo.fetched === "unAuthorize" ||
      deviceListStatus === "unAuthorize"
    ) {
      logOut();
    } else {
      setIsLoading(false);
    }
    dispatch(clearErrorMessage());
  };

  const logOut = () => {
    let path = window.location.origin + "/Logout";
    window.location.href = path;
  };

  const closeOnlineStatus = () => {
    setOnlineStatus(false);
  };

  return isLoading || deviceListInfo.fetched === "pending" ? (
    <Processing progressText={true} />
  ) : deviceListInfo.fetched === "fulfilled" ? (
    <div className={classes.mainBodyContainer}>
      <div className="deviceNameContainer">
        <div className="headingUppercaseDiv">
          {deviceListInfo.deviceListByID.name}
        </div>
        <span className="headSpan">
          ({deviceListInfo.deviceListByID.icCode})
        </span>
      </div>
      <div className="deviceAddNewVersionDiv">
        <div
          id="divVersionContainer"
          style={{
            display: "inline-flex",
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "center",
          }}>
          <div
            id="divVersionlabel"
            style={{
              width: "121px",
              height: "inherit",
              margin: "auto 0px",
              paddingRight: "15px",
              font: "var(--zui-typography-subtitle2)",
              fontSize: "12px !important",
              lineHeight: "16px !important",
              color: "#555E6B",
            }}>
            {"Version"}
          </div>
          <div
            id="versionGrid"
            style={{
              display: "block",
              width: "calc(100% - 72px)",
              alignItems: "center",
              overflowX: "auto",
              marginRight: "10px",
            }}>
            <div id="divVersions" style={{ display: "inline-flex" }}>
              {deviceListInfo.deviceListByID.deviceKeyGeneratorMappings.map(
                (item, index) => {
                  return (
                    <div
                      key={"deviceListByID" + index}
                      style={{
                        padding: "8px",
                        font: "var(--zui-typography-subtitle2)",
                        minWidth: "105px",
                        alignItems: "center",
                      }}>
                      <div
                        key={"deviceListByID" + index}
                        style={{
                          width: "100%",
                          background: "#07090D",
                          color: "#EDF2F7",
                          display: "flex",
                          padding: "5px 16px",
                          fontSize: "12px",
                          userSelect: "none",
                        }}>
                        {item.operator}
                        {item.version}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="addNewVersionContainer">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "26px 0 0 24px",
          }}>
          <div style={{ width: "33.3%" }}>
            <form autoComplete="off">
              <div
                style={{
                  width: "100%",
                  paddingBottom: "20px",
                  font: "var(--zui-typography-subtitle2)",
                  fontSize: "12px !important",
                  lineHeight: "16px !important",
                  color: "#555E6B",
                }}>
                {"Add a New Version"}
              </div>
              <div style={{ width: "65%", height: "40px" }}>
                <input
                  type="text"
                  placeholder="Add Version"
                  className={`${
                    isValidVersion
                      ? classes.adminErrorInput
                      : classes.adminDeviceInput
                  }`}
                  value={newVersion}
                  onChange={(e) => {
                    let ve = e.target.value;
                    ve = ve.replace(/[A-Za-z]/gi, "");
                    setNewVersion(ve);
                    setIsVersionExists(false);
                    setIsValidVersion(false);
                  }}
                />
                <span
                  className={`${
                    isValidVersion || isVersionExists
                      ? classes.adminErrorSpan
                      : classes.displayNone
                  }`}>
                  {isVersionExists
                    ? "The Software version already exists"
                    : "The software version should start with '=', '<', '<=', '>' or '>='"}
                </span>
              </div>
              <div style={{ width: "65%", fontWeight: "bold" }}>
                <div
                  style={{
                    padding: "30px 0 20px 0",
                    font: "var(--zui-typography-caption)",
                    fontSize: "12px !important",
                    lineHeight: "16px !important",
                    color: "#1A2329",
                  }}>
                  {"Key Generators"}
                </div>
                <ZuiRadioButtonGroup name="keyGenerator" value={keyGenerator}>
                  <ZuiRadioButton
                    name="keyGenerator"
                    label="Genlic"
                    value="Genlic"
                    style={{ paddingBottom: "10px" }}
                    onchange={(v) =>
                      setKeyGenerator(v.target.value)
                    }></ZuiRadioButton>
                  <ZuiRadioButton
                    name="keyGenerator"
                    label="Ladon 1"
                    value="Ladon1"
                    style={{ paddingBottom: "10px" }}
                    onchange={(v) =>
                      setKeyGenerator(v.target.value)
                    }></ZuiRadioButton>
                  <ZuiRadioButton
                    name="keyGenerator"
                    label="Ladon 2"
                    value="Ladon2"
                    style={{ paddingBottom: "10px" }}
                    onchange={(v) =>
                      setKeyGenerator(v.target.value)
                    }></ZuiRadioButton>
                </ZuiRadioButtonGroup>
              </div>
            </form>
          </div>
          <div style={{ width: "33.3%" }}>
            <div style={{ width: "100%" }}></div>
          </div>
          <div style={{ width: "33.3%" }}>
            <div style={{ width: "100%" }}></div>
          </div>
        </div>
      </div>
      <div className="footerContainer">
        <ZuiButton
          size="s"
          title="Cancel"
          environment="auto"
          className="footerLargeButton2"
          onClick={() => {
            history.push({
              pathname: "/home/Devices/ViewDeviceDetails",
              state: { deviceCode: deviceCode, role: role },
            });
          }}>
          {"Cancel"}
        </ZuiButton>
        <ZuiButton
          size="s"
          title="Update"
          environment="auto"
          emphasis="active-primary"
          className="footerLargeButton1"
          disabled={newVersion !== "" && keyGenerator !== "" ? false : true}
          onClick={() => addDeviceVersion()}>
          {"Update"}
        </ZuiButton>
      </div>
      <div id="fseModalPopup">
        <WarnignModalPopup
          state={isError}
          title={errorText}
          clickClose={handleClose}
        />
      </div>
      <InternetDisconnected
        state={onlineStatus}
        clickClose={closeOnlineStatus}></InternetDisconnected>
    </div>
  ) : (
    <>
      {(deviceListStatus === "failed" ||
        deviceListStatus === "unAuthorize" ||
        deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize") &&
      isError ? (
        <div id="fseModalPopup">
          <WarnignModalPopup
            state={isError}
            title={errorText}
            clickClose={handleClose}
          />
        </div>
      ) : (
        <RetrieveDataError />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      GetDeviceByDeviceId: actions.GetDeviceByDeviceCode,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  reduxState: {
    deviceListInfo: state.deviceListInfo,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewVersion);
