import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as actions from "../../actions/index";
import RetrieveDataError from "./RetrieveDataError";
import useStyles from "./../KeyTypes/CommonStyle";
import "./Admin.css";
import { useHistory } from "react-router-dom";
import {
  ZuiButton,
  ZuiRadioButtonGroup,
  ZuiRadioButton,
  ZuiCheckbox,
  ZuiList,
  ZuiListItem,
  ZuiIconEwiInfo,
} from "@zeiss/zui-react";
import "@zeiss/zui";
import WarnignModalPopup from "./../Modal/WarningModal";
import InternetDisconnected from "./../Modal/InternetDisconnected";
import Processing from "./Processing";
import { ListItemAvatar } from "@material-ui/core";

const AddDevice = () => {
  const classes = useStyles();
  const history = useHistory();
  const token = useSelector((state) => state.userAccount.tokenId);
  const [skillsetList, setskillsetList] = useState([]);
  const [skillsetName, setSkillsetName] = useState("");
  const [newDeviceName, setNewDeviceName] = useState("");
  const [isDeviceNameExists, setIsDeviceNameExists] = useState(false);
  const [newSoftwareVersion, setNewSoftwareVersion] = useState("");
  const [isInvalidVersion, setIsInvalidVersion] = useState(false);
  //const [isVersionExists, setIsVersionExists] = useState(false);
  const [newIcCode, setNewIcCode] = useState("");
  const [isIcCodeExists, setIsIcCodeExists] = useState(false);
  const [isInvalidIcCode, setIsInvalidIcCode] = useState(false);
  const [isKeyTypeChecked, setIsKeyTypeChecked] = useState(false);
  const [keyGenerator, setKeyGenerator] = useState("");
  const [userRole, setUserRole] = useState("");
  const [keyTypes] = useState([
    { isChecked: false, id: 1, description: "FSE/Master Key" },
    { isChecked: false, id: 2, description: "OneDay Key" },
    { isChecked: false, id: 3, description: "IT Admin PWD" },
    { isChecked: false, id: 4, description: "Pentero & Old Service PWD" },
    { isChecked: false, id: 5, description: "BIOMed Key" },
    { isChecked: false, id: 6, description: "Factory Key" },
  ]);

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [addDeviceStatus, setAddDeviceStatus] = useState("Pending");
  const [isPenteroDevice, setIsPenteroDevice] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState(false);

  useEffect(() => {
    setAddDeviceStatus("fulfilled");
    setIsLoading(false);
  }, []);

  const addNewDevice = async () => {
    setIsLoading(true);

    const keyType = keyTypes
      .map((t) => (t.isChecked ? t.id : ""))
      .filter((t) => t !== "");

    if (newIcCode !== "" && newIcCode.length < 4) {
      setIsInvalidIcCode(true);
      setIsLoading(false);
    }
    if (
      newDeviceName !== "" &&
      skillsetName !== "" &&
      keyGenerator !== "" &&
      userRole !== "" &&
      keyType.length !== 0 &&
      newIcCode !== "" &&
      newIcCode.length === 4
    ) {
      if (
        keyGenerator === "Genlic" &&
        (newSoftwareVersion === "" || isPenteroDevice)
      ) {
        setIsInvalidVersion(false);
        await submitDevice(keyType);
      } else {
        if (
          newSoftwareVersion !== "" &&
          (newSoftwareVersion.charAt(0) === "<" ||
            newSoftwareVersion.charAt(0) === ">" ||
            newSoftwareVersion.charAt(0) === "=")
        ) {
          setIsInvalidVersion(false);
          await submitDevice(keyType);
        } else {
          setIsInvalidVersion(true);
          setIsLoading(false);
        }
      }
    } else {
      setIsLoading(false);
    }
  };
  const submitDevice = async (keyType) => {
    const deviceToBeAdded = {
      deviceName: newDeviceName,
      version:
        isPenteroDevice && keyGenerator.includes("Genlic")
          ? ""
          : newSoftwareVersion,
      icCode: newIcCode,
      skill: skillsetName,
      keyType: keyType,
      keyGenerator: keyGenerator,
      role: userRole,
    };
    let onlineStatus = await actions.checkOnlineStatus();
    if (onlineStatus !== false) {
      actions
        .AddDevice(token, deviceToBeAdded)
        .then((resp) => {
          if (resp.status) {
            history.push("/home/Devices");
          } else {
            if (resp.statusCode === 401) {
              setAddDeviceStatus("unAuthorize");
              displayPopUp(resp.message);
            } else if (resp.statusCode === 400) {
              if (resp.message.includes("Device already exists"))
                setIsDeviceNameExists(true);
              else if (resp.message.includes("Device IcCode already exists"))
                setIsIcCodeExists(true);
              else if (resp.message.includes("Invalid IcCode")) {
                setIsInvalidIcCode(true);
              } else {
                setAddDeviceStatus("failed");
                displayPopUp(resp.message);
              }
            }
            setIsLoading(false);
            //displayPopUp(resp.message);
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
      setOnlineStatus(true);
    }
  };
  const displayPopUp = (text) => {
    setErrorText(text);
    setIsError(true);
  };

  const handleClose = () => {
    setIsLoading(true);
    setIsError(false);
    setErrorText("");
    if (addDeviceStatus === "unAuthorize") {
      logOut();
    } else {
      setIsLoading(false);
      setAddDeviceStatus("fulfilled");
    }
  };

  const logOut = () => {
    let path = window.location.origin + "/Logout";
    window.location.href = path;
  };

  const closeOnlineStatus = () => {
    setOnlineStatus(false);
  };

  return isLoading || addDeviceStatus === "pending" ? (
    <Processing progressText={true} />
  ) : addDeviceStatus === "fulfilled" ? (
    <div className={classes.mainBodyContainer}>
      <div
        style={{
          margin: "8px",
          width: "98.5%",
          background: "var(	--zui-color-gs-10)",
        }}>
        <div
          style={{
            padding: "18px 0 18px 24px",
            fontSize: "16px !important",
            lineHeight: "24px !important",
            margin: "0",
            color: "#1A2329",
            font: "var(--zui-typography-h4)",
          }}>
          {"Add Device"}
        </div>
      </div>
      <div className="addDeviceBodyContainer">
        <div className="addDeviceBodyDiv">
          <div className="addDeviceBlocks">
            <input
              style={{ width: "100%" }}
              type="text"
              placeholder="New Device Name"
              className={`${
                isDeviceNameExists
                  ? classes.adminErrorInput
                  : classes.adminDeviceInput
              }`}
              value={newDeviceName}
              onChange={(e) => {
                setNewDeviceName(e.target.value);
                setIsDeviceNameExists(false);
                setIsPenteroDevice(false);
              }}
              onBlur={async (e) => {
                setIsPenteroDevice(
                  e.target.value.toUpperCase().includes("PENTERO")
                );
                e.persist();
                let onlineStatus = await actions.checkOnlineStatus();
                if (onlineStatus !== false) {
                  await actions
                    .GetDeviceByName(token, newDeviceName)
                    .then((resp) => {
                      if (resp.statusCode === 200) {
                        setIsDeviceNameExists(true);
                      } else if (resp.statusCode === 401) {
                        setAddDeviceStatus("unAuthorize");
                        displayPopUp(resp.message);
                      } else {
                        setIsDeviceNameExists(false);
                      }
                    });
                } else {
                  setOnlineStatus(true);
                }
              }}
            />
            <span
              className={`${
                isDeviceNameExists
                  ? classes.adminErrorSpan
                  : classes.displayNone
              }`}>
              {"Device already exists"}
            </span>
          </div>
          <div className="addDeviceBlocks">
            <input
              type="text"
              placeholder="Software version"
              className={`${
                isInvalidVersion
                  ? classes.adminErrorInput
                  : classes.adminDeviceInput
              }`}
              value={newSoftwareVersion}
              onChange={(e) => {
                setIsInvalidVersion(false);
                let ve = e.target.value;
                ve = ve.replace(/[A-Za-z]/gi, "");
                setNewSoftwareVersion(ve);
              }}
              disabled={
                isPenteroDevice
                  ? keyGenerator.includes("Genlic")
                    ? true
                    : false
                  : false
              }
            />
            <span
              className={`${
                isInvalidVersion ? classes.adminErrorSpan : classes.displayNone
              }`}>
              {
                "The software version should start with '=', '<', '<=', '>' or '>='"
              }
            </span>
          </div>
          <div className="addDeviceBlocks">
            <input
              style={{ width: "100%" }}
              type="text"
              maxLength="4"
              placeholder="IC code"
              className={`${
                isIcCodeExists || isInvalidIcCode
                  ? classes.adminErrorInput
                  : classes.adminDeviceInput
              }`}
              value={newIcCode}
              onChange={(e) => {
                setIsInvalidIcCode(false);
                setIsIcCodeExists(false);
                setNewIcCode(e.target.value);
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            <span
              className={`${
                isIcCodeExists || isInvalidIcCode
                  ? classes.adminErrorSpan
                  : classes.displayNone
              }`}>
              {isInvalidIcCode
                ? "Enter 4-digit IC code"
                : "IC code already exists"}
            </span>
          </div>
          <div className="addDeviceBlocks">
            <input
              autoComplete="off"
              style={{ width: "100%" }}
              className={`${classes.adminDeviceInput}`}
              name="skillsetNameAddDevice"
              placeholder="Skillset"
              type="text"
              onChange={async (e) => {
                if (e.target.value === " ") {
                  e.preventDefault();
                  return;
                }
                keyTypes.map((item) => (item.isChecked = false));
                setIsKeyTypeChecked(false);
                e.persist();
                setSkillsetName(e.target.value);
                let onlineStatus = await actions.checkOnlineStatus();
                if (onlineStatus !== false) {
                  await actions
                    .SearchSkills(e.target.value, token)
                    .then((certificates) => {
                      if (
                        certificates.status === true &&
                        certificates.data !== undefined
                      ) {
                        const itemsss = certificates.data.data.map((item) => {
                          if (
                            item.name !== "" ||
                            item.name !== undefined ||
                            item.name !== null
                          ) {
                            return item.name;
                          } else {
                            return "";
                          }
                        });
                        setskillsetList(itemsss.filter((it) => it !== ""));
                      } else {
                        if (certificates.statusCode === 401) {
                          setAddDeviceStatus("unAuthorize");
                          displayPopUp(certificates.message);
                        }
                        setskillsetList([]);
                      }
                    });
                } else {
                  setOnlineStatus(true);
                }
              }}
              value={skillsetName}
            />
            {skillsetList.length !== 0 && skillsetName !== "" ? (
              <ZuiList
                integrated
                hierarchy="second"
                style={{
                  width: "16%",
                  zIndex: "1000000",
                  position: "absolute",
                  height: "calc(100% - 40%)",
                  overflow: "auto",
                }}>
                {skillsetList
                  .filter((ss) => ss !== "")
                  .map((item, index) => {
                    return (
                      <ZuiListItem
                        style={{
                          height: "auto !important",
                          padding: "0px 10px 30px 10px",
                        }}
                        key={index}
                        onClick={async () => {
                          
                          setSkillsetName(item);
                          setskillsetList([]);
                          setIsLoading(true);
                          
                          await actions
                          .GetKeyTypesBySkills([item.substring(item.indexOf('|')+2,item.length)], token)
                            .then((k) => {
                              if (
                                k.data.data !== null ||
                                k.data.data !== undefined ||
                                k.data.data.length !== 0
                              ) {
                                keyTypes.map((a) => {
                                  // let isChecked = false;
                                  k.data.data.map((o1) => {
                                    if (a.id === o1.id) {
                                      a.isChecked = true;
                                    }
                                    return true;
                                  });
                                  return true;
                                });
                                setIsKeyTypeChecked(true);
                              } else {
                                setIsKeyTypeChecked(false);
                              }
                              setIsLoading(false);
                            });
                        }}>
                        {item}
                      </ZuiListItem>
                    );
                  })}
              </ZuiList>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="addDeviceBodyInnerDiv">
          <div className="addDeviceInnerBlocks">
            <div
              style={{
                paddingBottom: "15px",
                font: "var(--zui-typography-caption)",
                fontSize: "12px !important",
                lineHeight: "16px !important",
                color: "#1A2329",
              }}>
              {"Key Types"}
            </div>
            {keyTypes.map((item, index) => {
              return (
                <ZuiCheckbox
                  key={item.id}
                  value={item.isChecked}
                  name={item.description}
                  label={item.description}
                  style={{ paddingBottom: "10px" }}
                  onChange={(e) => {
                    keyTypes[index].isChecked = e.target.value;
                    let isAllSelected = false;
                    keyTypes.map((item) => {
                      if (item.isChecked === true) {
                        isAllSelected = true;
                      }
                      return true;
                    });
                    setIsKeyTypeChecked(isAllSelected);
                  }}></ZuiCheckbox>
              );
            })}
          </div>
          <div className="addDeviceInnerBlocks">
            <div
              style={{
                paddingBottom: "15px",
                font: "var(--zui-typography-caption)",
                fontSize: "12px !important",
                lineHeight: "16px !important",
                color: "#1A2329",
              }}>
              {"Key Generators"}
            </div>
            <ZuiRadioButtonGroup name="keyGenerator" value={keyGenerator}>
              <ZuiRadioButton
                name="keyGenerator"
                label="Genlic"
                value="Genlic"
                style={{ paddingBottom: "10px" }}
                onClick={(v) => {
                  setKeyGenerator(v.target.value);
                }}></ZuiRadioButton>
              <ZuiRadioButton
                name="keyGenerator"
                label="Ladon 1"
                value="Ladon1"
                style={{ paddingBottom: "10px" }}
                onClick={(v) => {
                  setKeyGenerator(v.target.value);
                }}></ZuiRadioButton>
              <ZuiRadioButton
                name="keyGenerator"
                label="Ladon 2"
                value="Ladon2"
                style={{ paddingBottom: "10px" }}
                onClick={(v) => {
                  setKeyGenerator(v.target.value);
                }}></ZuiRadioButton>
            </ZuiRadioButtonGroup>
          </div>
          <div className="addDeviceInnerBlocks">
            <div
              style={{
                paddingBottom: "15px",
                font: "var(--zui-typography-caption)",
                fontSize: "12px !important",
                lineHeight: "16px !important",
                color: "#1A2329",
              }}>
              {"Skill Name"}
            </div>
            <ZuiRadioButtonGroup name="userRole" value={userRole}>
              <ZuiRadioButton
                name="userRole"
                label="General"
                value="General"
                style={{ paddingBottom: "10px" }}
                onClick={(v) => {
                  setUserRole(v.target.value);
                }}></ZuiRadioButton>
             {/* <ZuiRadioButton
                name="userRole"
                label="CIC"
                value="CIC"
                style={{ paddingBottom: "10px" }}
                onClick={(v) => {
                  setUserRole(v.target.value);
                }}></ZuiRadioButton>
              <ZuiRadioButton
                name="userRole"
                label="BioMed"
                value="BIOMed"
                style={{ paddingBottom: "10px" }}
                onClick={(v) => {
                  setUserRole(v.target.value);
                }}></ZuiRadioButton>*/}
            </ZuiRadioButtonGroup>
          </div>
          <div className="addDeviceInnerBlocks"></div>
        </div>
      </div>
      <div className="footerContainer">
        <div className="adminInfoFooterContainer">
          <ZuiIconEwiInfo></ZuiIconEwiInfo>
          <span style={{ marginLeft: "8px" }}>
            {"Ensure the format of skillset is same as mentioned in Cornerstone "}
          </span>
        </div>
        <ZuiButton
          size="s"
          title="Cancel"
          environment="auto"
          className="footerLargeButton2"
          onClick={() => {
            history.push("/home/Devices");
          }}>
          {"Cancel"}
        </ZuiButton>
        <ZuiButton
          size="s"
          title="Add a Device"
          environment="auto"
          emphasis="active-primary"
          className="footerLargeButton1"
          disabled={
            skillsetName !== ""
              ? newIcCode !== ""
                ? newDeviceName !== ""
                  ? isKeyTypeChecked
                    ? keyGenerator !== ""
                      ? userRole !== ""
                        ? keyGenerator.includes("Genlic")
                          ? false
                          : newSoftwareVersion !== ""
                          ? false
                          : true
                        : true
                      : true
                    : true
                  : true
                : true
              : true
          }
          onclick={() => {
            addNewDevice();
          }}>
          {"Add a device"}
        </ZuiButton>
      </div>
      <div id="fseModalPopup">
        <WarnignModalPopup
          state={isError}
          title={errorText}
          clickClose={handleClose}
        />
      </div>
      <InternetDisconnected
        state={onlineStatus}
        clickClose={closeOnlineStatus}></InternetDisconnected>
    </div>
  ) : (
    <>
      {(addDeviceStatus === "failed" || addDeviceStatus === "unAuthorize") &&
      isError ? (
        <div id="fseModalPopup">
          <WarnignModalPopup
            state={isError}
            title={errorText}
            clickClose={handleClose}
          />
        </div>
      ) : (
        <RetrieveDataError />
      )}
    </>
  );
};
export default AddDevice;
