import * as ActionTypes from "../../constants/actionTypes";

export const setOnlineStatus = (status) => ({
    type: ActionTypes.SET_INTERNET_ONLINE_STATUS,
    payload: status,
  });
  
  export const setAuthentication = (data) => ({
    type: ActionTypes.SET_AUTHENTICATION_STATUS,
    payload: data,
  });
  
  export const setAuthorization = (data) => ({
    type: ActionTypes.SET_AUTHORIZATION_STATUS,
    payload: data,
  });
  
  export const clearErrorState = () => ({
    type: ActionTypes.CLEAR_MESSAGE,
  });
