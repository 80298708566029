import React, { useState } from "react";
import { makeStyles, Modal } from "@material-ui/core";
import { ZuiButton } from "@zeiss/zui-react";
import CustomDatePicker from "./../CustomDatePicker/CustomDatePicker";
import { addDays } from "date-fns";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
    // background: "#1a23293b !important",
    zIndex: "3002 !important",
  },
  modalPaper: {
    position: "fixed",
    [theme.breakpoints.down("xs")]: {
      width: 180,
      height: 236,
      top: "136px",
      right: "142px",
    },
    [theme.breakpoints.up("sm")]: {
      width: 199,
      height: 222,
      top: "136px",
      right: "138px",
    },
    [theme.breakpoints.up("md")]: {
      width: 199,
      height: 222,
      top: "136px",
      right: "152px",
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: "none",
    padding: "0px",
    textAlign: "center",
    borderRadius: "5px",
    outline: "none",
  },
  modalHeader: {
    display: "flex",
    padding: "8px 16px 2px 16px",
    textAlign: "left",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    border: "0px",
  },
  modalCloseIcon: {
    border: "none",
    background: "white",
    cursor: "pointer",
    fontWeight: "bolder",
    height: "100%",
    color: "#626c7a",
    fontSize: "large",
  },
  modalBody: {
    height: "calc(100% - 90px)",
    padding: "0px 16px",
    position: "relative",
    textAlign: "left",
  },
  showErrorMessage: {
    width: "100%",
    padding: "1px 0px",
    font: "var(--zui-typography-body)",
    fontSize: "12px",
    lineHeight: "12px",
    textAlign: "center",
    color: "#FF0000",
    [theme.breakpoints.up("xs")]: {
      fontSize: "11px",
      color: "#FF0000",
    },
  },
  errorMessageHide: {
    visibility: "hidden",
    [theme.breakpoints.up("xs")]: {
      fontSize: "11px",
      color: "#FF0000",
    },
  },
  messagebody: {
    width: "inherit",
    position: "relative",
    display: "block",
    [theme.breakpoints.up("xs")]: {
      margin: "1px 0px",
    },
    [theme.breakpoints.up("md")]: {
      margin: "5px 0px",
    },
  },
  modalFooter: {
    // borderTop: "1px solid #DDE3ED",
    alignSelf: "center",
    display: "block",
    width: "100%",
    textAlign: "center",
    position: "absolute",
    left: "0",
    bottom: "0",
    paddingBottom: "12px",
  },
  formLableText: {
    textAlign: "left",
    fontSize: "14px",
    marginTop: "2px",
    lineHeight: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    datepickerText: {
      textAlign: "left",
      fontSize: "14px",
      lineHeight: "20px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
  },
}));

function getModalStyle() {
  // const top = 50;
  // const left = 50;
  // return {
  //   top: `${top}%`,
  //   left: `${left}%`,
  //   transform: `translate(-${top}%, -${left}%)`,
  // };
  return;
}

const LogFileDownloadModal = ({ state, clickClose, downloadFile }) => {
  const classes = useStyles();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isFromDateError, setIsFromDateError] = useState(false);
  const [isToDateError, setIsToDateError] = useState(false);
  const [downloadModalStyle] = useState(getModalStyle);

  let fromErrorClass = isFromDateError
    ? `${classes.showErrorMessage}`
    : `${classes.errorMessageHide}`;

  let toErrorClass = isToDateError
    ? `${classes.showErrorMessage}`
    : `${classes.errorMessageHide}`;

  const downloadLogFiles = () => {
    if (fromDate === null || fromDate === "") {
      setIsFromDateError(true);
    } else {
      setIsFromDateError(false);
    }
    if (toDate === null || toDate === "") {
      setIsToDateError(true);
    } else {
      setIsToDateError(false);
    }
    if (fromDate !== "" && toDate !== "") {
      const fromDateValue =
        fromDate &&
        Intl.DateTimeFormat("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }).format(fromDate);
      const toDateValue =
        toDate &&
        Intl.DateTimeFormat("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }).format(toDate);
      downloadFile({ FromDate: fromDateValue, ToDate: toDateValue });
    } else {
      return;
    }
  };

  const closeModal = () => {
    setFromDate("");
    setToDate("");
    setIsFromDateError(false);
    setIsToDateError(false);
    clickClose();
  };
  return (
    <>
      <Modal
        disableAutoFocus={true}
        className={classes.modal}
        open={state}
        onClose={closeModal}
        aria-labelledby="download-modal-title"
        aria-describedby="download-modal-description"
        hideBackdrop={true}>
        <div style={downloadModalStyle} className={classes.modalPaper}>
          <div
            className={classes.modalHeader}
            style={{ justifyContent: "space-between" }}>
            <span>{"Download log files"}</span>
            <span className={classes.modalCloseIcon} onClick={closeModal}>
              &times;
            </span>
          </div>
          <div className={classes.modalBody}>
            <div className={""}>
              <div className={classes.formLableText}>{"From"}</div>
              <div id="divFromDate" className={classes.datepickerText}>
                <CustomDatePicker
                  isInvalid={false}
                  disableInput={false}
                  value={fromDate}
                  minDate={addDays(new Date(), -181)}
                  maxDate={toDate ? toDate : new Date()}
                  setSelectedDate={(value) => {
                    setFromDate(value);
                  }}></CustomDatePicker>
              </div>

              <span className={fromErrorClass}>
                {"* Please select From Date"}
              </span>
            </div>
            <div className={""}>
              <div className={classes.formLableText}>{"To"}</div>
              <div id="divToDate" className={classes.datepickerText}>
                <CustomDatePicker
                  isInvalid={false}
                  disableInput={false}
                  value={toDate}
                  minDate={fromDate ? fromDate : addDays(new Date(), -181)}
                  maxDate={new Date()}
                  setSelectedDate={(value) => {
                    setToDate(value);
                  }}></CustomDatePicker>
              </div>
              <span className={toErrorClass}>{"* Please select To Date"}</span>
            </div>
          </div>
          <div className={`${classes.modalFooter}`}>
            <ZuiButton emphasis="active-primary" onClick={downloadLogFiles}>
              {"Download"}
            </ZuiButton>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default LogFileDownloadModal;
