/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Grid, Paper } from "@material-ui/core";
import { bindActionCreators } from "redux";
import * as actions from "./../../../actions";
import { connect, useDispatch } from "react-redux";
import { clearDeviceList, checkOnlineStatus } from "actions";
import keyTypeEnum from "../../../constants/KeyTypeEnum";
import { printIframe } from "./../FSEMasterKey/FSEMasterKey";
import DeviceNotAvailable from "../DeviceNotAvailable";
import crossIcon from "./../../../assets/icons/close-error-icn.png";
// import InfoModalPopup from "./../../Modal/InformationModal";
import WarnignModalPopup from "./../../Modal/WarningModal";
import InternetDisconnected from "./../../Modal/InternetDisconnected";
import "@zeiss/zui";
import useStyles from "../CommonStyle";
import Processing from "Components/Admin/Processing";
// import "./../CommonStyleSheet.css";

const PenteroKeyViewMaster = ({
  selectedDeviceName,
  selectedDeviceSerialNo,
  penteroKeyList,
  downloadKey,
  reloadPenteroKey,
  getPrintingText,
}) => {
  const classes = useStyles();
  const isIpad = /iPad|Macintosh/i.test(navigator.userAgent);
  const isTouchDevice = navigator.maxTouchPoints > 0;
  let showPrint = isIpad && isTouchDevice ? false : true;
  return (
    <>
      <div id="div_Pentero_Conainer" className={`${classes.divContainer}`}>
        <div
          id="divSelectionView"
          className={classes.divSelectionViewContainer}>
          <div className={`${classes.headerText}`}>
            {"Pentero & Old Service PWD"}
          </div>
          <div
            id="divDeviceListkeyContainer"
            className={`${classes.divDeviceListkeyContainer} divPenteroKeyContainerHeight`}
            style={{ background: "#FFFF" }}>
            <div className={`${classes.displayPenteroKeyDiv}`}>
              <div className={`${classes.PenteroDeviceName}`}>
                {" "}
                {selectedDeviceName}{" "}
              </div>
              <div
                className={`${classes.PenteroDeviceSerialNum}`}>{`Serial Number: ${selectedDeviceSerialNo}`}</div>
            </div>
            <div
              className={`divPenteroKeyContainerHeight ${classes.displayPenteroKeysCls}`}>
              <div id="divKeylistContainter">
                {penteroKeyList.map((device, index) => {
                  return !device.identifier.includes("Autofocus/AutoDrape") ? (
                    <div
                      id={`divKeyContainter${index}`}
                      className={`${classes.divKeyContainter}`}>
                      <div className={`${classes.displayPenteroKeyIdentifier}`}>
                        {`${device.identifier}`}
                      </div>
                      <div className={`${classes.displayPenteroKeyValue}`}>
                        {`${device.key.toString()}`}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div style={{ height: "auto" }}>
                        <div className={`${classes.onlyForPenteroText}`}>
                          {
                            "Only for Pentero SW 2.5.1 (Please enter the following licences)"
                          }
                        </div>
                      </div>
                      <div
                        id={`divKeyContainter${index}`}
                        className={`${classes.divKeyContainter}`}>
                        <div
                          className={`${classes.displayPenteroKeyIdentifier}`}>
                          {`${device.identifier}`}
                        </div>
                        <div className={`${classes.displayPenteroKeyValue}`}>
                          {`${device.key.toString()}`}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div id="fixedFooter" className={`${classes.fixedFooter}`}>
          <div className={`${classes.footerContainer} `}>
            <button
              type="button"
              className={`${classes.button} btn-clear`}
              onClick={() => {
                reloadPenteroKey();
              }}>
              {"Back"}
            </button>
            {showPrint && (
              <button
                type="button"
                className={`${classes.button} btn-clear`}
                onClick={() => {
                  getPrintingText();
                }}>
                {"Print"}
              </button>
            )}
            <button
              type="button"
              className={`${classes.button} ${classes.btnSubmit}`}
              onClick={() => {
                downloadKey();
              }}>
              {"Download"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
const DevicesViewMaster = ({ deviceListInfo, sendDataToParent }) => {
  const classes = useStyles();
  const [selectedDeviceSerialNo, setSelectedDeviceSerialNo] = useState("");
  const [selectedDeviceName, setSelectedDeviceName] = useState("");
  const [sixDigitNo, setSixDigitNo] = useState("");
  const [isValidInput, setIsValidInput] = useState(true);
  const [displayClearCross, setDisplayClearCross] = useState(false);

  const handleInputChange = (e, device) => {
    setSixDigitNo(e.target.value);
    setSelectedDeviceSerialNo(device.icCode + "" + e.target.value);
  };

  const selectedDevice = (device) => {
    setIsValidInput(true);
    setDisplayClearCross(false);
    if (selectedDeviceName !== device.name) {
      setSelectedDeviceName(device.name);
      setSixDigitNo("");
    } else {
      setSelectedDeviceName("");
      setSixDigitNo("");
    }
  };
  return (
    <>
      <div id="div_Pentero_Conainer" className={`${classes.divContainer}`}>
        <div id="divSelectionView" style={{ height: "calc(100% - 120px)" }}>
          <div className={`${classes.headerText}`}>
            {"Pentero & Old Service PWD"}
          </div>
          <div
            id="div_selection"
            className={`${classes.divSelectionContainer} ${classes.displayInlineGrid}`}>
            <div id="form_label" className={classes.formLableKey}>
              {"Select device and enter last 6 digits serial number"}
            </div>
          </div>
          <div
            id="divDeviceListContainer"
            className={`divDeviceListContainerHeight ${classes.divDeviceListContainer}`}>
            <form autoComplete="off">
              <Grid
                className={`${classes.muiGridNoSpacing}`}
                container
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start">
                {deviceListInfo.map((device, index) => {
                  const labelId = `transfer-list-all-item-${device.id}-label`;
                  const list1 = `list${device.id}`;
                  return (
                    <Grid
                      key={index}
                      role="listitem"
                      className={`${classes.gridPaddingXl} `}
                      value={device.id}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={2}>
                      <Paper
                        id={labelId}
                        className={`${classes.paper} ${classes.gridTile} ${
                          classes.gridPenteroTile
                        }
                       cursorPoiner
                       ${
                         selectedDeviceName === device.name
                           ? classes.highlight
                           : ""
                       }`}>
                        <li
                          id={list1}
                          className={`${classes.deviceName} ${classes.ITAdminDeviceTextFormat}`}
                          onClick={() => selectedDevice(device)}>
                          {device.name}
                        </li>
                        <li
                          id={labelId}
                          className={`${classes.displayInputField} ${classes.cursorMenu}`}>
                          {/* <label
                          className={`${classes.displayIccodeLabel}`}
                          onClick={() => selectedDevice(device)}
                        >
                          {`${device.icCode}`}
                        </label> */}
                          <span onClick={() => selectedDevice(device)}>
                            <input
                              type="text"
                              value={`${device.icCode}`}
                              className={`${classes.displayIccodeLabel} ${classes.deviceInput}`}
                              disabled
                              onClick={() => selectedDevice(device)}
                            />
                          </span>
                          <input
                            type="tel"
                            id={`pentero-Input-${device.id}`}
                            placeholder="Last 6 digits of Serial Number"
                            className={`${
                              isValidInput === true
                                ? classes.deviceInput
                                : selectedDeviceName === device.name
                                ? classes.errorInput
                                : classes.deviceInput
                            }`}
                            value={
                              selectedDeviceName === device.name
                                ? sixDigitNo
                                : ""
                            }
                            onPaste={(event) => {
                              event.preventDefault();
                            }}
                            onClick={(e) => {
                              if (selectedDeviceName !== device.name) {
                                selectedDevice(device);
                              }
                            }}
                            onFocus={(e) => {
                              setIsValidInput(true);
                            }}
                            onInputCapture={(event) => {
                              event.target.value.replace(/[^0-9]*/g, "");
                            }}
                            onKeyPress={(event) => {
                              if (
                                event.charCode >= 48 &&
                                event.charCode <= 57 &&
                                event.target.value.length < 6
                              ) {
                                if (event.target.value.length === 6) {
                                  setIsValidInput(true);
                                  device.serialNumber = event.target.value;
                                }
                                return true;
                              } else {
                                event.preventDefault();
                                return false;
                              }
                            }}
                            onChange={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]*/g,
                                ""
                              );
                              e.target.value.replace(/[^0-9]*/g, "");
                              if (e.target.value.length === 6) {
                                device.serialNumber = e.target.value;
                                device.isValid = true;
                              } else {
                                if (e.target.value.length > 0) {
                                  setDisplayClearCross(true);
                                }
                                if (e.target.value.length < 1) {
                                  setDisplayClearCross(false);
                                }
                              }
                              device.isValid = false;
                              setIsValidInput(true);
                              handleInputChange(e, device);
                            }}
                            onBlur={(e) => {
                              setIsValidInput(true);
                            }}
                            maxLength="6"
                          />
                          <img
                            height="16"
                            width="16"
                            src={crossIcon}
                            alt=""
                            className={`${
                              selectedDeviceName === device.name &&
                              displayClearCross === true
                                ? classes.crossClear
                                : classes.displayNone
                            }`}
                            onClick={() => {
                              device.serialNumber = "";
                              setSixDigitNo("");
                              setDisplayClearCross(false);
                              setIsValidInput(true);
                            }}
                          />
                          <span
                            className={`${
                              isValidInput === false &&
                              selectedDeviceName === device.name
                                ? classes.errorSpan
                                : classes.displayNone
                            }`}>
                            {"Please enter last 6 digits serial number"}
                          </span>
                        </li>
                        <div
                          className={`${classes.divLablePadding}`}
                          onClick={() => selectedDevice(device)}></div>
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            </form>
          </div>
        </div>
        <div id="fixedFooter" className={`${classes.fixedFooter}`}>
          <div id="InfoConsent" className={classes.InfoConsent}>
            {
              "*All the devices displayed are based on the training certificates completed on LMS, in absense of certificate, kindly contact Cornerstone."
            }
          </div>
          <div className={`${classes.footerContainer} `}>
            <button
              type="button"
              disabled={selectedDeviceName !== "" ? false : true}
              className={`${classes.button} ${
                selectedDeviceName === ""
                  ? classes.btnDisabled
                  : classes.btnSubmit
              }`}
              onClick={() => {
                if (sixDigitNo.length === 6) {
                  setIsValidInput(true);
                  sendDataToParent(
                    selectedDeviceName,
                    selectedDeviceSerialNo,
                    selectedDeviceSerialNo
                  );
                } else {
                  setIsValidInput(false);
                }
              }}>
              {"Generate Key"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export function Pentero(props) {
  const classes = useStyles();
  sessionStorage.setItem("KeyTypeValue", 4);
  const { reduxState, reduxActions } = props;
  const { deviceListInfo } = reduxState;
  const { getDeviceList, generateKey, downloadKeys, getPrintText } =
    reduxActions;
  const [isdeviceListReady, setisdeviceListReady] = useState(true);
  const [downloadStatus, setDownloadStatus] = useState(200);
  const [isKeyLoading, setKeyLoading] = useState(true);
  const [showKeyView, setShowKeyView] = useState(false);
  const [penteroKey, setPenteroKey] = useState("");
  const [deviceSerialNo, setDeviceSerialNo] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [open, setOpen] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState(false);
  const [modalText, setModalText] = useState("");
  const [isDeviceNotAvailable, setIsDeviceNotAvailable] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setisdeviceListReady(false);
    dispatch(clearDeviceList());
  }, [dispatch]);

  useEffect(() => {
    if (isdeviceListReady === false && deviceListInfo.fetched !== "fulfilled") {
      getDeviceList();
      setisdeviceListReady(true);
      setKeyLoading(false);
      setIsDeviceNotAvailable(false);
    }
    if (
      deviceListInfo.isSuccess === false &&
      (deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize")
    ) {
      displayPopUp(deviceListInfo.errorMessage);
      setKeyLoading(false);
    }
    if (
      isdeviceListReady &&
      deviceListInfo.fetched === "fulfilled" &&
      penteroKey !== ""
    ) {
      setShowKeyView(true);
      setKeyLoading(false);
    } else if (
      isdeviceListReady &&
      deviceListInfo.fetched === "fulfilled" &&
      deviceListInfo.deviceList.length === 0
    ) {
      setIsDeviceNotAvailable(true);
    }
  }, [deviceListInfo, getDeviceList, isdeviceListReady, penteroKey]);

  const generatePenteroKey = async (selectedDeviceSerialNo) => {
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      let Penterokeys = await generateKey(4, selectedDeviceSerialNo);
      if (Penterokeys.status !== false) {
        setPenteroKey(Penterokeys.data);
      } else {
        displayPopUp(Penterokeys.message);
      }
    } else {
      setOnlineStatus(true);
    }
    setKeyLoading(false);
  };

  const printPenteroKey = async () => {
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      let downloadedKeyData = await downloadKeys([...penteroKey], 4);
      // if (
      //   downloadedKeyData !== false &&
      //   downloadedKeyData.data !== undefined &&
      //   downloadedKeyData.data.config !== null
      // ) {
      //   await downloadURI(downloadedKeyData.data.config[0].url);
      // } else {
      //   setModalText("Failed to download files");
      //   setOpen(true);
      // }
      if (
        downloadedKeyData.status === true &&
        downloadedKeyData.data.data !== undefined &&
        downloadedKeyData.data.data.config !== null
      ) {
        await downloadURI(downloadedKeyData.data.data.config[0].url);
      } else {
        setDownloadStatus(downloadedKeyData.statusCode);
        displayPopUp(downloadedKeyData.message);
      }
    } else {
      setOnlineStatus(true);
    }
    setKeyLoading(false);
  };

  const getPrintoutText = async () => {
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      const keyType = keyTypeEnum[4];
      let result = await getPrintText([...penteroKey], keyType);
      if (result.status !== false) {
        printIframe("ipdf", result.data);
      } else {
        setKeyLoading(false);
        setModalText(result.message);
        setOpen(true);
        setDownloadStatus(result.statusCode);
      }
    } else {
      setOnlineStatus(true);
    }
    setKeyLoading(false);
  };

  async function reloadPenteroKey() {
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      window.location.reload(true);
    } else {
      setOnlineStatus(true);
      setKeyLoading(false);
    }
  }

  const handleCallback = (
    selectedDeviceName,
    selectedDeviceSerialNo,
    deviceList
  ) => {
    setDeviceName(selectedDeviceName);
    setDeviceSerialNo(selectedDeviceSerialNo);
    setKeyLoading(true);
    generatePenteroKey(deviceList);
  };

  const displayPopUp = (text) => {
    setModalText(text);
    setOpen(true);
  };

  const closeOnlineStatus = () => {
    setOnlineStatus(false);
  };

  const handleClose = () => {
    setKeyLoading(true);
    setOpen(false);
    setModalText("");
    if (deviceListInfo.fetched === "unAuthorize") {
      logOut();
    } else if (downloadStatus === 401) {
      logOut();
    } else {
      setKeyLoading(false);
    }
  };

  const logOut = () => {
    let path = window.location.origin + "/Logout";
    window.location.href = path;
  };

  const downloadURI = async (uri) => {
    let link = document.createElement("a");
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (deviceListInfo.fetched === "pending" ||
    isKeyLoading ||
    !isdeviceListReady) ? (
    <Processing progressText={true} />
  ) : deviceListInfo.fetched === "fulfilled" ? (
    <>
      {showKeyView && !isKeyLoading ? (
        <PenteroKeyViewMaster
          selectedDeviceName={deviceName}
          selectedDeviceSerialNo={deviceSerialNo}
          penteroKeyList={penteroKey}
          downloadKey={printPenteroKey}
          reloadPenteroKey={reloadPenteroKey}
          getPrintingText={getPrintoutText}
        />
      ) : (
        <>
          {isDeviceNotAvailable ? (
            <DeviceNotAvailable headerText={"Pentero & Old Service PWD"} />
          ) : (
            <DevicesViewMaster
              deviceListInfo={deviceListInfo.deviceList}
              sendDataToParent={handleCallback}
            />
          )}
        </>
      )}
      <div id="itAdminInfoModalPopup">
        <WarnignModalPopup
          state={open}
          title={modalText}
          clickClose={handleClose}
        />
      </div>
      <InternetDisconnected
        state={onlineStatus}
        clickClose={closeOnlineStatus}></InternetDisconnected>
    </>
  ) : (
    <>
      {(deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize") &&
      open ? (
        <div id="itAdminModalPopup">
          <WarnignModalPopup
            state={open}
            title={modalText}
            clickClose={handleClose}
          />
        </div>
      ) : (
        <div id="div_Pentero_Conainer" className={`${classes.divContainer}`}>
          <div className={`${classes.headerText}`}>
            {"Pentero & Old Service PWD"}
          </div>
          <div
            id="div_selection"
            className={`${classes.divSelectionContainer} ${classes.displayFlex}`}>
            <div id="form_label" className={classes.formLableKey}>
              {"Unable to retrieve data from the server"}
            </div>
            <div id="form_Select_all" className={classes.divSelectAll}>
              <button
                className={`${classes.btnSelectAll} cursorPoiner`}
                onClick={() => {
                  reloadPenteroKey();
                }}>
                {"Reload"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      getDeviceList: actions.getDeviceList,
      generateKey: actions.GetGeneratedKey,
      downloadKeys: actions.DownloadKey,
      getPrintText: actions.GetPrintText,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  reduxState: {
    deviceListInfo: state.deviceListInfo,
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Pentero);
