import React from "react";
import { Redirect } from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles"
import { Modal} from "@material-ui/core";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { bindActionCreators } from "redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { setUserStateValue } from "actions/index";
import warningIcon from "./../../assets/icons/warning.png";
import "@zeiss/zui";
import Processing from "Components/Admin/Processing";
const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
    backgroundColor: "rgba(26, 35, 41,0.6) !important",
    zIndex: "3000 !important",
  },
  modalPaper: {
    position: "absolute",
    width: 450,
    height: 290,
    backgroundColor: theme.palette.background.paper,
    borderTop: "5px solid #0050F2",
    boxShadow: theme.shadows[5],
    border: "none",
    padding: "0px",
    textAlign: "center",
    borderRadius: "5px",
    outline: "none",
  },
  modalWarningPaper: {
    position: "absolute",
    width: 450,
    height: 290,
    backgroundColor: theme.palette.background.paper,
    borderTop: "5px solid #FDCA40",
    boxShadow: theme.shadows[5],
    border: "none",
    padding: "0px",
    textAlign: "center",
    borderRadius: "5px",
    outline: "none",
  },
  modalHeader: {
    textAlign: "center",
    padding: "0px",
    border: "0px",
    marginTop: "70px",
  },
  modalBody: {
    position: "relative",
    padding: "0px 25px",
    textAlign: "center",
    height: "calc(100% - 155px)",
  },
  showErrorMessage: {
    width: "100%",
    padding: "5px 0px",
    font: "var(--zui-typography-body)",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#828D9E",
  },
  messagebody: {
    margin: "30px 0px",
    width: "inherit",
    position: "relative",
    display: "block",
  },
  modalFooter: {
    borderTop: "1px solid #DDE3ED",
    height: "64px",
    alignSelf: "center",
    display: "block",
    width: "100%",
    textAlign: "center",
    position: "absolute",
    left: "0",
    bottom: "0",
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const WarnignModalPopup = ({ state, title, clickClose }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  return (
    <>
      <Modal
        disableAutoFocus={true}
        className={classes.modal}
        open={state}
        onClose={() => {
          clickClose();
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <div style={modalStyle} className={classes.modalWarningPaper}>
          <div className={classes.modalHeader}>
            <img height="30" width="30" src={warningIcon} alt="info" />
          </div>
          <div className={classes.modalBody}>
            <div className={classes.showErrorMessage}>
              <span className={classes.messagebody}>{title}</span>
            </div>
          </div>
          <div
            className={`${classes.modalFooter} modalbtn cursorPoiner`}
            onClick={() => {
              clickClose();
            }}>
            <button type="button" id="DiscardData" className="btn">
              OK
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export const Authenticate = (props) => {
  const token = props.location.hash.split("=")[1];
  const path = localStorage.getItem("requestPath");
  const history = useHistory();
  const { reduxState, reduxActions } = props;
  const { userAccount } = reduxState;
  const { getUserAccount, setUserStateValue } = reduxActions;
  const [userAccountCalled, setUserAccountCalled] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [requestPath, setRequestPath] = useState(path);

  useEffect(() => {
    if (token === undefined || token === null) {
      history.push("/Logout");
    }
  });

  useEffect(() => {
    if (
      requestPath !== "" &&
      requestPath !== null &&
      requestPath.localeCompare("/home") !== true
    ) {
    } else {
      setRequestPath("/home");
      localStorage.clear();
    }

    if (userAccount.stateValue === ``) {
      setUserStateValue(token);
    }
    if (
      userAccount.stateValue !== `` &&
      userAccount.fetched !== `fulfilled` &&
      window.location.pathname === `/authenticate` &&
      !userAccountCalled
    ) {
      setUserAccountCalled(true);
      getUserAccount();
    }
    if (
      userAccount.stateValue !== "" &&
      userAccount.fetched === `failed` &&
      userAccountCalled === true &&
      userAccount.loggedIn === false
    ) {
      displayPopUp(userAccount.message);
    }
  }, [
    getUserAccount,
    requestPath,
    setUserStateValue,
    token,
    userAccount,
    userAccountCalled,
  ]);

  const displayPopUp = (text) => {
    setModalText(text);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setModalText("");
    logOut();
  };

  const logOut = () => {
    history.push("/Logout");
  };

  return !userAccount.loggedIn &&
    (userAccount.fetched === "pending" ||
      userAccount.fetched === undefined ||
      userAccount.fetched.length === 0) ? (
    <Processing />
  ) : !userAccount.loggedIn &&
    (userAccount.fetched === `failed` ||
      userAccount.fetched === `unAuthorize`) ? (
    <div id="oneDayModalPopup">
      <WarnignModalPopup
        state={open}
        title={modalText}
        clickClose={handleClose}></WarnignModalPopup>
    </div>
  ) : (
    <Redirect to={`${requestPath}`} />
  );
};

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      getUserAccount: actions.getUserAccount,
      setUserStateValue: (stateValue) =>
        dispatch(setUserStateValue(stateValue)),
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  reduxState: {
    userAccount: state.userAccount,
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Authenticate);
