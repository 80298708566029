const keyType = {
  1: "FseKey",
  2: "OneDayKey",
  3: "ITAdminKey",
  4: "OldServicePasswordKey",
  5: "BioMedKey",
  6: "FactoryKey",
  7: "SalesAndApplication",
};
export default Object.freeze(keyType);
