import axios from "axios";
import * as ErrorMessage from "../../constants/errorMessages";
import {
  // sendNewRequestEmailUrl,
  // getSubscription,
  getBaseUrl,
  getXApiKey,
} from "../../Utils/getBaseUrl";

export const SendRequestEmail = (emailContents) => {
  let url = getBaseUrl();
  if (url === "http://localhost:3001") {
    url = "https://qa.fsekeygen.zeiss.com";
  }
  // const sendRequestEmailUrl = sendNewRequestEmailUrl();
  const sendRequestEmailUrl = `${url}/fsekeygenservice/api/v1/RequestType/SendEmail`;
  const xapikey = getXApiKey();
  // const esbSubscriptionKey = getSubscription();
  const config = {
    headers: {
      "Content-Type": `application/json`,
      "Access-Control-Allow-Origin": "*",
      XApiKey: `${xapikey}`,
      // "EsbApi-Subscription-Key": esbSubscriptionKey,
    },
  };
  return axios
    .create(config)
    .post(`${sendRequestEmailUrl}`, emailContents)
    .then((res) => {
      if (res.status === 200) {
        if (res.data) {
          return {
            status: true,
            statusCode: 200,
            message: "Your request has been submitted.",
          };
        } else {
          return {
            status: false,
            statusCode: 200,
            message: "Unable to submit your request.",
          };
        }
      } else if (res.status === 401) {
        return {
          status: false,
          statusCode: 401,
          message: ErrorMessage.SESSION_TIMEOUT_ERROR,
        };
      } else if (res.status === 400) {
        return {
          status: false,
          statusCode: 400,
          message: res.error.message,
        };
      } else {
        return {
          status: false,
          statusCode: 400,
          message: ErrorMessage.BAD_REQUEST,
        };
      }
    })
    .catch((error) => {
      let status = error;
      if (error.response) {
        status = error.response.status;
        if (status === 401) {
          return {
            status: false,
            statusCode: 401,
            message: ErrorMessage.SESSION_TIMEOUT_ERROR,
          };
        } else {
          return {
            status: false,
            statusCode: status,
            message: error.response.statusText,
          };
        }
      } else if (error.request) {
        return {
          status: false,
          statusCode: 401,
          message: ErrorMessage.NETWORK_ERROR,
        };
      } else {
        return {
          status: false,
          statusCode: 401,
          message: ErrorMessage.NETWORK_ERROR,
        };
      }
    });
};
