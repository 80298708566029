import * as ActionTypes from "../../constants/actionTypes";
import axios from "axios";

import { getSubscription, getUserDetails } from "../../Utils/getBaseUrl";

export const pendingAC = () => ({
	type: ActionTypes.GET_USER_ACCOUNT_PENDING,
});

export const failedAC = (data) => ({
	type: ActionTypes.GET_USER_ACCOUNT_FAILED,
	payload: data,
});

export const fulfilledAC = (data) => ({
	type: ActionTypes.GET_USER_ACCOUNT_FULFILLED,
	payload: data,
});

export const getUserAccount = () => (dispatch, getState) => {
	const state = getState();
	const token = state.userAccount.stateValue;
	const validCertificateUrl = getUserDetails();
	const esbSubscriptionKey = getSubscription();
	dispatch(pendingAC());
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": `application/json`,
			"Access-Control-Allow-Origin": "*",
			"EsbApi-Subscription-Key": esbSubscriptionKey,
		},
	};
	axios
		.create(config)
		.get(`${validCertificateUrl}`)
		.then((res) => {
			if (res.status === 200) {
				dispatch(fulfilledAC(res.data));
				return true;
			} else if (res.status === 400) {
				dispatch(
					failedAC("Unable to process the request. Please try again.")
				);
				return false;
			} else if (res.status === 401) {
				dispatch(
					failedAC(
						"Access is denied due to the session timeout. Please login again."
					)
				);
				return false;
			} else if (res.status === 404) {
				dispatch(
					failedAC("The Requested URL was Not Found on this Server")
				);
				return false;
			} else if (res.status === 500) {
				dispatch(failedAC("Internal server error. Please try again."));
				return false;
			} else {
				dispatch(failedAC("Something went wrong"));
				return false;
			}
		})
		.catch((error) => {
			let status = error;
			if (error.response) {
				status = error.response.status;

				if (status === 400) {
					dispatch(
						failedAC(
							"Unable to process the request. Please try again."
						)
					);
					return false;
				} else if (status === 401) {
					dispatch(
						failedAC(
							"Access is denied due to the session timeout. Please login again."
						)
					);
					return false;
				} else if (status === 404) {
					dispatch(
						failedAC(
							"The Requested URL was Not Found on this Server"
						)
					);
					return false;
				} else if (status === 500) {
					dispatch(
						failedAC("Internal server error. Please try again.")
					);
					return false;
				} else {
					dispatch(failedAC("Something went wrong"));
					return false;
				}
			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				dispatch(
					failedAC(
						"Your request could not be processed due to Network Interruption. Please login again."
					)
				);
				return false;
			} else {
				// Something happened in setting up the request that triggered an Error
				dispatch(failedAC(error.message));
				return false;
			}
		});
};
export const setloggedOut = () => ({
	type: ActionTypes.SET_LOGGED_OUT,
});

export const setUserStateValue = (data) => ({
	type: ActionTypes.SET_USER_STATE_VALUE,
	payload: data,
});
