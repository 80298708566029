import axios from "axios";
import * as ActionTypes from "../../constants/actionTypes";
import * as ErrorMessage from "../../constants/errorMessages";
import {
  getSubscription,
  getKeyTypeBySkillUrl,
} from "./../../Utils/getBaseUrl";
import { getTokenAction } from "actions/getToken";

export const pendingKeyType = () => ({
  type: ActionTypes.GET_KEYTYPES_ROLE_PENDING,
});

export const failedKeyType = (data) => ({
  type: ActionTypes.GET_KEYTYPES_ROLE_FAILED,
  payload: data,
});

export const fulfilledKeyType = (data) => ({
  type: ActionTypes.GET_KEYTYPES_ROLE_FULFILLED,
  payload: data,
});

export const setDrawerState = (data) => ({
  type: ActionTypes.SET_DRAWER_STATE,
  payload: data,
});

export const getKeyTypeInfo = () => (dispatch, getState) => {
  dispatch(pendingKeyType());
  return getTokenAction(dispatch, getState).then((token) => {
    const state = getState();
    const skills = state.userAccount.skills;
    const keyTypeBaseUrl = getKeyTypeBySkillUrl();
    const esbSubscriptionKey = getSubscription();
    const isAdmin = state.userAccount.isAdmin;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": `application/json`,
        "Access-Control-Allow-Origin": "*",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
        "EsbApi-Subscription-Key": esbSubscriptionKey,
      },
    };
    const data = {
      skills: skills,
      keyType: 0,
    };

    return axios
      .create(config)
      .post(`${keyTypeBaseUrl}`, data)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.state) {
            if (res.data.data.length === 0) {
              if (isAdmin) {
                const adminMenu = {
                  // data: [{ id: 10, description: "Devices" }],
                  data: [],
                };
                dispatch(fulfilledKeyType(adminMenu));
              } else {
                dispatch(failedKeyType(ErrorMessage.NO_CERTIFICATE_ERROR));
              }
              return false;
            } else {
              dispatch(fulfilledKeyType(res.data));
              return true;
            }
          } else {
            dispatch(failedKeyType(res.data.error.message));
            return false;
          }
        } else if (res.status === 400) {
          dispatch(failedKeyType(ErrorMessage.UNABLE_TO_PROCESS_ERROR));
          return false;
        } else if (res.status === 401) {
          dispatch(failedKeyType(ErrorMessage.SESSION_TIMEOUT_ERROR));
          return false;
        } else if (res.status === 404) {
          dispatch(failedKeyType(ErrorMessage.URL_NOTFOUND_ERROR));
          return false;
        } else if (res.status === 500) {
          dispatch(failedKeyType(ErrorMessage.INTERNAL_SERVER_ERROR));
          return false;
        } else {
          dispatch(failedKeyType(ErrorMessage.SOMETHING_WENT_WRONG));
          return false;
        }
      })
      .catch((error) => {
        let status = error;
        if (error.response) {
          status = error.response.status;
          if (status === 400) {
            dispatch(failedKeyType(ErrorMessage.UNABLE_TO_PROCESS_ERROR));
            return false;
          } else if (status === 401) {
            dispatch(failedKeyType(ErrorMessage.SESSION_TIMEOUT_ERROR));
            return false;
          } else if (status === 404) {
            dispatch(failedKeyType(ErrorMessage.URL_NOTFOUND_ERROR));
            return false;
          } else if (status === 500) {
            dispatch(failedKeyType(ErrorMessage.INTERNAL_SERVER_ERROR));
            return false;
          } else {
            dispatch(failedKeyType(ErrorMessage.SOMETHING_WENT_WRONG));
            return false;
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          dispatch(failedKeyType(ErrorMessage.NETWORK_ERROR));
          return false;
        } else {
          // Something happened in setting up the request that triggered an Error
          dispatch(failedKeyType(error.message));
          return false;
        }
      });
  });
};
