import * as ActionTypes from "../constants/actionTypes";

const initialState = {
  isSuccess:false,
  DeviceListFor:"",
  KeyType: 0,
  fetched:"pending",
  state:"",
  deviceList:[{id:'',name:'',icCode:'',serialNumber:''}],
  deviceListByRole:[],
  deviceListByID:{},
  errorMessage:"",
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.GET_DEVICE_LIST_PENDING: {
      return {
        ...state,
        fetched: "pending",
        KeyType: action.payload,
      };
    }
    case ActionTypes.GET_DEVICE_LIST_FULFILLED: {
      return {
        ...state,
        fetched: "fulfilled",
        isSuccess:true,
        deviceList: action.payload ,
      };
    }
    case ActionTypes.GET_DEVICE_LIST_BY_ROLE_FULFILLED: {
      return {
        ...state,
        fetched: "fulfilled",
        isSuccess:true,
        deviceListByRole: action.payload ,
      };
    }
    case ActionTypes.GET_DEVICE_BY_ID: {
      return {
        ...state,
        fetched: "fulfilled",
        isSuccess:true,
        deviceListByID: action.payload,
      };
    }
    case ActionTypes.GET_DEVICE_LIST_FAILED: {
      return {
        ...state,
        fetched: action.payload.statusCode === 401 || action.payload.statusCode === 406 ? "unAuthorize" : "failed",
        errorMessage: action.payload.message,
      };
    }
    case ActionTypes.CLEAR_DEVICE_LIST_ErrorMessage: {
      return {
        ...state,
        fetched: "failed",
        isSuccess:true,
        errorMessage:"",
      };
    }
    case ActionTypes.CLEAR_DEVICE_LIST: {
      return initialState;
    }
    default:
      return state;
  }
};
