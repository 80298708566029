import React, {useState} from "react";
import { ZuiDatePicker, ZuiDatePickerInput, ZuiPopover } from "@zeiss/zui-react";
import "@zeiss/zui";
import { makeStyles} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
	pointerEventNone: {
    pointerEvents: "none",
  },
  pointerEventAuto: {
    pointerEvents: "auto",
  },
 datePickerContainer: {
    position: "fixed",
    "& > * + *": {
			marginLeft: theme.spacing(2),
		},
    flexGrow: 1,
    left: "0",
    top: "0",
    width: "100%",
    height:"100%",
    zIndex: "3000",
    inset: "0px",
    backgroundColor: "rgba(26, 35, 41,0.6)",
},
datePickerPopOver: {
    right: "0",
    left: "50%",
    top: "50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    whiteSpace:"nowrap",
    display:"flex",
    justifyContent: "center",
}
}));

export default function CustomDatePicker({name,disableInput, value, isInvalid, maxDate, minDate,parentSelector, setSelectedDate }){
  const classes = useStyle();
  const [showDatePicker, setShowDatePicker] = useState(false);
  return (
      <>
      <div className={`${disableInput === true
                  ? classes.pointerEventNone
                  : classes.pointerEventAuto
              } selectIcon`} onClick={(e)=> setShowDatePicker(!showDatePicker)}>
        <ZuiDatePickerInput
          style={{width:"100%", lineHeight: "normal"}}
          close-on-date-selected
          name= {name ? name:"customDatePicker"}
          locale="en-IN"
          placeholderDay="dd"
          placeholderMonth="mm"
          placeholderYear="yyyy"
          selectedDate={value}
          invalid={isInvalid}
          parentSelector={parentSelector}
          onClick={(e)=> {e.target.blur();e.preventDefault();}}
          onFocus={(e)=> {e.target.blur();e.preventDefault();}}
          onkeydown={(e)=> {e.target.blur();e.preventDefault();}}
        >
        </ZuiDatePickerInput>
      </div>
      <div className={`${classes.datePickerContainer}`} style={{display: showDatePicker === true ? "flex" : "none"}} onClick={(e)=> setShowDatePicker(!showDatePicker)}>
      <ZuiPopover className={`${classes.datePickerPopOver}`} onClick={(e)=> e.stopPropagation()}>

    {showDatePicker === true ? 
          <ZuiDatePicker
            style={{whiteSpace:"nowrap !important"}}
            locale="gb-GB"
            selectedDate={value !== "" && value !==undefined && value !==null ? value : minDate !== "" && minDate !== undefined && minDate !== null && minDate >= new Date() ? new Date(minDate) : maxDate !== "" && maxDate !== undefined && maxDate !== null && maxDate <= new Date()? new Date(maxDate): new Date()}
            currentDate ={value !== "" && value !==undefined && value !==null ? value : minDate !== "" && minDate !== undefined && minDate !== null && minDate >= new Date() ? new Date(minDate) : maxDate !== "" && maxDate !== undefined && maxDate !== null && maxDate <= new Date()? new Date(maxDate): new Date()}
            minDate={minDate ? minDate : ""}
            maxDate={maxDate ? maxDate : ""}
            onClick={(e)=> e.stopPropagation()}
            emitDatePickerDateSelectedEvent={(e)=>{setSelectedDate(e.value);setShowDatePicker(false)}}> 
            </ZuiDatePicker>
    :<></>}
        </ZuiPopover>

    </div>
  </>
    )
};