import React from "react";
import "./dataProtection.css";

const DataProtection = () => {
  document.title = "ServiceKey_Data Protection";
  return (
    <div className="dataProtection">
      <div className="WordSection">
      <table style={{fontFamily: "ZEISSFrutigerNextW1G-Reg, sans-serif",textAlign: "left"}} border="0" >
        <tbody>
            <tr>
                <td>
                  <span className="MsoNormal" style={{fontFamily: "ZEISSFrutigerNextW1G-Bold, sans-serif"}}>
                    <b>
                      <span style={{lineHeight: "100%"}}>
                        {"Data Privacy Notice for CZM Service Password Portal"}
                      </span>
                    </b>
                    </span>
                    <p style={{fontFamily: "ZEISSFrutigerNextW1G-Light", margin: "10px"}} className="MsoNormal"> 
                        <span style={{fontFamily: "ZEISSFrutigerNextW1G-Reg, sans-serif"}}>
                          {"Carl Zeiss Meditec AG"}
                        </span>
                        <br/>
                        <span style={{fontFamily: "ZEISSFrutigerNextW1G-Reg, sans-serif"}}>
                          {"Göschwitzer Straße 51-52"}
                        </span>
                            <br/>
                            <span style={{fontFamily: "ZEISSFrutigerNextW1G-Reg, sans-serif"}}>
                                {`07745 Jena, Germany`}
                            </span>
                            <br/>
                              <span style={{fontFamily: "ZEISSFrutigerNextW1G-Reg, sans-serif"}}>
                               {` Phone: +49 3641 220-0`}
                            </span>
                            <br/>
                            <span style={{fontFamily: "ZEISSFrutigerNextW1G-Reg, sans-serif"}} >
                              {`Email:`} <a href="mailto:do-not-reply@zeiss.com" className="MsoHyperlink">{"info.meditec@zeiss.com"}</a>
                            </span>
                      </p>
                  </td>
              </tr>
            </tbody>
          </table>
          <table style={{textAlign: "left"}} border="0"  >
                <tbody>
                    <tr>
                        <td>
                          <p  className="MsoNormal" style={{ margin: "5px 0px"}}>
                            <span style={{ color: "black"}} lang="EN">
                                <span style={{fontWeight: "normal"}}>
                                {`This Data Privacy Notice applies to users registered with a ZEISS ID of the`} <b style={{fontFamily: "ZEISSFrutigerNextW1G-Bold, sans-serif"}}>{`“CZM Service Password Portal”`} </b>{`(hereinafter referred to as “Application” or “Platform”).`}
                                </span>
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="MsoNormal" style={{ margin: "5px 0px"}}>
                            <span style={{ color: "black"}} lang="EN">
                                <span style={{fontWeight: "normal"}}>
                                  <b style={{fontFamily: "ZEISSFrutigerNextW1G-Bold, sans-serif"}}>{`Responsibility:`}</b>{` Carl Zeiss Meditec AG (hereinafter referred to as “ZEISS”) makes CZM Service Password Portal available to its internal ZEISS employees at their request to fulfill the purposes listed below.`}
                                </span>
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="MsoNormal" style={{ margin: "5px 0px"}}>
                            <span style={{ color: "black"}} lang="EN">
                                <span style={{fontWeight: "normal"}}>
                                  <b style={{fontFamily: "ZEISSFrutigerNextW1G-Bold, sans-serif"}}>{`Data collection and processing:`}</b>{` In the course of using CZM Service Password Portal for its intended purpose, users’ data are collected, processed, and stored permanently or temporarily within the application during the following processes:`}
                                </span>
                            </span>
                          </p>
                            <ul className="dashed">
                                <li style={{listStyleType: "none"}}>
                                    <span style={{ color: "black", letterSpacing: "0.1pt",}} lang="EN">
                                    <p className="MsoNormal" style={{ margin: "10px"}}>{`The user can register themselves for the application.`}<br/>
                                      </p>
                                    </span>
                                </li>
                                <li style={{listStyleType: "none", fontWeight: "normal"}}>
                                  <p  className="MsoNormal" style={{ margin: "10px"}}>
                                    <span style={{ color: "black"}} lang="EN">
                                    	{`The user carries out the necessary user registration steps using the ZEISS ID for the product (voluntary information also possible).`}<br/>
                                    </span>
                                  </p>
                                </li>
                                <li style={{listStyleType: "none", fontWeight: "normal"}}>
                                    <span style={{ color: "black"}} lang="EN">
                                    <p  className="MsoNormal" style={{ margin: "10px"}}>{`	The user logs in to the application.`}</p>
                                    </span>
                                </li>
                                <li style={{listStyleType: "none", fontWeight: "normal"}}>
                                    <span style={{ color: "black"}} lang="EN">
                                    <p  className="MsoNormal" style={{ margin: "10px"}}>{`	The user selects one or multiple devices to generate keys.`}</p>
                                    </span>
                                </li>
                                <li style={{listStyleType: "none", fontWeight: "normal"}}>
                                    <span style={{ color: "black"}} lang="EN">
                                    <p  className="MsoNormal" style={{ margin: "10px"}}>{` The user downloads the generated keys.`}</p>
                                    </span>
                                </li>
                                <li style={{listStyleType: "none", fontWeight: "normal"}}>
                                    <span style={{ color: "black"}} lang="EN">
                                    <p  className="MsoNormal" style={{ margin: "10px"}}>{` Application logs usage data and is able to output the collected data as log files. These log files are transferred to ZEISS, especially for service or repair purposes. ZEISS also uses the data to improve its own products.`}</p>
                                    </span>
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: "0px"}}>
                          <p  className="MsoNormal" style={{ margin: "5px 0px"}}>
                            <span style={{ color: "black"}} lang="EN">
                                <span style={{fontWeight: "normal"}}>
                                <b style={{fontFamily: "ZEISSFrutigerNextW1G-Bold, sans-serif"}}>{"Purpose and Legal Basis:"}</b> {`CZM Service Password Portal application, including all modes of usage; viz., Websites and Mobile Applications, is an Internet-based platform, owned and operated by Carl Zeiss Meditec AG (hereinafter, referred to as "ZEISS" or "We" or "Our" or "Us" or "Company"). The application will be referred to as "CZM Service Password Portal" or “the Platform.”`}
                                </span>
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{border: "0px"}}>
                          <p  className="MsoNormal" style={{ margin: "5px 0px"}}>
                            <span style={{ color: "black"}} lang="EN">
                                <span style={{fontWeight: "normal"}}>
                                {"The Platform is an interface that generates a range of security keys for the User to access different software levels of the Company’s medical devices. To get these security keys, it is mandatory for the User to undergo training on that particular medical device and to get the certification, available on the learning management system (Cornerstone). The learning management system is the primary source for all training certificates."}
                                </span>
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{border: "0px"}}>
                          <p  className="MsoNormal" style={{ margin: "5px 0px"}}>
                            <span style={{ color: "black"}} lang="EN">
                                <span style={{fontWeight: "normal"}}>
                                {"For the purpose of providing Services and for other purposes identified in this Privacy Notice, ZEISS will be required to collect and process email IDs of the Users. ZEISS will always be committed to protecting the personal information of the Users and takes all reasonable precautions for maintaining confidentiality of the User's personal information. ZEISS will process personal data only in compliance with the applicable laws."}
                                </span>
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p  className="MsoNormal" style={{ margin: "5px 0px"}}>
                            <span style={{ color: "black"}} lang="EN">
                                <span style={{fontWeight: "normal"}}>
                                {"Zeiss has entered into necessary legally binding contracts with its Internal Employees as well as with the External Employees (Contractors) to ensure compliance of relevant regulations for collection, processing, and storage of data."}
                                </span>
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p  className="MsoNormal" style={{ margin: "5px 0px"}}>
                            <span style={{ color: "black"}} lang="EN">
                                <span style={{fontWeight: "normal"}}>
                                  <b style={{fontFamily: "ZEISSFrutigerNextW1G-Bold, sans-serif"}}>{"Data categories:"}</b> {"The following data or data categories pertaining to the user are processed when using CZM Service Password Portal:"}
                                </span>
                            </span>
                          </p>
                            <ul className="dashed" style={{fontWeight: "normal"}}>
                            <li style={{listStyleType: "none",}}>
                                  <p  className="MsoNormal" style={{ margin: "10px"}}>
                                    <span style={{ color: "black"}}>
                                    {"Email ID "}
                                    </span>
                                  </p>
                                 </li>
                                 <li style={{listStyleType: "none",}}>
                                  <p  className="MsoNormal" style={{ margin: "10px"}}>
                                    <span style={{ color: "black"}}>
                                    {"Name of the skill/skillset available in Cornerstone"}
                                    </span>
                                  </p>
                                 </li>
                                 <li style={{listStyleType: "none",}}>
                                  <p  className="MsoNormal" style={{ margin: "10px"}}>
                                    <span style={{ color: "black"}}>
                                    {"Validity (expiry) of the skill/skillset "}
                                    </span>
                                  </p>
                                 </li>
                                 <li style={{listStyleType: "none",}}>
                                  <p  className="MsoNormal" style={{ margin: "10px"}}>
                                    <span style={{ color: "black"}}>
                                    {"Security key file and pdf "}
                                    </span>
                                  </p>
                                 </li>
                                <li style={{listStyleType: "none",}}>
                                  <p  className="MsoNormal" style={{ margin: "10px"}}>
                                    <span style={{ color: "black"}}>
                                    	{"Technical data (contents of a log file along with a record of the date and time it was sent to ZEISS)"}
                                    </span>
                                  </p>
                                 </li>
                            </ul>
                        </td>
                    </tr>
                      <tr>
                        <td style={{border: "0px"}}>
                          <p className="MsoNormal" style={{ margin: "5px 0px"}}>
                            <span style={{ color: "black"}} lang="EN">
                                <span style={{fontWeight: "normal"}}>
                                  <b style={{fontFamily: "ZEISSFrutigerNextW1G-Bold, sans-serif"}}>{`Protection measures:`}</b>
                                  {" The Personal data received from the Internal Employees as well as from the Contract Employees are treated with implementation of adequate Technical and Administrative controls across Zeiss. The data are predominantly transmitted and saved in an encrypted form."}
                                </span>
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{border: "0px"}}>
                          <p  className="MsoNormal" style={{ margin: "5px 0px"}}>
                            <span style={{ color: "black"}} lang="EN">
                                <span style={{fontWeight: "normal"}}>
                                {"The data is used only for the purpose for which it has been collected and is shared within the organization on a need to know basis. Access to the data is protected and restricted."}
                                </span>
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p  className="MsoNormal" style={{ margin: "5px 0px"}}>
                            <span style={{ color: "black"}} lang="EN">
                                <span style={{fontWeight: "normal"}}>
                                {"The Technical and Administrative controls ensure preservation of the Confidentiality, Integrity and Availability of information as per the contractual obligations that Zeiss has committed itself to."}
                                </span>
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{border: "0px"}}>
                          <p className="MsoNormal" style={{ margin: "5px 0px"}}>
                            <span style={{ color: "black"}} lang="EN">
                                <span style={{fontWeight: "normal"}}>
                                  <b style={{fontFamily: "ZEISSFrutigerNextW1G-Bold, sans-serif"}}>{"Deletion of data:"}</b> {"We process the user data for as long as this is necessary for carrying out the service processes. Depending on the relevant function, the data may be stored permanently or temporarily. In addition, it is possible for the user to have their ZEISS ID deleted in the ZEISS ID Self-Service Portal at"}&nbsp;
                                  <span lang="EN-US"><a href="https://id.zeiss.com/" style={{fontFamily: "ZEISSFrutigerNextW1G-Bold", color:"#4472C4"}}>{"https://id.zeiss.com/"}</a></span>&nbsp;{"at any time. The user can also have their login and saved data deleted in the CZM Service Password Portal ("}<span lang="EN-US" ><a href="mailto:support.servicekey@zeiss.com" style={{fontFamily: "ZEISSFrutigerNextW1G-Bold, sans-serif", color:"#4472C4"}}>{"support.servicekey@zeiss.com"}</a></span>{")."}
                                </span>
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p  className="MsoNormal" style={{ margin: "5px 0px"}}>
                            <span style={{ color: "black"}} lang="EN">
                                <span style={{fontWeight: "normal"}}>
                                {"If a user is removed by an administrator, the user no longer has access to CZM Service Password Portal. All the users’ personal data will be removed from the database but the user activity data will remain forever."}
                                </span>
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p  className="MsoNormal" style={{ margin: "5px 0px"}}>
                            <span style={{ color: "black"}} lang="EN">
                                <span style={{fontWeight: "normal"}}>
                                  <b style={{fontFamily: "ZEISSFrutigerNextW1G-Bold, sans-serif"}}>{"Server location:"}</b> {"The processed user data are generally saved within Europe. The operation and error handling of CZM Service Password Portal can take place in a third country (e.g. India). A backup copy of the data may also be made in a third country (e.g. India). Appropriate protection measures have been defined for this in order to ensure adequate protection when processing third-country data."}
                                </span>
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p  className="MsoNormal" style={{ margin: "5px 0px"}}>
                            <span style={{ color: "black"}} lang="EN">
                                <span style={{fontWeight: "normal"}}>
                                  <b style={{fontFamily: "ZEISSFrutigerNextW1G-Bold, sans-serif"}}>{"Rights of the data subject:"}</b> {"Users are entitled to the following rights in particular: right of access in accordance with Article 15 GDPR, right to rectification in accordance with Article 16 GDPR, right to erasure in accordance with Article 17 GDPR, right to restriction of processing in accordance with Article 18 GDPR, right of notification in accordance with Article 19 GDPR, the right to data portability in accordance with Article 20 GDPR, and, if necessary, a right to object in accordance with Article 21 GDPR. The relevant rights can be claimed at the following e-mail address:"}&nbsp;<span lang="EN-US"><a href="mailto:support.servicekey@zeiss.com" style={{fontFamily: "ZEISSFrutigerNextW1G-Bold, sans-serif", color:"#4472C4"}}>{"support.servicekey@zeiss.com"}</a></span>&nbsp;.
                                </span>
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p  className="MsoNormal" style={{ margin: "5px 0px"}}>
                            <span style={{ color: "black"}} lang="EN">
                                <span style={{fontWeight: "normal"}}>
                                  {"The following ZEISS Data Privacy Notice applies here:"} &nbsp;<span lang="EN-US"><a href="http://www.zeiss.com/data-protection" style={{fontFamily: "ZEISSFrutigerNextW1G-Bold, sans-serif", color:"#4472C4"}}>{"www.zeiss.com/data-protection"}</a></span>.
                                </span>
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{border:"0px"}}>
                          <p  className="MsoNormal" style={{ margin: "5px 0px"}}>
                            <span style={{ color: "black"}} lang="EN">
                                <span style={{fontWeight: "normal"}}>
                                  {"If you have any questions, you can also contact us at:"} &nbsp;<span lang="EN-US"><a href="mailto:dataprivacy@zeiss.com" style={{fontFamily: "ZEISSFrutigerNextW1G-Bold, sans-serif", color:"#4472C4"}}>{"dataprivacy@zeiss.com"}</a></span>.
                                </span>
                            </span>
                          </p>
                        </td>
                      </tr>
                   </tbody>
      </table>
      <hr style={{height: "0px", background:"black"}}/>
          <p style={{ textAlign: "justify",font: "var(--zui-typography-body)" }}>
            {"© Carl Zeiss AG – Last updated: July 2021"}
          </p>
      <hr style={{height: "0px", background:"black"}}/>
    </div>
  </div>
  );
};

export default DataProtection;
