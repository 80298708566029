import {
  applyMiddleware,
  compose,
  createStore as createReduxStore,
} from "redux";
import persistState from "redux-sessionstorage";
import thunk from "redux-thunk";
import makeRootReducer from "./reducers";
// import logger from "redux-logger";

const createStore = (initialState = {}) => {

  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [thunk];

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = [
    persistState([
      `userAccount`,
      `layoutInfo`,
      `deviceListInfo`,
      `errorLogs`,
      `settings`,
    ]),
  ];

  let composeEnhancers = compose;

  if (process.env.REACT_APP_NODE_ENV !== `production`) {
    // middleware.push(logger);
    if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === `function`) {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createReduxStore(
    makeRootReducer(),
    initialState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers)
  );
  store.asyncReducers = {};

  return store;
};

export default createStore;
