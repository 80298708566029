import React from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import {setOnlineStatus} from "../../actions";
import useWindowSize from "actions/useWindowDimensions/useWindowSize";
import HeaderAppBar from "../Header/HeaderAppBar";
import LayoutBody from "../LayoutBody/LayoutBody";
import AdminLayout from "../LayoutBody/AdminLayout/AdminLayout";
import { setDrawerState } from "actions/layoutInfo";
import { useEffect } from "react";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    background: "#EDF2F7",   
    position: "relative",
    display: "inline-block",
    flexDirection: "column",
    [theme.breakpoints.up("xs")]: {
      height: "auto",
    },
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
  },
  layout_topbar: {
    zIndex: "2000",
    backgroundColor: "#fff",
    top: "0",
    left: "0",
    width: "100%",
    padding: "0",
    position: "relative",
    userSelect: "none",
  },
  layout_section: {
    width: "100%",
    height: "100%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "calc(100% - 72px)",
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: "calc(100% - 72px)",
    },
  },
  Header: {
    height: "64px",
    display: "flex",
  },
  BackgroundWhite: {
    background: "white",
    padding: "0px",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  
}));

const Home = (props) => {
  const classes = useStyles();
  const [height, width] = useWindowSize();
  const state = width > 959 ? true : false;
  const [sidenavState, setSidenavState] = React.useState(state);
  const userAccountState = useSelector((state) => state.userAccount);
  const dispatch = useDispatch();

  useEffect(()=>{
    if(state === true){
      setSidenavState(true);
    } else{
        setSidenavState(false);
      }
  },[state]);

  //const [open, setOpen] = useState(false);
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // function setOnlineStatus(status) {
  //   //if(status && !open) setOpen(true);
  //   setInternetStatus(status);
  //   //history.push("/");
  //   //setModalText("");
  //   // if(deviceListInfo.fetched === "unAuthorize")
  //   // {
  //   //   logOut();
  //   // }
  // };
  const handleDrawerOpen = () => {
    setSidenavState(true);
    dispatch(setDrawerState(true))
  };

  const handleDrawerClose = () => {
    setSidenavState(false);
    dispatch(setDrawerState(false));
  };
  
  return (
    <div id="main" className={`${classes.main}`} style={{ maxHeight: height, maxWidth: width }}>
      <div id="layoutHeader" className={`${classes.layout_topbar} ${classes.Header} ${classes.BackgroundWhite}`}>
        <HeaderAppBar isNavSwitchable={state} handleDrawer={handleDrawerOpen} />
      </div>
      <div id="layoutBody" className={`${classes.layout_section}`} >
        {
          userAccountState.isAdmin === false ? (
            <LayoutBody isNavSwitchable={state} navState={sidenavState} closeNav={handleDrawerClose} />
          ):(
            <AdminLayout isNavSwitchable={state} navState={sidenavState} closeNav={handleDrawerClose} ></AdminLayout>
          )
        }
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      setInternetStatus: (stateValue) =>
        dispatch(setOnlineStatus(stateValue)),
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  reduxState: {
    errorlogs: state.errorlogs,
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);

