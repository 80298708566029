import * as ActionTypes from "../constants/actionTypes";

const initialState = {
  isOnline:true,
  isLoggedin: true,
  isAuthenticated: true,
  isAuthorize:false,
  isSuccess:false,
  requestState:"Pending",
  errorMessage:"",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.SET_INTERNET_ONLINE_STATUS: {
      return {
        ...state,
        isOnline: action.payload,
      };
    }
    case ActionTypes.SET_AUTHENTICATION_STATUS: {
      return {
        ...state,
        isSuccess:true,
        isAuthorize: true,
        statusMessage: "fulfilled",
        deviceList: action.payload ,
      };
    }
    case ActionTypes.SET_AUTHORIZATION_STATUS: {
      return {
        ...state,
        isSuccess:false,
        isAuthenticated: action.payload.statusCode === 401 ? false : true,
        isAuthorize: action.payload.statusCode === 401 ? false : true,
        statusMessage: action.payload.statusCode === 401 ? "unAuthorize" : "failed",
        errorMessage: action.payload.message,
      };
    }
    case ActionTypes.SET_TRANSACTION_STATUS: {
      return {
        ...state,
        isSuccess:false,
        isAuthenticated: action.payload.statusCode === 401 ? false : true,
        isAuthorize: action.payload.statusCode === 401 ? false : true,
        statusMessage: action.payload.statusCode === 401 ? "unAuthorize" : "failed",
        errorMessage: action.payload.message,
      };
    }
    case ActionTypes.CLEAR_MESSAGE: {
      return initialState;
    }
    default:
      return state;
  }
};
