import { useState, useLayoutEffect } from 'react';

export default function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([0, 0]);
      // setSize([ window.innerHeight < 640 ? "640px" : window.innerHeight, window.innerWidth < 320 ? 320 : window.innerWidth ]);
      setSize([
        window.innerHeight,
        window.innerWidth < 320 ? 320 : window.innerWidth,
      ]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}