/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { getBaseUrl, getZeissIDAuthBaseUrl } from "Utils/getBaseUrl";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { bindActionCreators } from "redux";
import "@zeiss/zui";
import Processing from "Components/Admin/Processing";

const Logout = (props) => {
  const [isloggingut] = useState(true);
  const { reduxState, reduxActions } = props;
  const { userAccount } = reduxState;
  const { getUserAccount } = reduxActions;
  useEffect(() => {
    const applicationUrl = getBaseUrl();
    const ZeissIdAuthUrl = getZeissIDAuthBaseUrl();
    if (window.location.hostname.includes("localhost")) {
      window.location.href = `${ZeissIdAuthUrl}/Logout?p=B2C_1A_ZeissIdNormalSignIn&post_logout_redirect_uri=${applicationUrl}`;
    } else if (window.location.hostname.includes("dev")) {
      window.location.href = `${ZeissIdAuthUrl}/Logout?p=B2C_1A_ZeissIdNormalSignIn&post_logout_redirect_uri=${applicationUrl}`;
    } else if (window.location.hostname.includes("demo")) {
      window.location.href = `${ZeissIdAuthUrl}/Logout?p=B2C_1A_ZeissIdNormalSignIn&post_logout_redirect_uri=${applicationUrl}`;
    } else if (window.location.hostname.includes("fd-fkgen-qa")) {
      window.location.href = `${ZeissIdAuthUrl}/Logout?p=B2C_1A_ZeissIdNormalSignIn&post_logout_redirect_uri=${applicationUrl}`;
    } else if (window.location.hostname.includes("qa")) {
      window.location.href = `${ZeissIdAuthUrl}/Logout?p=B2C_1A_ZeissIdNormalSignIn&post_logout_redirect_uri=${applicationUrl}`;
    } else {
      window.location.href = `${ZeissIdAuthUrl}/Logout?p=B2C_1A_ZeissIdNormalSignIn&post_logout_redirect_uri=${applicationUrl}`;
    }
  }, [getUserAccount, userAccount.stateValue]);

  return isloggingut ? <Processing /> : <Redirect to="/" />;
};

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      getUserAccount: actions.getUserAccount,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  reduxState: {
    userAccount: state.userAccount,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
