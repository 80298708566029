//getUserAccount
export const SET_USER_STATE_VALUE = `SET_USER_STATE_VALUE`;
export const GET_USER_ACCOUNT_FULFILLED = `GET_USER_ACCOUNT_FULFILLED`;
export const GET_USER_ACCOUNT_FAILED = `GET_USER_ACCOUNT_FAILED`;
export const GET_USER_ACCOUNT_PENDING = `GET_USER_ACCOUNT_PENDING`;
export const SET_LOGGED_OUT = `SET_LOGGED_OUT`;

//layoutinfo
export const GET_KEYTYPES_ROLE_FULFILLED = `GET_KEYTYPES_ROLE_FULFILLED`;
export const GET_KEYTYPES_ROLE_FAILED = `GET_KEYTYPES_ROLE_FAILED`;
export const GET_KEYTYPES_ROLE_PENDING = `GET_KEYTYPES_ROLE_PENDING`;
export const SET_DRAWER_STATE = `SET_DRAWER_STATE`;

//Devicelist
export const GET_DEVICE_LIST_PENDING = `GET_DEVICE_LIST_PENDING`;
export const GET_DEVICE_LIST_FAILED = `GET_DEVICE_LIST_FAILED`;
export const GET_DEVICE_LIST_FULFILLED = `GET_DEVICE_LIST_FULFILLED`;
export const CLEAR_DEVICE_LIST = `CLEAR_DEVICE_LIST`;
export const CLEAR_DEVICE_LIST_ErrorMessage = `CLEAR_DEVICE_LIST_ErrorMessage`;
export const GET_DEVICE_BY_ID = `GET_DEVICE_BY_ID`;
export const GET_DEVICE_LIST_BY_ROLE_FULFILLED = `GET_DEVICE_LIST_BY_ROLE_FULFILLED`;
//Error logs
export const SET_INTERNET_ONLINE_STATUS = `SET_INTERNET_ONLINE_STATUS`;
export const SET_AUTHENTICATION_STATUS = `SET_AUTHENTICATION_STATUS`;
export const SET_AUTHORIZATION_STATUS = `SET_AUTHORIZATION_STATUS`;
export const SET_TRANSACTION_STATUS = `SET_TRANSACTION_STATUS`;
export const CLEAR_MESSAGE = `CLEAR_MESSAGE`;

//settings
export const GET_TEMPLATES_PENDING = `GET_TEMPLATES_PENDING`;
export const GET_EMAIL_TEMPLATES_FULFILLED = `GET_EMAIL_TEMPLATES_FULFILLED`;
export const GET_PRINT_TEMPLATES_FULFILLED = `GET_PRINT_TEMPLATES_FULFILLED`;
export const GET_REQUEST_LIST_ITEMS = `GET_REQUEST_LIST_ITEMS`;
export const GET_TEMPLATES_FAILED = `GET_TEMPLATES_FAILED`;
export const CLEAR_TEMPLATES_ERROR = `CLEAR_TEMPLATES_ERROR`;
export const CLEAR_TEMPLATES = `CLEAR_TEMPLATES`;