import React, { useState } from "react";
import { makeStyles, Modal } from "@material-ui/core";
import warningIcon from "./../../assets/icons/warning.png";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
    background: "#1a23293b !important",
    zIndex: "3001 !important",
  },
  modalWarningPaper: {
    position: "absolute",
    [theme.breakpoints.up("xs")]: {
      height: 239,
      width: 294,
    },
    [theme.breakpoints.up("md")]: {
      width: 450,
      height: 290,
    },
    backgroundColor: theme.palette.background.paper,
    borderTop: "5px solid #FDCA40",
    boxShadow: theme.shadows[5],
    border: "none",
    padding: "0px",
    textAlign: "center",
    borderRadius: "5px",
    outline: "none",
  },
  modalHeader: {
    textAlign: "center",
    padding: "0px",
    border: "0px",
    [theme.breakpoints.up("xs")]: {
      marginTop: "25px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "40px",
    },
  },
  modalBody: {
    position: "relative",
    padding: "0px 25px",
    textAlign: "center",
    height: "calc(100% - 155px)",
  },
  showErrorMessage: {
    width: "100%",
    padding: "1px 0px",
    font: "var(--zui-typography-body)",
    fontSize: "24px",
    textAlign: "center",
    color: "#828D9E",
    [theme.breakpoints.up("xs")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "24px",
    },
  },
  messagebody: {
    width: "inherit",
    position: "relative",
    display: "block",
    [theme.breakpoints.up("xs")]: {
      margin: "12px 0px",
    },
    [theme.breakpoints.up("md")]: {
      margin: "20px 0px",
    },
  },
  modalFooter: {
    borderTop: "1px solid #DDE3ED",
    height: "64px",
    alignSelf: "center",
    display: "block",
    width: "100%",
    textAlign: "center",
    position: "absolute",
    left: "0",
    bottom: "0",
  },
  modalBtn: {
    display: "inline-block",
    border: "1px solid #DDE3ED",
    padding: "21px",
    width: "50%",
    textAlign: "center",
    cursor: "pointer",
    userSelect: "none",
    outline: "none",
    background: "transparent",
    whiteSpace: "nowrap",
    font: "var(--zui-typography-subtitle1)",
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const DisableDeviceWarningModal = ({ state, title, clickClose, clickOk }) => {
  const classes = useStyles();
  const [warningModalStyle] = useState(getModalStyle);
  return (
    <>
      <Modal
        disableAutoFocus={true}
        className={classes.modal}
        open={state}
        onClose={() => {
          clickClose();
        }}
        aria-labelledby="warning-modal-title"
        aria-describedby="warning-modal-description">
        <div style={warningModalStyle} className={classes.modalWarningPaper}>
          <div className={classes.modalHeader}>
            <img height="30" width="30" src={warningIcon} alt="info" />
          </div>
          <div className={classes.modalBody}>
            <div className={classes.showErrorMessage}>
              <span className={classes.messagebody}>{title}</span>
            </div>
          </div>
          <div className={`${classes.modalFooter}`}>
            <button
              type="button"
              id="ConfirmData"
              className={`${classes.modalBtn} modalbtn2 cursorPoiner`}
              onClick={() => {
                clickOk();
              }}>
              Yes
            </button>
            <button
              type="button"
              id="DiscardData"
              className={`${classes.modalBtn} modalbtn2 cursorPoiner`}
              onClick={() => {
                clickClose();
              }}>
              No
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DisableDeviceWarningModal;