import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "./actions";
import App from "./App";

export const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
      {
        getUserAccount: actions.getUserAccount,
      },
      dispatch
  ),
});

export const mapStateToProps = (state) => ({
  reduxState: {
    userAccount: state.userAccount,
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
