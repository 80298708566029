import axios from "axios";
import * as ActionTypes from "../../../constants/actionTypes";
import { getDeviceByIdUrl, getSubscription } from "Utils/getBaseUrl";
export const pendingDL = (data) => ({
  type: ActionTypes.GET_DEVICE_LIST_PENDING,
  payload: data,
});

export const failedDL = (data) => ({
  type: ActionTypes.GET_DEVICE_LIST_FAILED,
  payload: data,
});

export const fulfilledDL = (data) => ({
  type: ActionTypes.GET_DEVICE_BY_ID,
  payload: data,
});

export const clearDeviceList = () => ({
  type: ActionTypes.CLEAR_DEVICE_LIST,
});

export const mapper = (deviceDetails) => {
  const deviceViewModel = {
    id: deviceDetails.device.id,
    name: deviceDetails.device.name,
    icCode: deviceDetails.device.icCode,
    isActive: deviceDetails.device.isActive,
    isGenelic:
      deviceDetails.device.name.toUpperCase().includes("PENTERO") === true ||
      deviceDetails.deviceKeyGeneratorMappings[0].version
        .toUpperCase()
        .includes("ALL") === true
        ? true
        : false,
    deviceKeyGeneratorMappings: deviceDetails.deviceKeyGeneratorMappings.map(
      (item) => {
        return {
          id:item.id,
          operator: item.operator,
          version: item.version,
          isActive: item.isActive,
        };
      }
    ),
    skills: deviceDetails.skills,
  };
  // deviceDetails.deviceKeyGeneratorMappings.map((item) => {
  //    if(item.enigma1 || item.enigma2)
  //    {
  //      deviceViewModel.isGenelic = false;
  //     }
  //     return true;
  //   });

  return deviceViewModel;
};
export const GetDeviceByDeviceCode =
  (deviceCode, role) => (dispatch, getState) => {
    const state = getState();
    const token = state.userAccount.stateValue;
    const getDeviceByIcCodeUrl = getDeviceByIdUrl() + role + "/" + deviceCode;
    const esbSubscriptionKey = getSubscription();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": `application/json`,
        //Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "EsbApi-Subscription-Key": esbSubscriptionKey,
      },
    };
    const errorMessage = {
      statusCode: "",
      message: "",
    };
    return axios
      .create(config)
      .get(`${getDeviceByIcCodeUrl}`)
      .then((res) => {
        errorMessage.statusCode = res.status;
        if (res.status === 200) {
          let data = mapper(res.data.data);
          dispatch(fulfilledDL(data));
          return true;
        } else if (res.status === 400) {
          errorMessage.message =
            "Unable to process the request. Please try again.";
          dispatch(failedDL(errorMessage));
          return false;
        } else if (res.status === 401) {
          errorMessage.message =
            "Access is denied due to the session timeout. Please login again.";
          dispatch(failedDL(errorMessage));
          return false;
        } else if (res.status === 404) {
          errorMessage.message =
            "The Requested URL was Not Found on this Server";
          dispatch(failedDL(errorMessage));
          return false;
        } else if (res.status === 500) {
          errorMessage.message = "Internal server error. Please try again.";
          dispatch(failedDL(errorMessage));
          return false;
        } else {
          //errorMessage.message = "Something went wrong";
          dispatch(failedDL(errorMessage));
          return false;
        }
      })
      .catch((error) => {
        let status = error;

        if (error.response) {
          status = error.response.status;
          errorMessage.statusCode = error.response.status;

          if (status === 400) {
            errorMessage.message =
              "Unable to process the request. Please try again.";
            dispatch(failedDL(errorMessage));
            return false;
          } else if (status === 401) {
            errorMessage.message =
              "Access is denied due to the session timeout. Please login again.";
            dispatch(failedDL(errorMessage));
            return false;
          } else if (status === 404) {
            errorMessage.message =
              "The Requested URL was Not Found on this Server";
            dispatch(failedDL(errorMessage));
            return false;
          } else if (status === 500) {
            errorMessage.message = "Internal server error. Please try again.";
            dispatch(failedDL(errorMessage));
            return false;
          } else {
            errorMessage.message = "Something went wrong";
            dispatch(failedDL(errorMessage));
            return false;
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          if (error.request.status === 0) {
            errorMessage.statusCode = 401;
            errorMessage.message =
              "Your request could not be processed due to Network Interruption. Please login again.";
          } else {
            errorMessage.message = error.message;
          }
          dispatch(failedDL(errorMessage));
          return false;
        } else {
          // Something happened in setting up the request that triggered an Error
          dispatch(failedDL(errorMessage));
          return false;
        }
      });
  };
