/* eslint-disable import/no-anonymous-default-export */
import * as ActionTypes from "../constants/actionTypes";

const initialState = {
  isSuccess: false,
  fetched: "pending",
  state: "",
  emailData: [],
  printData: [],
  requestData: [],
  errorMessage: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.GET_TEMPLATES_PENDING: {
      return {
        ...state,
        fetched: "pending",
      };
    }
    case ActionTypes.GET_EMAIL_TEMPLATES_FULFILLED: {
      return {
        ...state,
        fetched: "fulfilled",
        isSuccess: true,
        emailData: action.payload,
      };
    }
    case ActionTypes.GET_PRINT_TEMPLATES_FULFILLED: {
      return {
        ...state,
        fetched: "fulfilled",
        isSuccess: true,
        printData: action.payload,
      };
    }
    case ActionTypes.GET_REQUEST_LIST_ITEMS: {
      return {
        ...state,
        fetched: "fulfilled",
        isSuccess: true,
        requestData: action.payload,
      };
    }
    case ActionTypes.GET_TEMPLATES_FAILED: {
      return {
        ...state,
        fetched:
          action.payload.statusCode === 401 || action.payload.statusCode === 404
            ? "unAuthorize"
            : "failed",
        errorMessage: action.payload.message,
      };
    }
    case ActionTypes.CLEAR_TEMPLATES_ERROR: {
      return {
        ...state,
        fetched: "failed",
        isSuccess: true,
        errorMessage: "",
      };
    }
    case ActionTypes.CLEAR_TEMPLATES: {
      return initialState;
    }
    default:
      return state;
  }
};
