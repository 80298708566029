import { useEffect, useState } from "react";
import * as actions from "../../actions";
import { useSelector } from "react-redux";
import { Pagination } from "@material-ui/lab";
import HistoryKeyViewModal from "../Modal/HistoryKeyViewModal";
import useWindowSize from "actions/useWindowDimensions/useWindowSize";
import { addDays } from "date-fns";
import WarnignModalPopup from "../Modal/WarningModal";
import InternetDisconnected from "../Modal/InternetDisconnected";
import { checkOnlineStatus } from "actions";
import useStyles from "../KeyTypes/CommonStyle";
import "./History.css";
import  CustomDatePicker  from "./../CustomDatePicker/CustomDatePicker";
import { ZuiButton, ZuiIconShow, ZuiIconDownload } from "@zeiss/zui-react";
import "@zeiss/zui";
import Processing from "Components/Admin/Processing";

const PaginationControlled = ({ paginationInfo, handlePage }) => {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [height, width] = useWindowSize();
  const size = width > 599 ? 1 : 0;
  const handleChange = (event, value) => {
    handlePage(value);
  };

  return paginationInfo.TotalCount !== 0 ? (
    <div className={classes.divPaginationContainer}>
      <div id="divPagination" className={classes.divPaginationCenterAlign}>
        <Pagination
          count={parseInt(paginationInfo.TotalPages)}
          siblingCount={size}
          page={parseInt(paginationInfo.CurrentPage)}
          onChange={handleChange}
        />
      </div>
    </div>
  ) : (
    <div className={classes.divPaginationContainer}>
      <div id="divPagination" className={classes.divPaginationCenterAlign}>
        {" "}
      </div>
    </div>
  );
};
const HistorySectionMobile = ({
  userKeysHistory,
  handleGeneratedKeys,
  handleDownload,
}) => {
  const classes = useStyles();
  const showGeneratedKeys = (value) => {
    handleGeneratedKeys(value);
  };
  const downloadKey = (value) => {
    handleDownload(value);
  };

  return (
    <div
      id="divHistoryListSectionMobile"
      className={`${classes.userKeysHistoryTableDiv} ${classes.divDeviceListContainer} ${classes.historySectionMobile}`}>
      {userKeysHistory.length !== 0 ? (
        userKeysHistory.map((historyData, index) => {
          return (
            <div
              key={index}
              id="div_History_ListsMobile"
              style={{
                margin: "8px 0px",
                minHeight: "90px",
                background: "#FFFF",
              }}>
              <div
                id="historylistContainer"
                style={{
                  display: "block",
                  width: "calc(100% - 32px)",
                  minHeight: "90px",
                  margin: "0px 16px",
                }}>
                <div
                  id="historyListGrid"
                  style={{ display: "inline-flex", width: "100%" }}>
                  <div
                    id="historyListGridRow"
                    style={{ width: "60%", display: "inline-grid" }}>
                    <span
                      className={`${classes.tableTextSpan}`}
                      style={{
                        font: "var(--zui-typography-label2)",
                        textTransform: "UpperCase",
                        color: "#626C7A",
                      }}>
                      {"KEY TYPES"}
                    </span>
                    <span className={`${classes.tableTextSpan}`}>
                      {historyData.keyTypeName}
                    </span>
                    <span className={`${classes.tableTextSpan}`}>
                      {historyData.status === "Valid" ? (
                        <div className={classes.validKeyText}>{"VALID"}</div>
                      ) : historyData.status === "Expired" ? (
                        <div className={classes.expiredKeyText}>
                          {"EXPIRED"}
                        </div>
                      ) : (
                        <div className={classes.futureValidKeyText}>
                          {"FUTURE VALID"}
                        </div>
                      )}
                    </span>
                  </div>
                  <div
                    id="historyListGridRow"
                    style={{ width: "40%", display: "inline-grid" }}>
                    <span
                      className={`${classes.tableTextSpan}`}
                      style={{
                        font: "var(--zui-typography-label2)",
                        textTransform: "UpperCase",
                        color: "#626C7A",
                      }}>
                      {"VALID FROM"}
                    </span>
                    <span
                      className={`${classes.tableTextSpan}`}
                      style={{ width: "auto" }}>
                      {historyData.creationDate === ""
                        ? ""
                        : Intl.DateTimeFormat("en-GB", {
                            timeZone: "UTC",
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                          }).format(new Date(historyData.creationDate))}
                    </span>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          width: "24px",
                          margin: "4px",
                          display: "inline-block",
                        }}
                        onClick={() => showGeneratedKeys(historyData)}>
                        <ZuiIconShow className="historyViewIcon"></ZuiIconShow>
                      </span>
                      <span style={{ marginLeft: "18px", width: "auto" }}>
                        {historyData.filePath === null ? (
                          ""
                        ) : (
                          <span
                            style={{
                              width: "24px",
                              margin: "4px",
                              display: "inline-block",
                            }}
                            onClick={() => downloadKey(historyData.filePath)}>
                            <ZuiIconDownload className="downloadKeyIcon"></ZuiIconDownload>
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div
          id="div_History_ListsMobile"
          style={{ margin: "8px 0px", minHeight: "90px", background: "#FFFF" }}>
          <div
            id="historylistContainer"
            style={{
              display: "Block",
              width: "calc(100% - 32px)",
              minHeight: "90px",
              margin: "0px 16px",
            }}>
            <div
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                lineHeight: "90px",
              }}>
              {" No keys found"}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const HistorySectionDesktop = ({
  userKeysHistory,
  handleGeneratedKeys,
  handleDownload,
}) => {
  const classes = useStyles();
  const showGeneratedKeys = (value) => {
    handleGeneratedKeys(value);
  };
  const downloadKey = (value) => {
    handleDownload(value);
  };

  return (
    <div
      id="divHistoryListSectionDesktop"
      className={`${classes.userKeysHistoryTableDiv} ${classes.divDeviceListContainer} ${classes.historySectionDesktop} `}>
      <table>
        <thead>
          <tr key={"Header"}>
            <th>
              <span className={`${classes.tableTextSpan}`}>{"KEY TYPES"}</span>
            </th>
            <th>
              <span className={`${classes.tableTextSpan}`}>{"VALID FROM"}</span>
            </th>
            <th>
              <span className={`${classes.tableTextSpan}`}>{"STATUS"}</span>
            </th>
            <th>
              <span className={`${classes.tableTextSpan}`}>{"DOWNLOAD"}</span>
            </th>
            <th>
              <span className={`${classes.tableTextSpan}`}>{"VIEW"}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {userKeysHistory.length !== 0 ? (
            userKeysHistory.map((row, index) => {
              return (
                <tr key={`historylist` + index.toString()}>
                  <td style={{ borderLeft: "1px solid #c1cbd9" }}>
                    <span className={`${classes.tableTextSpan}`}>
                      {row.keyTypeName}
                    </span>
                  </td>
                  <td>
                    <span className={`${classes.tableTextSpan}`}>
                      {row.creationDate === ""
                        ? ""
                        : Intl.DateTimeFormat("en-GB", {
                            timeZone: "UTC",
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                          }).format(new Date(row.creationDate))}
                    </span>
                  </td>
                  <td>
                    <span className={`${classes.tableTextSpan}`}>
                      {row.status === "Valid" ? (
                        <div className={classes.validKeyText}>{"VALID"}</div>
                      ) : row.status === "Expired" ? (
                        <div className={classes.expiredKeyText}>
                          {"EXPIRED"}
                        </div>
                      ) : (
                        <div className={classes.futureValidKeyText}>
                          {"FUTURE VALID"}
                        </div>
                      )}
                    </span>
                  </td>
                  <td className={classes.minDownloadHeight}>
                    <span>
                      {row.filePath === null ? (
                        ""
                      ) : (
                        <span
                          style={{
                            width: "24px",
                            margin: "4px",
                            display: "inline-block",
                          }}
                          onClick={() => downloadKey(row.filePath)}>
                          <ZuiIconDownload
                            size="l"
                            className="downloadKeyIcon"></ZuiIconDownload>
                        </span>
                      )}
                    </span>
                  </td>
                  <td style={{ borderRight: "1px solid #c1cbd9" }}>
                    <span
                      style={{
                        width: "24px",
                        margin: "4px",
                        display: "inline-block",
                      }}
                      onClick={() => showGeneratedKeys(row)}>
                      <ZuiIconShow
                        size="l"
                        className="historyViewIcon"></ZuiIconShow>
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr key={"NoKeys"}>
              <td
                colSpan={5}
                align={"center"}
                style={{ border: "1px solid #c1cbd9" }}>
                {" No keys found "}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
const History = (props) => {
  const classes = useStyles();
  const tokenId = useSelector((state) => state.userAccount.tokenId);
  const role = useSelector((state) => state.userAccount.role);
  const [isHQ, setIsHQ] = useState(false);
  const [userKeysHistory, setUserKeysHistory] = useState([]);
  const [onlineStatus, setOnlineStatus] = useState(false);
  const [isKeyLoading, setKeyLoading] = useState(false);
  const [isUserKeysHistoryReady, setIsUserKeysHistoryReady] = useState(false);
  const [isViewHistoryOpen, setIsViewHistoryOpen] = useState(false);
  const [viewHistoryContent, setViewHistoryContent] = useState();
  const [paginationInfo, setPaginationInfo] = useState();
  const [statusHistory, setStatusHistory] = useState("all");
  const [fromDateHistory, setFromDateHistory] = useState();
  const [toDateHistory, setToDateHistory] = useState();
  const [isError, setIsError] = useState(false);
  const [inValidModel, setModelState] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [downloadStatus, setDownloadStatus] = useState(200);
  const [prevfromDateHistory, setPrevFromDateHistory] =
    useState(fromDateHistory);
  const [prevtoDateHistory, setPrevToDateHistory] = useState(toDateHistory);
  const [prevStatus, setPrevStatus] = useState("all");
  const [isfilterChanged, setfilterChangeStatus] = useState(false);
  function localDateToISOString(date) {
    const offsetMs = date.getTimezoneOffset() * 60 * 1000;
    const msLocal = date.getTime() - offsetMs;
    const dateLocal = new Date(msLocal);
    const iso = dateLocal.toISOString();
    const isoLocal = iso.slice(0, 19);
    return isoLocal;
  }

  const getFilterParam = (
    statusHistory,
    fromDateHistory,
    toDateHistory,
    currentPageNumber
  ) => {
    return {
      Status: statusHistory,
      FromDate:
        fromDateHistory &&
        Intl.DateTimeFormat("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }).format(fromDateHistory),
      ToDate:
        toDateHistory &&
        Intl.DateTimeFormat("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }).format(toDateHistory),
      RequestDate: localDateToISOString(new Date()) + "Z",
      PageNumber: currentPageNumber ? currentPageNumber : 1,
      PageSize: 6,
    };
  };

  useEffect(() => {
    let isMounted = true;
    if (role.toUpperCase().includes("HQ")) {
      setIsHQ(true);
    } else {
      setIsHQ(false);
    }
    actions
      .GetHistoryDetails(
        tokenId,
        getFilterParam("all", fromDateHistory, toDateHistory, 1)
      )
      .then((res) => {
        if (isMounted) {
          if (res.status === true) {
            setPaginationInfo(res.paginationInfo);
            // setCurrentPage(res.paginationInfo.CurrentPage);
            setUserKeysHistory(res.data);
            setIsViewHistoryOpen(false);
            setIsUserKeysHistoryReady(true);
          } else {
            setIsUserKeysHistoryReady(true);
            setIsViewHistoryOpen(false);
            setIsError(true);
            setIsViewHistoryOpen(false);
            setIsUserKeysHistoryReady(true);
            setErrorText(res.message);
          }
        }
      });
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, tokenId]);

  const searchUserHistory = async ({
    statusHistory,
    fromDateHistory,
    toDateHistory,
  }) => {
    const filterParams = getFilterParam(
      statusHistory,
      fromDateHistory,
      toDateHistory,
      1
    );
    if (
      (filterParams.FromDate !== null && filterParams.ToDate === null) ||
      (filterParams.FromDate !== undefined && filterParams.ToDate === undefined)
    ) {
      setErrorText("Please select To Date");
      setIsError(true);
      setModelState(true);
      setIsUserKeysHistoryReady(true);
      return;
    } else if (
      (filterParams.FromDate === null && filterParams.ToDate !== null) ||
      (filterParams.FromDate === undefined && filterParams.ToDate !== undefined)
    ) {
      setErrorText("Please select From Date");
      setIsError(true);
      setModelState(true);
      setIsUserKeysHistoryReady(true);
      return;
    } else {
      setModelState(false);
    }
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      if (isfilterChanged) {
        setfilterChangeStatus(false);
        setPrevStatus(statusHistory);
        setPrevFromDateHistory(fromDateHistory);
        setPrevToDateHistory(toDateHistory);
      }
      setIsUserKeysHistoryReady(false);
      setKeyLoading(false);
      actions.GetHistoryDetails(tokenId, filterParams).then((res) => {
        if (res.status === true) {
          setIsUserKeysHistoryReady(true);
          setPaginationInfo(res.paginationInfo);
          setUserKeysHistory(res.data);
          setIsViewHistoryOpen(false);
          setIsUserKeysHistoryReady(true);
        } else {
          setIsViewHistoryOpen(false);
          setIsUserKeysHistoryReady(true);
          setIsError(true);
          setErrorText(res.message);
        }
      });
    } else {
      setOnlineStatus(true);
    }
    setKeyLoading(false);
  };

  const getUserHistorybyPageNumber = async (pageNumber) => {
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      setIsUserKeysHistoryReady(false);
      if (isfilterChanged) {
        setStatusHistory(prevStatus);
        setFromDateHistory(prevfromDateHistory);
        setToDateHistory(prevtoDateHistory);
        setfilterChangeStatus(false);
      }
      const filterParams = getFilterParam(
        prevStatus,
        prevfromDateHistory,
        prevtoDateHistory,
        pageNumber
      );

      actions.GetHistoryDetails(tokenId, filterParams).then((res) => {
        if (res.status === true) {
          setPaginationInfo(res.paginationInfo);
          setUserKeysHistory(res.data);
          setIsViewHistoryOpen(false);
          setIsUserKeysHistoryReady(true);
        } else {
          setIsUserKeysHistoryReady(true);
          setIsError(true);
          setErrorText(res.message);
          setIsViewHistoryOpen(false);
          setIsUserKeysHistoryReady(true);
        }
      });
    } else {
      setOnlineStatus(true);
    }
    setKeyLoading(false);
  };

  const downloadKeyHistory = async (uri) => {
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      setIsUserKeysHistoryReady(false);
      actions.validateUrl(uri).then((res) => {
        if (res.status === false) {
          setIsUserKeysHistoryReady(true);
          setDownloadStatus(res.statusCode);
          setErrorText(res.message);
          setIsError(true);
        } else {
          let link = document.createElement("a");
          link.href = uri;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setIsUserKeysHistoryReady(true);
        }
      });
    } else {
      setOnlineStatus(true);
    }
    setKeyLoading(false);
  };

  const viewGeneratedKeys = (historyData) => {
    setIsViewHistoryOpen(true);
    setViewHistoryContent(historyData);
  };

  async function reloadHistory() {
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      window.location.reload(true);
    } else {
      setOnlineStatus(true);
      setKeyLoading(false);
    }
  }

  const handleClose = () => {
    setIsViewHistoryOpen(false);
    setViewHistoryContent();
    setErrorText();
    setIsError(false);
    if (downloadStatus === 401) {
      logOut();
    }
  };
  const closeOnlineStatus = () => {
    setOnlineStatus(false);
  };

  const logOut = () => {
    let path = window.location.origin + "/Logout";
    window.location.href = path;
  };

  return isKeyLoading || isUserKeysHistoryReady === false ? (
    <Processing progressText={true} />
  ) : (
    <>
      <div id="div_UserHistory_Conainer" className={`${classes.divContainer}`}>
        <div className={`${classes.headerText}`}>{"History"}</div>
        <div id="div_Filter_Selection" className={classes.divHistoryContainer}>
          <div
            id="div_Hisory_Filters"
            className={classes.divHistoryFiltersContainer}>
            <div className={classes.divFilterContainer}>
              <div className={classes.filterGrid}>
                <div className={classes.formLableHistory}>
                  <div className={classes.formLableHistoryText}>{"Status"}</div>
                  <div
                    className={`${classes.formLableSelectDiv} formSelectBtnDiv`}>
                    <select
                      className={classes.formLableHistoryFilter}
                      onChange={(e) => {
                        setToDateHistory(null);
                        setFromDateHistory(null);
                        setStatusHistory(e.target.value);
                        setfilterChangeStatus(true);
                      }}
                      value={statusHistory}>
                      <option value="all">All</option>
                      <option value="valid">Valid</option>
                      <option value="expired">Expired</option>
                      <option value="futurevalid">Future Valid</option>
                      {/* {isHQ ? <option value="futurevalid">Future Valid</option> : ""} */}
                    </select>
                  </div>
                </div>
              </div>
              <div className={classes.filterGrid}>
                <div className={classes.formLableHistory}>
                  <div className={classes.formLableHistoryText}>
                    {"From Date"}
                  </div>
                  <div
                    id="divFromDate"
                    className={classes.formLableHistoryText}>
                    <CustomDatePicker
                      isInvalid={false}
                      disableInput={false}
                      value={fromDateHistory}
                      maxDate={
                        !isHQ
                          ? toDateHistory
                            ? toDateHistory
                            : new Date()
                          : toDateHistory
                          ? toDateHistory
                          : ""
                      }
                      minDate={addDays(new Date(), -61)}
                      setSelectedDate={(value) => {
                        setfilterChangeStatus(true);
                        setFromDateHistory(value);
                      }}></CustomDatePicker>
                  </div>
                </div>
                <div className={classes.formLableHistory}>
                  <div className={classes.formLableHistoryText}>
                    {"To Date"}
                  </div>
                  <div id="divToDate" className={classes.formLableHistoryText}>
                    <CustomDatePicker
                      isInvalid={false}
                      disableInput={false}
                      value={toDateHistory}
                      maxDate={!isHQ ? new Date() : ""}
                      minDate={
                        fromDateHistory
                          ? fromDateHistory
                          : addDays(new Date(), -61)
                      }
                      setSelectedDate={(value) => {
                        setfilterChangeStatus(true);
                        setToDateHistory(value);
                      }}></CustomDatePicker>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.divFilterBtnContainer}>
              <div
                id="form_Select_all"
                className={`${classes.formLableHistoryButtons}`}>
                <ZuiButton
                  className={`${classes.historyBtnReset}`}
                  size="l"
                  environment="auto"
                  onClick={() => {
                    reloadHistory();
                  }}>
                  {"Reset"}
                </ZuiButton>
                <ZuiButton
                  size="l"
                  environment="auto"
                  emphasis="active-primary"
                  className={`${classes.buttonHistory}`}
                  onClick={() => {
                    searchUserHistory({
                      statusHistory,
                      fromDateHistory,
                      toDateHistory,
                    });
                  }}>
                  {"Search"}
                </ZuiButton>
              </div>
            </div>
          </div>
        </div>
        <div
          id="divSelectionViewHistory"
          className={classes.divSelectionViewHistory}>
          <HistorySectionMobile
            className={`${classes.historySectionMobile}`}
            userKeysHistory={userKeysHistory}
            handleGeneratedKeys={viewGeneratedKeys}
            handleDownload={downloadKeyHistory}></HistorySectionMobile>
          <HistorySectionDesktop
            className={`${classes.historySectionDesktop}`}
            userKeysHistory={userKeysHistory}
            handleGeneratedKeys={viewGeneratedKeys}
            handleDownload={downloadKeyHistory}></HistorySectionDesktop>
        </div>
        <div
          id="fixedFooter"
          className={`${classes.fixedFooter}historyfixedFooter`}>
          <PaginationControlled
            paginationInfo={paginationInfo}
            handlePage={(pageNumber) => {
              getUserHistorybyPageNumber(pageNumber);
            }}
          />
        </div>
        {isViewHistoryOpen ? (
          <HistoryKeyViewModal
            state={isViewHistoryOpen}
            historyContent={viewHistoryContent}
            clickClose={handleClose}
          />
        ) : (
          ""
        )}
        {isError ? (
          <WarnignModalPopup
            state={isError}
            title={errorText}
            clickClose={() => {
              inValidModel !== true ? logOut() : handleClose();
            }}
          />
        ) : (
          ""
        )}
        <InternetDisconnected
          state={onlineStatus}
          clickClose={closeOnlineStatus}></InternetDisconnected>
      </div>
    </>
  );
};

export default History;
