import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import * as actions from "actions";
import { clearDeviceList, checkOnlineStatus } from "actions";
import { DisableDeviceSkillset } from "./../../actions/index";
import RetrieveDataError from "./RetrieveDataError";
import {} from "@material-ui/core";
import WarnignModalPopup from "../Modal/WarningModal";
import { ZuiButton, ZuiToggleSwitch, ZuiIconEwiInfo } from "@zeiss/zui-react";
import {} from "@zeiss/zui";
import useStyles from "../KeyTypes/CommonStyle";
import "./Template.css";
import InternetDisconnected from "../Modal/InternetDisconnected";
import Processing from "./Processing";

const DisableSkillset = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { reduxState, reduxActions } = props;
  const { deviceListInfo } = reduxState;
  const { GetDeviceByDeviceId } = reduxActions;

  const [updateSkillStatus, setUpdateSkillStatus] = useState("pending");
  const [isDeviceListReady, setDeviceListReady] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [onlineStatus, setOnlineStatus] = useState(false);

  const [isActiveState, setIsActiveState] = useState("Enabled");
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

  const token = useSelector((state) => state.userAccount.tokenId);

  const deviceCode =
    props.location.state !== undefined ? props.location.state.deviceCode : null;
  const role =
    props.location.state !== undefined ? props.location.state.role : undefined;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearDeviceList());
    setIsLoading(true);
  }, [dispatch]);

  useEffect(() => {
    if (
      (!isDeviceListReady && deviceListInfo.fetched === "pending") ||
      (isActiveState !== "" && isUpdateSuccess)
    ) {
      GetDeviceByDeviceId(deviceCode, role);
      setIsLoading(false);
      setIsUpdateSuccess(false);
    }
    if (deviceListInfo.fetched === "fetched") {
      setDeviceListReady(true);
      setIsLoading(false);
    }
    if (
      deviceListInfo.isSuccess === false &&
      (deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize")
    ) {
      displayPopUp(deviceListInfo.errorMessage);
      setIsLoading(false);
    }
  }, [
    GetDeviceByDeviceId,
    deviceCode,
    deviceListInfo,
    isDeviceListReady,
    role,
    isActiveState,
    isUpdateSuccess,
  ]);

  const displayPopUp = (text) => {
    setErrorText(text);
    setIsError(true);
  };

  const handleClose = () => {
    setIsLoading(true);
    setIsError(false);
    setErrorText("");
    if (
      deviceListInfo.fetched === "unAuthorize" ||
      updateSkillStatus === "unAuthorize"
    ) {
      logOut();
    } else {
      setIsLoading(false);
      setUpdateSkillStatus("fulfilled");
    }
  };

  const logOut = () => {
    let path = window.location.origin + "/Logout";
    window.location.href = path;
  };

  const closeOnlineStatus = () => {
    setOnlineStatus(false);
  };

  const updateDisableSkillset = async ({ id, isActive }) => {
    const deviceId = deviceListInfo.deviceListByID.id;
    setIsLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      const result = await DisableDeviceSkillset(token, {
        deviceId,
        id,
        isActive,
      });
      if (result.status === true) {
        setIsUpdateSuccess(true);
        setIsActiveState("");
      } else {
        if (result.statusCode === 401) {
          setUpdateSkillStatus("unAuthorize");
          displayPopUp(result.message);
        } else if (result.statusCode === 400) {
          //Reload the page as the data already exists in server
          setIsUpdateSuccess(true);
          setIsActiveState("");
        } else {
          setUpdateSkillStatus("failed");
          displayPopUp(result.message);
        }
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      setOnlineStatus(true);
    }
  };

  return isLoading || deviceListInfo.fetched === "pending" ? (
    <Processing progressText={true} />
  ) : deviceListInfo.fetched === "fulfilled" ? (
    <div className={classes.mainBodyContainer}>
      <div
        className="deviceNameContainer"
        // show-searchbar={false}
      >
        <p className="headParagraph">Device: </p>
        <p className="headParagraphUpper">
          {deviceListInfo.deviceListByID.name}
          <span className="headSpan">
            ({deviceListInfo.deviceListByID.icCode})
          </span>
        </p>
      </div>
      <div className={classes.deviceHeading}>{"Disable Skillsets"}</div>
      <div className="deviceBodyContainer">
        {deviceListInfo.deviceListByID.skills.map((item, index) => {
          const isDisabled = item.isActive ? "" : classes.fadeDisplay;
          return (
            <div
              key={"skills" + index}
              className={`${isDisabled} deviceBodyItemDiv`}>
              {item.name} | {item.courseCode}
              <ZuiToggleSwitch
                key={item.id}
                name={item.name}
                value={item.isActive}
                emphasis="primary"
                onClick={(e) => {
                  const isActiveValue = item.isActive ? "Enabled" : "Disabled";
                  setIsActiveState(isActiveValue);
                  updateDisableSkillset({
                    id: item.id,
                    isActive: e.target.value,
                  });
                }}></ZuiToggleSwitch>
            </div>
          );
        })}
      </div>
      <div className="footerContainer">
        <div className="adminInfoFooterContainer">
          <ZuiIconEwiInfo
            // size="l"
            style={
              {
                // position: "absolute",
                // width: "30px",
                // height: "30px",
                // left: "13px",
                // top: "7px",
              }
            }></ZuiIconEwiInfo>
          <span
            style={{
              marginLeft: "8px",
            }}>
            {"All the Applicable Keytypes will also be disabled"}
          </span>
        </div>
        <ZuiButton
          size="s"
          title="Back"
          environment="auto"
          className="footerBackButton"
          onClick={() => {
            history.push({
              pathname: "/home/Devices/ViewDeviceDetails",
              state: { deviceCode: deviceCode, role: role },
            });
          }}>
          {"Back"}
        </ZuiButton>
      </div>
      <div id="fseModalPopup">
        <WarnignModalPopup
          state={isError}
          title={errorText}
          clickClose={handleClose}
        />
      </div>
      <InternetDisconnected
        state={onlineStatus}
        clickClose={closeOnlineStatus}></InternetDisconnected>
    </div>
  ) : (
    <>
      {(updateSkillStatus === "failed" ||
        updateSkillStatus === "unAuthorize" ||
        deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize") &&
      isError ? (
        <div id="fseModalPopup">
          <WarnignModalPopup
            state={isError}
            title={errorText}
            clickClose={handleClose}
          />
        </div>
      ) : (
        <RetrieveDataError />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      GetDeviceByDeviceId: actions.GetDeviceByDeviceCode,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  reduxState: {
    deviceListInfo: state.deviceListInfo,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DisableSkillset);
