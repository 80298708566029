/* eslint-disable array-callback-return */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as actions from "../../actions/index";
import RetrieveDataError from "./RetrieveDataError";
import useStyles from "./../KeyTypes/CommonStyle";
import { useHistory } from "react-router-dom";
import { AddSkillsetKeyTypes } from "./../../actions/index";
import {} from "@material-ui/core";
import WarnignModalPopup from "./../Modal/WarningModal";
import { ZuiButton, ZuiList, ZuiListItem, ZuiCheckbox } from "@zeiss/zui-react";
import "@zeiss/zui";
import { connect, useDispatch } from "react-redux";
import { clearDeviceList, checkOnlineStatus } from "actions";
import { bindActionCreators } from "redux";
import InternetDisconnected from "./../Modal/InternetDisconnected";
import Processing from "./Processing";

const AddNewSkillsetKeyType = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { reduxState, reduxActions } = props;
  const { deviceListInfo } = reduxState;
  const { GetDeviceByDeviceId } = reduxActions;
  const token = useSelector((state) => state.userAccount.tokenId);
  const [skillsetList, setskillsetList] = useState([]);
  const [skillsetName, setSkillsetName] = useState("");
  const [isKeyTypeChecked, setIsKeyTypeChecked] = useState(false);
  const [keyTypes] = useState([
    { isChecked: false, id: 1, description: "FSE/Master Key" },
    { isChecked: false, id: 2, description: "OneDay Key" },
    { isChecked: false, id: 3, description: "IT Admin PWD" },
    { isChecked: false, id: 4, description: "Pentero & Old Service PWD" },
    { isChecked: false, id: 5, description: "BIOMed Key" },
    { isChecked: false, id: 6, description: "Factory Key" },
  ]);
  const [updateSkillStatus, setUpdateSkillStatus] = useState("Pending");

  const [isDeviceListReady, setDeviceListReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [onlineStatus, setOnlineStatus] = useState(false);
  const deviceCode =
    props.location.state !== undefined ? props.location.state.deviceCode : null;
  const dispatch = useDispatch();
  const role =
    props.location.state !== undefined ? props.location.state.role : undefined;

  useEffect(() => {
    dispatch(clearDeviceList());
    setIsLoading(true);
  }, [dispatch]);

  useEffect(() => {
    if (!isDeviceListReady && deviceListInfo.fetched === "pending") {
      GetDeviceByDeviceId(deviceCode, role);
      setIsLoading(false);
    }
    if (deviceListInfo.fetched === "fetched") {
      setDeviceListReady(true);
      setIsLoading(false);
    }
    if (
      deviceListInfo.isSuccess === false &&
      (deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize")
    ) {
      displayPopUp(deviceListInfo.errorMessage);
      setIsLoading(false);
    }
  }, [
    GetDeviceByDeviceId,
    deviceCode,
    deviceListInfo,
    isDeviceListReady,
    role,
  ]);

  const addSkillset = async () => {
    const deviceId = deviceListInfo.deviceListByID.id;
    //let isMounted = true;
    setIsLoading(true);
    if (skillsetName !== "" && keyTypes.length !== 0) {
      const keyType = keyTypes
        .map((type) => {
          if (type.isChecked) {
            return type.id;
          }
        })
        .filter((t) => t !== undefined);
      let onlineStatus = await checkOnlineStatus();
      if (onlineStatus !== false) {
        const result = await AddSkillsetKeyTypes(token, {
          deviceId,
          skill: skillsetName,
          keyType,
          role,
        });
        //if (isMounted) {
        if (result.status === true) {
          history.push({
            pathname: "/home/Devices/ViewDeviceDetails",
            state: { deviceCode: deviceCode, role: role },
          });
        } else {
          if (result.statusCode === 401) {
            setUpdateSkillStatus("unAuthorize");
            displayPopUp(result.message);
          } else if (result.statusCode === 400) {
            setUpdateSkillStatus("failed");
            displayPopUp(result.message);
          } else {
            setUpdateSkillStatus("failed");
            displayPopUp(result.message);
          }
          setIsLoading(false);
          //displayPopUp(result.message);
        }
        //}
        // isMounted = false;
        // return () => {
        // 	isMounted = false;
        // };
      } else {
        setIsLoading(false);
        setOnlineStatus(true);
      }
    } else {
      setIsLoading(false);
      //history.push("/home/Devices");
    }
  };

  const displayPopUp = (text) => {
    setErrorText(text);
    setIsError(true);
  };

  const handleClose = () => {
    setIsLoading(true);
    setIsError(false);
    setErrorText("");
    if (
      deviceListInfo.fetched === "unAuthorize" ||
      updateSkillStatus === "unAuthorize"
    ) {
      logOut();
    } else {
      setIsLoading(false);
      setUpdateSkillStatus("fulfilled");
    }
  };

  const logOut = () => {
    let path = window.location.origin + "/Logout";
    window.location.href = path;
  };

  const closeOnlineStatus = () => {
    setOnlineStatus(false);
  };

  return isLoading || deviceListInfo.fetched === "pending" ? (
    <Processing progressText={true} />
  ) : deviceListInfo.fetched === "fulfilled" ? (
    <div className={classes.mainBodyContainer}>
      <div className="deviceNameContainer">
        <div className="headingUppercaseDiv">
          {deviceListInfo.deviceListByID.name}
        </div>
        <span className="headSpan">
          ({deviceListInfo.deviceListByID.icCode})
        </span>
      </div>
      <div
        style={{
          margin: "8px 8px 1px 8px",
          width: "98.5%",
          background: "#F5F7FA",
          display: "flex",
          padding: "15px 0 15px 24px",
          font: "var(--zui-typography-subtitle2)",
          fontSize: "12px !important",
          lineHeight: "16px !important",
          color: "#555E6B",
        }}>
        {"Add Key Types/ New Skillset"}
      </div>
      <div className="addNewSkillsetContainer">
        <div className="addNewSkillsetInnerdiv">
          <div className="addNewSkillsetBox">

              <div style={{ width: "100%", paddingBottom: "36px" }}>
                <input
                  style={{ width: "100%" }}
                  className={`${classes.adminDeviceInput}`}
                  name="skillsetName"
                  placeholder="Skillset Name"
                  type="text"
                  onChange={async (e) => {
                    if (e.target.value === " ") {
                      e.preventDefault();
                      return;
                    }
                    keyTypes.map((item) => (item.isChecked = false));
                    setIsKeyTypeChecked(false);
                    e.persist();
                    setSkillsetName(e.target.value);
                    let onlineStatus = await actions.checkOnlineStatus();
                    if (onlineStatus !== false) {
                      await actions
                        .SearchSkills(e.target.value, token)
                        .then((certificates) => {
                          if (
                            certificates.status === true &&
                            certificates.data !== undefined
                          ) {
                            const itemsss = certificates.data.data.map(
                              (item) => {
                                if (
                                  item.name !== "" ||
                                  item.name !== undefined ||
                                  item.name !== null
                                ) {
                                  return item.name;
                                } else {
                                  return "";
                                }
                              }
                            );
                            setskillsetList(itemsss.filter((it) => it !== ""));
                          } else {
                            if (certificates.statusCode === 401) {
                              setUpdateSkillStatus("unAuthorize");
                              displayPopUp(certificates.message);
                            }
                            setskillsetList([]);
                          }
                        });
                    } else {
                      setOnlineStatus(true);
                    }
                  }}
                  value={skillsetName}
                />
                {skillsetList.length !== 0 && skillsetName !== "" ? (
                  <ZuiList
                    integrated
                    hierarchy="second"
                    style={{
                      width: "16%",
                      zIndex: "1000000",
                      position: "absolute",
                      height: "calc(100% - 40%)",
                      overflow: "auto",
                    }}>
                    {skillsetList
                      .filter((ss) => ss !== "")
                      .map((item, index) => {
                        return (
                          <ZuiListItem
                            style={{
                              height: "auto !important",
                              padding: "0px 10px 30px 10px",
                            }}
                            key={index}
                            onClick={async () => {
                              setSkillsetName(item);
                              setskillsetList([]);
                              
                              setIsLoading(true);

                              await actions
                              .GetKeyTypesBySkills([item.substring(item.indexOf('|')+2,item.length)], token)
                                .then((k) => {
                                  if (
                                    k.data.data !== null ||
                                    k.data.data !== undefined ||
                                    k.data.data.length !== 0
                                  ) {
                                    keyTypes.map((a) => {
                                      k.data.data.map((o1) => {
                                        if (a.id === o1.id) {
                                          a.isChecked = true;
                                        }
                                        return true;
                                      });
                                      return true;
                                    });
                                    setIsKeyTypeChecked(true);
                                  } else {
                                    setIsKeyTypeChecked(false);
                                  }
                                  setIsLoading(false);
                                });
                            }}>
                            {item}
                          </ZuiListItem>
                        );
                      })}
                  </ZuiList>
                ) : (
                  ""
                )}
              </div>
              <div style={{ width: "100%" }}>
                <div style={{ width: "80%" }}>
                  <div
                    style={{
                      paddingBottom: "15px",
                      font: "var(--zui-typography-caption)",
                      fontSize: "12px !important",
                      lineHeight: "16px !important",
                      color: "#1A2329",
                    }}>
                    {"Key Types"}
                  </div>
                  {keyTypes.map((item, index) => {
                    return (
                      <ZuiCheckbox
                        key={item.id}
                        value={item.isChecked}
                        name={item.description}
                        label={item.description}
                        style={{
                          paddingBottom: "10px",
                        }}
                        onChange={(e) => {
                          keyTypes[index].isChecked = e.target.value;
                          let isAllSelected = false;
                          keyTypes.map((item) => {
                            if (item.isChecked === true) {
                              isAllSelected = true;
                            }
                            return true;
                          });
                          setIsKeyTypeChecked(isAllSelected);
                        }}></ZuiCheckbox>
                    );
                  })}
                </div>
              </div>
            
          </div>
          <div style={{ width: "33.3%" }}>
            <div style={{ width: "100%" }}></div>
          </div>
          <div style={{ width: "33.3%" }}>
            <div style={{ width: "100%" }}></div>
          </div>
        </div>
      </div>
      <div className="footerContainer">
        <div className="adminInfoFooterContainer">
          <span style={{ marginLeft: "8px" }}>
            {"Ensure the format of skillset is same as mentioned in Cornerstone"}
          </span>
        </div>
        <ZuiButton
          size="s"
          title="Cancel"
          environment="auto"
          className="footerLargeButton2"
          onClick={() => {
            history.push({
              pathname: "/home/Devices/ViewDeviceDetails",
              state: {
                deviceCode: deviceCode,
                role: role,
              },
            });
          }}>
          {"Cancel"}
        </ZuiButton>
        <ZuiButton
          size="s"
          title="Update"
          environment="auto"
          emphasis="active-primary"
          className="footerLargeButton1"
          disabled={skillsetName !== "" && isKeyTypeChecked ? false : true}
          onclick={() => addSkillset()}
          style={{ paddingLeft: "10px" }}>
          {"Update"}
        </ZuiButton>
      </div>
      <div id="fseModalPopup">
        <WarnignModalPopup
          state={isError}
          title={errorText}
          clickClose={handleClose}
        />
      </div>
      <InternetDisconnected
        state={onlineStatus}
        clickClose={closeOnlineStatus}></InternetDisconnected>
    </div>
  ) : (
    <>
      {(updateSkillStatus === "failed" ||
        updateSkillStatus === "unAuthorize" ||
        deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize") &&
      isError ? (
        <div id="fseModalPopup">
          <WarnignModalPopup
            state={isError}
            title={errorText}
            clickClose={handleClose}
          />
        </div>
      ) : (
        <RetrieveDataError />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      GetDeviceByDeviceId: actions.GetDeviceByDeviceCode,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  reduxState: {
    deviceListInfo: state.deviceListInfo,
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewSkillsetKeyType);
