import _ from "lodash";

/* Called by other actions to get the access token for making backend API calls. */
export const getTokenAction = (dispatch, getState) => {
  const state = getState();
  const oldToken = _.get(state, `userAccount.stateValue`, null);

  return new Promise((resolve, reject) => {
    resolve(oldToken);
  });
};

export const getToken = () => getTokenAction;
