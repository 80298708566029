import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import * as actions from "actions";
import { DisableDeviceVersion } from "./../../actions/index";
import { clearDeviceList, checkOnlineStatus } from "actions";
import RetrieveDataError from "./RetrieveDataError";
import { ZuiButton, ZuiToggleSwitch } from "@zeiss/zui-react";
import useStyles from "../KeyTypes/CommonStyle";
import "./Template.css";
import WarnignModalPopup from "../Modal/WarningModal";
import InternetDisconnected from "../Modal/InternetDisconnected";
import Processing from "./Processing";

const DisableVersion = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { reduxState, reduxActions } = props;
  const { deviceListInfo } = reduxState;
  const { GetDeviceByDeviceId } = reduxActions;

  const [updateVersion, setUpdateVersion] = useState("pending");
  const [isDeviceListReady, setDeviceListReady] = useState(false);
  const [isActiveState, setIsActiveState] = useState("Enabled");
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [onlineStatus, setOnlineStatus] = useState(false);

  const token = useSelector((state) => state.userAccount.tokenId);
  const deviceState = props.location.state;
  const deviceCode = deviceState !== undefined ? deviceState.deviceCode : null;
  const role = deviceState !== undefined ? deviceState.role : undefined;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearDeviceList());
    setIsLoading(true);
  }, [dispatch]);

  useEffect(() => {
    if (
      (!isDeviceListReady && deviceListInfo.fetched === "pending") ||
      (isActiveState !== "" && isUpdateSuccess)
    ) {
      GetDeviceByDeviceId(deviceCode, role);
      setIsLoading(false);
      setIsUpdateSuccess(false);
    }
    if (deviceListInfo.fetched === "fetched") {
      setDeviceListReady(true);
      setIsLoading(false);
    }
    if (
      deviceListInfo.isSuccess === false &&
      (deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize")
    ) {
      displayPopUp(deviceListInfo.errorMessage);
      setIsLoading(false);
    }
  }, [
    GetDeviceByDeviceId,
    deviceCode,
    deviceListInfo,
    isDeviceListReady,
    role,
    isActiveState,
    isUpdateSuccess,
  ]);

  const displayPopUp = (text) => {
    setErrorText(text);
    setIsError(true);
  };

  const handleClose = () => {
    setIsLoading(true);
    setIsError(false);
    setErrorText("");
    if (
      deviceListInfo.fetched === "unAuthorize" ||
      updateVersion === "unAuthorize"
    ) {
      logOut();
    } else {
      setIsLoading(false);
      setUpdateVersion("fulfilled");
    }
  };

  const logOut = () => {
    let path = window.location.origin + "/Logout";
    window.location.href = path;
  };

  const closeOnlineStatus = () => {
    setOnlineStatus(false);
  };

  const updateDisableVersion = async ({ id, isActive }) => {
    setIsLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      const result = await DisableDeviceVersion(token, {
        devicekeygeneratorMappingId: id,
        isActive,
      });
      if (result.status === true) {
        setIsUpdateSuccess(true);
        setIsActiveState("");
      } else {
        if (result.statusCode === 401) {
          setUpdateVersion("unAuthorize");
          displayPopUp(result.message);
        } else if (result.statusCode === 400) {
          //Reload the page as the data already exists in server
          setIsUpdateSuccess(true);
          setIsActiveState("");
        } else {
          setUpdateVersion("failed");
          displayPopUp(result.message);
        }
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      setOnlineStatus(true);
    }
  };

  return isLoading || deviceListInfo.fetched === "pending" ? (
    <Processing progressText={true} />
  ) : deviceListInfo.fetched === "fulfilled" ? (
    <div className={classes.mainBodyContainer}>
      <div
        // show-searchbar={false}
        className="deviceNameContainer">
        <p className="headParagraph">Device: </p>
        <p className="headParagraphUpper">
          {deviceListInfo.deviceListByID.name}
          <span className="headSpan">
            ({deviceListInfo.deviceListByID.icCode})
          </span>
        </p>
      </div>
      <div className={classes.deviceHeading}>{"Disable Version"}</div>
      <div className="deviceBodyContainer">
        {deviceListInfo.deviceListByID.deviceKeyGeneratorMappings.map(
          (item, index) => {
            const isDisabled = item.isActive ? "" : classes.fadeDisplay;
            return (
              <div
                key={"deviceListByID" + index}
                className={`${isDisabled} deviceBodyItemDiv`}>
                {item.operator}
                {item.version}
                <ZuiToggleSwitch
                  key={item.id}
                  name={item.version}
                  value={item.isActive}
                  emphasis="primary"
                  onClick={(e) => {
                    const isActiveValue = item.isActive
                      ? "Enabled"
                      : "Disabled";
                    setIsActiveState(isActiveValue);
                    updateDisableVersion({
                      id: item.id,
                      isActive: e.target.value,
                    });
                  }}></ZuiToggleSwitch>
              </div>
            );
          }
        )}
      </div>
      <div className="footerContainer">
        <ZuiButton
          size="s"
          title="Back"
          environment="auto"
          className="footerBackButton"
          onClick={() => {
            history.push({
              pathname: "/home/Devices/ViewDeviceDetails",
              state: { deviceCode: deviceCode, role: role },
            });
          }}>
          {"Back"}
        </ZuiButton>
      </div>
      <div id="fseModalPopup">
        <WarnignModalPopup
          state={isError}
          title={errorText}
          clickClose={handleClose}
        />
      </div>
      <InternetDisconnected
        state={onlineStatus}
        clickClose={closeOnlineStatus}></InternetDisconnected>
    </div>
  ) : (
    <>
      {(updateVersion === "failed" ||
        updateVersion === "unAuthorize" ||
        deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize") &&
      isError ? (
        <div id="fseModalPopup">
          <WarnignModalPopup
            state={isError}
            title={errorText}
            clickClose={handleClose}
          />
        </div>
      ) : (
        <RetrieveDataError />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      GetDeviceByDeviceId: actions.GetDeviceByDeviceCode,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  reduxState: {
    deviceListInfo: state.deviceListInfo,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DisableVersion);
