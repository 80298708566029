/* eslint-disable react-hooks/rules-of-hooks */
import "./AdminIT.css";
import { Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import * as actions from "./../../../actions";
import { connect, useDispatch } from "react-redux";
import { clearDeviceList, checkOnlineStatus } from "actions";
import keyTypeEnum from "../../../constants/KeyTypeEnum";
import { printIframe } from "./../FSEMasterKey/FSEMasterKey";
import DeviceNotAvailable from "../DeviceNotAvailable";
import crossIcon from "./../../../assets/icons/close-error-icn.png";
// import InfoModalPopup from "./../../Modal/InformationModal";
import WarnignModalPopup from "./../../Modal/WarningModal";
import InternetDisconnected from "./../../Modal/InternetDisconnected";
import "@zeiss/zui";
import useStyles from "../CommonStyle";
import "./../CommonStyleSheet.css";
import Processing from "Components/Admin/Processing";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const KeyViewMaster = ({
  deviceKeyList,
  getSelectedKey,
  reloadAdminITKey,
  getPrintingText,
}) => {
  const classes = useStyles();
  const isIpad = /iPad|Macintosh/i.test(navigator.userAgent);
  const isTouchDevice = navigator.maxTouchPoints > 0;
  let showPrint = isIpad && isTouchDevice ? false : true;
  return (
    <>
      <div id="div_ItAdminKey_Conainer" className={`${classes.divContainer}`}>
        <div
          id="divSelectionView"
          className={classes.divSelectionViewContainer}>
          <div className={`${classes.headerText}`}>{"IT Admin PWD"}</div>
          <div
            id="div_selection"
            className={`${classes.divSelectionContainer} ${classes.displayInlineGrid}`}>
            <div id="form_label" className={classes.formLableKey}>
              {'Please pay attention to "Applicable device versions”'}
            </div>
          </div>
          <div
            id="divDeviceListkeyContainer"
            className={`${classes.divDeviceListkeyContainer} divDeviceListkeyContainerHeight`}>
            <Grid
              className={`${classes.muiGridNoSpacing}`}
              container
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start">
              {deviceKeyList.map((value, index) => {
                const labelId = `transfer-list-all-item-${value}-label`;
                return (
                  <Grid
                    key={index}
                    role="listitem"
                    className={`${classes.gridPaddingX2}`}
                    value={value.device}
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={3}
                    xl={2}>
                    <Paper
                      className={`${classes.paper} ${classes.gridTileForKey}`}>
                      <li
                        id={labelId}
                        className={`${classes.deviceNameTileForKey}`}>
                        {value.device}
                      </li>
                      <li
                        id={labelId}
                        className={`${classes.deviceNameVersion}`}>
                        <span>{`Device Version ${value.version}`}</span>
                      </li>
                      <li id={labelId} className={`${classes.deviceNameSN}`}>
                        <span>{`Serial Number: ${value.serialNumber}`}</span>
                      </li>
                      <li
                        id={labelId}
                        className={`${classes.displayAdminITkey}`}>
                        <span>{`${value.key.substring(0, 18)}`}</span>
                        <span>{`${value.key.substring(18, 40)}`}</span>
                      </li>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
        <div id="fixedFooter" className={`${classes.fixedFooter}`}>
          <div className={`${classes.footerContainer} `}>
            <button
              type="button"
              className={`${classes.button} btn-clear`}
              onClick={() => {
                reloadAdminITKey();
              }}>
              {"Back"}
            </button>
            {showPrint && (
              <button
                type="button"
                className={`${classes.button} btn-clear`}
                onClick={() => {
                  getPrintingText();
                }}>
                {"Print"}
              </button>
            )}
            <button
              type="button"
              className={`${classes.button} ${classes.btnSubmit}`}
              onClick={() => {
                getSelectedKey();
              }}>
              {"Download"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const InputForITAdminDevice = ({
  deviceDetails,
  updateInputValue,
  isSelected,
  toggleData,
}) => {
  const classes = useStyles();
  const inputId = `input${deviceDetails.id}`;
  const spanId = `span${deviceDetails.id}`;
  const [isValidInput, setIsValidInput] = React.useState(true);
  const [displayClearCross, setDisplayClearCross] = React.useState(false);
  const clearInput = () => {
    document.querySelector(`#${inputId}`).value = "";
    document.querySelector(`#${inputId}`).focus();
    setDisplayClearCross(false);
  };
  return (
    <>
      <input
        type="tel"
        id={inputId}
        className={`${
          isValidInput === true ? classes.deviceInput : classes.errorInput
        }`}
        onPaste={(event) => {
          event.preventDefault();
        }}
        onClick={(e) => {
          if (e.target.value.length === 0 && !isSelected) {
            setIsValidInput(true);
          } else {
            if (e.target.value.length === 6) {
              setIsValidInput(true);
              deviceDetails.serialNumber = e.target.value;
              deviceDetails.isValid = isValidInput;
              updateInputValue(deviceDetails);
            } else {
              if (e.target.value.length > 0) {
                setDisplayClearCross(true);
              }
              if (e.target.value.length < 1) {
                setDisplayClearCross(false);
              }
              setIsValidInput(false);
            }
          }
        }}
        onFocus={(e) => {
          setIsValidInput(true);
        }}
        onInputCapture={(event) => {
          event.target.value.replace(/[^0-9]*/g, "");
        }}
        onKeyPress={(event) => {
          if (
            event.charCode >= 48 &&
            event.charCode <= 57 &&
            event.target.value.length < 6
          ) {
            if (event.target.value.length === 6) {
              setIsValidInput(true);
              deviceDetails.serialNumber = event.target.value;
              deviceDetails.isValid = isValidInput;
              updateInputValue(deviceDetails);
            }
            if (event.target.value.length < 6) {
              setIsValidInput(false);
              deviceDetails.serialNumber = event.target.value;
              deviceDetails.isValid = isValidInput;
              updateInputValue(deviceDetails);
            }
            return true;
          } else {
            event.preventDefault();
            return false;
          }
        }}
        onChange={(e) => {
          e.target.value = e.target.value.replace(/[^0-9]*/g, "");
          if (e.target.value.length > 6) {
            setIsValidInput(false);
            deviceDetails.serialNumber = e.target.value;
            deviceDetails.isValid = isValidInput;
            updateInputValue(deviceDetails);
            e.preventDefault();
            return false;
          } else {
            if (e.target.value.length === 0 && !isSelected) {
              setIsValidInput(true);
              e.preventDefault();
            }
            if (e.target.value.length > 0) {
              setDisplayClearCross(true);
              setIsValidInput(true);
            }
            if (e.target.value.length < 1) {
              setDisplayClearCross(false);
            }
            if (e.target.value.length === 6) {
              setIsValidInput(true);
              deviceDetails.serialNumber = e.target.value;
              deviceDetails.isValid = isValidInput;
              updateInputValue(deviceDetails);
            }
            deviceDetails.serialNumber = e.target.value;
            deviceDetails.isValid = isValidInput;
            updateInputValue(deviceDetails);
            return true;
          }
        }}
        onBlur={(e) => {
          setIsValidInput(true);
        }}
        placeholder="Last 6 digits of Serial Number"
        maxLength="6"></input>

      <img
        height="16"
        width="16"
        src={crossIcon}
        alt=""
        className={`${
          displayClearCross === false ? classes.displayNone : classes.crossClear
        }`}
        onClick={() => {
          deviceDetails.serialNumber = "";
          clearInput();
        }}
      />

      <span
        id={spanId}
        className={`${
          isValidInput === true ? classes.displayNone : classes.errorSpan
        }`}>
        {"Please enter last 6 digits serial number"}
      </span>
    </>
  );
};

const DevicesViewMaster = ({ deviceListInfo, sendDataToParent }) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [intialDeviceList] = React.useState(deviceListInfo);
  const leftChecked = intersection(checked, deviceListInfo);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
      document.querySelector("#input" + JSON.stringify(value.id)).focus();
    }
    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      return;
    } else {
      setChecked(union(checked, items));
    }
  };
  const ClearAll = (items) => () => {
    setChecked(not(checked, items));
    sendDataToParent(true, intialDeviceList);
  };
  return (
    <>
      <div id="div_ItAdminKey_Conainer" className={`${classes.divContainer}`}>
        <div id="divSelectionView" style={{ height: "calc(100% - 120px)" }}>
          <div className={`${classes.headerText}`}>{"IT Admin PWD"}</div>
          <div
            id="div_selection"
            className={`${classes.divSelectionContainer} ${classes.displayFlex}`}>
            <div id="form_label" className={classes.formLable}>
              {"Select device/s and enter last 6 digits serial number"}
            </div>
            <div id="form_Select_all" className={classes.divSelectAll}>
              <button
                className={`${classes.btnSelectAll} cursorPoiner`}
                disabled={leftChecked.length === deviceListInfo.length}
                onClick={handleToggleAll(deviceListInfo)}>
                {"Select All"}
              </button>
            </div>
          </div>
          <div
            id="divDeviceListContainer"
            className={`divDeviceListContainerHeight ${classes.divDeviceListContainer}`}>
            <form autoComplete="off">
              <Grid
                className={`${classes.muiGridNoSpacing}`}
                container
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start">
                {deviceListInfo.map((value, index) => {
                  const labelId = `transfer-list-all-item-${value.id}-label`;
                  const list1 = `list${value.id}`;
                  return (
                    <Grid
                      key={index}
                      role="listitem"
                      className={`${classes.gridPaddingXl} `}
                      value={value.id}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={2}>
                      <Paper
                        id={labelId}
                        className={`${classes.paper} ${classes.gridTile} ${
                          classes.gridAdminTile
                        } cursorPoiner ${
                          checked.indexOf(value) !== -1 ? "highlight" : ""
                        }`}>
                        <li
                          id={list1}
                          className={`${classes.deviceName} ${classes.ITAdminDeviceTextFormat}`}
                          onClick={handleToggle(value)}>
                          {value.name}
                        </li>
                        <li
                          id={labelId}
                          className={`${classes.displayInputField} ${classes.cursorMenu}`}>
                          {/* <label className={`${classes.displayIccodeLabel}`} onClick={handleToggle(value)}>
                          {`${value.icCode}`}
                        </label> */}
                          <span onClick={handleToggle(value)}>
                            <input
                              type="text"
                              value={`${value.icCode}`}
                              className={`${classes.displayIccodeLabel} ${classes.deviceInput}`}
                              disabled
                            />
                          </span>
                          <InputForITAdminDevice
                            deviceDetails={value}
                            isSelected={
                              checked.indexOf(value) !== -1 ? true : false
                            }
                            updateInputValue={(device) => {
                              value.serialNumber = device.serialNumber;
                              value.isValid = device.isValid;
                            }}
                            toggleData={(data) => {
                              document
                                .querySelector(
                                  "#list" + JSON.stringify(data.id)
                                )
                                .click();
                            }}></InputForITAdminDevice>
                        </li>
                        <div
                          className={`${classes.divLablePadding}`}
                          onClick={handleToggle(value)}></div>
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            </form>
          </div>
        </div>
        <div id="fixedFooter" className={`${classes.fixedFooter}`}>
          <div id="InfoConsent" className={classes.InfoConsent}>
            {
              "*All the devices displayed are based on the training certificates completed on LMS, in absense of certificate, kindly contact Cornerstone."
            }
          </div>
          <div className={`${classes.footerContainer} `}>
            <button
              type="button"
              className={`${classes.button} btn-clear ${
                leftChecked.length === 0 ? classes.btnClearDisabled : ""
              }`}
              disabled={leftChecked.length === 0}
              onClick={ClearAll(deviceListInfo)}>
              {"clear"}
            </button>
            <button
              type="button"
              className={`${classes.button} ${
                leftChecked.length === 0
                  ? classes.btnDisabled
                  : classes.btnSubmit
              }`}
              onClick={(e) => {
                let validData = true;
                leftChecked.forEach((value) => {
                  let state =
                    value.serialNumber.length >= 0 &&
                    value.serialNumber.length < 6
                      ? false
                      : true;
                  if (state === false) {
                    document
                      .querySelector("#input" + JSON.stringify(value.id))
                      .click();
                    validData = false;
                  }
                });
                if (validData) {
                  sendDataToParent(false, leftChecked);
                } else {
                  e.preventDefault();
                }
              }}
              disabled={leftChecked.length === 0}>
              {"Generate Key "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export function AdminIT(props) {
  const classes = useStyles();
  sessionStorage.setItem("KeyTypeValue", 3);
  const { reduxState, reduxActions } = props;
  const { deviceListInfo } = reduxState;
  const { getDeviceList, generateKey, downloadKeys, getPrintText } =
    reduxActions;
  const [isdeviceListReady, setisdeviceListReady] = useState(true);
  const [downloadStatus, setDownloadStatus] = useState(200);
  const [isKeyLoading, setKeyLoading] = useState(true);
  const [showKeyView, setShowKeyView] = useState(false);
  const [masterKey, setMasterKey] = useState("");
  const [open, setOpen] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState(false);
  const [modalText, setModalText] = useState("");
  const [isDeviceNotAvailable, setIsDeviceNotAvailable] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setisdeviceListReady(false);
    dispatch(clearDeviceList());
  }, [dispatch]);

  useEffect(() => {
    if (isdeviceListReady === false && deviceListInfo.fetched !== "fulfilled") {
      getDeviceList();
      setisdeviceListReady(true);
      setKeyLoading(false);
      setIsDeviceNotAvailable(false);
    }

    if (
      deviceListInfo.isSuccess === false &&
      (deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize")
    ) {
      displayPopUp(deviceListInfo.errorMessage);
      setKeyLoading(false);
      setIsDeviceNotAvailable(false);
    }

    if (
      isdeviceListReady &&
      deviceListInfo.fetched === "fulfilled" &&
      masterKey !== ""
    ) {
      setShowKeyView(true);
      setKeyLoading(false);
    } else if (
      isdeviceListReady &&
      deviceListInfo.fetched === "fulfilled" &&
      deviceListInfo.deviceList.length === 0
    ) {
      setIsDeviceNotAvailable(true);
    }
  }, [deviceListInfo, getDeviceList, isdeviceListReady, masterKey]);

  async function generateITAdminPwd(deviceData) {
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      let keyList = await generateKey(3, deviceData);
      if (keyList.status !== false) {
        setMasterKey(keyList.data);
      } else {
        displayPopUp(keyList.message);
      }
    } else {
      setOnlineStatus(true);
    }
    setKeyLoading(false);
  }

  const printSelectedKeys = async () => {
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      let downloadedKeyData = await downloadKeys([...masterKey], 3);
      // if (downloadedKeyData!== false && downloadedKeyData.data !== undefined && downloadedKeyData.data.config !== null) {
      //   await downloadURI(downloadedKeyData.data.config[0].url);
      // } else {
      //   setModalText("Failed to download files");
      //   setOpen(true);
      // }

      if (
        downloadedKeyData.status === true &&
        downloadedKeyData.data.data !== undefined &&
        downloadedKeyData.data.data.config !== null
      ) {
        await downloadURI(downloadedKeyData.data.data.config[0].url);
      } else {
        setDownloadStatus(downloadedKeyData.statusCode);
        displayPopUp(downloadedKeyData.message);
      }
    } else {
      setOnlineStatus(true);
    }
    setKeyLoading(false);
  };

  const getPrintoutText = async () => {
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      const keyType = keyTypeEnum[3];
      let result = await getPrintText([...masterKey], keyType);
      if (result.status !== false) {
        printIframe("ipdf", result.data);
      } else {
        setKeyLoading(false);
        setModalText(result.message);
        setOpen(true);
        setDownloadStatus(result.statusCode);
      }
    } else {
      setOnlineStatus(true);
    }
    setKeyLoading(false);
  };

  async function reloadAdminITKey() {
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      window.location.reload(true);
    } else {
      setOnlineStatus(true);
      setKeyLoading(false);
    }
  }
  const handleCallback = async (isReset, deviceList) => {
    if (isReset || deviceList === undefined || deviceList.length === 0) {
      window.location.reload(true);
    } else if (
      !isReset &&
      (deviceList === undefined || deviceList.length === 0)
    ) {
      displayPopUp("Please select device/s to generate key");
    } else {
      setKeyLoading(true);
      generateITAdminPwd(deviceList);
    }
  };

  const displayPopUp = (text) => {
    setModalText(text);
    setOpen(true);
  };

  const closeOnlineStatus = () => {
    setOnlineStatus(false);
  };

  const handleClose = () => {
    setKeyLoading(true);
    setOpen(false);
    setModalText("");
    if (deviceListInfo.fetched === "unAuthorize") {
      logOut();
    } else if (downloadStatus === 401) {
      logOut();
    } else {
      setKeyLoading(false);
    }
  };

  const logOut = () => {
    let path = window.location.origin + "/Logout";
    window.location.href = path;
  };

  const downloadURI = async (uri) => {
    let link = document.createElement("a");
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (deviceListInfo.fetched === "pending" ||
    isKeyLoading ||
    !isdeviceListReady) ? (
    <Processing progressText={true} />
  ) : deviceListInfo.fetched === "fulfilled" ? (
    <>
      {showKeyView && !isKeyLoading ? (
        <KeyViewMaster
          deviceKeyList={masterKey}
          getSelectedKey={printSelectedKeys}
          reloadAdminITKey={reloadAdminITKey}
          getPrintingText={getPrintoutText}
        />
      ) : (
        <>
          {isDeviceNotAvailable ? (
            <DeviceNotAvailable headerText={"IT Admin PWD"} />
          ) : (
            <DevicesViewMaster
              deviceListInfo={deviceListInfo.deviceList}
              sendDataToParent={handleCallback}
            />
          )}
        </>
      )}
      <div id="itAdminInfoModalPopup">
        <WarnignModalPopup
          state={open}
          title={modalText}
          clickClose={handleClose}
        />
      </div>
      <InternetDisconnected
        state={onlineStatus}
        clickClose={closeOnlineStatus}></InternetDisconnected>
    </>
  ) : (deviceListInfo.fetched === "failed" ||
      deviceListInfo.fetched === "unAuthorize") &&
    open ? (
    <div id="itAdminModalPopup">
      <WarnignModalPopup
        state={open}
        title={modalText}
        clickClose={handleClose}
      />
    </div>
  ) : (
    <div id="div_ItAdminKey_Conainer" className={`${classes.divContainer}`}>
      <div className={`${classes.headerText}`}>{"IT Admin PWD"}</div>
      <div
        id="div_selection"
        className={`${classes.divSelectionContainer} ${classes.displayFlex}`}>
        <div id="form_label" className={classes.formLable}>
          {"Unable to retrieve data from the server"}
        </div>
        <div id="form_Select_all" className={classes.divSelectAll}>
          <button
            className={`${classes.btnSelectAll} cursorPoiner`}
            onClick={() => {
              reloadAdminITKey();
            }}>
            {"Reload"}
          </button>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      getDeviceList: actions.getDeviceList,
      generateKey: actions.GetGeneratedKey,
      downloadKeys: actions.DownloadKey,
      getPrintText: actions.GetPrintText,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  reduxState: {
    deviceListInfo: state.deviceListInfo,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminIT);
