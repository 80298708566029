import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AppBar,
  Toolbar,
  Grid,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import MoreIcon from "@material-ui/icons/MoreVert";
import InternetDisconnected from "./../Modal/InternetDisconnected";
import { checkOnlineStatus } from "actions";
import zeisslogo from "assets/images/zeiss-logo.png";
import historyIcon from "assets/icons/keys-history-icon.png";
import userIcon from "assets/icons/user-icn.png";
import logoutIcon from "assets/icons/logout-icn2.png";
import editIcon from "assets/icons/edit-icn.png";
import "./../Header/HeaderAppBar.css";
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    position: "relative",
  },
  HeaderbgColor: {
    background: "#FFFFFF",
    color: "#353D45",
    height: "64px",
    boxShadow: "none !important",
  },
  title: {
    flexGrow: 1,
    display: "block",
    height: "20px",
    left: "80px",
    // padding: "15px 0px",
    font: "var(--zui-typography-subtitle2)",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#353D45",
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  userProfileBox: {
    display: "flex",
    paddingLeft: "15px",
    position: "relative",
    width: "auto",
    cursor: "default",
    color: "#353D45",
    fontWeight: "normal",
    verticalAlign: "middle",
    fontSize: "14px",
    lineHeight: "20px",
    textTransform: "capitalize",
    borderLeft: "1px solid #DDE3ED",
  },
  userProfileBoxMobile: {
    display: "block",
    position: "relative",
    // padding: "13px 16px",
    width: "100%",
    minWidth: "180px",
    cursor: "pointer",
    color: "#828D9E",
    // background: "#D1D9E5",
    fontWeight: "normal",
    verticalAlign: "middle",
    fontSize: "14px",
    lineHeight: "20px",
    textTransform: "capitalize",
  },
  userprofilefont: {
    fontSize: "14px",
    lineHeight: "20px",
    textTransform: "capitalize",
  },
  userprofilestatus: {
    fontFamily: "ZEISSFrutigerNextW1G-Light, sans-serif",
    fontSize: "12px",
    lineHeight: "16px",
    top: "3px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    cursor: "default !important",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    position: "absolute",
    height: "100%",
    right: "0",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    float: "right",
    position: "absolute",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  drawerMenuIcon: {
    position: "relative",
    margin: "0px 0px 0px 16px",
  },
  headerContainer: {
    display: "flex",
    position: "relative",
    width: "100%",
    height: "100%",
  },
  divHeaderText: {
    minWidth: "164px",
    maxWidth: "320px",
    margin: "auto 8px",
  },
  divLogo: {
    width: "Auto",
    margin: "auto 0px",
    cursor: "default",
    [theme.breakpoints.up("md")]: {
      width: "64px",
    },
  },
  logoContainer: {
    height: "auto",
    width: "auto",
    margin: "auto",
    borderRadius: "0px",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      width: "64px",
    },
  },
  editlogoContainer: {
    height: "auto",
    width: "auto",
    margin: "auto",
    borderRadius: "0px",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      width: "32px",
    },
  },
  zeissIconWidth: {
    width: "24px",
    [theme.breakpoints.up("md")]: {
      width: "32px",
    },
  },
  userprofile: {
    display: "flex",
    position: "relative",
    minWidth: "50px",
    margin: "14px 16px",
    [theme.breakpoints.up("md")]: {
      margin: "auto",
    },
  },
  userProfileIcon: {
    position: "relative",
    float: "right",
    right: "0",
    padding: "12px",
  },
  floatRight: {
    float: "right",
  },
  pullRight: {
    right: "0",
  },
  pullLeft: {
    left: "0",
  },
  margin24: {
    margin: "24px",
  },
  userName: {
    font: "var(--zui-typography-label2)",
    textTransform: "uppercase",
    color: "#828D9E",
    // fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "12px",
    },
  },
  userRole: {
    font: "var(--zui-typography-body)",
    fontWeight: "400",
    color: "#353D45",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  userEmail: {
    font: "var(--zui-typography-body)",
    color: "#353D45",
    lineHeight: "20px",
    textTransform: "none",
    display: "inline-block",
    width: "156px",
    wordWrap: "break-word",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  iconHeight: {
    height: "16px !important",
  },
}));

export default function HeaderAppBar({ isNavSwitchable, handleDrawer }) {
  const classes = useStyles();
  const history = useHistory();
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const userAccountState = useSelector((state) => state.userAccount);
  const userEmailId = userAccountState.email;
  const skills = userAccountState.skills.length;
  const [profileName] = useState(
    `${userAccountState.firstName} ${userAccountState.lastName.charAt(0)}`
  );
  const [role] = useState(`${userAccountState.role}`);
  // const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [onlineStatus, setOnlineStatus] = useState(false);
  useEffect(() => {
    if (isNavSwitchable === true) {
      handleMobileMenuClose();
    }
  }, [isNavSwitchable]);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const logOut = async () => {
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      history.push("/Logout");
    } else {
      setOnlineStatus(true);
    }
  };

  const userHistory = async () => {
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      window.location.href = "/home/History";
    } else {
      setOnlineStatus(true);
    }
  };

  const raiseRequest = async () => {
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      window.location.href = "/home/RaiseRequest";
    } else {
      setOnlineStatus(true);
    }
  };

  const closeOnlineStatus = () => {
    setOnlineStatus(false);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      className={`${classes.sectionMobile}`}
      style={{
        zIndex: "2000",
        backgroundColor: "rgba(26, 35, 41,0.6)",
        minHeight: "184px",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <MenuItem
        onClick={handleMobileMenuClose}
        style={{ width: "223px", padding: "1px" }}>
        <div className={classes.userProfileBoxMobile}>
          <div id="userprofile" className={classes.userprofile}>
            <span id="userProfileIcon" className={classes.userProfileIcon}>
              <img src={userIcon} height="16" width="16" alt="user" />
            </span>
            <span style={{ whiteSpace: "normal" }}>
              {" "}
              {userAccountState.isAdmin === false ? (
                <>
                  <span className={classes.userName}> {profileName} </span>{" "}
                  <br />
                  <span className={classes.userEmail}>{userEmailId}</span>
                </>
              ) : (
                <span className={classes.userEmail}>{userEmailId}</span>
              )}
              <span className={classes.userRole}> {role}</span>{" "}
            </span>
          </div>
        </div>
      </MenuItem>
      {userAccountState.isAdmin === true && skills === 0 ? (
        <div></div>
      ) : (
        <div>
          <MenuItem onClick={raiseRequest}>
            <IconButton aria-label="raiseRequest" color="inherit">
              <img height="16" width="16" src={editIcon} alt="RaiseRequest" />
            </IconButton>
            <p
              style={{
                font: "var(--zui-typography-subtitle1)",
                fontSize: "14px",
                lineHeight: "20px",
              }}>
              {"Raise a Service Request"}
            </p>
          </MenuItem>
          <MenuItem onClick={userHistory}>
            <IconButton aria-label="history" color="inherit">
              <img height="16" width="16" src={historyIcon} alt="history" />
            </IconButton>
            <p
              style={{
                font: "var(--zui-typography-subtitle1)",
                fontSize: "14px",
                lineHeight: "20px",
              }}>
              {"History"}
            </p>
          </MenuItem>
        </div>
      )}
      <MenuItem onClick={logOut}>
        <IconButton
          aria-label="logout"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit">
          <img height="16" width="12" src={logoutIcon} alt="logout" />
        </IconButton>
        <p
          style={{
            font: "var(--zui-typography-subtitle1)",
            fontSize: "14px",
            lineHeight: "20px",
          }}>
          {"Logout"}
        </p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <div className={classes.grow}>
        <AppBar className={`${classes.HeaderbgColor}`}>
          <Toolbar
            style={{
              float: "left",
              display: "flex",
              height: "inherit",
              padding: "0",
            }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{
                float: "left",
                display: "flex",
                height: "inherit",
              }}>
              <Grid
                item
                xs={12}
                style={{
                  float: "left",
                  display: "flex",
                  height: "inherit",
                }}>
                <div
                  id="div_drawerMenuIcon"
                  className={`${classes.sectionMobile} ${classes.pullLeft} ${classes.drawerMenuIcon}`}>
                  <IconButton
                    aria-label="open drawer"
                    aria-haspopup="true"
                    style={{
                      background: "transparent",
                      marginLeft: "-5px !imprtant",
                    }}
                    onClick={() => {
                      handleDrawer();
                    }}
                    color="inherit"
                    edge="start">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M3 5.9624H21V8.9624H3V5.9624Z" fill="#262E36" />
                      <path
                        d="M3 11.9624H21V14.9624H3V11.9624Z"
                        fill="#262E36"
                      />
                      <path
                        d="M21 17.9624H3V20.9624H21V17.9624Z"
                        fill="#262E36"
                      />
                    </svg>
                  </IconButton>
                </div>
                <div
                  id="divHeaderContainer"
                  className={`${classes.headerContainer}`}>
                  <div id="divLogo" className={`${classes.divLogo}`}>
                    <div
                      className={classes.logoContainer}
                      color="inherit"
                      aria-label="open drawer"
                      style={{
                        backgroundColor: "transparent",
                      }}>
                      <img
                        height="32"
                        width="32"
                        src={zeisslogo}
                        alt="Zeiss logo"
                        className={`${classes.zeissIconWidth}`}
                      />
                    </div>
                  </div>

                  <div
                    id="divProductName"
                    className={`${classes.divHeaderText}`}>
                    <Typography
                      className={`${classes.title}`}
                      variant="h6"
                      noWrap>
                      {"CZM Service Password Portal"}
                    </Typography>
                  </div>
                  <div className={classes.sectionDesktop}>
                    {userAccountState.isAdmin === true && skills === 0 ? (
                      <div></div>
                    ) : (
                      <>
                        <div
                          id="divRequestIcon"
                          className={`${classes.editlogoContainer}`}>
                          <Tooltip title="Raise a Service Request">
                            <IconButton
                              aria-label="raiseRequest"
                              color="inherit"
                              className={``}
                              onClick={raiseRequest}>
                              <img
                                height="16"
                                width="16"
                                src={editIcon}
                                alt="RaiseRequest"
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div
                          id="divHistoryIcon"
                          className={`${classes.logoContainer}`}>
                          <Tooltip title="History">
                            <IconButton
                              aria-label="history"
                              color="inherit"
                              className={``}
                              onClick={userHistory}>
                              <img
                                height="16"
                                width="16"
                                src={historyIcon}
                                alt="history"
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </>
                    )}
                    <div className={classes.userProfileBox}>
                      <div id="userprofile" className={classes.userprofile}>
                        <span>
                          {userAccountState.isAdmin === false ? (
                            <>
                              <span className={classes.userName}>
                                {" "}
                                {profileName}{" "}
                              </span>{" "}
                              <br />
                              <span
                                className={classes.userEmail}
                                style={{ width: "auto" }}>
                                {userEmailId}
                              </span>
                              <br />
                            </>
                          ) : (
                            <>
                              <span
                                className={classes.userEmail}
                                style={{ width: "auto" }}>
                                {userEmailId}
                              </span>
                              <br />
                            </>
                          )}
                          <span className={`${classes.userRole}`}>{role}</span>
                        </span>
                      </div>
                      <div
                        id="divUserIcon"
                        className={`${classes.logoContainer}`}>
                        <img
                          height="16"
                          width="16"
                          src={userIcon}
                          alt="user"
                          className={`${classes.margin24}`}
                        />
                      </div>
                    </div>
                    <div id="divLogout" className={`${classes.logoContainer}`}>
                      <Tooltip title="Logout">
                        <IconButton
                          aria-label="logout"
                          color="inherit"
                          className={``}
                          onClick={logOut}>
                          <img
                            height="16"
                            width="12"
                            src={logoutIcon}
                            alt="logout"
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>

                  <div
                    className={`${classes.sectionMobile} ${classes.pullRight}`}>
                    <IconButton
                      aria-label="show more"
                      aria-controls={mobileMenuId}
                      aria-haspopup="true"
                      style={{
                        background: "transparent",
                      }}
                      onClick={handleMobileMenuOpen}
                      color="inherit">
                      <MoreIcon />
                    </IconButton>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {/* {renderMenu} */}
      </div>
      <InternetDisconnected
        state={onlineStatus}
        clickClose={closeOnlineStatus}></InternetDisconnected>
    </>
  );
}
