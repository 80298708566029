import React, { useEffect, useState } from "react";
import "./FSEMasterKey.css";
import { Grid, Paper } from "@material-ui/core";
import { bindActionCreators } from "redux";
import * as actions from "../../../actions";
import { connect, useDispatch } from "react-redux";
import { clearDeviceList, checkOnlineStatus } from "actions";
import keyTypeEnum from "../../../constants/KeyTypeEnum";
import DeviceNotAvailable from "../DeviceNotAvailable";
// import InfoModalPopup from "./../../Modal/InformationModal";
import WarnignModalPopup from "./../../Modal/WarningModal";
import InternetDisconnected from "./../../Modal/InternetDisconnected";
import "@zeiss/zui";
import useStyles from "../CommonStyle";
import "./../CommonStyleSheet.css";
import Processing from "Components/Admin/Processing";

const KeyViewMaster = ({ masterKey }) => {
  const stylesCls = useStyles();
  return (
    <div className={`${stylesCls.divContainer}`}>
      <div className={`${stylesCls.headerText}`}> {"FSE/Master Key"}</div>
      <div className={`${stylesCls.divDeviceListkeyContainer}`}>
        <div className="displayMasterKeyDiv">
          <div className={`${stylesCls.deviceNameTile}`}>
            {"FSE Master Key"}
          </div>
          <div className={`${stylesCls.deviceNameVersion}`}>
            {"Applicable for all Device"}
          </div>
          <div className={`${stylesCls.displayMasterKey}`}>{masterKey}</div>
        </div>
      </div>

      <div className={`${stylesCls.footerContainer}`}>
        <button
          type="button"
          className={`${stylesCls.button} btn-clear`}
          onClick={() => window.location.reload()}>
          {"Back"}
        </button>
        <button
          type="button"
          className={`${stylesCls.button} ${stylesCls.btnSubmit}`}>
          {"Download"}
        </button>
      </div>
    </div>
  );
};

const DevicesViewMaster = ({
  deviceListInfo,
  generateMasterKey,
  getPrintoutText,
}) => {
  const stylesCls = useStyles();
  const isIpad = /iPad|Macintosh/i.test(navigator.userAgent);
  const isTouchDevice = navigator.maxTouchPoints > 0;
  let showPrint = isIpad && isTouchDevice ? false : true;
  return (
    <div className={`${stylesCls.divContainer}`}>
      <div id="divSelectionView" style={{ height: "calc(100% - 120px)" }}>
        <div className={`${stylesCls.headerText}`}> {"FSE/Master Key"}</div>
        <div
          className={`${stylesCls.divSelectionContainer} ${stylesCls.displayInlineGrid}`}>
          <div className={`${stylesCls.formLableKey}`}>
            {
              "(Generated key would be applicable for all the listed devices on screen)"
            }
          </div>
        </div>
        <div
          className={`divDeviceListContainerHeight ${stylesCls.divDeviceListContainer}`}>
          <Grid
            className={`${stylesCls.muiGridNoSpacing}`}
            container
            spacing={3}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start">
            {deviceListInfo.deviceList.map((value) => {
              const labelId = `fse-list-all-device-${value.id}-label`;
              return (
                <Grid
                  key={value.id}
                  role="listitem"
                  className={`${stylesCls.gridPaddingXl}`}
                  value={value.icCode}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={2}
                  style={{ width: "250px" }}>
                  <Paper className={`${stylesCls.paper} `}>
                    <li className={`${stylesCls.deviceName}`} id={labelId}>
                      {`${value.name}`}
                    </li>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
      <div id="fixedFooter" className={`${stylesCls.fixedFooter}`}>
        <div id="InfoConsent" className={stylesCls.InfoConsent}>
          {
            "*All the devices displayed are based on the training certificates completed on LMS, in absense of certificate, kindly contact Cornerstone."
          }
        </div>
        <div className={`${stylesCls.footerContainer}`}>
          {showPrint && (
            <button
              type="button"
              className={`${stylesCls.button} btn-clear`}
              onClick={getPrintoutText}>
              {"Print"}
            </button>
          )}
          <button
            type="button"
            className={`${stylesCls.button} ${stylesCls.btnSubmit}`}
            onClick={generateMasterKey}>
            {"Download"}
          </button>
        </div>
      </div>
    </div>
  );
};

export const printIframe = (id, data) => {
  const iframe = document.createElement("iframe");
  iframe.id = id;
  document.body.appendChild(iframe);
  iframe.contentWindow.document.open();
  iframe.contentWindow.document.write(
    `<html><head><style type="text/css" media="print">
      @page {
        size: auto;
        margin: 0mm;
      }
      html {
        background-color: #FFFFFF;
        margin: 0px;
      }
      body {
        margin: 10mm 15mm 10mm 15mm;
      }
     </style><title></title></head><body>${data}</body>`
  );
  iframe.contentWindow.document.close();
  document.getElementById(id).contentWindow.print();
  document.body.removeChild(iframe);
};

const FSEMasterKey = (props) => {
  sessionStorage.setItem("KeyTypeValue", 1);
  const stylesCls = useStyles();
  const { reduxState, reduxActions } = props;
  const { deviceListInfo } = reduxState;
  const { getDevice, generateKey, downloadKeys, getPrintText } = reduxActions;
  const [isfsedeviceListReady, setFsedeviceListReady] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState(200);
  const [showKey, setShowKey] = useState(false);
  const [masterKey] = useState("");
  const [isKeyGeneratedLoad, setIsKeyGeneratedLoad] = useState(false);
  const [isKeyLoading, setKeyLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState(false);
  const [modalText, setModalText] = useState("");
  const [isDeviceNotAvailable, setIsDeviceNotAvailable] = useState(false);
  const dispatch = useDispatch();
  const displayPopUp = (text) => {
    setModalText(text);
    setOpen(true);
  };

  useEffect(() => {
    dispatch(clearDeviceList());
  }, [dispatch]);

  useEffect(() => {
    if (!isfsedeviceListReady && deviceListInfo.fetched !== "fulfilled") {
      setFsedeviceListReady(true);
      getDevice();
      setIsDeviceNotAvailable(false);
    }
    if (
      deviceListInfo.isSuccess === false &&
      (deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize")
    ) {
      displayPopUp(deviceListInfo.errorMessage);
    }
    if (
      isfsedeviceListReady &&
      deviceListInfo.fetched === "fulfilled" &&
      masterKey !== ""
    ) {
      setIsKeyGeneratedLoad(true);
      setShowKey(true);
    } else if (
      isfsedeviceListReady &&
      deviceListInfo.fetched === "fulfilled" &&
      deviceListInfo.deviceList.length === 0
    ) {
      setIsDeviceNotAvailable(true);
    }
  }, [deviceListInfo, getDevice, isfsedeviceListReady, masterKey]);

  const generateMasterKey = async () => {
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      let res = await generateKey(1, deviceListInfo.deviceList);
      if (res.status !== false) {
        let downloadKeysUrl = await downloadKeys([res.data[0]], 1);
        // if (downloadKeysUrl !== false && downloadKeysUrl.data !== undefined && downloadKeysUrl.data.config !== null) {
        //     await downloadURI(downloadKeysUrl.data.config[0].url);
        //   } else {
        //     setKeyLoading(false);
        //     displayPopUp("Failed to download files");
        //   }
        if (
          downloadKeysUrl.status === true &&
          downloadKeysUrl.data.data !== undefined &&
          downloadKeysUrl.data.data.config !== null
        ) {
          await downloadURI(downloadKeysUrl.data.data.config[0].url);
        } else {
          setDownloadStatus(downloadKeysUrl.statusCode);
          displayPopUp(downloadKeysUrl.message);
        }
      } else {
        setKeyLoading(false);
        setModalText(res.message);
        setOpen(true);
      }
    } else {
      setOnlineStatus(true);
    }
    setKeyLoading(false);
  };

  const getPrintoutText = async () => {
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      let res = await generateKey(1, deviceListInfo.deviceList);
      if (res.status) {
        const keyType = keyTypeEnum[1];
        let result = await getPrintText([res.data[0]], keyType);
        if (result.status !== false) {
          printIframe("ipdf", result.data);
        } else {
          setKeyLoading(false);
          setModalText(result.message);
          setOpen(true);
          setDownloadStatus(result.statusCode);
        }
      } else {
        setKeyLoading(false);
        setModalText(res.message);
        setOpen(true);
      }
    } else {
      setOnlineStatus(true);
    }
    setKeyLoading(false);
  };

  const handleClose = () => {
    setKeyLoading(true);
    setOpen(false);
    setModalText("");
    if (deviceListInfo.fetched === "unAuthorize") {
      logOut();
    } else if (downloadStatus === 401) {
      logOut();
    } else {
      setKeyLoading(false);
    }
  };
  const closeOnlineStatus = () => {
    setOnlineStatus(false);
  };

  const logOut = () => {
    let path = window.location.origin + "/Logout";
    window.location.href = path;
  };

  const downloadURI = async (uri) => {
    let link = document.createElement("a");
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (deviceListInfo.fetched === "pending" || isKeyLoading) ? (
    <Processing progressText={true} />
  ) : deviceListInfo.fetched === "fulfilled" ? (
    <>
      {showKey && isKeyGeneratedLoad ? (
        <KeyViewMaster masterKey={masterKey} />
      ) : (
        <>
          {isDeviceNotAvailable ? (
            <DeviceNotAvailable headerText={"FSE/ Master Key"} />
          ) : (
            <DevicesViewMaster
              deviceListInfo={deviceListInfo}
              generateMasterKey={generateMasterKey}
              getPrintoutText={getPrintoutText}
            />
          )}
        </>
      )}
      <div id="fseModalPopup">
        <WarnignModalPopup
          state={open}
          title={modalText}
          clickClose={handleClose}
        />
      </div>
      <InternetDisconnected
        state={onlineStatus}
        clickClose={closeOnlineStatus}></InternetDisconnected>
    </>
  ) : (
    <>
      {(deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize") &&
      open ? (
        <div id="fseModalPopup">
          <WarnignModalPopup
            state={open}
            title={modalText}
            clickClose={handleClose}
          />
        </div>
      ) : (
        <div id="div_FSEKey_Conainer" className={`${stylesCls.divContainer}`}>
          <div className={`${stylesCls.headerText}`}>{"FSE/ Master Key"}</div>
          <div
            id="div_selection"
            className={`${stylesCls.divSelectionContainer} ${stylesCls.displayFlex}`}>
            <div id="form_label" className={`${stylesCls.formLable}`}>
              {"Unable to retrieve data from the server"}
            </div>
            <div id="form_Select_all" className={stylesCls.divSelectAll}>
              <button
                className={`${stylesCls.btnSelectAll} cursorPoiner`}
                onClick={() => {
                  window.location.reload(true);
                }}>
                Reload
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      getDevice: actions.getDeviceList,
      generateKey: actions.GetGeneratedKey,
      downloadKeys: actions.DownloadKey,
      getPrintText: actions.GetPrintText,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  reduxState: {
    deviceListInfo: state.deviceListInfo,
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(FSEMasterKey);
