import React, { useState } from "react";
import { makeStyles, Modal } from "@material-ui/core";
//import infoIcon from "./../../assets/icons/info-icon.png";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  modal: {
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
    background: "#1a23294a !important",
    zIndex: "3000 !important",
  },
  modalPaper: {
    position: "absolute",
    height: "80%",
    backgroundColor: theme.palette.background.paper,
    borderTop: "8px solid #555E6B",
    boxShadow: theme.shadows[5],
    border: "none",
    padding: "0px",
    borderRadius: "5px",
    outline: "none",
    [theme.breakpoints.up("xs")]: {
      width: "85%",
    },
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
  },
  modalHeader: {
    padding: "8px 16px",
    // fontWeight: "bold",
    display: "flex",
    font: "var(--zui-typography-subtitle1)",
    // justifyContent: "space-between",
  },
  modalBody: {
    position: "relative",
    height: "calc(100% - 170px)",
    display: "inline",
  },
  showErrorMessage: {
    width: "100%",
    height: "inherit",
    padding: "5px 0px",
    font: "var(--zui-typography-body)",
    color: "#828D9E",
  },
  messagebody: {
    position: "relative",
    display: "block",
    height: "inherit",
    width: "inherit",
    overflow: "auto",
    font: "var(--zui-typography-body)",
    margin: "8px 16px",
  },
  modalFooter: {
    borderTop: "1px solid #DDE3ED",
    alignSelf: "center",
    display: "block",
    width: "100%",
    textAlign: "center",
    position: "absolute",
    left: "0",
    bottom: "0",
  },
  modalCloseIcon: {
    border: "none",
    background: "white",
    cursor: "pointer",
    fontWeight: "bolder",
    height: "100%",
    color: "#626c7a",
    fontSize: "larger",
  },
  tdColor: {
    background: "#F5F7FA",
  },
  tableTextSpan: {
    margin: "8px",
  },
  tableTextDeviceName: {
    fontFamily: "ZEISSFrutigerNextW1G-Bold, sans-serif",
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const HistoryKeyViewModal = ({ state, historyContent, clickClose }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  return (
    <>
      <Modal
        disableAutoFocus={true}
        className={classes.modal}
        open={state}
        onClose={() => {
          clickClose();
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.modalPaper}>
          <div
            className={classes.modalHeader}
            style={{ justifyContent: "space-between" }}
          >
            <span>{historyContent.keyTypeName}</span>
            <span
              className={classes.modalCloseIcon}
              onClick={() => clickClose()}
            >
              &times;
            </span>
          </div>
          <div className={classes.modalBody}>
            <div className={classes.messagebody}>
              <table>
                <thead>
                  <tr>
                    {historyContent.keyTypeId === 1 ? (
                      <>
                        <th>
                          <p className={`${classes.tableTextSpan}`}>
                            {"DEVICE NAME"}
                          </p>
                        </th>
                      </>
                    ) : historyContent.keyTypeId === 2 ||
                      historyContent.keyTypeId === 6 ? (
                      <>
                        <th>
                          <p className={`${classes.tableTextSpan}`}>
                            {"DEVICE NAME"}
                          </p>
                        </th>
                        <th>
                          <p className={`${classes.tableTextSpan}`}>{"VERSION"}</p>
                        </th>
                      </>
                    ) : historyContent.keyTypeId === 3 ||
                      historyContent.keyTypeId === 4 ||
                      historyContent.keyTypeId === 5 ? (
                      <>
                        <th>
                          <p className={`${classes.tableTextSpan}`}>
                            {"DEVICE NAME"}
                          </p>
                        </th>
                        <th>
                          <p className={`${classes.tableTextSpan}`}>{"VERSION"}</p>
                        </th>
                        <th>
                          <p className={`${classes.tableTextSpan}`}>
                            {"SERIAL NUMBER"}
                          </p>
                        </th>
                      </>
                    ) : (
                      <></>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {historyContent.keyTypeId === 1 ? (
                    historyContent.historyDetails
                      .filter(
                        (v, i, a) =>
                          a.findIndex((t) => t.icCode === v.icCode) === i
                      )
                      .map((divice, index) => {
                        return (
                          <tr key={index}>
                            <td className={classes.tdColor} style={{border:"1px solid #c1cbd9"}}>
                              <p
                                className={`${classes.tableTextSpan} ${classes.tableTextDeviceName}`}
                              >
                                {divice.deviceName}
                              </p>
                            </td>
                          </tr>
                        );
                      })
                  ) : historyContent.keyTypeId === 2 ||
                    historyContent.keyTypeId === 6 ? (
                    historyContent.historyDetails.map((divice, index) => {
                      return (
                        <tr key={index}>
                          <td className={classes.tdColor}  style={{borderLeft:"1px solid #c1cbd9"}}>
                            <p
                              className={`${classes.tableTextSpan} ${classes.tableTextDeviceName}`}
                            >
                              {divice.deviceName}
                            </p>
                          </td>
                          <td className={classes.tdColor} style={{borderRight:"1px solid #c1cbd9"}}>
                            <p className={`${classes.tableTextSpan}`}>
                              {divice.version}
                            </p>
                          </td>
                        </tr>
                      );
                    })
                  ) : historyContent.keyTypeId === 3 ||
                    historyContent.keyTypeId === 4 ||
                    historyContent.keyTypeId === 5 ? (
                    historyContent.historyDetails.map((divice, index) => {
                      return (
                        <tr key={index}>
                          <td className={classes.tdColor} style={{borderLeft:"1px solid #c1cbd9"}}>
                            <p
                              className={`${classes.tableTextSpan} ${classes.tableTextDeviceName}`}
                            >
                              {divice.deviceName}
                            </p>
                          </td>
                          <td className={classes.tdColor}>
                            <p className={`${classes.tableTextSpan}`}>
                              {divice.version}
                            </p>
                          </td>
                          <td className={classes.tdColor} style={{borderRight:"1px solid #c1cbd9"}}>
                            <p className={`${classes.tableTextSpan}`}>
                              {divice.icCode}
                            </p>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className={`${classes.modalFooter} modalbtn cursorPoiner`}
            onClick={() => {
              clickClose();
            }}
          >
            <button type="button" id="DiscardData" className="btn">
              {"OK"}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HistoryKeyViewModal;
