import axios from "axios";
import {
  // getPrintoutTemplateByIdUrl,
  // getSubscription,
  getBaseUrl,
} from "Utils/getBaseUrl";
import * as ErrorMessage from "../../../../constants/errorMessages";

export const GetPrintoutTemplateById = (token, templateDetails) => {
  let url = getBaseUrl();
  if (url === "http://localhost:3001") {
    url = "https://qa.fsekeygen.zeiss.com";
  }
  // const printoutTemplateByIdUrl = getPrintoutTemplateByIdUrl() + "/GetPrintoutTemplateById";
  // const esbSubscriptionKey = getSubscription();
  const printoutTemplateByIdUrl = `${url}/fsekeygenservice/api/v1/PrintoutTemplate/GetPrintoutTemplateById/${templateDetails.id}`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": `application/json`,
      "Access-Control-Allow-Origin": "*",
      //"EsbApi-Subscription-Key": esbSubscriptionKey,
    },
  };
  return axios
    .create(config)
    .get(`${printoutTemplateByIdUrl}`, templateDetails)
    .then((res) => {
      if (res.status === 200) {
        if (res.data) {
          return {
            status: true,
            statusCode: 200,
            data: res.data.data,
          };
        } else {
          return {
            status: false,
            statusCode: 200,
            message: "Unable to get the printout template details",
          };
        }
      } else if (res.status === 401) {
        return {
          status: false,
          statusCode: 401,
          message: ErrorMessage.SESSION_TIMEOUT_ERROR,
        };
      } else if (res.status === 400) {
        return {
          status: false,
          statusCode: 400,
          message: res.error.message,
        };
      } else {
        return {
          status: false,
          statusCode: 400,
          message: ErrorMessage.BAD_REQUEST,
        };
      }
    })
    .catch((error) => {
      let status = error;
      if (error.response) {
        status = error.response.status;
        if (status === 401) {
          return {
            status: false,
            statusCode: 401,
            message: ErrorMessage.SESSION_TIMEOUT_ERROR,
          };
        } else {
          return {
            status: false,
            statusCode: status,
            message: error.response.data.error.message,
          };
        }
      } else if (error.request) {
        return {
          status: false,
          statusCode: 401,
          message: ErrorMessage.NETWORK_ERROR,
        };
      } else {
        return {
          status: false,
          statusCode: 401,
          message: ErrorMessage.NETWORK_ERROR,
        };
      }
    });
};
