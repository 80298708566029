/*  ********** Online / Offline Detection **********  */

// Request a small image at an interval to determine status
// ** Get a 1x1 pixel image here: http://www.1x1px.me/
// ** Use this code with an HTML element with id="status"

export const checkOnlineStatus = async () => {

    try {
      const online = await fetch("https://httpbin.org/get");
      return online.status >= 200 && online.status < 300; // either true or false
    } catch (err) {
      return false; // user is definately offline
    }
  };
  
  // setInterval(async () => {
  //   const result = await checkOnlineStatus();
  //   const statusDisplay = document.getElementById("internetStatus");
  //   statusDisplay.textContent = result ? "Online" : "OFFline";
  // }, 3000); // probably too often, try 30000 for every 30 seconds
  
  // // forgot to include async load event listener in the video! 
  // window.addEventListener("load", async (event) => {
  //   const statusDisplay = document.getElementById("internetStatus");
  //   statusDisplay.textContent = (await checkOnlineStatus())
  //     ? "Online"
  //     : "OFFline";
  // });