import React, { useState, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  GetAllRequestType,
  SendRequestEmail,
  checkOnlineStatus,
  clearErrorTemplates,
  clearTemplates,
} from "../../actions";
import RetrieveDataError from "../Admin/RetrieveDataError";
import {
  ZuiSelect,
  ZuiSelectOption,
  ZuiSelectPlaceholder,
} from "@zeiss/zui-react";
import { AppBar, Toolbar, Typography } from "@material-ui/core/";
import crossIcon from "assets/icons/cross-icon.png";
import infoIcon from "assets/icons/info-icon.png";
import zeisslogo from "assets/images/zeiss-logo.png";
import WarnignModalPopup from "../Modal/WarningModal";
import InformationModalPopup from "../Modal/InformationModal";
import InternetDisconnected from "../Modal/InternetDisconnected";
import { makeStyles } from "@material-ui/core/styles";
import useWindowSize from "actions/useWindowDimensions/useWindowSize";
import useStyles from "./../KeyTypes/CommonStyle";
import "../Admin/Template.css";
import Processing from "Components/Admin/Processing";

const userStylesRaise = makeStyles((theme) => ({
  main: {
    width: "100%",
    background: "#EDF2F7",
    position: "relative",
    display: "inline-block",
    flexDirection: "column",
    [theme.breakpoints.up("xs")]: {
      height: "auto",
    },
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
  },
  layout_topbar: {
    zIndex: "2000",
    backgroundColor: "#fff",
    top: "0",
    left: "0",
    width: "100%",
    padding: "0",
    position: "relative",
    userSelect: "none",
  },
  layout_section: {
    width: "100%",
    height: "100%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "calc(100% - 64px)",
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: "calc(100% - 64px)",
    },
  },
  Header: {
    height: "64px",
    display: "flex",
  },
  BackgroundWhite: {
    background: "white",
    padding: "0px",
  },
  grow: {
    flexGrow: 1,
    position: "relative",
  },
  HeaderbgColor: {
    background: "#FFFFFF",
    color: "#353D45",
    height: "64px",
    boxShadow: "none !important",
  },
  headerContainer: {
    display: "flex",
    position: "relative",
    width: "100%",
    height: "100%",
  },
  divHeaderText: {
    minWidth: "164px",
    maxWidth: "320px",
    margin: "auto 8px",
  },
  divLogo: {
    width: "auto",
    margin: "auto 0px",
    cursor: "default",
    [theme.breakpoints.up("md")]: {
      width: "64px",
    },
    [theme.breakpoints.down("md")]: {
      margin: "auto 0px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "auto 5px",
    },
  },
  logoContainer: {
    height: "auto",
    width: "auto",
    margin: "auto",
    borderRadius: "0px",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      width: "64px",
    },
  },
  zeissIconWidth: {
    width: "24px",
    [theme.breakpoints.up("md")]: {
      width: "32px",
    },
  },
  title: {
    flexGrow: 1,
    display: "block",
    height: "20px",
    left: "80px",
    // padding: "15px 0px",
    font: "var(--zui-typography-subtitle2)",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#353D45",
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  divContainer: {
    [theme.breakpoints.up("sm")]: { minHeight: "74px" },
  },
  fixedFooter: {
    width: "100% !important",
  },
  requestToolbar: {
    float: "left",
    display: "flex",
    height: "inherit",
    padding: "0",
  },
  divSelectionView: {
    height: "calc(100% - 80px)",
    [theme.breakpoints.down("sm")]: {
      height: "500px",
    },
  },
  divSelectionViewLogin: {
    height: "calc(100% - 80px)",
    [theme.breakpoints.down("sm")]: {
      height: "543px",
    },
  },
}));

const RaiseRequest = (props) => {
  const classes = useStyles();
  const style = userStylesRaise();
  const [height, width] = useWindowSize();
  const selectInputRef = useRef();
  const selectFileRef = useRef();
  const dispatch = useDispatch();
  const { reduxState, reduxActions } = props;
  const { raiseRequestInfo } = reduxState;
  const { GetAllRequestType } = reduxActions;

  const pathname = props.location.pathname;
  const [isLoginScreen, setIsLoginScreen] = useState(false);
  const [errorEmailId, setErrorEmailId] = useState(false);
  const [emailIdValue, setEmailIdValue] = useState("");
  const emailId = useSelector((state) => state.userAccount.email);

  const selcectClassName = isLoginScreen
    ? "selectContainer login"
    : "selectContainer";
  const divContainer = isLoginScreen ? `${style.divContainer}` : "";
  const fixedFooter = isLoginScreen ? `${style.fixedFooter}` : "";
  const bodyContainer = isLoginScreen
    ? `${style.divSelectionViewLogin}`
    : `${style.divSelectionView}`;

  const [isReady, setIsReady] = useState(false);
  const [isTemplateLoaded, setIsTemplateLoaded] = useState(false);
  const [isTemplateListReady, setIsTemplateListReady] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [onlineStatus, setOnlineStatus] = useState(false);
  const [updateTemplateStatus, setUpdateTemplateStatus] = useState("pending");

  const [isRequired, setIsRequired] = useState(false);
  const [successNotification, setSuccessNotification] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [isAttached, setIsAttached] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [messageBody, setMessageBody] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectRequired, setSelectRequired] = useState(false);
  const [showPopUp, setshowPopUp] = useState(false);
  const [popUpText, setpopUpText] = useState("");

  const isIpad = /iPad|Macintosh/i.test(navigator.userAgent);
  const isTouchDevice = navigator.maxTouchPoints > 0;
  const ipadDevice = isIpad && isTouchDevice ? true : false;

  useEffect(() => {
    dispatch(clearTemplates());
    setIsReady(true);
  }, [dispatch]);

  useEffect(() => {
    if (pathname === "/raiserequest") {
      setIsLoginScreen(true);
    } else {
      setEmailIdValue(emailId);
    }
  }, []);

  useEffect(() => {
    if (
      raiseRequestInfo.fetched === "pending" &&
      isReady &&
      !isTemplateLoaded
    ) {
      GetAllRequestType();
      setIsTemplateLoaded(true);
      setIsLoading(false);
    }
    if (
      raiseRequestInfo.fetched === "fulfilled" &&
      !isTemplateListReady &&
      isTemplateLoaded
    ) {
      setIsTemplateListReady(true);
      setIsLoading(false);
    }

    if (
      raiseRequestInfo.isSuccess === false &&
      (raiseRequestInfo.fetched === "failed" ||
        raiseRequestInfo.fetched === "unAuthorize")
    ) {
      displayPopUp(raiseRequestInfo.errorMessage);
      setIsLoading(false);
    }
  }, [
    GetAllRequestType,
    raiseRequestInfo,
    isTemplateListReady,
    isLoading,
    isReady,
    isTemplateLoaded,
  ]);

  const displayPopUp = (text) => {
    setErrorText(text);
    setIsError(true);
  };

  const displayConfirmPopUp = (text) => {
    setpopUpText(text);
    setshowPopUp(true);
  };

  const handleClose = () => {
    setIsLoading(true);
    setIsError(false);
    setErrorText("");
    if (
      raiseRequestInfo.fetched === "unAuthorize" ||
      updateTemplateStatus === "unAuthorize"
    ) {
      logOut();
    } else {
      setIsLoading(false);
    }
    dispatch(clearErrorTemplates());
  };

  const handleCloseModal = () => {
    setIsLoading(true);
    setshowPopUp(false);
    setpopUpText("");
    if (raiseRequestInfo.fetched === "unAuthorize") {
      logOut();
    } else {
      setIsLoading(false);
    }
  };

  const logOut = () => {
    let path = window.location.origin + "/Logout";
    window.location.href = path;
  };

  const closeOnlineStatus = () => {
    setOnlineStatus(false);
  };

  const cancelButtonHandler = () => {
    setSelectedOption("");
    selectInputRef.current.value = null;
    setIsRequired(false);
    setMessageBody("");
    setAttachments([]);
    setUploadedFiles([]);
    setIsAttached(false);
    setErrorEmailId(false);
    setSelectRequired(false);
    if (isLoginScreen) {
      setEmailIdValue("");
    }
  };

  const addAttachmentHandler = (event) => {
    let files = [...event.target.files];
    let attachment = [];
    let invalidExtension = false;
    for (let i = 0; i < files.length; i++) {
      let filename = files[i].name.replaceAll(" ", "_");
      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      //File type validation
      if (!allowedExtensions.exec(filename)) {
        invalidExtension = true;
      }
      let reader = new FileReader();
      reader.readAsDataURL(files[i]);
      // image64[i] = reader;
      reader.onload = function () {
        let base64String = reader.result.split(";");
        base64String[0] = `filename=${filename}`;
        attachment[i] = base64String.join(";");
      };
      if (!invalidExtension) {
        reader.onloadend = function () {
          if (attachments.length !== 0) {
            setAttachments([...attachments, ...attachment]);
          } else {
            setAttachments(attachment);
          }
        };
      }
    }
    if (invalidExtension) {
      displayPopUp(
        "Please upload file having extensions .jpeg, .jpg, .png and .gif only."
      );
      return false;
    } else {
      setUploadedFiles([...uploadedFiles, ...event.target.files]);
      setIsAttached(true);
    }
    selectFileRef.current.value = null;
  };

  const deleteAttachmentHandler = (event) => {
    event.persist();
    const removeId = parseInt(event.target.dataset.remove);
    const remainingFiles = uploadedFiles.filter(
      (item, index) => index !== removeId
    );
    const remainingAttachment = attachments.filter(
      (item, index) => index !== removeId
    );
    setUploadedFiles(remainingFiles);
    setAttachments(remainingAttachment);
  };

  const isEmailIdValid = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const emailIdHandler = (event) => {
    if (!isEmailIdValid(event.target.value)) {
      setErrorEmailId(true);
    } else {
      setErrorEmailId(false);
    }
    setEmailIdValue(event.target.value);
  };
  const submitRaiseRequest = async () => {
    setIsLoading(true);
    if (
      messageBody !== "" &&
      emailIdValue !== "" &&
      !errorEmailId &&
      selectedOption !== ""
    ) {
      setIsRequired(false);
      setErrorEmailId(false);
      setSelectRequired(false);
      setIsLoading(false);
      displayConfirmPopUp(`Are you sure, you want to submit?`);
    } else {
      if (messageBody === "") setIsRequired(true);
      if (emailIdValue === "") setErrorEmailId(true);
      if (selectedOption === "") setSelectRequired(true);
      setIsLoading(false);
    }
  };
  const submitRequest = async () => {
    setIsLoading(true);
    let onlineStatus = await checkOnlineStatus();
    const messageContent = `<pre>${messageBody}</pre>`;
    if (onlineStatus !== false) {
      const emailData = {
        subject: selectedOption,
        messageBody: messageContent,
        senderEmailAddesss: emailIdValue,
        attachments: attachments,
      };
      const result = await SendRequestEmail(emailData);
      if (result.status === true) {
        setIsLoading(false);
        cancelButtonHandler();
        setSuccessNotification(result.message);
        setShowNotification(true);
      } else {
        if (result.statusCode === 401) {
          setUpdateTemplateStatus("unAuthorize");
          displayPopUp(result.message);
        } else {
          setUpdateTemplateStatus("failed");
          displayPopUp(result.message);
        }
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      setOnlineStatus(true);
    }
  };

  const closeNotification = () => {
    setSuccessNotification("");
    setShowNotification(false);
  };

  const selectInputHandler = (e) => {
    setSelectedOption(e.target.value[0]);
    setSelectRequired(false);
  };

  const messageBodyHandler = (e) => {
    setMessageBody(e.target.value);
    setIsRequired(false);
  };

  const selectOption = raiseRequestInfo.requestData.map((item, index) => (
    <ZuiSelectOption key={index} value={item}>
      {item}
    </ZuiSelectOption>
  ));

  const RequestBody = (
    <div className={`${classes.divContainer}`}>
      <div id="divSelectionView" className={bodyContainer}>
        {showNotification && (
          <div className="requestnotifDiv">
            <img
              className="requestImg"
              height="22"
              width="22"
              src={infoIcon}
              alt="info"
            />
            <span className="requestnotifSpan">{successNotification}</span>
            <span className="requestCross">
              <img
                height="12px"
                width="12px"
                src={crossIcon}
                alt="cancel"
                className="crossIcon"
                onClick={closeNotification}
              />
            </span>
          </div>
        )}
        <div className={`${classes.headerText}`} style={{ fontWeight: "350" }}>
          {"Raise a Service Request"}
        </div>
        <div
          className={`${classes.divSelectionContainer} ${classes.displayInlineGrid} ${divContainer}`}>
          <div
            className={`${classes.formLableKey}`}
            style={{ fontWeight: "350" }}>
            {
              "This service allows user to raise a service request/issue faced on the FSE KeyGen app."
            }
          </div>
        </div>
        <div className="divRequestContainer">
          <div className="reqContainer">
            {isLoginScreen ? (
              <>
                <div className="emailDiv login">
                  <label className="emailLabel">{"E-Mail Address"}</label>
                  <input
                    type="email"
                    className="emailInput login"
                    placeholder="Enter e-mail here"
                    value={emailIdValue}
                    onChange={emailIdHandler}
                    required
                  />
                </div>
                <span
                  className={`${
                    errorEmailId
                      ? classes.textareaErrorSpan
                      : classes.displayNone
                  }`}>
                  {"Please check the email address."}
                </span>
              </>
            ) : (
              <div className="emailDiv">
                {ipadDevice ? (
                  <input
                    type="email"
                    className="emailInput"
                    defaultValue={emailId}
                    readOnly
                  />
                ) : (
                  <input
                    type="email"
                    className="emailInput"
                    defaultValue={emailId}
                    disabled
                  />
                )}
              </div>
            )}
            <div className={selcectClassName}>
              <ZuiSelect
                id="raiserequestlist"
                ref={selectInputRef}
                // value={selectedOption}
                style={{ width: "250px" }}
                className={`${
                  selectRequired
                    ? classes.textareaErrorInput
                    : classes.selectInput
                }`}
                onChange={selectInputHandler}>
                <ZuiSelectPlaceholder slot="placeholder">
                  {"Select"}
                </ZuiSelectPlaceholder>
                {selectOption}
              </ZuiSelect>
            </div>
            <div className="textareaDiv">
              <textarea
                className={`textareaBody ${
                  isRequired
                    ? classes.textareaErrorInput
                    : classes.textareaInput
                }`}
                onChange={messageBodyHandler}
                value={messageBody}
                placeholder="Comments*"
                wrap="hard"
                required></textarea>
            </div>
            <span
              className={`${
                isRequired ? classes.textareaErrorSpan : classes.displayNone
              }`}>
              {"This is mandatory field."}
            </span>
            <div>
              <button
                type="button"
                className={`${classes.btnAttach} btn-clear`}
                onClick={(event) => {
                  event.persist();
                  selectFileRef.current.click();
                }}>
                {"Attach Screenshot"}
              </button>
              <input
                ref={selectFileRef}
                type="file"
                name="file"
                id="uploadfile"
                accept=".png, .jpg, .jpeg, .gif"
                onChange={addAttachmentHandler}
                multiple
                style={{ display: "none" }}
              />
              <div>
                {isAttached
                  ? uploadedFiles.map((item, index) => (
                      <div key={`${item}_${index}`}>
                        <span className="attachFileSpan">{item.name}</span>
                        <span>
                          <img
                            height="10.41px"
                            width="10.41px"
                            src={crossIcon}
                            alt="cancel"
                            className="crossIcon"
                            data-remove={index}
                            onClick={deleteAttachmentHandler}
                          />
                        </span>
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="fixedFooter" className={fixedFooter}>
        <div className={`${classes.footerContainer} `}>
          <button
            type="reset"
            title="Cancel"
            className={`${classes.button} btn-clear`}
            onClick={cancelButtonHandler}>
            {"Cancel"}
          </button>
          <button
            title="submit"
            type="button"
            className={`${classes.button} btnSubmit`}
            onClick={submitRaiseRequest}>
            {"Submit"}
          </button>
        </div>
      </div>
      <div id="fseModalPopup">
        <WarnignModalPopup
          state={isError}
          title={errorText}
          clickClose={handleClose}
        />
      </div>
      <div id="infoModalPopup">
        <InformationModalPopup
          state={showPopUp}
          title={popUpText}
          clickClose={handleCloseModal}
          clickOk={() => {
            handleCloseModal();
            submitRequest();
          }}
        />
      </div>
      <InternetDisconnected
        state={onlineStatus}
        clickClose={closeOnlineStatus}></InternetDisconnected>
    </div>
  );

  return isLoading || raiseRequestInfo.fetched === "pending" ? (
    <Processing progressText={true} />
  ) : isTemplateListReady ? (
    <>
      {isLoginScreen ? (
        <div
          id="main"
          className={`${style.main}`}
          style={{ maxHeight: height, maxWidth: width }}>
          <div
            id="layoutHeader"
            className={`${style.layout_topbar} ${style.Header} ${style.BackgroundWhite}`}>
            <div className={style.grow}>
              <AppBar className={`${style.HeaderbgColor}`}>
                <Toolbar className={style.requestToolbar}>
                  <div
                    id="divHeaderContainer"
                    className={`${style.headerContainer}`}>
                    <div id="divLogo" className={`${style.divLogo}`}>
                      <div
                        className={style.logoContainer}
                        color="inherit"
                        aria-label="open drawer"
                        style={{
                          backgroundColor: "transparent",
                        }}>
                        <img
                          height="32"
                          width="32"
                          src={zeisslogo}
                          alt="Zeiss logo"
                          className={`${style.zeissIconWidth}`}
                        />
                      </div>
                    </div>
                    <div
                      id="divProductName"
                      className={`${style.divHeaderText}`}>
                      <Typography
                        className={`${style.title}`}
                        variant="h6"
                        noWrap>
                        {"CZM Service Password Portal"}
                      </Typography>
                    </div>
                  </div>
                </Toolbar>
              </AppBar>
            </div>
          </div>
          <div id="layoutBody" className={`${style.layout_section}`}>
            {RequestBody}
          </div>
        </div>
      ) : (
        <>{RequestBody}</>
      )}
    </>
  ) : (
    <>
      {(raiseRequestInfo.fetched === "failed" ||
        raiseRequestInfo.fetched === "unAuthorize" ||
        updateTemplateStatus === "failed" ||
        updateTemplateStatus === "unAuthorize") &&
      isError ? (
        <div id="fseModalPopup">
          <WarnignModalPopup
            state={isError}
            title={errorText}
            clickClose={handleClose}
          />
        </div>
      ) : (
        <RetrieveDataError />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      GetAllRequestType: GetAllRequestType,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  reduxState: {
    raiseRequestInfo: state.settings,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RaiseRequest);
