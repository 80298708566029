/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Grid, Paper } from "@material-ui/core";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "./../../../actions";
import { clearDeviceList, checkOnlineStatus } from "actions";
import keyTypeEnum from "../../../constants/KeyTypeEnum";
import { printIframe } from "./../FSEMasterKey/FSEMasterKey";
import DeviceNotAvailable from "../DeviceNotAvailable";
// import InfoModalPopup from "./../../Modal/InformationModal";
import WarnignModalPopup from "./../../Modal/WarningModal";
import InternetDisconnected from "./../../Modal/InternetDisconnected";
import "@zeiss/zui";
import "./onedayStyles.css";
import useStyles from "../CommonStyle";
import "./../CommonStyleSheet.css";
import Processing from "Components/Admin/Processing";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const KeyViewMaster = ({
  deviceKeyList,
  getSelectedKey,
  reloadOneDayKey,
  getPrintingText,
}) => {
  const classes = useStyles();
  const isIpad = /iPad|Macintosh/i.test(navigator.userAgent);
  const isTouchDevice = navigator.maxTouchPoints > 0;
  let showPrint = isIpad && isTouchDevice ? false : true;
  return (
    <>
      <div id="div_OneDayKey_Conainer" className={`${classes.divContainer}`}>
        <div
          id="divSelectionView"
          className={classes.divSelectionViewContainer}>
          <div className={`${classes.headerText}`}>{"OneDay Key"}</div>
          <div
            id="div_selection"
            className={`${classes.divSelectionContainer} ${classes.displayInlineGrid}`}>
            <div id="form_label" className={classes.formLableKey}>
              {`Please pay attention to "Applicable device versions”`}
            </div>
          </div>
          <div
            id="divDeviceListkeyContainer"
            className={`${classes.divDeviceListkeyContainer} divDeviceListkeyContainerHeight`}>
            <Grid
              className={`${classes.muiGridNoSpacing}`}
              container
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start">
              {deviceKeyList.map((value, index) => {
                const labelId = `transfer-list-all-item-${value}-label`;
                return (
                  <Grid
                    key={index}
                    role="listitem"
                    // onClick={handleToggle(value)}
                    className={`${classes.gridPaddingX2}`}
                    value={value.device}
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={3}
                    xl={2}>
                    <Paper
                      className={`${classes.paper} ${classes.gridTile}`}
                      //${checked.indexOf(value) !== -1 ? "highlight" : ""}
                    >
                      <li id={labelId} className={`${classes.deviceNameTile}`}>
                        {value.device}
                      </li>
                      <li
                        id={labelId}
                        className={`${classes.deviceNameVersion}`}>
                        <span>{`Device Version ${value.version}`}</span>
                      </li>
                      <li
                        id={labelId}
                        className={`${classes.displayOneDaykey}`}>
                        <span>{`${value.key.substring(0, 18)}`}</span>
                        <span>{`${value.key.substring(18, 40)}`}</span>
                      </li>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
        <div id="fixedFooter" className={`${classes.fixedFooter}`}>
          <div className={`${classes.footerContainer} `}>
            <button
              className={`${classes.button} btn-clear`}
              onClick={() => {
                reloadOneDayKey();
              }}>
              {"Back"}
            </button>
            {showPrint && (
              <button
                type="button"
                className={`${classes.button} btn-clear`}
                onClick={() => {
                  getPrintingText();
                }}>
                {"Print"}
              </button>
            )}
            <button
              variant="outlined"
              className={`${classes.button} ${classes.btnSubmit}`}
              onClick={() => {
                getSelectedKey();
              }}>
              {"Download"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const DevicesViewMaster = ({ deviceListInfo, sendDataToParent }) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);

  const leftChecked = intersection(checked, deviceListInfo);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      return;
    } else {
      setChecked(union(checked, items));
    }
  };

  const ClearAll = (items) => () => {
    setChecked(not(checked, items));
  };

  return (
    <>
      <div id="div_OneDayKey_Conainer" className={`${classes.divContainer}`}>
        <div id="divSelectionView" style={{ height: "calc(100% - 120px)" }}>
          <div className={`${classes.headerText}`}>{"OneDay Key"}</div>

          <div
            id="div_selection"
            className={`${classes.divSelectionContainer} ${classes.displayFlex}`}>
            <div id="form_label" className={classes.formLable}>
              {"Select device/s you want to generate OneDay Key"}
            </div>
            <div id="form_Select_all" className={classes.divSelectAll}>
              <button
                className={`${classes.btnSelectAll} cursorPoiner`}
                disabled={leftChecked.length === deviceListInfo.length}
                onClick={handleToggleAll(deviceListInfo)}>
                {"Select All"}
              </button>
            </div>
          </div>
          <div
            id="divDeviceListContainer"
            className={`divDeviceListContainerHeight ${classes.divDeviceListContainer}`}>
            <Grid
              className={`${classes.muiGridNoSpacing}`}
              container
              spacing={3}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start">
              {deviceListInfo.map((value) => {
                const labelId = `transfer-list-all-item-${value}-label`;
                return (
                  <Grid
                    key={value.id}
                    role="listitem"
                    className={`${classes.gridPaddingXl}`}
                    value={value.icCode}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    style={{ width: "250px" }}>
                    <Paper
                      className={`${classes.paper} cursorPoiner
                    ${checked.indexOf(value) !== -1 ? "highlight" : ""}`}
                      onClick={handleToggle(value)}>
                      <li className={`${classes.deviceName}`} id={labelId}>
                        {`${value.name}`}
                      </li>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
        <div id="fixedFooter" className={`${classes.fixedFooter}`}>
          <div id="InfoConsent" className={classes.InfoConsent}>
            {
              "*All the devices displayed are based on the training certificates completed on LMS, in absense of certificate, kindly contact Cornerstone."
            }
          </div>
          <div className={`${classes.footerContainer} `}>
            <button
              type="button"
              className={`${classes.button} btn-clear ${
                leftChecked.length === 0 ? classes.btnClearDisabled : ""
              }`}
              disabled={leftChecked.length === 0}
              onClick={ClearAll(deviceListInfo)}>
              {" Clear"}
            </button>
            <button
              type="button"
              className={`${classes.button}
              ${
                leftChecked.length === 0
                  ? classes.btnDisabled
                  : classes.btnSubmit
              }`}
              onClick={() => {
                sendDataToParent(leftChecked);
              }}
              disabled={leftChecked.length === 0}>
              {"Generate Key"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export function OnedayKey(props) {
  const classes = useStyles();
  sessionStorage.setItem("KeyTypeValue", 2);
  const { reduxState, reduxActions } = props;
  const { deviceListInfo } = reduxState;
  const { getDeviceList, generateKey, downloadKeys, getPrintText } =
    reduxActions;
  const [isdeviceListReady, setisdeviceListReady] = useState(true);
  const [downloadStatus, setDownloadStatus] = useState(200);
  const [isKeyLoading, setKeyLoading] = useState(true);
  const [showKeyView, setShowKeyView] = useState(false);
  const [masterKey, setMasterKey] = useState("");
  const [open, setOpen] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState(false);
  const [modalText, setModalText] = useState("");
  const [isDeviceNotAvailable, setIsDeviceNotAvailable] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setisdeviceListReady(false);
    dispatch(clearDeviceList());
  }, [dispatch]);

  useEffect(() => {
    if (isdeviceListReady === false && deviceListInfo.fetched !== "fulfilled") {
      getDeviceList();
      setisdeviceListReady(true);
      setKeyLoading(false);
      setIsDeviceNotAvailable(false);
    }
    if (
      deviceListInfo.isSuccess === false &&
      (deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize")
    ) {
      displayPopUp(deviceListInfo.errorMessage);
      setKeyLoading(false);
    }
    if (
      isdeviceListReady &&
      deviceListInfo.fetched === "fulfilled" &&
      masterKey !== ""
    ) {
      setShowKeyView(true);
      setKeyLoading(false);
    } else if (
      isdeviceListReady &&
      deviceListInfo.fetched === "fulfilled" &&
      deviceListInfo.deviceList.length === 0
    ) {
      setIsDeviceNotAvailable(true);
    }
  }, [deviceListInfo, getDeviceList, isdeviceListReady, masterKey]);

  async function generateOneDayKey(deviceData) {
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      let keyList = await generateKey(2, deviceData);
      if (keyList.status !== false) {
        setMasterKey(keyList.data);
      } else {
        displayPopUp(keyList.message);
      }
    } else {
      setOnlineStatus(true);
    }
    setKeyLoading(false);
  }

  const printSelectedKeys = async () => {
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      let downloadedKeyData = await downloadKeys([...masterKey], 2);
      // if (
      //   downloadedKeyData !== false &&
      //   downloadedKeyData.data !== undefined &&
      //   downloadedKeyData.data.config !== null
      // ) {
      //   await downloadURI(downloadedKeyData.data.config[0].url);
      // } else {
      //   setModalText("Failed to download files");
      //   setOpen(true);
      // }
      if (
        downloadedKeyData.status === true &&
        downloadedKeyData.data.data !== undefined &&
        downloadedKeyData.data.data.config !== null
      ) {
        await downloadURI(downloadedKeyData.data.data.config[0].url);
      } else {
        setDownloadStatus(downloadedKeyData.statusCode);
        displayPopUp(downloadedKeyData.message);
      }
    } else {
      setOnlineStatus(true);
    }
    setKeyLoading(false);
  };

  const getPrintoutText = async () => {
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      const keyType = keyTypeEnum[2];
      let result = await getPrintText([...masterKey], keyType);
      if (result.status !== false) {
        printIframe("ipdf", result.data);
      } else {
        setKeyLoading(false);
        setModalText(result.message);
        setOpen(true);
        setDownloadStatus(result.statusCode);
      }
    } else {
      setOnlineStatus(true);
    }
    setKeyLoading(false);
  };

  async function reloadOneDayKey() {
    setKeyLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      window.location.reload(true);
    } else {
      setOnlineStatus(true);
      setKeyLoading(false);
    }
  }
  const handleCallback = (deviceList) => {
    if (deviceList === undefined || deviceList.length === 0) {
      displayPopUp("Please select device/s to generate key");
    } else {
      setKeyLoading(true);
      generateOneDayKey(deviceList);
    }
  };

  const displayPopUp = (text) => {
    setModalText(text);
    setOpen(true);
  };

  const closeOnlineStatus = () => {
    setOnlineStatus(false);
  };

  const handleClose = () => {
    setKeyLoading(true);
    setOpen(false);
    setModalText("");
    if (deviceListInfo.fetched === "unAuthorize") {
      logOut();
    } else if (downloadStatus === 401) {
      logOut();
    } else {
      setKeyLoading(false);
    }
  };

  const logOut = () => {
    let path = window.location.origin + "/Logout";
    window.location.href = path;
  };

  const downloadURI = async (uri) => {
    let link = document.createElement("a");
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (deviceListInfo.fetched === "pending" ||
    isKeyLoading ||
    !isdeviceListReady) ? (
    <Processing progressText={true} />
  ) : deviceListInfo.fetched === "fulfilled" ? (
    <>
      {showKeyView && !isKeyLoading ? (
        <KeyViewMaster
          deviceKeyList={masterKey}
          getSelectedKey={printSelectedKeys}
          reloadOneDayKey={reloadOneDayKey}
          getPrintingText={getPrintoutText}
        />
      ) : (
        <>
          {isDeviceNotAvailable ? (
            <DeviceNotAvailable headerText={"OneDay Key"} />
          ) : (
            <DevicesViewMaster
              deviceListInfo={deviceListInfo.deviceList}
              sendDataToParent={handleCallback}
            />
          )}
        </>
      )}
      <div id="oneDayModalPopup">
        <WarnignModalPopup
          state={open}
          title={modalText}
          clickClose={handleClose}
        />
      </div>
      <InternetDisconnected
        state={onlineStatus}
        clickClose={closeOnlineStatus}></InternetDisconnected>
    </>
  ) : (
    <>
      {(deviceListInfo.fetched === "failed" ||
        deviceListInfo.fetched === "unAuthorize") &&
      open ? (
        <div id="oneDayModalPopup">
          <WarnignModalPopup
            state={open}
            title={modalText}
            clickClose={handleClose}
          />
        </div>
      ) : (
        <div id="div_OneDayKey_Conainer" className={`${classes.divContainer}`}>
          <div className={`${classes.headerText}`}>OneDay Key</div>
          <div
            id="div_selection"
            className={`${classes.divSelectionContainer} ${classes.displayFlex}`}>
            <div id="form_label" className={classes.formLable}>
              {"Unable to retrieve data from the server"}
            </div>
            <div id="form_Select_all" className={classes.divSelectAll}>
              <button
                className={`${classes.btnSelectAll} cursorPoiner`}
                onClick={() => {
                  reloadOneDayKey();
                }}>
                {"Reload"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      getDeviceList: actions.getDeviceList,
      generateKey: actions.GetGeneratedKey,
      downloadKeys: actions.DownloadKey,
      getPrintText: actions.GetPrintText,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  reduxState: {
    deviceListInfo: state.deviceListInfo,
  },
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OnedayKey)
);
