import React from "react";
import useStyles from "./../KeyTypes/CommonStyle";

const RetrieveDataError = () => {
  const classes = useStyles();
  return (
    <div
      id="div_AdminDeviceList_Conainer"
      className={`${classes.divContainer}`}
      style={{ margin: "8px 8px 0px 8px" }}>
      <div
        id="div_selection"
        className={`${classes.divSelectionContainer} ${classes.displayFlex}`}>
        <div id="form_label" className={`${classes.formLable}`}>
          {"Unable to retrieve data from the server"}
        </div>
        <div id="form_Select_all" className={classes.divSelectAll}>
          <button
            className={`${classes.btnSelectAll} cursorPoiner`}
            onClick={() => {
              window.location.reload(true);
            }}>
            {"Reload"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RetrieveDataError;
