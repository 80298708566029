/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {
  NavLink,
  useRouteMatch,
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import { connect } from "react-redux";
import PrivateRoute from "routes/PrivateRoute";
import FSEMasterKey from "../KeyTypes/FSEMasterKey/FSEMasterKey";
import OnedayKey from "../KeyTypes/OnedayKey/OnedayKey";
import Biomed from "../KeyTypes/Biomed/Biomed";
import Factory from "../KeyTypes/Factory/Factory";
import Pantero from "../KeyTypes/Pantero/Pantero";
import AdminIT from "../KeyTypes/AdminITKey/AdminIT";
import keyTypeWithPath from "./../../constants/KeyTypes";
import HomePage from "Components/Home/HomePage";
import History from "Components/History/History";
import RaiseRequest from "./../RaiseRequest/RaiseRequest";
import { makeStyles } from "@material-ui/core/styles";
import "@zeiss/zui";
import Processing from "Components/Admin/Processing";

const drawerWidth = 184;
const appDrawerWidth = 174;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    flexGrow: 1,
    position: "fixed",
    background: "rgba(26, 35, 41, 0.6)",
    bottom: 0,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: "10000",
  },
  progressRoot: {
    display: "inline-block",
  },
  progress: {
    position: "absolute",
    margin: "auto",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  progressText: {
    width: "100%",
    height: "30px",
    top: "110px",
    textAlign: "center",
    right: 0,
    bottom: 0,
    margin: "auto",
    position: "absolute",
    color: "#FFFFFF",
    textTransform: "capitalize",
    userSelect: "none",
    font: "var(--zui-typography-subtitle1)",
  },
  layoutBody: {
    height: "100%",
    minHeight: "inherit",
    display: "flex",
    font: "(--zui-typography-subtitle1)",
    // overflow: "auto",
    [theme.breakpoints.up("xs")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  drawer: {
    width: drawerWidth,
    height: "100%",
    // minHeight: "640px",
    flexShrink: 0,
    top: "auto !important",
    userSelect: "none",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      zIndex: "2000",
      marginTop: "0px !important",
      boxShadow: theme.shadows[5],
      backgroundColor: "rgba(26, 35, 41, 0.6)",
    },
    [theme.breakpoints.up("md")]: {
      position: "relative !important",
    },
  },
  drawerPaper: {
    width: appDrawerWidth,
    top: "auto !important",
    height: "calc(100% - 80px)",
    margin: "8px 0px 8px 8px",
    color: "#D1D9E5",
    background: "#353D45",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100% - 64px)",
      margin: "0px",
      width: "200px",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: "transparent",
    padding: "0px 10px",
    overflowY: "Scroll",
  },
  listbgcolor: {
    background: "inherit",
    borderBottom: "2px solid #353D45",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  listItemHead: {
    font: "var(--zui-typography-h4)",
    fontSize: "16px",
    lineHeight: "24px",
    boxSizing: "border-box",
    textAlign: "left",
    padding: "6px",
    color: "#D1D9E5",
  },
  listItemFont: {
    font: "var(--zui-typography-subtitle1)",
    fontSize: "14px",
    lineHeight: "24px",
    boxSizing: "border-box",
    textAlign: "left",
    color: "#D1D9E5",
    padding: "6px",
  },
  linkNoStyle: {
    textDecoration: "none",
    color: "inherit",
    background: "#1A2329",
  },
  linkActive: {
    font: "var(--zui-typography-subtitle1)",
    background: "blue !important",
    color: "#FFFFFF",
  },
}));

export function LayoutBody(props, { closeNav }) {
  const classes = useStyles();
  const { path, url } = useRouteMatch();
  const { reduxState, reduxActions } = props;
  const { layoutInfo } = reduxState;
  const { getKeyTypeInfo } = reduxActions;
  const [layInfoCalled, setlayInfoCalled] = useState(false);
  useEffect(() => {
    if (!layInfoCalled) {
      setlayInfoCalled(true);
      getKeyTypeInfo();
    }
  }, [getKeyTypeInfo, layInfoCalled]);

  const handleClose = () => {
    if (!props.isNavSwitchable) {
      props.closeNav();
    }
  };
  return layoutInfo.fetched === "pending" ||
    layoutInfo.fetched === undefined ||
    layoutInfo.fetched.length === 0 ? (
    <Processing progressText={true} />
  ) : (
    <div className={classes.layoutBody}>
      <BrowserRouter>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={props.navState}
          classes={{
            paper: classes.drawerPaper,
          }}
          style={{
            position: props.navState === true ? "fixed" : "relative",
            display: props.navState === true ? "block" : "none",
          }}
          onClick={handleClose}>
          <List style={{ paddingTop: "0px" }}>
            <ListItem>
              <span className={`${classes.listItemHead}`}>
                {"Type of Keys"}
              </span>
            </ListItem>
            {layoutInfo.data.map((dataList, Index) => (
              <NavLink
                key={dataList.id}
                to={`${path}/${keyTypeWithPath[dataList.id]}`}
                className={classes.linkNoStyle}
                activeClassName={classes.linkActive}>
                <ListItem className={classes.listbgcolor}>
                  <span className={`${classes.listItemFont}`}>
                    {dataList.description}
                  </span>
                </ListItem>
              </NavLink>
            ))}
          </List>
        </Drawer>
        <Switch>
          {layoutInfo.data.map((dataList, index) =>
            dataList.id === 1 ? (
              <PrivateRoute
                key={`routePath` + index}
                exact
                path={url + "/FSEMasterKey"}
                component={FSEMasterKey}
              />
            ) : dataList.id === 2 ? (
              <PrivateRoute
                key={`routePath` + index}
                exact
                path={url + "/OnedayKey"}
                component={OnedayKey}
              />
            ) : dataList.id === 3 ? (
              <PrivateRoute
                key={`routePath` + index}
                exact
                path={url + "/AdminItKey"}
                component={AdminIT}
              />
            ) : dataList.id === 4 ? (
              <PrivateRoute
                key={`routePath` + index}
                exact
                path={url + "/Pentero"}
                component={Pantero}
              />
            ) : dataList.id === 5 ? (
              <PrivateRoute
                key={`routePath` + index}
                exact
                path={url + "/Biomed"}
                component={Biomed}
              />
            ) : dataList.id === 6 ? (
              <PrivateRoute
                key={`routePath` + index}
                exact
                path={url + "/Factory"}
                component={Factory}
              />
            ) : (
              ""
            )
          )}
          {/* <PrivateRoute
            exact
            path={url + "/FSEMasterKey"}
            component={FSEMasterKey}
          />
          <PrivateRoute exact path={url + "/OnedayKey"} component={OnedayKey} />
          <PrivateRoute exact path={url + "/Factory"} component={Factory} />
          <PrivateRoute exact path={url + "/Biomed"} component={Biomed} />
          <PrivateRoute exact path={url + "/AdminItKey"} component={AdminIT} />
          <PrivateRoute exact path={url + "/Pentero"} component={Pantero} /> */}
          <PrivateRoute exact path={"/home/History"} component={History} />
          <PrivateRoute
            exact
            path={"/home/RaiseRequest"}
            component={RaiseRequest}
          />
          <Route path="*">
            <HomePage isAdmin={false} />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      getKeyTypeInfo: actions.getKeyTypeInfo,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  reduxState: {
    layoutInfo: state.layoutInfo,
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(LayoutBody);
