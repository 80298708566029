import { makeStyles } from "@material-ui/core/styles";
const drawerWidth = 184;
const appDrawerWidth = 174;
const settingDrawerWidth = 172;

const useLayoutStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    flexGrow: 1,
    position: "fixed",
    background: "rgba(26, 35, 41, 0.6)",
    bottom: 0,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: "10000",
  },
  progressRoot: {
    display: "inline-block",
  },
  progress: {
    position: "absolute",
    margin: "auto",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  progressText: {
    width: "100%",
    height: "30px",
    top: "110px",
    textAlign: "center",
    right: 0,
    bottom: 0,
    margin: "auto",
    position: "absolute",
    color: "#FFFFFF",
    textTransform: "capitalize",
    userSelect: "none",
    font: "var(--zui-typography-subtitle1)",
  },
  layoutBody: {
    height: "100%",
    minHeight: "inherit",
    display: "flex",
    font: "(--zui-typography-subtitle1)",
    // overflow: "auto",
    [theme.breakpoints.up("xs")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  drawer: {
    width: drawerWidth,
    height: "100%",
    // minHeight: "640px",
    flexShrink: 0,
    top: "auto !important",
    userSelect: "none",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      zIndex: "2000",
      marginTop: "0px !important",
      boxShadow: theme.shadows[5],
      backgroundColor: "rgba(26, 35, 41, 0.6)",
    },
    [theme.breakpoints.up("md")]: {
      position: "relative !important",
    },
  },
  drawerPaper: {
    width: appDrawerWidth,
    top: "auto !important",
    height: "calc(100% - 80px)",
    margin: "8px 0px 8px 8px",
    color: "#D1D9E5",
    background: "#353D45",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100% - 64px)",
      margin: "0px",
      width: "200px",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: "transparent",
    padding: "0px 10px",
    overflowY: "Scroll",
  },
  listbgcolor: {
    background: "inherit",
    borderBottom: "2px solid #353D45",
    paddingTop: "5px",
    paddingBottom: "5px",
    height: "64px",
  },
  listItemHead: {
    //font: "var(--zui-typography-h4)",
    //fontSize: "16px",
    //lineHeight: "24px",
    boxSizing: "border-box",
    textAlign: "left",
    padding: "6px",
    color: "#D1D9E5",
  },
  listItemFont: {
    //font: "var(--zui-typography-subtitle1)",
    //fontSize: "14px",
    //lineHeight: "24px",
    boxSizing: "border-box",
    textAlign: "left",
    margin: "auto 18px",
    color: "#D1D9E5",
    padding: "6px",
  },
  linkNoStyle: {
    // font: "inherit",
    textDecoration: "none",
    color: "inherit",
    background: "#1A2329",
  },
  linkActive: {
    // font: "var(--zui-typography-subtitle1)",
    background: "blue !important",
    color: "#FFFFFF",
  },
  zeissIconWidth: {
    width: "16px",
  },
  adminMenuIcon: {
    position: "relative",
    float: "left",
    padding: "6px",
  },
  accordianDrawer: {
    width: settingDrawerWidth,
    color: "#FFFFFF",
    marginTop: "0px",
    maxHeight: "64px !important",
    height: "64px",
    flexShrink: 0,
    top: "auto !important",
    userSelect: "none",
    position: "relative",
    backgroundColor: "#1A2329",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      zIndex: "2001",
      marginTop: "0px !important",
      boxShadow: theme.shadows[5],
      backgroundColor: "rgba(26, 35, 41, 0.6)",
    },
    [theme.breakpoints.up("md")]: {
      position: "relative !important",
    },
  },
  adminaccordianDrawer: {
    width: settingDrawerWidth,
    color: "#FFFFFF",
    marginTop: "0px",
    marginBottom: "2px",
    minHeight: "64px !important",
    // maxHeight: "64px !important",
    // height: "64px",
    flexShrink: 0,
    top: "auto !important",
    userSelect: "none",
    position: "relative",
    backgroundColor: "#353D45",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      zIndex: "2002",
      marginTop: "0px !important",
      boxShadow: theme.shadows[5],
      backgroundColor: "rgba(26, 35, 41, 0.6)",
    },
    [theme.breakpoints.up("md")]: {
      position: "relative !important",
    },
  },
  accordianSummary: {
    color: "#FFFFFF",
    height: "64px",
    backgroundColor: "#1A2329",
  },
  accordianExpanded: {
    marginTop: "1px !important",
    marginBottom: "0px !important",
  },
  accordianDetails: {
    width: settingDrawerWidth,
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      width: "199px",
    },
  },
  settingListItemFont: {
    boxSizing: "border-box",
    textAlign: "left",
    margin: "auto 10px auto 15px",
    color: "#D1D9E5",
  },
  settingFont: {
    boxSizing: "border-box",
    color: "#D1D9E5",
    textAlign: "left",
    margin: "auto 10px auto 15px",
    // font: "var(--zui-typography-body)",
  },
  settingListbgcolor: {
    background: "inherit",
    borderBottom: "2px solid #1A2329",
    padding: "5px",
  },
  settingListItem: {
    background: "inherit",
    borderBottom: "2px solid #1A2329",
    paddingTop: "20px",
    height: "64px",
    display: "block",
    textAlign: "center",
    // font: "inherit",
  },
}));

  export default useLayoutStyle;