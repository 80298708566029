import React, { useState, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  GetPrintoutTemplates,
  checkOnlineStatus,
  clearErrorTemplates,
  GetPrintoutTemplateById,
  UpdatePrintoutTemplate,
  clearTemplates,
} from "../../actions";
import JoditEditor from "jodit-react";
import RetrieveDataError from "./RetrieveDataError";
import Processing from "./Processing";
import { ZuiButton, ZuiSelect, ZuiSelectOption } from "@zeiss/zui-react";
import keyTypeEnum from "./../../constants/KeyTypeEnum";
import WarnignModalPopup from "../Modal/WarningModal";
import InternetDisconnected from "../Modal/InternetDisconnected";
import "./Template.css";
import useStyles from "../KeyTypes/CommonStyle";

const PrintTemplate = (props) => {
  const classes = useStyles();
  const editor = useRef(null);
  const token = useSelector((state) => state.userAccount.tokenId);
  const dispatch = useDispatch();
  const { reduxState, reduxActions } = props;
  const { printTemplateInfo } = reduxState;
  const { GetPrintoutTemplates } = reduxActions;
  const [isReady, setIsReady] = useState(false);
  const [isTemplateLoaded, setIsTemplateLoaded] = useState(false);
  const [isTemplateListReady, setIsTemplateListReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [onlineStatus, setOnlineStatus] = useState(false);
  const [updateTemplateStatus, setUpdateTemplateStatus] = useState("pending");
  const [isEdited, setIsEdited] = useState(false);
  const [successNotification, setSuccessNotification] = useState("");

  useEffect(() => {
    dispatch(clearTemplates());
    // setIsTemplateListReady(false);
    setIsReady(true);
  }, [dispatch]);

  useEffect(() => {
    if (
      printTemplateInfo.fetched === "pending" &&
      isReady &&
      !isTemplateLoaded
    ) {
      GetPrintoutTemplates();
      setIsTemplateLoaded(true);
      setIsLoading(false);
    }
    if (
      printTemplateInfo.fetched === "fulfilled" &&
      !isTemplateListReady &&
      isTemplateLoaded
    ) {
      setIsTemplateListReady(true);
      setSelectedOption(printTemplateInfo.printData[0].description);
      setInitialValue(printTemplateInfo.printData[0].templateText);
      setContent(printTemplateInfo.printData[0].templateText);
      setIsLoading(false);
    }

    if (
      printTemplateInfo.isSuccess === false &&
      (printTemplateInfo.fetched === "failed" ||
        printTemplateInfo.fetched === "unAuthorize")
    ) {
      displayPopUp(printTemplateInfo.errorMessage);
      setIsLoading(false);
    }
  }, [
    GetPrintoutTemplates,
    printTemplateInfo,
    isTemplateListReady,
    isLoading,
    isReady,
    isTemplateLoaded,
  ]);

  const [selectedOption, setSelectedOption] = useState("");
  const [initialValue, setInitialValue] = useState("");
  const selectOption = printTemplateInfo.printData.map((item, index) => (
    <ZuiSelectOption key={item.id} value={item.description}>
      {item.description}
    </ZuiSelectOption>
  ));
  const [content, setContent] = useState("");
  const [showNotification, setShowNotification] = useState(false);

  //NOTE: JoditEditor Reference URLs
  //Options page: http://rmamuzic.rs/node_modules/jodit/examples/index.html
  //Github: https://github.com/jodit/jodit-react
  //configuration: https://xdsoft.net/jodit/docs/classes/config.Config.html
  const config = {
    placeholder: "Start typing text ...",
    readonly: false,
    buttons: [
      "copyformat",
      "paragraph",
      "ul",
      "ol",
      "|",
      "font",
      "fontsize",
      "bold",
      "italic",
      "underline",
      "brush",
      "|",
      "align",
      "outdent",
      "indent",
      "|",
      "superscript",
      "subscript",
      "strikethrough",
      "|",
      "eraser",
      "link",
    ],
    //Buttons for tablet and ipad
    buttonsMD: [
      "copyformat",
      "paragraph",
      "ul",
      "ol",
      "|",
      "font",
      "fontsize",
      "bold",
      "italic",
      "underline",
      "brush",
      "|",
      "align",
      "outdent",
      "indent",
      "|",
      "superscript",
      "subscript",
      "strikethrough",
      "|",
      "eraser",
      "link",
    ],
    removeButtons: [
      "source",
      "classSpan",
      "lineHeight",
      "file",
      "image",
      "video",
      "spellcheck",
      "cut",
      "copy",
      "paste",
      "selectall",
      "hr",
      "table",
      "symbols",
      "undo",
      "redo",
      "find",
      "fullsize",
      "about",
      "print",
      "preview",
    ],
    colorPickerDefaultTab: "color",
    toolbarButtonSize: "small",
    allowTabNavigation: true,
    hidePoweredByJodit: true,
    toolbarSticky: true,
    toolbarStickyOffset: -16,
  };

  const displayPopUp = (text) => {
    setErrorText(text);
    setIsError(true);
  };

  const handleClose = () => {
    setIsLoading(true);
    setIsError(false);
    setErrorText("");
    if (
      printTemplateInfo.fetched === "unAuthorize" ||
      updateTemplateStatus === "unAuthorize"
    ) {
      logOut();
    } else {
      setIsLoading(false);
    }
    dispatch(clearErrorTemplates());
  };

  const logOut = () => {
    let path = window.location.origin + "/Logout";
    window.location.href = path;
  };

  const closeOnlineStatus = () => {
    setOnlineStatus(false);
  };

  const getEmailTemplateBySelectId = async ({ id }) => {
    setIsLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      const result = await GetPrintoutTemplateById(token, { id: id });
      if (result.status === true) {
        setInitialValue(result.data.templateText);
        setContent(result.data.templateText);
      } else if (result.statusCode === 401) {
        setUpdateTemplateStatus("unAuthorize");
        displayPopUp(result.message);
      } else {
        setUpdateTemplateStatus("failed");
        displayPopUp(result.message);
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setOnlineStatus(true);
    }
  };

  const updatePrintoutTemplateDetails = async ({
    id,
    keyType,
    templateText,
  }) => {
    setIsLoading(true);
    let onlineStatus = await checkOnlineStatus();
    if (onlineStatus !== false) {
      const result = await UpdatePrintoutTemplate(token, {
        id: id,
        keyType: keyType,
        templateText: templateText,
      });

      if (result.status === true) {
        setSuccessNotification(result.message);
        setInitialValue(templateText);
        setIsEdited(false);
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
          setSuccessNotification("");
        }, 1500); //hide notification after 1.5s
      } else if (result.statusCode === 401) {
        setUpdateTemplateStatus("unAuthorize");
        displayPopUp(result.message);
      } else {
        setUpdateTemplateStatus("failed");
        displayPopUp(result.message);
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setOnlineStatus(true);
    }
  };

  const savePrintTemplate = () => {
    const templateDetails = printTemplateInfo.printData.filter(
      (item) => item.description === selectedOption
    );
    const keyType = keyTypeEnum[templateDetails[0].keyTypeId];
    updatePrintoutTemplateDetails({
      id: templateDetails[0].id,
      keyType: keyType,
      templateText: content,
    });
  };

  return isLoading || printTemplateInfo.fetched === "pending" ? (
    <Processing progressText={true} />
  ) : isTemplateListReady ? (
    <div className={classes.mainBodyContainer}>
      <div className="headContainer">
        <p className="headParagraph">{"Print Template"}</p>
        {!isEdited && (
          <ZuiButton
            size="s"
            title="Edit"
            environment="auto"
            emphasis="active-primary"
            className="editButton"
            style={{ marginRight: "16px", width: "80px" }}
            onClick={() => {
              setIsEdited(true);
            }}>
            {"Edit"}
          </ZuiButton>
        )}
      </div>
      {!isEdited ? (
        <div className="bodyContainer">
          <p className="bodyParagraph">
            Please select access key to define the template.
          </p>
          <div className="selectContainer">
            <ZuiSelect
              value={selectedOption}
              style={{ width: "250px" }}
              onChange={(e) => {
                const optionValue = printTemplateInfo.printData.filter(
                  (item) => item.description === e.target.value[0]
                );
                const id = optionValue[0].id;
                getEmailTemplateBySelectId({ id: id });
                setSelectedOption(e.target.value[0]);
              }}>
              {selectOption}
            </ZuiSelect>
          </div>
          <div
            id="templateBody"
            dangerouslySetInnerHTML={{ __html: initialValue }}></div>
        </div>
      ) : (
        <div className="bodyContainer rte">
          <p className="bodyParagraph">
            Please select access key to define the template.
          </p>
          <div className="selectContainer">
            <ZuiSelect
              value={selectedOption}
              style={{ width: "250px" }}
              onChange={(e) => {
                const optionValue = printTemplateInfo.printData.filter(
                  (item) => item.description === e.target.value[0]
                );
                const id = optionValue[0].id;
                getEmailTemplateBySelectId({ id: id });
                setSelectedOption(e.target.value[0]);
              }}>
              {selectOption}
            </ZuiSelect>
          </div>
          <div id="templateBodyrte">
            <JoditEditor
              ref={editor}
              value={content}
              config={config}
              tabIndex={1} // tabIndex of textarea
              onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
              onChange={(newContent) => {}}
            />
          </div>
        </div>
      )}

      {isEdited && (
        <div className="footerContainer">
          <ZuiButton
            size="s"
            title="Cancel"
            environment="auto"
            className="footerButton2"
            onClick={() => {
              setIsEdited(false);
              setContent(initialValue); //setting content to old value
            }}>
            {"Cancel"}
          </ZuiButton>
          <ZuiButton
            size="s"
            title="Save"
            environment="auto"
            emphasis="active-primary"
            className="footerButton1"
            onClick={savePrintTemplate}>
            {"Save"}
          </ZuiButton>
        </div>
      )}
      <div id="fseModalPopup">
        <WarnignModalPopup
          state={isError}
          title={errorText}
          clickClose={handleClose}
        />
      </div>
      <InternetDisconnected
        state={onlineStatus}
        clickClose={closeOnlineStatus}></InternetDisconnected>
      {showNotification && (
        <div className="notificationDiv">
          <p className="notificationPara">{successNotification}</p>
        </div>
      )}
    </div>
  ) : (
    <>
      {(printTemplateInfo.fetched === "failed" ||
        printTemplateInfo.fetched === "unAuthorize" ||
        updateTemplateStatus === "failed" ||
        updateTemplateStatus === "unAuthorize") &&
      isError ? (
        <div id="fseModalPopup">
          <WarnignModalPopup
            state={isError}
            title={errorText}
            clickClose={handleClose}
          />
        </div>
      ) : (
        <RetrieveDataError />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      GetPrintoutTemplates: GetPrintoutTemplates,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  reduxState: {
    printTemplateInfo: state.settings,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PrintTemplate);
