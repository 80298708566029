import axios from "axios";
// import * as ActionTypes from "../../../constants/actionTypes";
import {
  // getAddNewDeviceUrl,
  // getSubscription,
  getBaseUrl,
} from "Utils/getBaseUrl";

export const AddDevice = (token, deviceToBeAdded) => {
  let url = getBaseUrl();
  if (url === "http://localhost:3001") {
    //url = "http://localhost:5601"; //"https://qa.fsekeygen.zeiss.com";
    url = "https://qa.fsekeygen.zeiss.com";
  }
  const getAddDeviceUrl = `${url}/fsekeygenservice/api/v1/Device/AddDevice`;
  // const getAddDeviceUrl = getAddNewDeviceUrl();
  // const esbSubscriptionKey = getSubscription();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": `application/json`,
      "Access-Control-Allow-Origin": "*",
      // "EsbApi-Subscription-Key": esbSubscriptionKey,
    },
  };

  return axios
    .create(config)
    .post(`${getAddDeviceUrl}`, deviceToBeAdded)
    .then((res) => {
      if (res.status === 200) {
        if (res.data) {
          return {
            status: true,
            statusCode: 200,
            data: "Device added successfully",
          };
        } else {
          return {
            status: false,
            statusCode: 200,
            message: "Unable to add device",
          };
        }
      } else if (res.status === 401) {
        return {
          status: false,
          statusCode: 401,
          message:
            "Access is denied due to the session timeout. Please login again.",
        };
      } else if (res.status === 400) {
        return {
          status: false,
          statusCode: 400,
          message: "Bad request",
        };
      } else {
        return {
          status: false,
          statusCode: 400,
          message: "Bad Request",
        };
      }
    })
    .catch((error) => {
      let status = error;
      if (error.response) {
        status = error.response.status;
        if (status === 401) {
          return {
            status: false,
            statusCode: 401,
            message:
              "Access is denied due to the session timeout. Please login again.",
          };
        } else {
          return {
            status: false,
            statusCode: status,
            message: error.response.data.error.message,
          };
        }
      } else if (error.request) {
        return {
          status: false,
          statusCode: 401,
          message:
            "Your request could not be processed due to Network Interruption. Please login again.",
        };
      } else {
        return {
          status: false,
          statusCode: 401,
          message:
            "Your request could not be processed due to Network Interruption. Please login again.",
        };
      }
    });
};
