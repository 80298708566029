import { getHistoryDetailsUrl, getSubscription } from "../../Utils/getBaseUrl";
import axios from "axios";
export const GetHistoryDetails = (token, filterParams) => {
	//   const state = getState();
	//   const token = state.userAccount.stateValue;
	//let downloadKeysUrl = getDownloadKeysUrl(keyType);
	let historyDetailsUrl = getHistoryDetailsUrl();
	const esbSubscriptionKey = getSubscription();
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": `application/json`,
			"Access-Control-Allow-Origin": "*",
			"EsbApi-Subscription-Key": esbSubscriptionKey,
		},
	};
	const errorMessage = {
		statusCode: "",
		message: "",
	};
	return axios
		.create(config)
		.get(`${historyDetailsUrl}`, { params: filterParams })
		.then((res) => {
			errorMessage.statusCode = res.status;
			if (res.status === 200) {
				if (res.data.state && res.data !== null) {
					return {
						status: true,
						data: res.data.data,
						paginationInfo: JSON.parse(res.headers["x-pagination"]),
						message: "",
					};
				} else {
					// dispatch(clearDeviceList());
					return {
						status: false,
						statusCode: 200,
						message:
							res.data.error !== null
								? res.data.error.message
								: "Unable to generate key due to bad request",
					};
				}
			} else if (res.status === 400) {
				errorMessage.message =
					"Unable to process the request. Please try again.";
				//dispatch(failedDL(errorMessage));
				return {
					status: false,
					statusCode: 400,
					message: errorMessage.message,
				};
			} else if (res.status === 401) {
				errorMessage.message =
					"Access is denied due to the session timeout. Please login again.";
				//dispatch(failedDL(errorMessage));
				return {
					status: false,
					statusCode: 401,
					message: errorMessage.message,
				};
			} else if (res.status === 404) {
				errorMessage.message =
					"The Requested URL was Not Found on this Server";
				//dispatch(failedDL(errorMessage));
				return {
					status: false,
					statusCode: 404,
					message: errorMessage.message,
				};
			} else if (res.status === 500) {
				errorMessage.message =
					"Internal server error. Please try again.";
				//dispatch(failedDL(errorMessage));
				return {
					status: false,
					statusCode: 500,
					message: errorMessage.message,
				};
			} else {
				errorMessage.message = "Something went wrong";
				//dispatch(failedDL(errorMessage));
				return {
					status: false,
					statusCode: res.status,
					message: errorMessage.message,
				};
			}
		})
		.catch((error) => {
			let status = error;
			if (error.response) {
				status = error.response.status;
				errorMessage.statusCode = error.response.status;
				if (status === 400) {
					errorMessage.message =
						"Unable to process the request. Please try again.";
					// dispatch(failedDL(errorMessage));
					return {
						status: false,
						statusCode: 400,
						message: errorMessage.message,
					};
				} else if (status === 401) {
					errorMessage.message =
						"Access is denied due to the session timeout. Please login again.";
					// dispatch(failedDL(errorMessage));
					return {
						status: false,
						statusCode: 401,
						message: errorMessage.message,
					};
				} else if (status === 404) {
					errorMessage.message =
						"The Requested URL was Not Found on this Server";
					// dispatch(failedDL(errorMessage));
					return {
						status: false,
						statusCode: 404,
						message: errorMessage.message,
					};
				} else if (status === 500) {
					errorMessage.message =
						"Internal server error. Please try again.";
					// dispatch(failedDL(errorMessage));
					return {
						status: false,
						statusCode: 500,
						message: errorMessage.message,
					};
				} else {
					errorMessage.message = "Something went wrong";
					// dispatch(failedDL(errorMessage));
					return {
						status: false,
						statusCode: 503,
						message: errorMessage.message,
					};
				}
			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				errorMessage.statusCode = 401;
				errorMessage.message =
					"Your request could not be processed due to Network Interruption. Please login again.";
				// dispatch(failedDL(errorMessage));
				return {
					status: false,
					statusCode: 401,
					message: errorMessage.message,
				};
			} else {
				errorMessage.statusCode = 401;
				errorMessage.message =
					"Access is denied due to the session timeout. Please login again.";
				// dispatch(failedDL(errorMessage));
				return {
					status: false,
					statusCode: 401,
					message: errorMessage.message,
				};
			}
		});
};
