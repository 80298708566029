/* eslint-disable import/no-anonymous-default-export */
import * as ActionTypes from "../constants/actionTypes";

const initialState = {
  tokenId: "",
  firstName: ``,
  lastName: ``,
  isAdmin: false,
  message: "",
  stateValue: "",
  tokenValidTo: "",
  role: "",
  loggedIn: false,
  fetched: "pending",
  skills: [],
  email: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.GET_USER_ACCOUNT_PENDING: {
      return {
        ...state,
        fetched: "pending",
      };
    }
    case ActionTypes.GET_USER_ACCOUNT_FULFILLED: {
      return {
        ...state,
        fetched: "fulfilled",
        loggedIn: true,
        tokenId: action.payload.idToken,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        isAdmin: action.payload.isAdmin,
        tokenValidTo: action.payload.tokenValidTo,
        role: action.payload.role,
        skills: action.payload.skills,
        email: action.payload.email,
      };
    }
    case ActionTypes.GET_USER_ACCOUNT_FAILED: {
      return {
        ...state,
        fetched: "failed",
        loggedIn: false,
        message: action.payload,
      };
    }
    case ActionTypes.SET_USER_STATE_VALUE: {
      return {
        ...state,
        stateValue: action.payload,
      };
    }
    case ActionTypes.SET_LOGGED_OUT: {
      return initialState;
    }
    default:
      return state;
  }
};
