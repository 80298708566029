import * as ActionTypes from "../../constants/actionTypes";
import { getSubscription, getDeviceBySkillUrl } from "../../Utils/getBaseUrl";
import axios from "axios";
export const pendingDL = (data) => ({
	type: ActionTypes.GET_DEVICE_LIST_PENDING,
	payload: data,
});

export const failedDL = (data) => ({
	type: ActionTypes.GET_DEVICE_LIST_FAILED,
	payload: data,
});

export const fulfilledDL = (data) => ({
	type: ActionTypes.GET_DEVICE_LIST_FULFILLED,
	payload: data,
});

export const clearDeviceList = () => ({
	type: ActionTypes.CLEAR_DEVICE_LIST,
});
export const clearErrorMessage = () => ({
	type: ActionTypes.CLEAR_DEVICE_LIST_ErrorMessage,
});

export const getDeviceList = () => (dispatch, getState) => {
	const state = getState();
	const token = state.userAccount.stateValue;
	const skills = state.userAccount.skills;
	let devices = state.deviceListInfo.deviceList;
	const keyType = sessionStorage.getItem("KeyTypeValue");
	const deviceBySkillUrl = getDeviceBySkillUrl();
	const esbSubscriptionKey = getSubscription();
	dispatch(pendingDL(keyType));
	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": `application/json`,
			"Access-Control-Allow-Origin": "*",
			"EsbApi-Subscription-Key": esbSubscriptionKey,
		},
	};
	const data = {
		skills: skills,
		keyType: parseInt(keyType, 10),
	};
	const errorMessage = {
		statusCode: "",
		message: "",
	};
	axios
		.create(config)
		.post(`${deviceBySkillUrl}`, data)
		.then((res) => {
			errorMessage.statusCode = res.status;
			if (res.status === 200) {
				if (res.data.state) {
					let data = res.data.data;
					devices = data.map((currentTask) => {
						return {
							...currentTask,
							serialNumber: "",
							isValid: true,
						};
					});
					dispatch(fulfilledDL(devices));
					return true;
				} else {
					errorMessage.message = res.data.error.message;
					dispatch(failedDL(errorMessage));
					return false;
				}
			} else if (res.status === 400) {
				errorMessage.message =
					"Unable to process the request. Please try again.";
				dispatch(failedDL(errorMessage));
				return false;
			} else if (res.status === 401) {
				errorMessage.message =
					"Access is denied due to the session timeout. Please login again.";
				dispatch(failedDL(errorMessage));
				return false;
			} else if (res.status === 404) {
				errorMessage.message =
					"The Requested URL was Not Found on this Server";
				dispatch(failedDL(errorMessage));
				return false;
			} else if (res.status === 406) {
				errorMessage.message =
					"You don’t have valid certificates for one or more devices in Cornerstone. Please complete your training and log in again";
				dispatch(failedDL(errorMessage));
				return {
					status: false,
					statusCode: 406,
					message: errorMessage.message,
				};
			} else if (res.status === 500) {
				errorMessage.message =
					"Internal server error. Please try again.";
				dispatch(failedDL(errorMessage));
				return false;
			} else {
				//errorMessage.message = "Something went wrong";
				dispatch(failedDL(errorMessage));
				return false;
			}
		})
		.catch((error) => {
			let status = error;

			if (error.response) {
				status = error.response.status;
				errorMessage.statusCode = error.response.status;

				if (status === 400) {
					errorMessage.message =
						"Unable to process the request. Please try again.";
					dispatch(failedDL(errorMessage));
					return false;
				} else if (status === 401) {
					errorMessage.message =
						"Access is denied due to the session timeout. Please login again.";
					dispatch(failedDL(errorMessage));
					return false;
				} else if (status === 404) {
					errorMessage.message =
						"The Requested URL was Not Found on this Server";
					dispatch(failedDL(errorMessage));
					return false;
				} else if (status === 406) {
					errorMessage.message =
						"You don’t have valid certificates for one or more devices in Cornerstone. Please complete your training and log in again";
					dispatch(failedDL(errorMessage));
					return {
						status: false,
						statusCode: 406,
						message: errorMessage.message,
					};
				} else if (status === 500) {
					errorMessage.message =
						"Internal server error. Please try again.";
					dispatch(failedDL(errorMessage));
					return false;
				} else {
					errorMessage.message = "Something went wrong";
					dispatch(failedDL(errorMessage));
					return false;
				}
			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				if (error.request.status === 0) {
					errorMessage.statusCode = 401;
					errorMessage.message =
						"Your request could not be processed due to Network Interruption. Please login again.";
				} else {
					errorMessage.message = error.message;
				}
				dispatch(failedDL(errorMessage));
				return false;
			} else {
				// Something happened in setting up the request that triggered an Error
				dispatch(failedDL(errorMessage));
				return false;
			}
		});
};

export const logErrorResponse = () => ({
	type: ActionTypes.CLEAR_DEVICE_LIST,
});
