import * as ActionTypes from "../constants/actionTypes";


const initialState = {
  isDrawerOpened: false,
  fetched:"pending",
  state:"",
  data:[],
  message:"",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.GET_KEYTYPES_ROLE_PENDING: {
      return {
        ...state,
        fetched: "pending",
      };
    }
    case ActionTypes.GET_KEYTYPES_ROLE_FULFILLED: {
      return {
        ...state,
        fetched: "fulfilled",
        data: action.payload.data,
      };
    }
    case ActionTypes.GET_KEYTYPES_ROLE_FAILED: {
      return {
        ...state,
        fetched: "failed",
        state: "false",
        message: action.payload,
      };
    }
    case ActionTypes.SET_DRAWER_STATE:{
      return {
        ...state,
        isDrawerOpened: action.payload,
      };
    }
    case ActionTypes.SET_LOGGED_OUT: {
      return initialState;
    }
    default:
      return state;
  }
};
