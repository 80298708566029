/* eslint-disable no-undef */
import React from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  NavLink,
  useRouteMatch,
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../../actions";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import PrivateRoute from "routes/PrivateRoute";
import FSEMasterKey from "../../KeyTypes/FSEMasterKey/FSEMasterKey";
import OnedayKey from "../../KeyTypes/OnedayKey/OnedayKey";
import Biomed from "../../KeyTypes/Biomed/Biomed";
import Factory from "../../KeyTypes/Factory/Factory";
import Pantero from "../../KeyTypes/Pantero/Pantero";
import AdminIT from "../../KeyTypes/AdminITKey/AdminIT";
import keyTypeWithPath from "./../../../constants/KeyTypes";
import HomePage from "Components/Home/HomePage";
import History from "Components/History/History";
import RaiseRequest from "./../../RaiseRequest/RaiseRequest";
import Devices from "Components/Admin/DeviceList";
import AddDevice from "Components/Admin/AddDevice";
import DisableVersion from "Components/Admin/DisableVersion";
import AddNewVersion from "Components/Admin/AddNewVersion";
import AddNewSkillsetKeyType from "Components/Admin/AddNewSkillsetKeyType";
import DisableSkillset from "Components/Admin/DisableSkillset";
import DisableKeytype from "Components/Admin/DisableKeytype";
import ViewDeviceDetails from "Components/Admin/ViewDeviceDetails";
import EmailTemplate from "Components/Admin/EmailTemplate";
import PrintTemplate from "Components/Admin/PrintTemplate";
import deviceslogo from "assets/icons/devices-menu.png";
import settingslogo from "assets/icons/settings-menu.png";
import useLayoutStyle from "../AdminLayoutStyle";
import "@zeiss/zui";
import Processing from "Components/Admin/Processing";

export function AdminLayout(props, { closeNav }) {
  const classes = useLayoutStyle();
  const { path, url } = useRouteMatch();
  const { reduxState, reduxActions } = props;
  const { layoutInfo } = reduxState;
  const { getKeyTypeInfo } = reduxActions;
  const [layInfoCalled, setlayInfoCalled] = useState(false);
  const [isSkillsetAvailable, setIsSkillsetAvailable] = useState(false);

  useEffect(() => {
    if (!layInfoCalled) {
      setlayInfoCalled(true);
      getKeyTypeInfo();
    }
    if (layoutInfo.fetched === "fulfilled") {
      if (layoutInfo.data.length > 0) {
        setIsSkillsetAvailable(true);
      } else {
        setIsSkillsetAvailable(false);
      }
    }
  }, [getKeyTypeInfo, layInfoCalled, layoutInfo]);
  const handleClose = () => {
    if (!props.isNavSwitchable) {
      props.closeNav();
    }
  };
  return layoutInfo.fetched === "pending" ||
    layoutInfo.fetched === undefined ||
    layoutInfo.fetched.length === 0 ? (
    <Processing progressText={true} />
  ) : (
    <div className={classes.layoutBody}>
      <BrowserRouter>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={props.navState}
          classes={{
            paper: classes.drawerPaper,
          }}
          style={{
            position: props.navState === true ? "fixed" : "relative",
            display: props.navState === true ? "block" : "none",
          }}
          // onClick={handleClose}
        >
          <List style={{ paddingTop: "0px" }}>
            <NavLink
              key={`deviceList`}
              to={`${path}/Devices`}
              className={classes.linkNoStyle}
              activeClassName={classes.linkActive}
              onClick={handleClose}>
              <ListItem className={`${classes.listbgcolor}`}>
                <span id="userProfileIcon" className={classes.adminMenuIcon}>
                  <img
                    height="16"
                    width="16"
                    src={deviceslogo}
                    alt="devices"
                    className={`${classes.zeissIconWidth}`}
                  />
                </span>
                <span className={`${classes.listItemHead}`}>{`Devices`}</span>
              </ListItem>
            </NavLink>
          </List>
          {isSkillsetAvailable ? (
            <Accordion
              className={classes.adminaccordianDrawer}
              classes={{
                expanded: classes.accordianExpanded,
              }}>
              <AccordionSummary
                classes={{
                  root: classes.accordianSummary,
                  expanded: classes.accordianExpanded,
                }}
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}>
                <ListItem className={classes.settingListbgcolor}>
                  <span className={`${classes.listItemHead}`}>
                    {"Type of Keys"}
                  </span>
                </ListItem>
              </AccordionSummary>
              <AccordionDetails
                classes={{
                  root: classes.accordianDetails,
                }}>
                <List
                  style={{
                    paddingTop: "0px",
                    width: "inherit",
                    // display: "inline-table !important",
                  }}>
                  {layoutInfo.data.map((dataList, Index) => (
                    <NavLink
                      key={dataList.id}
                      to={`${path}/${keyTypeWithPath[dataList.id]}`}
                      className={classes.linkNoStyle}
                      activeClassName={classes.linkActive}>
                      <ListItem
                        className={classes.listbgcolor}
                        onClick={handleClose}>
                        <span className={`${classes.listItemFont}`}>
                          {dataList.description}
                        </span>
                      </ListItem>
                    </NavLink>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ) : (
            ""
          )}
          <Accordion
            className={classes.accordianDrawer}
            classes={{
              expanded: classes.accordianExpanded,
            }}>
            <AccordionSummary
              classes={{
                root: classes.accordianSummary,
                expanded: classes.accordianExpanded,
              }}
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}>
              <ListItem className={classes.settingListbgcolor}>
                <span id="settingsIcon" className={classes.adminMenuIcon}>
                  <img
                    height="16"
                    width="16"
                    src={settingslogo}
                    alt="settings"
                    className={`${classes.zeissIconWidth}`}
                  />
                </span>
                <span className={`${classes.listItemHead}`}>{`Settings`}</span>
              </ListItem>
            </AccordionSummary>
            <AccordionDetails
              classes={{
                root: classes.accordianDetails,
              }}>
              <List style={{ paddingTop: "0px", width: "inherit" }}>
                <NavLink
                  key={`emailTemplateList`}
                  to={`${path}/Settings/EmailTemplate`}
                  className={classes.linkNoStyle}
                  activeClassName={classes.linkActive}>
                  <ListItem
                    className={classes.settingListItem}
                    onClick={handleClose}>
                    <span
                      className={`${classes.settingListItemFont}`}>{`Email Template`}</span>
                  </ListItem>
                </NavLink>
                <NavLink
                  key={`printTemplateList`}
                  to={`${path}/Settings/PrintTemplate`}
                  className={classes.linkNoStyle}
                  activeClassName={classes.linkActive}>
                  <ListItem
                    className={classes.settingListItem}
                    onClick={handleClose}>
                    <span
                      className={`${classes.settingListItemFont}`}>{`Print Template`}</span>
                  </ListItem>
                </NavLink>
              </List>
            </AccordionDetails>
          </Accordion>
        </Drawer>
        <Switch>
          {layoutInfo.data.map((dataList, index) =>
            dataList.id === 1 ? (
              <PrivateRoute
                key={`routePath` + index}
                exact
                path={url + "/FSEMasterKey"}
                component={FSEMasterKey}
              />
            ) : dataList.id === 2 ? (
              <PrivateRoute
                key={`routePath` + index}
                exact
                path={url + "/OnedayKey"}
                component={OnedayKey}
              />
            ) : dataList.id === 3 ? (
              <PrivateRoute
                key={`routePath` + index}
                exact
                path={url + "/AdminItKey"}
                component={AdminIT}
              />
            ) : dataList.id === 4 ? (
              <PrivateRoute
                key={`routePath` + index}
                exact
                path={url + "/Pentero"}
                component={Pantero}
              />
            ) : dataList.id === 5 ? (
              <PrivateRoute
                key={`routePath` + index}
                exact
                path={url + "/Biomed"}
                component={Biomed}
              />
            ) : dataList.id === 6 ? (
              <PrivateRoute
                key={`routePath` + index}
                exact
                path={url + "/Factory"}
                component={Factory}
              />
            ) : (
              ""
            )
          )}
          <PrivateRoute exact path={"/home/Devices"} component={Devices} />
          <PrivateRoute
            exact
            path={"/home/Devices/AddDevice"}
            component={AddDevice}
          />
          <PrivateRoute
            exact
            path={"/home/Devices/ViewDeviceDetails"}
            component={ViewDeviceDetails}
          />
          <PrivateRoute
            exact
            path={"/home/Devices/AddNewVersion"}
            component={AddNewVersion}
          />
          <PrivateRoute
            exact
            path={"/home/Devices/AddNewSkillsetKeyType"}
            component={AddNewSkillsetKeyType}
          />

          <PrivateRoute
            exact
            path={"/home/Devices/DisableVersion"}
            component={DisableVersion}
          />
          <PrivateRoute
            exact
            path={"/home/Devices/DisableSkillset"}
            component={DisableSkillset}
          />
          <PrivateRoute
            exact
            path={"/home/Devices/DisableKeytype"}
            component={DisableKeytype}
          />
          <PrivateRoute
            exact
            path={"/home/Settings/EmailTemplate"}
            component={EmailTemplate}
          />
          <PrivateRoute
            exact
            path={"/home/Settings/PrintTemplate"}
            component={PrintTemplate}
          />
          <PrivateRoute exact path={"/home/History"} component={History} />
          <PrivateRoute
            exact
            path={"/home/RaiseRequest"}
            component={RaiseRequest}
          />
          <Route path="*">
            <HomePage isAdmin={true} />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  reduxActions: bindActionCreators(
    {
      getKeyTypeInfo: actions.getKeyTypeInfo,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  reduxState: {
    layoutInfo: state.layoutInfo,
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
