import axios from "axios";
import {
  // getPrintoutTextUrl,
  // getSubscription,
  getBaseUrl,
} from "Utils/getBaseUrl";
import { DownloadRequestmapper } from "actions";
import * as ErrorMessage from "../../../../constants/errorMessages";

export const GetPrintText = (keysData, keyType) => (dispatch, getState) => {
  const state = getState();
  const token = state.userAccount.stateValue;
  let url = getBaseUrl();
  if (url === "http://localhost:3001") {
    url = "https://qa.fsekeygen.zeiss.com";
  }
  // const getPrintTextUrl = getPrintoutTextUrl() + "/GetPrintoutTemplateById";
  // const esbSubscriptionKey = getSubscription();
  const getPrintTextUrl = `${url}/fsekeygenservice/api/v1/PrintoutTemplate/PrintText/${keyType}`;
  let payload = DownloadRequestmapper(keysData);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": `application/json`,
      "Access-Control-Allow-Origin": "*",
      //"EsbApi-Subscription-Key": esbSubscriptionKey,
    },
  };
  return axios
    .create(config)
    .post(`${getPrintTextUrl}`, payload)
    .then((res) => {
      if (res.status === 200) {
        if (res.data) {
          return {
            status: true,
            statusCode: 200,
            data: res.data.data,
          };
        } else {
          return {
            status: false,
            statusCode: 200,
            message: "Unable to get the printout text details",
          };
        }
      } else if (res.status === 401) {
        return {
          status: false,
          statusCode: 401,
          message: ErrorMessage.SESSION_TIMEOUT_ERROR,
        };
      } else {
        return {
          status: false,
          statusCode: 400,
          message: ErrorMessage.BAD_REQUEST,
        };
      }
    })
    .catch((error) => {
      let status = error;
      if (error.response) {
        status = error.response.status;
        if (status === 401) {
          return {
            status: false,
            statusCode: 401,
            message: ErrorMessage.SESSION_TIMEOUT_ERROR,
          };
        } else {
          return {
            status: false,
            statusCode: status,
            message: "Unable to get the printout text details",
          };
        }
      } else if (error.request) {
        return {
          status: false,
          statusCode: 401,
          message: ErrorMessage.NETWORK_ERROR,
        };
      } else {
        return {
          status: false,
          statusCode: 401,
          message: ErrorMessage.NETWORK_ERROR,
        };
      }
    });
};
