import { makeStyles } from "@material-ui/core/styles";
// const deviceListContainerHeight = window.innerHeight < 640 ? "286px": "calc(100% - 275px)";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    flexGrow: 1,
    position: "fixed",
    background: "rgba(26, 35, 41,0.6)",
    bottom: 0,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: "10000",
  },
  progressRoot: {
    display: "inline-block",
  },
  progress: {
    position: "absolute",
    margin: "auto",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  progressText: {
    width: "100%",
    height: "30px",
    top: "110px",
    textAlign: "center",
    right: 0,
    bottom: 0,
    margin: "auto",
    position: "absolute",
    color: "#FFFFFF",
    textTransform: "capitalize",
    userSelect: "none",
    font: "var(--zui-typography-subtitle1)",
  },
  divContainer: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  paper: {
    position: "relative",
    textAlign: "center",
    borderRadius: "0px",
    color: "black",
    outline: "none",
    border: "2px solid #fff",
    boxShadow: "none !important",
    [theme.breakpoints.up("xs")]: {
      padding: "20px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "24px",
    },
  },
  headerText: {
    position: "relative",
    font: "var(--zui-typography-h2)",
    color: "#353D45",
    padding: "15px",
    textAlign: "start",
    userSelect: "none",
    [theme.breakpoints.up("xs")]: {
      fontSize: "16px",
      lineHeight: "24px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "24px",
      lineHeight: "36px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "20px",
    },
  },
  divSelectionContainer: {
    position: "relative",
    textAlign: "center",
    width: "100%",
    left: "0px",
    top: "0px",
    borderRadius: "0px",
    background: "#FFFFFF",
    minHeight: "60px",
    [theme.breakpoints.up("md")]: {
      minHeight: "80px",
      // marginBottom: "8px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "100px",
    },
  },
  displayInlineGrid: { display: "inline-grid" },
  displayFlex: {
    display: "inline-block",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  formLable: {
    // width: "100%",
    position: "relative",
    font: "var(--zui-typography-h2)",
    color: "#1A2329",
    textAlign: "center",
    userSelect: "none",
    minWidth: "calc(100% - 242px)",
    [theme.breakpoints.up("xs")]: {
      fontSize: "14px",
      lineHeight: "20px",
      margin: "16px 40px",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "auto",
      minWidth: "calc(100% - 400px)",
      fontSize: "18px",
      lineHeight: "28px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
      lineHeight: "36px",
      margin: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "24px",
      lineHeight: "36px",
      margin: "auto",
    },
  },
  formLableKey: {
    // width: "100%",
    position: "relative",
    font: "var(--zui-typography-h2)",
    color: "#1A2329",
    textAlign: "center",
    userSelect: "none",
    [theme.breakpoints.up("xs")]: {
      fontSize: "14px",
      lineHeight: "24px",
      margin: "16px 40px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
      lineHeight: "24px",
      margin: "auto",
    },
    [theme.breakpoints.up("md")]: {
      height: "auto",
      fontSize: "24px",
      lineHeight: "36px",
      margin: "auto",
    },
  },
  divSelectAll: {
    position: "relative",
    right: "0",
    cursor: "default",
    [theme.breakpoints.up("xs")]: {
      right: "1px",
      margin: "-5px 10px",
      position: "absolute",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "6px 16px",
      position: "absolute",
    },
    [theme.breakpoints.up("md")]: {
      margin: "16px 16px",
      position: "absolute",
    },
    [theme.breakpoints.up("lg")]: {
      position: "absolute",
      margin: "16px",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "26px",
      position: "absolute",
    },
  },
  btnSelectAll: {
    boxSizing: "border-box",
    borderRadius: "3px",
    background: "#555E6B",
    color: "#FFFFFF",
    border: "1px solid #262E36",
    height: "48px",
    font: "var(--zui-typography-subtitle1)",
    width: "130px",
    fontSize: "14px",
    lineHeight: "20px",
    [theme.breakpoints.up("xl")]: {
      width: "150px",
      height: "48px",
    },
  },
  divDeviceListContainer: {
    height: "100%",
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      paddingBottom: "150px",
    },
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "130px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "120px",
      paddingBottom: "20px",
      bottom: "0px",
    },
  },
  divDeviceListkeyContainer: {
    height: "auto",
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      paddingBottom: "80px",
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "20px",
    },
  },
  divSelectionViewContainer: {
    [theme.breakpoints.up("xs")]: {
      height: "100%",
    },
    [theme.breakpoints.up("md")]: {
      height: "calc(100% - 80px)",
    },
  },
  divSelectionViewHistory: {
    height: "calc(100% - 218px)",
    width: "100%",
    background: "#FFFF",
    position: "relative",
    [theme.breakpoints.up("xs")]: {
      margin: "0px",
    },
    [theme.breakpoints.up("md")]: {
      // width: "calc(100% - 16px)",
      padding: "0px 16px",
    },
  },
  deviceName: {
    fontFamily: "ZEISSFrutigerNextW1G-Bold, sans-serif",
    fontSize: "16px !important",
    lineHeight: "24px",
    userSelect: "none",
    fontWeight: "Bold",
    color: "#1A2329",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.up("xl")]: {
      fontSize: "24px !important",
      lineHeight: "36px",
    },
  },
  deviceNameTile: {
    fontFamily: "ZEISSFrutigerNextW1G-Reg, sans-serif",
    padding: "10px 0px 10px 0px",
    fontSize: "32px !important",
    lineHeight: "48px",
    textTransform: "uppercase",
    userSelect: "none",
    fontWeight: "300",
    color: "#404952",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.up("md")]: {
      paddingTop: "24px",
    },
  },
  deviceNameTileForKey: {
    fontFamily: "ZEISSFrutigerNextW1G-Reg, sans-serif",
    fontSize: "32px !important",
    padding: "10px 0px 10px 0px",
    lineHeight: "48px",
    textTransform: "uppercase",
    userSelect: "none",
    fontWeight: "300",
    color: "#404952",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.up("md")]: {
      paddingTop: "24px",
    },
  },
  deviceNameVersion: {
    font: "var(--zui-typography-h3)",
    fontSize: "20px",
    lineHeight: "28px",
    color: "#07090D",
    userSelect: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "500",
    },
  },
  penteroNameVersion: {
    font: "var(--zui-typography-h3)",
    fontSize: "14px !important",
    lineHeight: "20px",
    color: "#404952",
    userSelect: "none",
  },
  InfoConsent: {
    position: "relative",
    padding: "8px",
    textAlign: "center",
    font: "var(--zui-typography-h4)",
    color: "#626C7A",
    userSelect: "none",
    background: "#EDF2F7",
    [theme.breakpoints.up("xs")]: {
      fontSize: "14px",
      lineHeight: "20px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
  fixedFooter: {
    position: "relative",
    bottom: "0px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      position: "relative",
      bottom: "8px",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "0px",
      width: "100%",
    },
  },
  footerContainer: {
    position: "relative",
    // width: "calc(100% - 10px)",
    width: "100%",
    background: "white",
    textAlign: "Center",
    padding: "0px",
    boxShadow: "none !important",
    height: "80px",
    [theme.breakpoints.up("sm")]: {
      textAlign: "right",
    },
    [theme.breakpoints.up("xl")]: {
      height: "96px",
      zIndex: "100",
    },
  },
  buttonPrimary: {
    height: "48px",
    font: "var(--zui-typography-subtitle1)",
    fontSize: "14px",
    lineHeight: "20px",
    border: "none !important",
    borderRadius: "3px",
    cursor: "pointer",
    textTransform: "capitalize",
    margin: "16px 8px",
    [theme.breakpoints.up("sm")]: {
      margin: "16px 16px 16px 0px",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "24px 24px 24px 0px",
      height: "48px",
    },
  },
  button: {
    height: "48px",
    font: "var(--zui-typography-subtitle1)",
    fontSize: "14px",
    lineHeight: "20px",
    border: "none !important",
    borderRadius: "3px",
    cursor: "pointer",
    textTransform: "capitalize",
    margin: "16px 8px",
    [theme.breakpoints.up("sm")]: {
      margin: "16px 16px 16px 0px",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "24px 24px 24px 0px",
      height: "48px",
    },
  },
  btnAttach: {
    height: "32px",
    font: "var(--zui-typography-body)",
    color: "var(--zui-color-text-default)",
    fontSize: "14px",
    lineHeight: "20px",
    borderRadius: "3px",
    cursor: "pointer",
    textTransform: "capitalize",
    margin: "16px 8px",
    [theme.breakpoints.up("sm")]: {
      margin: "16px 16px 0px 0px",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "24px 24px 0px 0px",
      height: "48px",
    },
  },
  buttonHistory: {
    height: "48px",
    margin: "8px",
    font: "var(--zui-typography-subtitle1)",
    fontSize: "14px",
    lineHeight: "20px",
    border: "none !important",
    borderRadius: "3px",
    cursor: "pointer",
    textTransform: "capitalize",
    [theme.breakpoints.up("xl")]: {
      margin: "16px",
      height: "48px",
    },
  },
  btnSubmit: {
    backgroundColor: "#0050F2 !important",
    border: "1px solid #0029AF",
    color: "#fff !important",
    width: "140px",
    [theme.breakpoints.up("xl")]: {
      width: "150px",
    },
  },
  gridPaddingXl: {
    // margin: "8px !important",
    [theme.breakpoints.up("xs")]: {
      padding: "8px 16px !important",
    },
    [theme.breakpoints.up("md")]: {
      padding: "10px !important",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "16px !important",
    },
  },
  gridPaddingX2: {
    [theme.breakpoints.up("xs")]: {
      padding: "12px 24px !important",
    },
    [theme.breakpoints.up("md")]: {
      padding: "12px !important",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "16px !important",
    },
  },
  linkActive: {
    borderColor: "blue !important",
  },
  btnClearDisabled: {
    cursor: "not-allowed",
  },
  btnDisabled: {
    background: "#0050f266 !important",
    border: "1px solid #0029AF",
    color: "#fff !important",
    width: "140px",
    cursor: "not-allowed",
    [theme.breakpoints.up("xl")]: {
      width: "150px",
    },
  },
  deviceInput: {
    width: "70%",
    marginLeft: "8px",
    border: "none",
    borderRadius: "0px",
    borderBottom: "1px solid #828D9E",
    // color: "#1A2329 !important",
    background: "transparent",
    // outline: "none",
    lineHeight: "23px",
    zIndex: "1000",
    padding: "0px 2px",
    [theme.breakpoints.up("xs")]: {
      marginLeft: "2px",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "0px",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "8px",
    },
  },
  crossClear: {
    position: "absolute",
    right: "16px",
    margin: "5px 0px",
    cursor: "pointer",
    display: "inline-block",
  },
  errorInput: {
    width: "70%",
    marginLeft: "8px",
    border: "none",
    borderRadius: "0px",
    borderBottom: "1px solid #ED5353",
    color: "#ED5353",
    // outline: "none",
    lineHeight: "20px",
    zIndex: "1000",
    // [theme.breakpoints.up("md")]: {
    //   marginLeft: "15px",
    // },
  },
  displayIccodeLabel: {
    float: "left",
    width: "15%",
    font: "var(--zui-typography-body)",
    borderBottom: "1px solid #828D9E",
    userSelect: "none",
    cursor: "pointer",
    color: "#828D9E",
    [theme.breakpoints.up("md")]: {
      padding: "0px",
    },
  },
  displayInputField: {
    font: "var(--zui-typography-body)",
    maxHeight: "60px",
    overflow: "hidden",
    fontSize: "14px",
    lineHeight: "20px",
    lineBreak: "anywhere",
    // padding: "0px 8px",
    [theme.breakpoints.up("xs")]: {
      padding: "0px 2px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0px 8px",
    },
  },
  errorSpan: {
    position: "absolute",
    display: "block",
    font: "var(--zui-typography-caption)",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#ED5353",
    left: "25%",
    padding: "0px 16px 0px 8px",
    textAlign: "left",
    [theme.breakpoints.up("lg")]: {
      padding: "0px 20px 0px 8px",
    },
  },
  divLablePadding: {
    display: "block",
    padding: "16px",
    cursor: "pointer",
    position: "relative",
    minWidth: "85%",
    left: "0px",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "calc(100% - 146px)",
      padding: "24px 0px",
    },
  },
  highlight: {
    border: "2px solid #008bd0 !important",
  },
  penterodivKeyListContainer: {
    // height: "100%",
    // margin: "10px 0px",
    [theme.breakpoints.up("md")]: {
      height: "calc(100% - 146px)",
      // margin: "25px 0px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "calc(100% - 182px)",
      // margin: "25px 0px",
    },
  },
  displayPenteroKeyDiv: {
    // display: "block",
    // maxWidth: "100%",
    // maxHeight: "calc(100% - 10px)",
    // height: "100%",
    // backgroundColor: "white",
    textAlign: "center",
    // color: "#353D45",
  },
  displayPenteroKeysCls: {
    minHeight: "60px",
    overflowY: "auto",
    // color: "#29C192",
    lineBreak: "anywhere",
    fontSize: "16px !important",
    lineHeight: "24px",
    padding: "6px",
    [theme.breakpoints.up("md")]: {
      padding: "30px 50px 30px 50px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "24px !important",
      lineHeight: "36px",
    },
  },
  divKeyContainter: {
    margin: "8px",
    background: "#FFFFFF",
    [theme.breakpoints.up("md")]: {
      margin: "0px",
      display: "flex",
      // margin: "20px 0px",
    },
  },
  gridTile: {
    width: "100%",
    minHeight: "200px",
    position: "relative",
    padding: "0px !important",
    boxSizing: "content-box",
  },
  gridTileForKey: {
    width: "100%",
    position: "relative",
    padding: "0px !important",
    minHeight: "220px",
    minWidth: "248px",
    boxSizing: "content-box",
    [theme.breakpoints.up("lg")]: {
      minWidth: "248px",
      minHeight: "220px",
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: "260px",
      minHeight: "200px",
    },
  },
  gridTileForKeyHistory: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    background: "white",
    margin: "8px 10px",
    padding: "0px !important",
  },
  gridOneDayTile: {
    minWidth: "248px",
    minHeight: "160px",
    [theme.breakpoints.up("md")]: {
      minWidth: "248px",
      height: "220px",
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "248px",
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: "260px",
      minHeight: "200px",
    },
  },
  gridAdminTile: {
    minWidth: "230px",
    minHeight: "120px",
    [theme.breakpoints.up("lg")]: {
      minWidth: "248px",
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: "260px",
      minHeight: "240px",
    },
  },
  gridPenteroTile: {
    minWidth: "230px",
    minHeight: "120px",
    [theme.breakpoints.up("lg")]: {
      minWidth: "248px",
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: "260px",
      minHeight: "240px",
    },
  },
  displayOneDaykey: {
    font: "var(--zui-typography-h4)",
    display: "grid",
    minHeight: "60px",
    overflow: "hidden",
    fontSize: "16px !important",
    lineHeight: "24px",
    color: "#29C192",
    lineBreak: "anywhere",
    margin: "16px",
    [theme.breakpoints.up("md")]: {
      margin: "20px",
    },
  },
  displayAdminITkey: {
    font: "var(--zui-typography-h4)",
    display: "grid",
    maxHeight: "60px",
    overflow: "hidden",
    fontSize: "16px !important",
    lineHeight: "24px",
    color: "#29C192",
    lineBreak: "anywhere",
    padding: "8px",
    [theme.breakpoints.up("md")]: {
      padding: "16px",
    },
  },
  ITAdminDeviceTextFormat: {
    fontFamily: "ZEISSFrutigerNextW1G-Bold, sans-serif",
    padding: "40px 0px 25px 0px !important",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0px 13px 0px !important",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "70px 0px 13px 0px !important",
    },
  },
  deviceNameSN: {
    font: "var(--zui-typography-subtitle1)",
    fontSize: "14px !important",
    lineHeight: "20px",
    color: "#404952",
    fontWeight: "600",
  },
  displayNone: {
    display: "none",
  },
  penteroTable: {
    width: "100%",
    display: "table",
    borderSpacing: "0",
    borderCollapse: "collapse",
  },
  penteroTbody: {
    display: "table-row-group",
  },
  onlyForPenteroText: {
    font: "var(--zui-typography-body)",
    textAlign: "center",
    fontSize: "18px",
    lineHeight: "24px",
    padding: "20px",
    color: "#161D22",
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      fontSize: "24px",
      lineHeight: "36px",
      paddingBottom: "20px",
      paddingTop: "20px",
    },
  },
  PenteroDeviceName: {
    font: "var(--zui-typography-h3)",
    fontSize: "32px !important",
    lineHeight: "48px",
    textTransform: "uppercase",
    userSelect: "none",
    fontWeight: "300",
    color: "#404952",
    paddingTop: "18px",
    background: "#FFFFFF",
    [theme.breakpoints.up("md")]: {
      paddingTop: "32px",
    },
  },
  PenteroDeviceSerialNum: {
    font: "var(--zui-typography-subtitle1)",
    fontSize: "14px !important",
    lineHeight: "20px",
    color: "#07090D",
    userSelect: "none",
    background: "#FFFFFF",
    padding: "10px",
  },
  displayPenteroKeyIdentifier: {
    border: "1px solid rgba(224, 224, 224, 1)",
    width: "40%",
    font: "var(--zui-typography-h2)",
    fontSize: "24px !important",
    lineHeight: "36px !important",
    padding: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  displayPenteroKeyValue: {
    width: "60%",
    border: "1px solid rgba(224, 224, 224, 1)",
    color: "#29C192 !important",
    font: "var(--zui-typography-h4)",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    padding: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  penteroKeysTables: {
    borderSpacing: 0,
    width: "100%",
    textAlign: "left",
    color: "#404952",
  },

  divHistoryContainer: {
    display: "flex",
    position: "relative",
    width: "100%",
    minHeight: "80px",
    background: "#FFFF",
    userSelect: "none",
  },
  divHistoryFiltersContainer: {
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      display: "inline-block;",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      // flexWrap: "wrap",
    },
    [theme.breakpoints.up("md")]: {
      margin: "8px",
      flexWrap: "nowrap",
    },
  },
  divFilterContainer: {
    width: "70%",
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      display: "grid",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "70%",
    },
  },
  divFilterBtnContainer: {
    width: "30%",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
    [theme.breakpoints.up("md")]: {
      width: "40%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "30%",
    },
  },
  tableTextSpan: {
    width: "100%",
    display: "inline-block",
    margin: "4px",
  },
  filterGrid: {
    display: "inline-flex",
    height: "100%",
    // width: "160px",
    [theme.breakpoints.up("md")]: {
      width: "auto",
    },
  },
  formLableHistory: {
    width: "100%",
    position: "relative",
    padding: "0px 8px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 16px",
    },
  },
  formLableHistoryText: {
    width: "138px",
    font: "var(--zui-typography-subtitle1)",
    textAlign: "left",
    margin: "8px 0px;",
    color: "#626C7A",
    // fontSize: "14px",
    // fontWeight: "bold",
    // lineHeight: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  formLableSelectDiv: {
    width: "138px",
    font: "var(--zui-typography-subtitle1)",
    textAlign: "left",
    margin: "8px 0px",
    color: "#626C7A",
    border: "none",
    borderBottom: "1px solid #C1CBD9",
    // fontSize: "14px",
    // fontWeight: "bold",
    // lineHeight: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  formLableHistoryButton: {
    textAlign: "right",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#626C7A",
    lineHeight: "20px",
  },
  divDatePickerContainer: {
    display: "flex",
    position: "relative",
    width: "100%",
  },
  formLableDatePicker: {
    width: "50%",
  },
  formLableHistoryFilter: {
    background: "#FFFF",
    color: "#828D9E",
    outline: "transparent",
    border: "none",
    // borderBottom: "1px solid #C1CBD9",
    padding: "5px 0px",
    width: "138px",
    minHeight: "32px",
    font: "var(--zui-typography-body)",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "138px",
    },
  },
  formLableHistoryButtons: {
    position: "relative",
    width: "100%",
    font: "var(--zui-typography-subtitle1)",
    color: "#1A2329",
    fontSize: "24px",
    lineHeight: "36px",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      textAlign: "end",
    },
  },
  userKeysHistoryTableDiv: {
    height: "calc(100% - 158px)",
    width: "100%",
    display: "inline-block",
    // margin: "8px",
    [theme.breakpoints.up("xs")]: {
      height: "auto",
      paddingBottom: "70px;",
    },
    [theme.breakpoints.up("md")]: {
      // width: "calc(100% - 16px)",
      background: "#FFFF",
    },
  },
  validKeyText: {
    backgroundColor: "#D4F3E9",
    font: "var(--zui-typography-label2)",
    color: "#29C192",
    padding: "4px",
    fontSize: "10px",
    lineHeight: "20px",
    fontWeight: "bold",
    width: "80px",
    textAlign: "center",
  },
  minDownloadHeight: {
    [theme.breakpoints.up("md")]: {
      width: "120px",
    },
  },
  expiredKeyText: {
    backgroundColor: "#FBDDDD",
    color: "#ED5353",
    padding: "4px",
    fontSize: "10px",
    lineHeight: "20px",
    fontWeight: "bold",
    width: "80px",
    textAlign: "center",
  },
  futureValidKeyText: {
    backgroundColor: "#CCEDFE",
    display: "inline-flex",
    color: "#00A6FB",
    padding: "4px",
    fontSize: "10px",
    lineHeight: "20px",
    fontWeight: "bold",
    minWidth: "80px",
    textAlign: "center",
  },
  divPaginationContainer: {
    display: "block",
    position: "fixed",
    width: "100%",
    height: "60px",
    bottom: "8px",
    background: "#FFFF",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      bottom: "0px",
      position: "fixed",
    },
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 184px)",
    },
  },
  divPaginationCenterAlign: {
    margin: "auto",
    width: "fit-content",
    padding: "18px 0px",
    // [theme.breakpoints.up("md")]: {
    //   width: "35%",
    // },
  },
  historySectionDesktop: {
    display: "none",
    position: "absolute",
    userSelect: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
      position: "relative",
      left: "0px",
    },
  },
  historySectionMobile: {
    display: "block",
    // float: "right",
    background: "#EDF2F7",
    position: "relative",
    height: "100%",
    flexWrap: "wrap",
    userSelect: "none",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  historyBtnReset: {
    height: "48px",
    margin: "8px",
    // font: "var(--zui-typography-subtitle1)",
    // fontSize: "14px",
    // lineHeight: "20px",
    // border: "1px solid #A5B1C2",
    // borderRadius: "3px",
    // cursor: "pointer",
    // backgroundColor: "#C1CBD9",
    // width: "120px",
    // color: "#353D45",
    // fontWeight: "bold",
    [theme.breakpoints.up("xl")]: {
      margin: "16px",
      height: "48px",
    },
  },
  muiGridNoSpacing: {
    width: "auto !important",
    margin: "8px",
    [theme.breakpoints.up("sm")]: {
      margin: "0px 0px 8px 0px !important",
    },
    [theme.breakpoints.up("md")]: {
      margin: "0px 0px 8px 0px !important",
    },
  },
  cursorDefault: {
    cursor: "default",
  },
  cursorMenu: {
    cursor: "context-menu",
  },
  cursorDisabled: {
    cursor: "not-allowed",
  },
  factoryDeviceInput: {
    width: "100%",
    border: "none",
    minWidth: "220px",
    borderBottom: "1px solid #828D9E",
    outline: "none",
    lineHeight: "24px",
    zIndex: "9",
    paddingBottom: "0 !important",
    paddingRight: "0 !important",
    // display: "block",
    // position: "absolute",
    // transform: "translate(-50%, -50%)",
  },
  factoryErrorInput: {
    width: "100%",
    border: "none",
    borderBottom: "1px solid #ED5353 !important",
    color: "#ED5353",
    outline: "none",
    lineHeight: "24px",
    zIndex: "1000",
    paddingBottom: "0 !important",
    paddingRight: "0 !important",
  },
  factoryErrorSpan: {
    position: "absolute",
    display: "block",
    font: "var(--zui-typography-caption)",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#ED5353",
    padding: "8px 0px 0px 0px",
    textAlign: "left",
    [theme.breakpoints.up("md")]: {
      padding: "0px 14px 0px 0px",
    },
  },
  adminDeviceInput: {
    width: "100%",
    border: "none",
    borderBottom: "1px solid #828D9E",
    outline: "none",
    lineHeight: "24px",
    paddingBottom: "0 !important",
    paddingRight: "0 !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  adminErrorInput: {
    width: "100%",
    border: "none",
    borderBottom: "1px solid #ED5353 !important",
    //color: "#ED5353",
    outline: "none",
    lineHeight: "24px",
    paddingBottom: "0 !important",
    paddingRight: "0 !important",
  },
  textareaInput: {
    width: "100%",
    border: "none",
    borderBottom: "1px solid #828D9E",
    outline: "none",
    lineHeight: "24px",
  },
  selectInput: {
    width: "100%",
    border: "none",
    outline: "none",
    lineHeight: "24px",
  },
  textareaErrorInput: {
    width: "100%",
    border: "none",
    borderBottom: "2px solid #ED5353 !important",
    outline: "none",
    paddingBottom: "0 !important",
    paddingRight: "0 !important",
  },
  textareaErrorSpan: {
    position: "relative",
    width: "100%",
    display: "flex",
    alignItems: "center",
    font: "var(--zui-typography-caption)",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#ED5353",
  },
  adminErrorSpan: {
    width: "100%",
    display: "block",
    font: "var(--zui-typography-caption)",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#ED5353",
    textAlign: "left",
  },

  // device list css start
  AdminHeaderText: {
    position: "relative",
    font: "var(--zui-typography-h4)",
    color: "#1A2329",
    textAlign: "start",
    userSelect: "none",
    margin: "auto 16px",
  },
  divHeaderContainer: {
    position: "relative",
    textAlign: "center",
    width: "100%",
    left: "0px",
    top: "0px",
    borderRadius: "0px",
    background: "#FFFFFF",
    height: "60px",
  },
  divAdminDeviceListContainer: {
    height: "100%",
    width: "100%",
    bottom: "0px",
    background: "#FFFFFF",
    padding: "0px 8px",
  },
  divDeviceListTableContainer: {
    height: "calc(100% - 100px)",
    width: "100%",
    display: "inline-block",
    paddingBottom: "0px;",
    marginBottom: "0px",
  },
  // device list  css end

  //view device by id css
  btnDeviceActive: {
    width: "94px",
    height: "32px",
    backgroundColor: "#D1D9E5",
    cursor: "pointer",
    margin: "0px 8px",
    font: "var(--zui-typography-subtitle1)",
    background: "#D1D9E5",
    border: "1px solid #A5B1C2",
    boxSizing: "border-box",
    borderRadius: "3px",
  },
  // view device by id css end
  fadeDisplay: {
    opacity: "0.5",
  },
  hideButton: {
    visibility: "hidden",
  },
  deviceHeading: {
    margin: "0px 8px 0px 8px",
    width: "98.5%",
    background: "#f5f7fa",
    display: "flex",
    padding: "15px 0 15px 24px",
    font: "var(--zui-typography-subtitle2)",
    fontSize: "12px !important",
    lineHeight: "16px !important",
    color: "#555e6b",
  },
  mainBodyContainer: {
    width: "100%",
    fontFamily: "Segoe UI",
  },
}));

export default useStyles;
