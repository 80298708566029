/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getBaseUrl,
  getZeissIDAuthBaseUrl,
  getZeissIDClientId,
} from "../../Utils/getBaseUrl";
import { setUserStateValue } from "actions/index";
import { Redirect } from "react-router";
import { useState } from "react";
import "@zeiss/zui";
import Processing from "Components/Admin/Processing";

function Login({ userAccount }) {
  const [redirectToLogin, setRedirect] = useState(false);
  if (userAccount) {
    useEffect(() => {
      let path = "";
      const baseUrl = `${getBaseUrl()}/authenticate`;
      const ZeissIdAuthUrl = getZeissIDAuthBaseUrl();
      const ZeissIdClientId = getZeissIDClientId();
      if (window.location.hostname.includes("localhost")) {
        path = `${ZeissIdAuthUrl}/oauth2/v2.0/authorize?p=B2C_1A_ZeissIdNormalSignIn&client_id=${ZeissIdClientId}&redirect_uri=${baseUrl}&response_type=id_token&scope=openid%20profile`;
      } else if (window.location.hostname.includes("dev.fsekeygen.zeiss.com")) {
        path = `${ZeissIdAuthUrl}/oauth2/v2.0/authorize?p=B2C_1A_ZeissIdNormalSignIn&client_id=${ZeissIdClientId}&redirect_uri=${baseUrl}&response_type=id_token&scope=openid%20profile`;
      } else if (
        window.location.hostname.includes("demo.fsekeygen.zeiss.com")
      ) {
        path = `${ZeissIdAuthUrl}/oauth2/v2.0/authorize?p=B2C_1A_ZeissIdNormalSignIn&client_id=${ZeissIdClientId}&redirect_uri=${baseUrl}&response_type=id_token&scope=openid%20profile`;
      } else if (window.location.hostname.includes("qa.fsekeygen.zeiss.com")) {
        path = `${ZeissIdAuthUrl}/oauth2/v2.0/authorize?p=B2C_1A_ZeissIdNormalSignIn&client_id=${ZeissIdClientId}&redirect_uri=${baseUrl}&response_type=id_token&scope=openid%20profile`;
      } else if (
        window.location.hostname.includes("stage-czm-servicekey.zeiss.com.cn")
      ) {
        path = `${ZeissIdAuthUrl}/oauth2/v2.0/authorize?p=B2C_1A_ZeissIdNormalSignIn&client_id=${ZeissIdClientId}&redirect_uri=${baseUrl}&response_type=id_token&scope=openid%20profile`;
      } else {
        path = `${ZeissIdAuthUrl}/oauth2/v2.0/authorize?p=B2C_1A_ZeissIdNormalSignIn&client_id=${ZeissIdClientId}&redirect_uri=${baseUrl}&response_type=id_token&scope=openid%20profile`;
      }
      window.location.href = path;
    }, []);
  } else {
    setRedirect(true);
  }
  return !redirectToLogin ? <Processing /> : <Redirect to="/" />;
}
export const mapDispatchToProps = (dispatch) => ({
	setUserStateValue: (stateValue) => dispatch(setUserStateValue(stateValue)),
});

export const mapStateToProps = (state) => ({
	userAccount: state.userAccount,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
