import axios from "axios";
import * as ErrorMessage from "../../constants/errorMessages";
import * as ActionTypes from "../../constants/actionTypes";
import {
  // getAllRequestTypeUrl,
  // getSubscription,
  getBaseUrl,
  getXApiKey,
} from "../../Utils/getBaseUrl";

import { failedTemplates } from "./../Admin/EmailTemplate/GetEmailTemplates/GetEmailTemplates";

const fulfilledTemplates = (data) => ({
  type: ActionTypes.GET_REQUEST_LIST_ITEMS,
  payload: data,
});

export const GetAllRequestType = () => (dispatch) => {
  let url = getBaseUrl();
  if (url === "http://localhost:3001") {
    url = "https://qa.fsekeygen.zeiss.com";
  }
  // const getAllRequestTypeList = getAllRequestTypeUrl();
  const getAllRequestTypeList = `${url}/fsekeygenservice/api/v1/RequestType/GetAllRequestType`;
  const xapikey = getXApiKey();
  // const esbSubscriptionKey = getSubscription();
  const config = {
    headers: {
      "Content-Type": `application/json`,
      "Access-Control-Allow-Origin": "*",
      XApiKey: `${xapikey}`,
      // "EsbApi-Subscription-Key": esbSubscriptionKey,
    },
  };
  const errorMessage = {
    statusCode: "",
    message: "",
  };
  return axios
    .create(config)
    .get(`${getAllRequestTypeList}`)
    .then((res) => {
      errorMessage.statusCode = res.status;
      if (res.status === 200) {
        let data = res.data.data;
        dispatch(fulfilledTemplates(data));
        return true;
      } else if (res.status === 400) {
        errorMessage.message = ErrorMessage.UNABLE_TO_PROCESS_ERROR;
        dispatch(failedTemplates(errorMessage));
        return false;
      } else if (res.status === 401) {
        errorMessage.message = ErrorMessage.SESSION_TIMEOUT_ERROR;
        dispatch(failedTemplates(errorMessage));
        return false;
      } else if (res.status === 404) {
        errorMessage.message = ErrorMessage.URL_NOTFOUND_ERROR;
        dispatch(failedTemplates(errorMessage));
        return false;
      } else if (res.status === 500) {
        errorMessage.message = ErrorMessage.INTERNAL_SERVER_ERROR;
        dispatch(failedTemplates(errorMessage));
        return false;
      } else {
        dispatch(failedTemplates(errorMessage));
        return false;
      }
    })
    .catch((error) => {
      let status = error;
      if (error.response) {
        status = error.response.status;
        errorMessage.statusCode = error.response.status;

        if (status === 400) {
          errorMessage.message = ErrorMessage.UNABLE_TO_PROCESS_ERROR;
          dispatch(failedTemplates(errorMessage));
          return false;
        } else if (status === 401) {
          errorMessage.message = ErrorMessage.SESSION_TIMEOUT_ERROR;
          dispatch(failedTemplates(errorMessage));
          return false;
        } else if (status === 404) {
          errorMessage.message = ErrorMessage.URL_NOTFOUND_ERROR;
          dispatch(failedTemplates(errorMessage));
          return false;
        } else if (status === 500) {
          errorMessage.message = ErrorMessage.INTERNAL_SERVER_ERROR;
          dispatch(failedTemplates(errorMessage));
          return false;
        } else {
          errorMessage.message = ErrorMessage.SOMETHING_WENT_WRONG;
          dispatch(failedTemplates(errorMessage));
          return false;
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        if (error.request.status === 0) {
          errorMessage.statusCode = 401;
          errorMessage.message = ErrorMessage.NETWORK_ERROR;
        } else {
          errorMessage.message = error.message;
        }
        dispatch(failedTemplates(errorMessage));
        return false;
      } else {
        // Something happened in setting up the request that triggered an Error
        dispatch(failedTemplates(errorMessage));
        return false;
      }
    });
};
